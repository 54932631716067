import {Component, ElementRef, Input, Renderer2, ViewChild} from '@angular/core';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {GlobalsService} from '../../globals.service';
import {bool} from 'aws-sdk/clients/signer';

@Component({
  templateUrl: 'awaken-modal.component.html',
  selector: 'awaken-modal',
  styleUrls: ["./awaken-modal.component.scss"]
})

export class AwakenModal {
  @Input() formattedBody: string;
  title: any;
  subtitle: any;
  type:any;
  autoclose:boolean = false;
  allow_close: boolean = true;
  urgent:boolean = false;
  model:any;
  backgroundDismiss: boolean = true;
  values: {fill?: any; color?: any, attribute?: any, status?: any, name?: any, val?: any}[] = [];
  model_name: string;
  image:any;
  confirmationButtonAffirmative: string;
  confirmationButtonNegative: string;
  questions: {label: any, format: any, attribute: any, type: any, choices: {options: any[], value: any, display: any} }[];
  auth_token:any;
  @ViewChild('svg_image', {static: true}) svg: ElementRef;

  constructor(private params: NavParams,
              private alertCtrl: AlertController,
              private globals: GlobalsService,
              private modalCtrl: ModalController,
              private r2: Renderer2 ) {

    this.type = params.get('type')
    this.title = params.get('title')
    this.subtitle = params.get('subtitle')
    this.autoclose = params.get('autoclose')
    this.allow_close = params.get("allow_close")
    this.allow_close = params.get("confirmationButtonAffirmative")
    this.allow_close = params.get("confirmationButtonNegative")
    this.image = params.get('image')
    this.urgent = params.get('urgent')
    this.values = params.get('values')
    this.auth_token = params.get('auth_token')
    this.questions = params.get('questions')
    this.model = params.get('model')
    this.model_name = params.get('model_name')
    this.backgroundDismiss = params.get("backgroundDismiss") || true

    if ( this.allow_close == null ) { this.allow_close = true }

    this.image = images[this.image] || images['alert']
    let el = document.querySelector(".modal-wrapper")
  }

  ionViewDidEnter() {
    if ( this.autoclose == true ) {
      setTimeout(() => {
        this.dismiss()
      }, 3000)
    }
  }

  dismiss(response = undefined) {
    this.modalCtrl.dismiss(response)
  }
}

var images = {
  'piggy': `
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="business-color_pig" fill-rule="nonzero">
        <path d="M41,30.1123 C40.44775,30.1123 40,29.66503 40,29.1123 C40,28.55957 40.44775,28.1123 41,28.1123 C42.36865,28.1123 44,27.59179 44,25.1123 C44,23.4746 42.98242,23.13867 42.77832,23.08691 C42.25,22.95605 41.91357,22.42285 42.03516,21.8916 C42.15625,21.36035 42.66846,21.01855 43.20411,21.13379 C44.17139,21.33496 46,22.42578 46,25.1123 C46,28.14941 44.0376,30.1123 41,30.1123 Z" id="Shape" fill="#D39B75"></path>
        <path d="M29,16 L17.64111,16 C15.28173,11.25098 10.79297,11.31836 8.6709,12.05566 C8.1543,12.23535 7.87842,12.79687 8.05127,13.3164 L9.70703,18.28417 C7.61182,19.71094 5.97559,21.70312 5.01221,24 L1,24 C0.44775,24 0,24.44727 0,25 L0,35 C0,35.55273 0.44775,36 1,36 L6.0874,36 C7.52539,38.23633 9.5918,39.97852 12,40.98828 L12,45 C12,45.55273 12.44775,46 13,46 L19,46 C19.55225,46 20,45.55273 20,45 L20,42 L26,42 L26,45 C26,45.55273 26.44775,46 27,46 L33,46 C33.55225,46 34,45.55273 34,45 L34,40.98828 C38.81348,38.98047 42,34.25 42,29 C42,21.83203 36.16846,16 29,16 Z" id="Shape" fill="#EEBC99"></path>
        <path d="M29,22 L21,22 C20.44775,22 20,21.55273 20,21 C20,20.44727 20.44775,20 21,20 L29,20 C29.55225,20 30,20.44727 30,21 C30,21.55273 29.55225,22 29,22 Z" id="Shape" fill="#D39B75"></path>
        <circle  id="my-circle" fill="#EFD358" cx="25" cy="5" r="5"></circle>
          <animate
            xlink:href="#my-circle"
            attributeName="cy"
            from="6"
            to=30
            dur="0.5s"
            begin="0s"
            fill="freeze"
          />
        <path d="M13,27 C11.89697,27 11,26.10254 11,25 C11,23.89746 11.89697,23 13,23 C14.10303,23 15,23.89746 15,25 C15,26.10254 14.10303,27 13,27 Z M13,25.00098 L13.01025,25.00098 L13,25.00098 Z M13,25.00098 L13.01025,25.00098 L13,25.00098 Z M13,25.00098 L13.01025,25.00098 L13,25.00098 Z M13,25.00098 L13.01025,25.00098 L13,25.00098 Z M13,25.00098 L13.01025,25.00098 L13,25.00098 Z M13,25 L13.01025,25 L13,25 Z M13,25 L13.00977,25 L13,25 Z M13,25 L13.00977,25 L13,25 Z" id="Shape" fill="#444444"></path>
        <path d="M30.6,38 L19.4,38 C18.62685,38 18,34.42184 18,30 C18,25.57816 18.62685,22 19.4,22 L30.6,22 C31.37315,22 32,25.57816 32,30 C32,34.42184 31.37315,38 30.6,38 Z" id="Shape" fill="#EEBC99"></path>
      </g>
    </g>
  `,
  'new_user': `

    <svg width="64px" height="65px" viewBox="0 0 64 65" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 46.2 (44496) - http://www.bohemiancoding.com/sketch -->
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="iPad-Portrait" transform="translate(-747.000000, -777.000000)">
                <g id="man-add" transform="translate(747.000000, 777.000000)">
                    <rect id="Rectangle-path" fill="#DAC2A7" x="22" y="32" width="17" height="21"></rect>
                    <path d="M46,15 L46,25.9473684 C46,34.2468428 39.0461371,41 30.5,41 C21.9538629,41 15,34.2468428 15,25.9473684 L15,15 L46,15 Z" id="Shape" fill="#EAD8C5"></path>
                    <path d="M61,59.5839882 C61,57.3284651 59.2962542,54.7703256 57.1868072,53.8668925 L38.8181818,46 C36.4780004,47.6964286 33.6137731,48.7142857 30.5,48.7142857 C27.3862269,48.7142857 24.5219996,47.6964286 22.2067722,46 L3.81747982,53.8668925 C1.70914375,54.7688345 0,57.3316955 0,59.5839882 L0,63.6388813 C0,64.3906049 0.623607991,65 1.38868164,65 L59.6113216,65 C60.3782719,65 61,64.4057322 61,63.6388811 L61,59.5839882 Z" id="Shape" fill="#444444"></path>
                    <path d="M15,15.0035619 C15,10.3685967 18.5724538,5.34449635 22.9669722,3.78628545 L33.4022778,0.0861368925 C34.1340597,-0.173332811 35.007794,0.173332952 35.3527107,0.858232685 L37.5454545,5.21223718 C42.2147722,5.21223718 46,8.97973474 46,13.6061186 L46,22 C46,18.1368263 42.8456363,15.0050988 38.9545455,15.0050988 L22.0454545,15.0050988 C18.1543624,15.0050988 15,18.1368263 15,22 L15,15.0035619 Z" id="Shape" fill="#C6A279"></path>
                    <circle id="Oval" fill="#72C472" fill-rule="nonzero" cx="53" cy="50" r="11"></circle>
                    <path d="M56.625,48.125 L53.875,48.125 L53.875,45.375 C53.875,44.6155777 53.2594223,44 52.5,44 C51.7405777,44 51.125,44.6155777 51.125,45.375 L51.125,48.125 L48.375,48.125 C47.6155777,48.125 47,48.7405777 47,49.5 C47,50.2593384 47.6155777,50.875 48.375,50.875 L51.125,50.875 L51.125,53.625 C51.125,54.3843384 51.7405777,55 52.5,55 C53.2594223,55 53.875,54.3843384 53.875,53.625 L53.875,50.875 L56.625,50.875 C57.3844223,50.875 58,50.2593384 58,49.5 C58,48.7405777 57.3844223,48.125 56.625,48.125 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                </g>
            </g>
        </g>
    </svg>
  `,
  'standard': `

    <svg width="42px" height="45px" viewBox="0 0 42 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="animated tada">
        <!-- Generator: Sketch 43.2 (39069) - http://www.bohemiancoding.com/sketch -->
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="bell-55" fill-rule="nonzero">
                <path d="M15.0238,38.76404 C15.02063,38.84412 15,38.91919 15,39 C15,42.31372 17.68628,45 21,45 C24.31372,45 27,42.31372 27,39 C27,38.91919 26.97937,38.84412 26.9762,38.76404 L15.0238,38.76404 Z" id="Shape" fill="#A58C21"></path>
                <path d="M21,40 C8.24316,40 0,37.25195 0,33 C0,30.99805 1.27588,29.59863 2.62646,28.11816 C4.28516,26.2998 6,24.41895 6,21 L6,15 C6,6.58887 12.58887,0 21,0 C29.41113,0 36,6.58887 36,15 L36,21 C36,24.41895 37.71484,26.2998 39.37354,28.11816 C40.72412,29.59863 42,30.99805 42,33 C42,37.25195 33.75684,40 21,40 Z" id="Shape" fill="#EFD358"></path>
            </g>
        </g>
    </svg>
  `,
  'delete_user': `
    <g >
    <rect x="17" y="23.9999981" fill-rule="evenodd" clip-rule="evenodd" fill="#DAC2A7" width="12" height="15"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="#EAD8C5" d="M34,11.9999981v8c0,6.0650005-4.9349995,11-11,11
    s-11-4.9349995-11-11v-8H34z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="#444444" d="M45,44.0092506
    c0-1.6619644-1.2289314-3.5469093-2.7504997-4.2125969L29,33.9999962c-1.6879997,1.25-3.7539997,2-6,2s-4.3120003-0.75-5.9820004-2
    L3.753592,39.7966537C2.232825,40.4612427,1,42.3496666,1,44.0092506v2.9878197c0,0.5539017,0.4498156,1.0029259,1.001672,1.0029259
    h41.9966583C44.5515404,47.9999962,45,47.5621147,45,46.9970703V44.0092506z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="#C6A279" d="M12,11.9988995
    c0-3.3131027,2.5352898-6.9043617,5.6539803-8.0181808l7.4057007-2.6448898
    c0.5193291-0.1854706,1.1393986,0.0623283,1.3841782,0.551899L28,4.9999981c3.3137093,0,6,2.6930313,6,6v6
    c0-2.7614212-2.2385807-5-5-5H17c-2.7614202,0-5,2.2385788-5,5V11.9988995z"/>
    <circle fill="#E86C60" cx="39" cy="36.9999962" r="8"/>
    <path fill="#FFFFFF" d="M42,37.9999962h-6c-0.5522842,0-1-0.4477119-1-0.9999962v-0.0000038c0-0.5522842,0.4477158-1,1-1h6
    c0.5522842,0,1,0.4477158,1,1V37C43,37.5522842,42.5522842,37.9999962,42,37.9999962z"/>
    </g>
  `,
  'alert': `
     <svg width="27px" height="82px" viewBox="0 0 27 82" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="animate_svg animated tada">
        <title>np_exclamation-mark_920809_FDFF25</title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="iPad-Portrait" transform="translate(-371.000000, -1116.000000)" fill-rule="nonzero" fill="#FDFF25">
                <g id="np_exclamation-mark_920809_FDFF25" transform="translate(371.000000, 1116.000000)">
                    <path d="M16.648,63.301 C16.0262694,63.0291894 15.3701293,62.8441243 14.698,62.751 C14.1380094,62.6473705 13.5694896,62.5968131 13,62.6 C12.433,62.6 11.882,62.648 11.351,62.75 C10.677918,62.8431906 10.0207959,63.028248 9.398,63.3 C8.332,63.698 7.367,64.35 6.5,65.25 C4.699,67.081 3.8,69.284 3.8,71.85 C3.8,74.417 4.699,76.6 6.5,78.398 C8.3,80.198 10.465,81.101 13,81.101 C15.566,81.101 17.75,80.198 19.55,78.398 C21.351,76.601 22.25,74.418 22.25,71.851 C22.25,69.284 21.351,67.081 19.55,65.249 C18.683,64.351 17.715,63.699 16.648,63.3 L16.648,63.301 Z M25.898,13.148 C26.933,4.418 22.633,0.082 13,0.148 C3.399,0.183 -0.852,4.448 0.25,12.949 L6.852,58.351 L19.3,58.351 L25.898,13.148 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
  `,
  'celebration': `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" xml:space="preserve" width="48" height="48" class="animated tada">
      <g class="nc-icon-wrapper"><path fill="#EAD8C5" d="M36.85254,47H12c-0.55225,0-1-0.44775-1-1V24c0-0.08887,0.01172-0.17725,0.03516-0.26318l6-22 C17.15381,1.30176,17.54883,1,18,1c3.85986,0,7,3.14014,7,7v11h14.00684c2.04492,0,3.98145,0.89014,5.31299,2.44238 c1.33105,1.55176,1.91602,3.60107,1.60498,5.62207l-2.15332,13.99951C43.2417,44.50391,40.33154,47,36.85254,47z"></path> 
        <path fill="#444444" d="M11,23H2c-0.55225,0-1,0.44775-1,1v22c0,0.55225,0.44775,1,1,1h9V23z"></path>
      </g>
    </svg>
  `
}
