import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import * as _ from 'lodash';
import {IDraftRepositioningEntry} from '../models/repositioning';
import {GlobalsService} from '../globals.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private storage: Storage) { }


  /**
   * Stores the draft repositioning to the local storage.
   * @param key: Unique ID for the data
   * @param check_in_id: ID of the check in.
   * @param data: Actual data to be stored
   * @param isAutoSave: Whether or not the data is from a change to the form or from autosaving.
   */
  setDraftRepositioning(key, check_in_id, data, isAutoSave = false): Promise<boolean> {
    return new Promise((resolve) => {
      this.storage.ready().then(() => {
        this.storage.get("draftRepositionings").then(val => {
          let arr = Array.isArray(val) ? val : []
          let clientName, checkInTime
          [clientName, checkInTime] = key.split("/")
          let newRecord = {key: key, data: data, checkInTime: checkInTime}
          let clientIndex = _.findIndex(arr, {name: clientName})
          let client = arr[clientIndex] || {name: clientName, visits: [{id: check_in_id, date: checkInTime, changeBased: [], auto: {}}]}
          let visit = null

          if (client) {
            visit = _.find(client.visits, {id: check_in_id}) || {id: check_in_id, changeBased: [], auto: {}, date: checkInTime}
          } else {
            visit = {id: check_in_id, changeBased: [], auto: {}}
          }

          if (isAutoSave) {
            visit.auto = newRecord
          } else {
            visit.changeBased.unshift(newRecord)

            // Only save 5 changes for a single client
            visit.changeBased = visit.changeBased.splice(0, 5)
          }

          let visitIndex = _.findIndex(client.visits, {id: check_in_id})

          if (visitIndex > -1) {
            // Changes for this client and this visit already exists so let's replace it
            client.visits.splice(visitIndex, 1, visit)
          } else {
            // Changes for this client and this visit DO NOT exist so let's append to it
            client.visits.unshift(visit)
          }

          if (client.visits.length > 5) client.visits.pop()

          // If index is found, update the record, otherwise add to the front of the array.
          if (clientIndex > -1) {
            arr.splice(clientIndex, 1, client)
          } else {
            arr.unshift(client)
          }

          // Keep arr length less than 100
          if (arr.length >= 200) arr.pop()

          this.storage.set("draftRepositionings", arr)

          resolve(null)
        })
      })
    })
  }


  /**
   * Find draft repositioning based on the following attributes.
   * @param clientName
   * @param visit_id
   * @param key
   * @param isAutoSave
   */
  getDraftRepositioning(clientName: string, visit_id: string, key: string, isAutoSave: boolean = false): Promise<IDraftRepositioningEntry> {
    return new Promise(resolve => {
      let dr = null
      this.storage.ready().then(() => {
        this.storage.get("draftRepositionings").then(val => {
          let client = _.find(val, {name: clientName})
          if (client && client.visits) {
            let visit = _.find(client.visits, {id: parseInt(visit_id)})
            if (visit) {
              if (isAutoSave) {
                dr = visit.auto
              } else {
                dr = _.find(visit.changeBased, {key: key})
              }
              resolve(dr)
            }
          }
        })
      })
    })
  }


  /**
   * Clear the draft repositioning
   * @param clientName
   * @param checkInTime
   */
  removeDraftRepositioning(clientName: string, checkInTime: string): void {
    this.storage.ready().then(() => {
      this.storage.get("draftRepositionings").then(val => {
        console.log(val)
        console.log(clientName, checkInTime)

        let client = _.find(val, {name: clientName})

        console.log(client)

        if (client?.visits) {
          for (let i in client.visits) {
            let visit = client.visits[i]
            if (visit.auto && visit.auto.checkInTime === checkInTime) {
              this.clearDraftRepo(clientName, visit.id)
              client.visits.splice(parseInt(i), 1)
              break;
            }
            for (let changes of visit.changeBased) {
              if (changes.checkInTime === checkInTime) {
                this.clearDraftRepo(clientName, visit.id)
                client.visits.splice(parseInt(i), 1)
                break;
              }
            }
          }
        }

        if (client?.visits?.length === 0) {
          _.remove(val, {name: clientName})
        }

        this.storage.set("draftRepositionings", val)
      })
    })
  }


  clearDraftRepo(clientName: string, visit_id: number) {

  }


  /**
   * Clear all draft repositionings from local storage.
   */
  clearDraftRepositionings(): Promise<any> {
    return this.storage.remove("draftRepositionings")
  }
}
