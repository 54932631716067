import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IPayment, IPurchase, Purchase} from '../../../models/finance';
import {AuthService} from '../../../auth/auth.service';
import {EmployeeService} from '../../../providers/employee.service';
import {PurchasesService} from '../../../pages/clients/client-detail/providers/finances/purchases.service';
import {Comment, IComment} from '../../../models/comment';
import {CommentsProvider} from '../../../providers/comments.service';
import {GlobalsService} from '../../../globals.service';
import {IReferral} from '../../../models/referral';
import * as moment from 'moment'
import { ReferralsService } from 'src/app/providers/referrals.service';

@Component({
  selector: 'purchase-form',
  templateUrl: './purchase-form.component.html',
  styleUrls: ['./purchase-form.component.scss'],
})

export class PurchaseFormComponent implements OnInit {
  @Input() employee_id: number;
  @Input() client_id: number;
  @Input() submitButtonText: string = "Submit";
  @Input() partOfConsult: boolean = false;
  @Input() program_id: number;
  @Input() purchaseable_id: number;
  @Input() purchaseable_type: string;
  @Input() saleTypePreference: string;
  @Input() purchase: IPurchase;
  @Input() payment: IPayment;
  @Input() unusedReferrals: IReferral[];

  @Output() purchaseData = new EventEmitter<Purchase>();
  @Output() error = new EventEmitter<string>();
  @Output() newTotal = new EventEmitter<number>();
  @Output() paidInFullEmitter = new EventEmitter<boolean>();

  @ViewChild('purchaseForm', {static: true}) form: HTMLElement;

  sale_type: string;
  weeks: number;
  weekly_cost: number = 315;
  discount: number;
  purchase_comment: string;
  paid_in_full: boolean;
  has_referrals: boolean;
  referral_used: boolean;
  comment: Comment;

  constructor(private auth: AuthService,
              public employeeService: EmployeeService,
              private purchaseService: PurchasesService,
              private referralService: ReferralsService,
              private commentService: CommentsProvider,
              private global: GlobalsService) {}

  ngOnInit(): void {
    this.sale_type = this.saleTypePreference ? this.saleTypePreference : 'new_sale'
    if (this.purchase) {
      this.weeks = this.purchase.weeks
      this.weekly_cost = this.purchase.weekly_cost
      this.employee_id = this.purchase.employee_id
      this.discount = this.purchase.discount
      if (this.payment && this.payment.amount === this.calcTotal()) this.paid_in_full = true
    }
    console.log(this)
  }

  /**
   * Calculates the purchase total.
   */
  calcTotal() : number {
    let total;

    if (this.discount) {
      total = ( (this.weekly_cost || 0) * (this.weeks || 0) ) * ( 1 - (this.discount / 100) )
    } else {
      total = (this.weekly_cost || 0) * (this.weeks || 0)
    }

    if (this.referral_used) total -= this.weekly_cost

    // Never return a negative number
    total = total < 0 ? 0 : total
    return total
  }


  updateReferralStatus(value: CustomEvent) {
    console.log(value)
    if (value && value.detail.checked) this.newTotal.emit(this.calcTotal())
  }


  /**
   * Calls either {@link createPurchase} or {@link updatePurchase} depending on whether the purchase has already been saved.
   */
  createOrUpdatePurchase() {
    console.log(this.purchase)
    if (this.purchase) {
      this.updatePurchase()
    } else {
      this.createPurchase()
    }
  }


  markReferralUsed() {
    const referral = this.unusedReferrals[0]
    referral.used = true
    if (this.purchase.id) {
      referral.purchase_id = this.purchase.id

    }
    console.log('Marking Referral Used', referral)

    this.referralService.update(referral).subscribe(
      updatedReferral => { console.log(updatedReferral) },
      err => this.global.handleResponse(err.error)
    )
  }


  /**
   * Creates a purchase for the client.
   */
  createPurchase() {
    const purchase: Purchase = {
      weeks: this.weeks,
      weekly_cost: this.weekly_cost,
      discount: this.discount || 0,
      client_id: this.client_id,
      referral_used: this.referral_used,
      sale_type: this.sale_type,
      employee_id: this.employee_id,
      program_id: this.program_id,
      purchaseable_id: this.purchaseable_id,
      purchaseable_type: this.purchaseable_type,
      comments_attributes: []
    }

    console.log(purchase)

    if (this.purchase_comment) purchase.comments_attributes.push({
      body: this.purchase_comment,
      employee_id: this.employee_id
    })

    if (purchase.referral_used) {
      console.log('Using Referral')
      this.addReferralPurchase()
      purchase.weeks--
    }

    if (purchase.weekly_cost == 0 && this.auth.getCurrentUser().user_type > 2) {
      this.error.emit("You must enter a weekly cost > 0.  If this is an exception ask your manager to enter this sale.")

    } else {

      this.purchaseService.createPurchase(purchase).subscribe(
        success => {
          this.purchase = success
          if (this.paid_in_full) this.paidInFullEmitter.emit(this.paid_in_full)
          this.purchaseData.emit(success)
          this.newTotal.emit(this.calcTotal())

        }, err => {
          this.global.handleResponse(err.error)
        }
      )
    }
  }

  //TODO: changing use referral on update?

  addReferralPurchase() {
    const purchase: Purchase = {
      weeks: 1,
      weekly_cost: 0,
      discount: 0,
      client_id: this.client_id,
      referral_used: true,
      sale_type: this.sale_type,
      employee_id: this.employee_id,
      program_id: this.program_id,
      purchaseable_id: this.purchaseable_id,
      purchaseable_type: this.purchaseable_type,
      comments_attributes: [
        {
          body: `Referral #${this.unusedReferrals[0].id} used.  Referred ${this.unusedReferrals[0].referred_name}.`,
          employee_id: this.employee_id
        }
      ],
      referral_attributes: {id: this.unusedReferrals[0].id, used: true, used_at: moment().format()}
    }

    this.purchaseService.createPurchase(purchase).subscribe(() => {
      this.markReferralUsed()
    })

  }


  /**
   * Update's the client's purchase
   */
  updatePurchase() {
    this.purchase.weeks = this.weeks
    this.purchase.weekly_cost = this.weekly_cost
    this.purchase.discount = this.discount || 0
    this.purchase.client_id = this.client_id
    this.purchase.referral_used = this.referral_used
    this.purchase.sale_type = this.sale_type
    this.purchase.employee_id = this.employee_id
    this.purchase.purchaseable_id = this.purchaseable_id
    this.purchase.purchaseable_type = this.purchaseable_type

    if (this.purchase_comment) {
      this.purchase.comments_attributes = [{
        body: this.purchase_comment,
        employee_id: this.employee_id
      }]
    }

    if (this.purchase.referral_used) {
      const existingReferral = this.referralService.getReferrals().filter(referral => {
        return referral.purchase_id === this.purchase.id
      })
      console.log(existingReferral)
      if (existingReferral.length === 0) {
        if (!this.purchase.comments_attributes) this.purchase.comments_attributes = []
        this.purchase.comments_attributes.push({
          body: `Referral #${this.unusedReferrals[0].id} used.  Referred ${this.unusedReferrals[0].referred_name}.`,
          employee_id: this.employee_id
        })
        this.markReferralUsed()
      }
    }

    console.log(this.purchase)

    this.purchaseService.update(this.purchase).subscribe(
      success => {
        this.paidInFullEmitter.emit(this.paid_in_full)
        this.purchaseData.emit(success)
        if (this.paid_in_full) {
          this.newTotal.emit(this.calcTotal())
        }
      },
      err => this.global.handleResponse(err.error, true)
    )
  }
}
