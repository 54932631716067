export interface IReminder {
  id?: number;
  remindable_type?: string;
  remindable_id?: number;
  number_of_days?: number;
  number_of_items?: number;
  item_class?: string;
  body?: string;
  remaining_visits?: number;
  total_items?: number;
}

export class Reminder implements IReminder {
  public id?: number;
  public remindable_type?: string;
  public remindable_id?: number;
  public number_of_days?: number;
  public number_of_items?: number;
  public item_class?: string;
  public remaining_visits?: number;
  public body?: string;
  public total_items?: number;

  constructor(data: IReminder) {
    Object.entries(data).forEach(entry => {
      this[entry[0]] = entry[1]
    })
  }
}
