import { Injectable } from '@angular/core';
import {GlobalsService} from '../../globals.service';
import {HttpClient} from '@angular/common/http';
import {ReferralCard} from '../../models/referral_card';
import {Observable} from 'rxjs';
import {ReferralCardEntry} from '../../models/referral_card_entry';

@Injectable({
  providedIn: 'root'
})
export class ReferralCardService {

  constructor(private globals: GlobalsService,
              private http: HttpClient) { }


  createReferralCard(referral_card: ReferralCard): Observable<ReferralCard> {
    let url = `${this.globals.base_url}/clients/${referral_card.client_id}/referral_cards`
    return this.http.post<ReferralCard>(url, referral_card)
  }

  updateReferralCard(referral_card: ReferralCard): Observable<ReferralCard> {
    let url = `${this.globals.base_url}/referral_cards/${referral_card.id}`
    return this.http.put<ReferralCard>(url, referral_card)
  }

  searchForReferralCard(search: string): Observable<ReferralCard[]> {
    let url = `${this.globals.base_url}/referral_cards/search`
    return this.http.post<ReferralCard[]>(url, {search: search})
  }


  /**
   * Creates a {@link Referral} from the referral_card_entry
   * @param referral_card_entry
   * @param referred_id: The ID of the client being referred
   */
  linkToReferral(referral_card_entry: ReferralCardEntry, referred_id: number): Observable<ReferralCard> {
    let url = `${this.globals.base_url}/referral_cards/${referral_card_entry.id}/link_to_referral`
    return this.http.put<ReferralCard>(url, {referred_id: referred_id})
  }

  fetchReferralCardById(id: number): Observable<ReferralCard> {
    let url = `${this.globals.base_url}/referral_cards/${id}`
    return this.http.get<ReferralCard>(url)
  }
}
