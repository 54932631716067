import {Component, OnDestroy} from '@angular/core';
import {ClientService} from '../clients.service';
import {AuthService} from '../../../auth/auth.service';
import {Storage} from '@ionic/storage';
import * as moment from 'moment';
import {IndexClient} from '../../../models/client';
import {LocationService} from '../../../providers/locations.service';
import {ILocation} from '../../../models/location';
import {GlobalsService} from '../../../globals.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MenuController, ModalController, NavController} from '@ionic/angular';
import {ConsultationProvider} from '../../../providers/consultation.service';
import {InviteClientComponent} from '../client-invite/invite-client/invite-client.component';
import {listAnimation, listAnimation2} from '../../../animations/slideInListAnimation';
import * as _ from 'lodash';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-client-index',
  templateUrl: './client-index.page.html',
  styleUrls: ['./client-index.page.scss'],
  animations: [
    listAnimation, listAnimation2
  ]
})
export class ClientIndexPage implements OnDestroy {
  Array = Array;
  clients: any[];
  date: string;
  location: number;
  appointmentTypes: string[];
  scheduleSort: string;
  isLoading = true;
  loadingLocations = true;
  isSearching = false;
  locations: ILocation[];
  userSubscription: Subscription;

  constructor( public clientService: ClientService,
               private storage: Storage,
               private auth: AuthService,
               private locationService: LocationService,
               private title: Title,
               private navCtrl: NavController,
               private globals: GlobalsService,
               private menuCtrl: MenuController,
               private router: Router,
               private modalCtrl: ModalController,
               private consultService: ConsultationProvider) {
    console.log(this)
  }

  ngOnDestroy() : void {
    this.userSubscription.unsubscribe()
  }

  ionViewWillEnter(): void {
    this.title.setTitle('Client Index')
    console.log('activate the menu')
    this.menuCtrl.enable(true, 'myMenu')
  }


  ionViewDidEnter() : void {
    this.setDay();
    this.getLocations();
    this.userSubscription = this.auth.current_user$.subscribe(
      val => {
        if (val?.employee) {
          this.scheduleSort = val.employee.client_sort_method
          this.location = val.employee.location_sort
          this.lookForClients();
        } else {
          this.auth.logout()
          this.navCtrl.navigateRoot('/login')
        }
      }
    );
  }

  // intro() : void {
  //   const intro = introJs.introJs();
  //   intro.setOptions({
  //     steps: [
  //       {
  //         intro: 'Hello world!'
  //       },
  //       {
  //         element: '#step1',
  //         intro: 'This is a tooltip.',
  //         position: 'bottom'
  //
  //       },
  //       {
  //         element: '#step2',
  //         intro: 'Ok, wasn't that fun?',
  //         position: 'bottom'
  //       }
  //     ]
  //   });
  //   intro.start();
  // }


  /**
   * Sets the day for the schedule view of clients.
   */
  async setDay() : Promise<void> {
    await this.storage.ready()
    const storedOn = await this.storage.get('searchDateStoredOn')
    const date = await this.storage.get('date')
    if (moment(storedOn).isSame(moment(), 'day')) {
      this.date = date;
    } else {
      await this.storage.remove('date')
      this.date = moment().format()
    }
  }


  deleteClient(client: IndexClient) {
    this.clientService.deleteClient(client).subscribe()
  }


  /**
   * Fetch locations from storage and set them in the {@link LocationService}.
   */
  getLocations() : void {
    this.storage.get('locations').then((val) => {
      this.loadingLocations = false;
      this.locationService.setLocations(val)
    })
  }


  /**
   * Fetch daily clients from storage or from the server if storage is empty.
   * Set the value in storage if fetching from the server.
   */
  async lookForClients(newDate = null) : Promise<any> {
    this.isSearching = false;

    this.storage.ready().then(async () => {
      newDate = await this.storage.get('date')

      if (newDate) {
        this.date = newDate
      } else {
        this.date = moment().format('YYYY-MM-DD');
      }

      const search = `${this.location}_${this.date}`;

      this.storage.get( search ).then((val) => {
        if (_.isEmpty(val)) {
          this.clientService.getDailyClients(this.location, this.date).subscribe(
            success => {
              console.log('Got Daily Clients: ', success);
              this.appointmentTypes = Object.keys(success);
              this.clientService.setClients(success);
              this.storage.set(search, success);
              this.clientService.sortClients(this.scheduleSort);
              this.isLoading = false;
            },
            err => {
              if (err.status === 401) {
                this.storage.remove('current_user');
                this.storage.remove('location_id');
              }
              this.isLoading = false;
            },
            () => {
              this.isLoading = false;
            }
          )
        } else {
          console.log('Had Daily Clients: ', val);

          if ( Object.keys(val).length === 0 ) {
            this.refresh({location: this.location, date: this.date, force: true})

          } else {
            this.clientService.setClients(val);
            this.appointmentTypes = Object.keys(val);
            this.clients = val;
            this.clientService.sortClients(this.scheduleSort);
            this.isLoading = false;
          }
        }
      })
    })
  }


  /**
   * Responds to emission from {@link ClientIndexSearchComponent}
   * @param params location: number, day: string, force: boolean
   */
  refresh(params: { location : number, date: string, force: boolean }) : void {
    this.date = moment(params.date).format('YYYY-MM-DD');
    const date = this.date;
    const query = `${params.location}_${date}`;
    this.isLoading = true
    this.location = params.location;
    this.storage.set('date', this.date)
    this.storage.set('searchDateStoredOn', moment().format())

    if ( params.force ) {
      this.storage.remove(query).then(() => {
        this.storage.set('date', params.date);
        this.storage.set('location_id', params.location);
        this.lookForClients(this.date);
      })
    } else {
      console.log('no force')
      this.lookForClients(this.date);
    }
  }


  /**
   * Fetch the data for a client based on who was selected.
   * Redirect to the {@link ProfilePage} after loading.
   * @param id: Client ID to load
   */
  loadClient(id : number) : void {
    this.clientService.fetchClient( id ).subscribe(
      () => this.router.navigate(['clients', id, 'profile'])
    )
  }


  loadClientByUniqueMb(uniqueMb: number) : void {
    this.clientService.fetchClientByUniqueMb( uniqueMb ).subscribe(
      client => this.router.navigate(['clients', client.id, 'profile']),
      error => this.globals.handleResponse(error.error)
    )
  }


  /**
   * Fires when {@link ClientIndexRowComponent} emits client from the schedule and
   * generates consultation modal to facilitate in inviting the client.
   * @param client: New client to invite
   */
  async inviteClient( client : IndexClient ): Promise<any> {
    this.consultService.setConsultation(client.consultation);

    const modal = await this.modalCtrl.create({
      component: InviteClientComponent,
      componentProps: {
        consultClient: client,
        locationId: this.location
      },
      backdropDismiss: false,
      cssClass: 'modal-fullscreen'
    })

    return await modal.present()
  }



  inviteNewClient() : void {
    // let consult = new Consultation({})
    // this.consultService.setConsultation(consult);
    const consultClient = new IndexClient({
       id: null,
       name: null,
       user_id: null,
       location_id: null,
       mindbody_id: null,
       phone: null,
       appointment_type: null,
       email: null,
       found: null,
       time: null,
       referred_by: null,
       status: null,
       submitted: null,
       unique_mb: null,
       consult_status: null,
       consultation_id: null
    }, this.locationService)
    console.log(consultClient)

    this.modalCtrl.create({
      component: InviteClientComponent,
      componentProps: {
        consultClient,
        locationId: this.location
      },
      backdropDismiss: false,
      cssClass: 'modal-fullscreen'
    }).then( modal => modal.present());
  }
}
