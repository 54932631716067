import {Component, OnInit} from '@angular/core';
import {Storage} from '@ionic/storage';
import {IDraftRepositioningEntry} from '../../../models/repositioning';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import {LocalStorageService} from '../../../providers/local-storage.service';
import {AlertController} from '@ionic/angular';
import {GlobalsService} from '../../../globals.service';

@Component({
  selector: 'app-repositioning-index',
  templateUrl: './repositioning-index.component.html',
  styleUrls: ['./repositioning-index.component.scss'],
})
export class RepositioningIndexComponent implements OnInit {
  isLoading: boolean = true;
  noDraftRepos: boolean = false;
  draftRepos: {name: string, changeBased: IDraftRepositioningEntry[], auto: IDraftRepositioningEntry}[]
  draftRepo: {name: string, changeBased: IDraftRepositioningEntry[], auto: IDraftRepositioningEntry}
  clientsVisits: {id: number, date: string; changeBased: IDraftRepositioningEntry[], auto: {}}[];
  moment = moment;
  lodash = _;
  clientNames: string[]
  clientName: string;
  visit_id: string;

  constructor(private storage: Storage,
              private router: Router,
              public localStorage: LocalStorageService,
              private globals: GlobalsService,
              private alertCtrl: AlertController,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {this.clientName = params.get('clientName')})
    this.route.paramMap.subscribe(params => {this.visit_id = params.get('visit_id')})
    this.loadDraftRepositionings()
  }

  ionViewDidEnter(): void {
    this.loadDraftRepositionings()
  }


  /**
   * Loads the draft repos from local storage
   */
  loadDraftRepositionings() {
    this.storage.ready().then(() => {
      this.storage.get("draftRepositionings").then(draftRepos => {
        if (draftRepos) {
          this.draftRepos = draftRepos
          if (!Object.keys(draftRepos).length) this.noDraftRepos = true
          this.clientNames = Object.keys(draftRepos)
          this.isLoading = false
          if (this.clientName) this.filterReposForClient()
          if (this.visit_id) this.filterClientForVisit()
        } else {
          this.noDraftRepos = true
        }
      })
    })
    setTimeout(() => this.isLoading = false, 3000)
  }


  /**
   * Select client and display list of that client's draft repositionings
   * @param clientName
   */
  selectClient(clientName: string): void {
    this.router.navigate(["recover-lost-repositioning", clientName])
  }


  selectVisit(visit_id: number): void {
    this.router.navigate(["recover-lost-repositioning", this.clientName, visit_id])
  }


  /**
   * If a client name is present, filter the repos for this client
   */
  filterReposForClient() {
    if (this.clientName) {
      let client = _.find(this.draftRepos, {name: this.clientName})
      this.clientsVisits = client ? client.visits : []
      // this.clientsVisits = .visits
      //
    }
  }

  filterClientForVisit() {
    // console.log("ooooo", this.clientName, this.clientsVisits, this.visit_id)
    if (this.visit_id) {
      this.draftRepo = _.find(this.clientsVisits, {id: parseInt(this.visit_id)})
    }
    // console.log(this.draftRepo)
  }


  /**
   * Navigate to the specific draft repositioning
   * @param clientName
   * @param key
   * @param isAutoSave
   */
  openDraftRepositioning(key: string, isAutoSave: boolean): void {
    // console.log(clientName, key)
    this.router.navigate(["recover-lost-repositioning", this.clientName, this.visit_id, key],
      { queryParams: {isAutoSave: isAutoSave}}
      )
  }


  clearDraftRepositionings(): void {
    this.alertCtrl.create({
      header: "Are you sure?",
      subHeader: "This will clear your storage and cannot be reversed.",
      buttons: [
        {
          text: "No",
          role: "cancel"
        },
        {
          text: "Yes",
          handler: () => {
            this.localStorage.clearDraftRepositionings().then(() => {
              this.globals.handleResponse("Successfully cleared the memory", false, 'primary')
              this.loadDraftRepositionings()
            })
          }
        }
      ]
    }).then(alert => alert.present())
  }
}
