import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash'
import {BehaviorSubject, Observable, Observer, Subscription} from 'rxjs';
import {IPurchase, ITransaction, Purchase} from '../../../../../models/finance';
import {GlobalsService} from '../../../../../globals.service';
import {tap} from 'rxjs/operators';
import {ClientService} from '../../../clients.service';
import {TransactionsProvider} from './transactions.service';

@Injectable()
export class PurchasesService implements OnDestroy {

  base_url: string;


  private purchases = new BehaviorSubject(<IPurchase[]>[]);
  purchases$: Observable<IPurchase[]> = this.purchases.asObservable();

  private purchased_weeks = new BehaviorSubject(<number>0);
  purchased_weeks$: Observable<number> = this.purchased_weeks.asObservable();

  private clientSubscription: Subscription;
  private purchasesSubscription: Subscription;

  private total = new BehaviorSubject(<number>0);
  total$: Observable<number> = this.total.asObservable()

  constructor( private global: GlobalsService,
               private http: HttpClient,
               private clientService: ClientService,
               private transactionsService: TransactionsProvider) {

    this.base_url = this.global.base_url

    console.log("purchaseService instantiated")

    // this.purchasesObserver = this.purchases$.subscribe( purchases => {
    //   let total = 0
    //   let weeks = 0
    //
    //   for (let purchase of purchases) {
    //     let p = new Purchase(purchase)
    //     total += p.total()
    //     weeks += p.weeks
    //   }
    //
    //   this.total.next(total)
    //   this.purchased_weeks.next(weeks)
    //   this.weeks_purchased = weeks
    // })

    this.managePurchasesSubscription();
    this.manageClientSubscriptions();
  }

  ngOnDestroy(): void {
    // console.log("destroying purchasesService")
    this.purchasesSubscription.unsubscribe();
  }

  manageClientSubscriptions() : void {
    this.clientService.client$.subscribe( client => {
      this.purchased_weeks.next(client.purchased_weeks)
    })
  }

  managePurchasesSubscription() : void {
    this.purchasesSubscription = this.purchases$.subscribe( (purchases : IPurchase[]) => {
      var number_of_purchased_weeks = this.getPurchasedWeeksFromPurchases(purchases);
      var total = this.getTotalFromPurchases(purchases);
      this.purchased_weeks.next(number_of_purchased_weeks);
      this.total.next(total);

      console.log(`Modifying purchases... Total: ${total}, Purchased Weeks: ${number_of_purchased_weeks}`)
    })
  }

  /**
   * Adds number of weeks from last purchase to value from {@link getPurchasedWeeks}
   * @param purchases
   */
  getPurchasedWeeksFromPurchases( purchases : IPurchase[] ) : number {
    const purchase = _.last(purchases)
    if (purchase) {
      let weeks = purchase.weeks
      return weeks + this.getPurchasedWeeks()
    }
  }

  getTotalFromPurchases( purchases : IPurchase[] ) : number {
    const purchase = _.last(purchases)
    if (purchase) {
      let p = new Purchase(purchase)
      let total = p.total()
      return total + this.getTotal()
    }
  }

  getPurchasedWeeks() {
    return this.purchased_weeks.getValue()
  }

  getAndSetPurchases(client_id : number) : void {
    let final_url = `${ this.base_url }/clients/${ client_id }/purchases`
    this.http.get<Purchase[]>(final_url).subscribe(
      success => {
        this.purchases.next(success)
      },
      err => {
        this.global.handleResponse(err.error)
      }
    )
  }

  getTotal() : number {
    return this.total.getValue() || 0
  }

  setPurchases(purchases: IPurchase[]) {
    console.log(purchases)
    this.purchases.next(purchases)
  }

  getPurchases(): IPurchase[] {
    return Object.assign([], this.purchases.getValue())
  }

  addPurchase(purchase: Purchase) {
    console.log("Adding purchase", purchase)
    let purchases = this.getPurchases()
    purchases.push(purchase)
    this.transactionsService.addTransaction(purchase)
    this.setPurchases(purchases)
  }

  createPurchase(purchase: Purchase): Observable<Purchase> {
    console.log("Creating Purchase: ", purchase)
    let url = `${this.base_url}/clients/${purchase.client_id}/purchases`
    return this.http.post<Purchase>(url, purchase).pipe(
      tap( purchase => this.addPurchase(purchase) )
    )
  }

  update(purchase: Purchase) {
    let curr_purchases = this.purchases.getValue()
    let curr_transactions = this.transactionsService.getTransactions()
    let final_url = `${ this.base_url }/clients/${ purchase.client_id }/purchases/${ purchase.id }`
    return this.http.put<Purchase>(final_url, purchase).pipe(
      tap( purchase => {
        let transactionIndex = _.findIndex(curr_transactions, {id: purchase.id, tran_type: "purchase"})
        curr_transactions[transactionIndex] = purchase
        this.setPurchases(curr_purchases)
        this.transactionsService.setTransactions(curr_transactions)
      })
    )
  }

  fetchMindbodyPurchases(client_id) {
    let url = `${this.base_url}/clients/${client_id}/purchases/mindbody_reconciliation`
    return this.http.get(url)
  }

  deletePurchase(purchase: Purchase|ITransaction) {
    console.log("Deleting Purchase: ", purchase)
    let curr_transactions = this.transactionsService.getTransactions()
    let final_url = `${ this.base_url }/clients/${ purchase.client_id }/purchases/${ purchase.id }`
    return this.http.delete(final_url).pipe(
      tap(() => {
        let newVal = _.pull(this.purchases.getValue(), purchase)
        this.setPurchases(newVal)
        let updatedTransactions = _.pull(curr_transactions, purchase)
        this.transactionsService.setTransactions(updatedTransactions)
      })
    )
  }
}
