import { Component, OnInit } from '@angular/core';
import {Storage} from '@ionic/storage';
import {LocalStorageService} from '../../../providers/local-storage.service';
import {ActivatedRoute} from '@angular/router';
import {DraftRepositioning, IDraftRepositioning} from '../../../models/repositioning';
import * as moment from 'moment'
import {ModalController} from '@ionic/angular';
import {RecoverRepositioningModalComponent} from '../recover-repositioning-modal/recover-repositioning-modal.component';

@Component({
  selector: 'app-repositioning',
  templateUrl: './repositioning.component.html',
  styleUrls: ['./repositioning.component.scss'],
})
export class RepositioningComponent implements OnInit {
  clientName: string
  checkInDate: string
  key: string;
  visit_id: string;
  draftRepo: DraftRepositioning;
  booleans: string[];
  emojis: string[];
  isAutoSave: boolean = false;
  remedy_bools: string[];
  explanations: string[];
  moment = moment;

  constructor(private localStorage: LocalStorageService,
              private route: ActivatedRoute,
              private modalCtrl: ModalController) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.clientName = params.get('clientName')
      this.key = params.get('id')
      this.visit_id = params.get('visit_id')
    })
    this.route.queryParams.subscribe(params => {
      console.log(params.isAutoSave)
      this.isAutoSave = params.isAutoSave === 'true' ? true : false
      // if (params.isAutoSave) this.isAutoSave = true
    })

    this.localStorage.getDraftRepositioning(this.clientName, this.visit_id, this.key, this.isAutoSave).then(dr => {
      console.log("DR", dr)
      if (dr) {
        this.draftRepo = new DraftRepositioning(dr.data)
        this.checkInDate = dr.data.check_in_date
        let overview = this.draftRepo.setupEmojis()
        this.booleans = overview.bools
        this.emojis = overview.emojis
        this.remedy_bools = overview.remedy_bools
        this.explanations = overview.explanations
      }
    })
    console.log(this)
  }


  async selectDraftRepositioning() {
    let modal = await this.modalCtrl.create({
      component: RecoverRepositioningModalComponent,
      componentProps: {
        draftRepo: this.draftRepo
      }
    })

    await modal.present();
  }

}
