import {Component, OnInit} from '@angular/core';
import {ExclusionsService} from '../../../../../providers/exclusions.service';
import {ModalController, NavParams} from '@ionic/angular';
import {IUser} from '../../../../../models/user';
import * as _ from 'lodash';
import {GlobalsService} from '../../../../../globals.service';

@Component({
  selector: 'app-exclusion-modal',
  templateUrl: './exclusion-modal.component.html',
  styleUrls: ['./exclusion-modal.component.scss'],
})
export class ExclusionModalComponent implements OnInit {
  user: IUser;
  exclusionTypeOptions: string[]
  excluded: string[];

  constructor( private exclusionsService: ExclusionsService,
               private modalCtrl: ModalController,
               private globals: GlobalsService,
               private params: NavParams) {
    this.user = params.get("client").user
    console.log(this)
  }

  ngOnInit() {
    this.fetchExclusionTypes()
  }


  /**
   * Fetches the potential exclusion types from the database.
   */
  fetchExclusionTypes() {
    this.exclusionsService.fetchExclusionTypes(this.user.id).subscribe( exclusions => {
      this.exclusionTypeOptions = _.keys(exclusions.all)
      this.excluded = exclusions.individual.map(exclusion => exclusion.exclusion_type)
    })
  }


  submit() {
    // console.log(this.excluded)
    this.exclusionsService.create(this.user.id, this.excluded).subscribe(
      () => this.globals.handleResponse("Successfully excluded the client", false, 'success'),
      err => this.globals.handleResponse(err.error, true)
    )
  }


  /**
   * Closes the modal.
   */
  dismiss() {
    this.modalCtrl.dismiss()
  }


}
