import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TransparentHeaderScrollDirective} from './transparent-header-scroll.directive';



@NgModule({
  declarations: [
    TransparentHeaderScrollDirective
  ],
  imports: [
    IonicModule,
    CommonModule
  ],
  exports: [
    TransparentHeaderScrollDirective
  ]
})
export class TransparentHeaderScrollModule { }
