import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  online: boolean = navigator.onLine

  constructor( private auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.headers.has('authorization')) {
      const user = this.auth.getUser();
      // console.log(user)

      request = request.clone({
        setHeaders: {
          authentication: (user.auth_token || ''),
          email: (user.email || ''),
          unlock: (user.unlock || '')
        }
      });
      console.log('request', request)
    }
    return next.handle(request);
  }
}
