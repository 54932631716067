import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalsService} from '../globals.service';
import {IReviewRequest} from '../models/review_request';

@Injectable({
  providedIn: 'root'
})
export class ReviewRequestsService {

  base_url: string;

  constructor( private http: HttpClient,
               private global: GlobalsService ) {

    this.base_url = global.base_url

  }

  /**
   * Creates review request in database and sends request to the client.
   * @param review_request
   */
  create(review_request: IReviewRequest) {
    let url = `${this.base_url}/review_requests`
    return this.http.post<IReviewRequest>(url, review_request)
  }
}
