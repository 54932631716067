import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ClientIndexPage} from './client-index/client-index.page';
import {ClientDetailPage} from './client-detail/client-detail.page';
import {AuthGuard} from '../../auth/auth.guard';

const clientsRoutes: Routes = [
  {
    path: 'clients',
    component: ClientIndexPage,
    canActivate: [AuthGuard],
    data: {animation: 'ClientIndexPage'}
  }, {
    path: 'clients/invite',
    loadChildren: () => import('./client-invite/client-invite.module').then(m => m.ClientInviteModule),
  },
  { path: 'clients/:id',
    component: ClientDetailPage,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        loadChildren: () => import('./client-detail/profile/profile.module').then(m => m.ProfilePageModule),
        data: {animation: 'ProfilePage'}
      },
      {
        path: 'visits',
        loadChildren: () => import('./client-detail/visits/visits.module').then(m => m.VisitsPageModule),
        data: {
          showAllVisits: false
        }
      },
      {
        path: 'all-visits',
        loadChildren: () => import('./client-detail/visits/visits.module').then(m => m.VisitsPageModule),
        data: {
          showAllVisits: true
        }
      },
      {
        path: 'check-ins',
        loadChildren: () => import('../daily-check-ins/daily-check-ins.module').then(m => m.DailyCheckInsPageModule)
      },
      {
        path: 'finance',
        loadChildren: () => import('./client-detail/finance/client-finance.module').then(m => m.ClientFinancePageModule)
      },
      {
        path: 'images',
        loadChildren: () => import('./client-detail/images/images.module').then(m => m.ClientImagePageModule)
      },
      {
        path: 'documents',
        loadChildren: () => import('./client-detail/documents/documents.module').then(m => m.DocumentsPageModule),
      },
      {
        path: 'food-orders',
        loadChildren: () => import('./client-detail/food-orders/food-orders.module').then(m => m.FoodOrdersModule)
      },
      {
        path: 'meals',
        loadChildren: () => import('./client-detail/meals/meals.module').then(m => m.MealsPageModule)
      },
      {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full'
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(clientsRoutes),
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class ClientsRoutingModule { }
