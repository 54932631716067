<ion-row class="ion-align-items-center ion-justify-content-between">
  <ion-col size="4">
    <ion-searchbar
      class="bg-white"
      style="border:none;"
      (ionFocus)="isSearching.emit(true)"
      (ionChange)="clientSearch($event)"
      (ionClear)="cancelClientSearch()"
      [(ngModel)]="search"
    ></ion-searchbar>
  </ion-col>
  <ion-col size="2">
    <ion-select
      interface="popover"
      [value]="(authService.current_user$ | async).employee.client_sort_method"
      (ionChange)="sortClientList($event)">
      <ion-select-option value="name">Name</ion-select-option>
      <ion-select-option value="time">Time</ion-select-option>
    </ion-select>
  </ion-col>
  <ion-col size="2" class="flex flex-row">
    <ion-select
      interface="popover"
      name="location_id"
      [value]="(authService.current_user$ | async).employee.location_sort"
      style="margin:0 auto;max-width:100%"
      (ionChange)="updateLocationSort($event)">
      <ion-select-option *ngFor="let loc of locationService.locations" [value]="loc.id">
        {{ loc.name }}
      </ion-select-option>
    </ion-select>
  </ion-col>
  <ion-col size="2" class="flex flex-row">
    <a180-date-picker
      style="margin:0 auto;max-width:100%;"
      name="day"
      [closeModal]="false"
      [inputDate]="date"
      [displayDate]="true"
      (dateSelected)="handleNewDate($event)"
    ></a180-date-picker>
  </ion-col>
  <ion-col size="1">
    <ion-button color="primary" fill="clear" (click)="refreshEvent(true)">
      <ion-icon slot="icon-only" color="primary" class="fs-24" name="refresh"></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>
