import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {GoalService} from '../goal.service';
import {GlobalsService} from '../../../globals.service';
import {FormGroup} from '@angular/forms';
import {GoalFormComponent} from '../goal-form/goal-form.component';
import {ModalController} from '@ionic/angular';
import {Goal} from '../../../models/goal';

@Component({
  selector: 'a180-add-goal',
  templateUrl: './add-goal.component.html',
  styleUrls: ['./add-goal.component.scss'],
})
export class AddGoalComponent implements OnInit, OnChanges {
  @ViewChild(GoalFormComponent, {static: true}) goalFormComponent: GoalFormComponent;
  @Input() data: {programId: number};
  @Input() closeModal: () => void;
  @Input() modalCtrl: ModalController;
  @Input() duringOnboarding = false;
  @Output() onGoalCreate = new EventEmitter<Goal[]>()

  constructor(private goalService: GoalService,
              private globals: GlobalsService) { }

  ngOnInit() {
    console.log(this)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data) {
      this.data = {programId: changes.data.currentValue.programId}
    }
  }

  createGoal(goalForm: FormGroup): void {
    console.log(goalForm)
    const goals = goalForm.value
    this.goalService.createGoal(goals).subscribe(
      data => {
        this.globals.handleResponse(`Successfully saved ${data.length} new goals!`, false, 'success')
        this.goalFormComponent.clearForm()
        if (this.modalCtrl) this.modalCtrl.dismiss(data)
        this.onGoalCreate.emit(data)
      },
      err => {
        console.log(err)
        this.globals.handleResponse(err.error)
      }
    )
  }
}
