import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GlobalsService} from '../../globals.service';
import {HttpClient} from '@angular/common/http';
import {IUser, User} from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  base_url: string;

  constructor( private globals: GlobalsService,
               private http: HttpClient ) {
    this.base_url = this.globals.base_url
  }

  /**
   * Takes in {@link User} params and updates the user on the server.
   * @param data
   */
  update(data : User) : Observable<User> {
    let final_url = `${this.base_url}/users/${data.id}`;

    return this.http.put<User>(final_url, data)
  }

  /**
   * Searches for existing user with supplied email address.
   * @param {string} email Required
   * @param {string} name Optional, defaults to null
   * @param {string} phone Optional, defaults to null
   */
  fetchUserByEmail( email: string, name: string = null, phone: string = null ) : Observable<User|null> {
    let url = `${this.base_url}/users/getClientFromUserEmail?email=${email}&name=${name}&phone=${phone}`;
    return this.http.get<User|null>(url)
  }
}
