import {animate, state, style, transition, trigger} from '@angular/animations';

export const flyInOut = trigger('flyInOut', [
  state('active', style({transform: 'translateX(0)'})),
  state('inactive', style({transform: 'translateX(-100%)'})),
  transition('void => *', [
    style({transform: 'translateX(-100%)'}),
    animate(100)
  ]),
  transition('* => void', [
    animate(100, style({transform: 'translateX(100%)'}))
  ])
])
