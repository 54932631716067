import {Component, Input, OnInit} from '@angular/core';
import {IReminder, Reminder} from '../../../models/reminder';
import {ReminderService} from '../reminder.service';
import {GlobalsService} from '../../../globals.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'a180-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss'],
})
export class ReminderComponent implements OnInit {
  @Input() data: {
    reminder: IReminder,
    friendlyItemName: string,
    remindableType: string,
    remindableId: number,
    itemClass: string,
    itemSize: number,
    action: 'edit'|'create'
  }
  reminder: Reminder;


  constructor(private remindersService: ReminderService,
              private globals: GlobalsService,
              private modalCtrl: ModalController) { }

  ngOnInit() {
    if (!this.data?.reminder) {
      this.reminder = new Reminder({
        remindable_id: this.data?.remindableId,
        remindable_type: this.data?.remindableType,
        item_class: this.data?.itemClass,
      })
    } else {
      this.reminder = new Reminder(this.data.reminder)
    }
    console.log(this.reminder)
  }


  /**
   * Handles form data and either creates or updates the reminder.
   * @param reminder: Data for reminder
   * @param action: Whether it should update or create
   */
  handleSubmittedReminder(reminder: Reminder, action: 'edit'|'create') {
    console.log(reminder)
    reminder.number_of_items = (this.data?.itemSize || 0) + reminder.number_of_items

    if (action === 'edit') {
      this.updateReminder(reminder)
    } else if (action === 'create') {
      this.createReminder(reminder)
    }
  }


  /**
   * Creates a new reminder in the DB.
   * @param reminder
   */
  createReminder(reminder: IReminder) {
    this.remindersService.createReminder(reminder).subscribe(
      () => {
        this.globals.handleResponse("Successfully created the reminder!", false, 'success')
        this.modalCtrl.dismiss()
      },
      err => this.globals.handleResponse(err.error, true)
    )
  }


  /**
   * Updates a new reminder in the DB.
   * @param reminder
   */
  updateReminder(reminder: IReminder) {
    this.remindersService.updateReminder(reminder).subscribe(
      () => {
        this.globals.handleResponse("Successfully updated the reminder!", false, 'success')
        this.modalCtrl.dismiss()
      },
      err => this.globals.handleResponse(err.error, true)
    )
  }
}
