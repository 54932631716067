import {Component, ViewChild} from '@angular/core';
import {ClientService} from '../../pages/clients/clients.service';
import {ModalController} from '@ionic/angular';
import {PhotoService} from '../../providers/photo.service';
import {GlobalsService} from '../../globals.service';
import {ZendeskProvider} from '../../providers/zendesk.service';

@Component({
  selector: 'app-ticket-modal',
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss'],
})
export class TicketModalComponent {
  subject: string;
  comment: string;
  priority: string;
  type: string;
  include_client: boolean;
  allowScreenshot: boolean = false;

  @ViewChild('file', {static: true}) file;

  constructor( private viewCtrl: ModalController,
               private zendesk: ZendeskProvider,
               public clientService: ClientService,
               private photo: PhotoService,
               private global: GlobalsService ) {

    console.log(this)
  }

  submitForm() {
    let ticket = {
      subject: this.subject,
      comment: this.comment,
      priority: this.priority,
      type: this.type
    }

    let fullTicket = this.zendesk.initTicket( ticket, this.include_client )

    this.zendesk.createTicket(fullTicket).subscribe(
      () => this.dismiss(),
      () => this.global.handleResponse("Hmm... something went wrong.  Please email Jeremy a screenshot of this ticket")
    )
  }

  // searchZendesk( query : string ) {
  //   console.log(query)
  //
  //   this.zendesk.search( query ).subscribe(
  //     (data) => {
  //       console.log(data)
  //     },
  //     (err) => this.global.handleResponse(err.error),
  //     () => this.global.handleResponse("complete")
  //   )
  // }

  upload(file) {
    this.zendesk.uploadPhoto(file).subscribe(
      success => console.log(success),
      failure => console.log(failure),
      () => console.log('complete')
    )
  }

  uploadImage() {
    const file = this.file.nativeElement.files[0]
    console.log(file)

    let r = new FileReader()

    r.onload = (() => {
      let dataURL = r.result.toString().replace("data:image/jpeg;base64,/", "");
      console.log(dataURL)
      this.upload(dataURL)
    });

    r.readAsDataURL(file);
  }

  // selectImage(srcType) {
  //   this.photo.selectImage(srcType).then( image => {
  //     let file = this.photo.dataURItoBlob('data:image/jpeg;base64,' +   image)
  //     console.log(file)
  //   })
  // }

  dismiss() {
    this.viewCtrl.dismiss()
  }
}
