import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ISignedDocument, SignedDocument} from '../../models/signed_document';
import {GlobalsService} from '../../globals.service';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable()
export class SignedDocumentsService {

  private signedDocuments = new BehaviorSubject<SignedDocument[]>([])
  signedDocuments$ = this.signedDocuments.asObservable()

  selectedDocuments: SignedDocument[] = [];

  private executedDocuments = new BehaviorSubject<SignedDocument[]>([])
  executedDocuments$ = this.executedDocuments.asObservable()

  constructor( private globals: GlobalsService,
               private http: HttpClient ) { }

  /**
   * Searches by name and email for any signed documents that are not already linked to a client.
   * @param query
   * @param updateObservable
   * @param signedDocumentIds
   */
  searchForSignedDocuments(query: string, updateObservable: boolean = true, signedDocumentIds: string = ""): Observable<ISignedDocument[]> {
    let url = `${this.globals.base_url}/signed_documents/search?query=${query}&signedExternalDocumentIds=${signedDocumentIds}`

    return this.http.get<ISignedDocument[]>(url).pipe(
      tap(documents => {
        if (updateObservable) this.setSignedDocuments(documents.map(doc => new SignedDocument(doc)))
      })
    )
  }

  getExecutedDocuments(): SignedDocument[] {
    return Object.assign([], this.executedDocuments.getValue())
  }
  setExecutedDocuments(documents: SignedDocument[]) {
    this.executedDocuments.next(documents)
  }


  /**
   * Adds the chosen document to the selectedDocuments.
   * @param document
   */
  addToSelectedDocuments(document: SignedDocument): void {
    if (!_.find(this.selectedDocuments, {id: document.id})) {
      this.selectedDocuments.push(document)
    }
  }


  updateMultiple(documents: SignedDocument[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.update(documents[0]).subscribe(
        () => {
          documents.shift()
          if (documents.length) {
            this.updateMultiple(documents)
          } else {
            console.log("here")
            resolve(null)
          }
        },
        err => {
          reject(err)
        }
      )
    })
  }


  /**
   * Updates the provided document in the database.
   * @param document
   */
  update(document: SignedDocument): Observable<SignedDocument> {
    let url = `${this.globals.base_url}/signed_documents/${document.id}`
    return this.http.put<SignedDocument>(url, document)
  }


  /**
   * Removes the document from the selected documents.
   * @param document
   */
  removeFromSelectedDocuments(document: SignedDocument): void {
    this.selectedDocuments = _.reject(this.selectedDocuments, {id: document.id})
  }


  getSignedDocuments(): SignedDocument[] {
    return this.signedDocuments.getValue()
  }


  setSignedDocuments(documents: SignedDocument[]): void {
    this.signedDocuments.next(Object.assign([], documents))
  }



  // fetchExecutedSignedDocuments(client_id: number): Observable<SignedDocument[]> {
  //   let url = `${this.globals.base_url}/clients/${client_id}/signed_documents`
  //   return this.http.get<SignedDocument[]>(url).pipe(
  //     tap(documents => this.setExecutedDocuments(documents.map(doc => {
  //       console.log(doc)
  //       if (doc.signed_at) {
  //         return new SignedDocument(doc)
  //       }
  //     }))
  //   ))
  // }

  fetchExecutedSignedDocuments(client_id: number): Observable<SignedDocument[]> {
    let url = `${this.globals.base_url}/clients/${client_id}/signed_documents`
    return this.http.get<SignedDocument[]>(url).pipe(
      tap(documents => {
        console.log("xxx", documents)
        let signedDocuments = this.filterSignedDocuments(documents, 'signed_at')
        this.setExecutedDocuments(signedDocuments)
      })
    )
  }


  /**
   * Fetches executed signed documents for a given client
   * @param client_id
   * @param filterField: optional filter to use to filter the signed documents.
   */
  fetchSignedDocuments(client_id: number, filterField: string = null): Observable<SignedDocument[]> {
    let url = `${this.globals.base_url}/clients/${client_id}/signed_documents`
    return this.http.get<SignedDocument[]>(url).pipe(
      tap(documents => {
        if (filterField) return this.filterSignedDocuments(documents, filterField)
      })
    )
  }


  /**
   * Filters the documents for the presence of the provided field.
   * @param documents
   * @param field
   */
  filterSignedDocuments(documents: SignedDocument[], field: string): SignedDocument[] {
    console.log("222", documents)
    return documents.filter(doc => {
      if (doc[field]) {
        return new SignedDocument(doc)
      }
    })
  }
}
