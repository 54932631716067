<ion-header>
  <ion-toolbar class="transparent">
    <ion-buttons slot="start">
      <ion-button fill="clear" color="light" (click)="dismiss()" class="text-black">Close</ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center" color="light">Repositioning Recovery</ion-title>
  </ion-toolbar>

</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col *ngIf="!isLoading">
        <div *ngIf="client && !clientConfirmed">
          <h3 class="text-center">It looks like you'll be saving to {{client.name}}'s profile.</h3>
          <h4 class="text-center">Does that sound right?</h4>
          <div class="text-center">
            <ion-button (click)="client = null">No</ion-button>
            <ion-button (click)="confirmClient()">Yes</ion-button>
          </div>
        </div>

        <ng-container *ngIf="!client && !clientConfirmed">
          <h3>Let's search for the client below:</h3>
          <ion-searchbar
            [(ngModel)]="search"
            [showCancelButton]="true"
            placeholder="referred..."
            animated="true"
            (ionInput)="clientSearch($event)">
          </ion-searchbar>
          <ion-list>
            <ion-item *ngFor="let client of clients" button (click)="confirmClient(client)" [id]="client.id">
              <ion-label class="flex align-items-center ion-text-wrap">
                {{ client.name | nameFormat }}
                <ion-badge class="ml-3" slot="end" color="primary">
                  {{lodash.find(locationsService.locations, {id: client.location_id}).name}}
                </ion-badge>
                <br>
                <small>
                  <ion-note class="px-3">Client: {{ client.id}}</ion-note>
                  <ion-note class="px-3">User: {{client.user_id}}</ion-note>
                  <ion-note class="px-3">MB: {{client.mindbody_id}}</ion-note>
                </small>
              </ion-label>

            </ion-item>
          </ion-list>
        </ng-container>

        <div *ngIf="client && clientConfirmed">
          <h3 class="text-center">Let's choose where to save the repositioning:</h3>
          <ion-list *ngIf="recommendedCheckIn">
            <ion-list-header>
              <ion-label class="mt-3">Recommended:</ion-label>
            </ion-list-header>
            <ion-item button (click)="convertDraftRepoToRealRepo(recommendedCheckIn)">
              <ion-label>
                Visit from: {{recommendedCheckIn.created_at | date:'short'}}
                <ion-badge *ngIf="recommendedCheckIn.repositioning">Repo</ion-badge>
                <ion-badge *ngIf="recommendedCheckIn.weigh_in">Weigh In</ion-badge>
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-list>
            <ion-list-header>
              <ion-label class="mt-3">
                <span *ngIf="recommendedCheckIn">Other Visits</span>
                <span *ngIf="!recommendedCheckIn">Visits</span>
              </ion-label>
            </ion-list-header>
            <ion-item button *ngFor="let checkIn of this.checkIns" (click)="convertDraftRepoToRealRepo(checkIn)">
              <ion-label class="ion-text-wrap">
                Visit from: {{checkIn.created_at | date:'short'}}
                <ion-badge *ngIf="checkIn.repositioning">Repo</ion-badge>
                <ion-badge *ngIf="checkIn.weigh_in">Weigh In</ion-badge>
              </ion-label>
            </ion-item>
          </ion-list>
        </div>

<!--        <ion-slides>-->
<!--          <ion-slide>-->
<!--            <ion-grid>-->
<!--              <ion-row>-->
<!--                <ion-col *ngIf="client">-->
<!--                  <h3>It looks like you'll be saving to {{client.name}}'s profile.</h3>-->
<!--                  <h4>Does that sound right?</h4>-->
<!--                </ion-col>-->
<!--              </ion-row>-->
<!--            </ion-grid>-->
<!--          </ion-slide>-->
<!--        </ion-slides>-->

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer *ngIf="statusText !== ''">
  <ion-toolbar class="transparent">
    <ion-title>{{statusText}}</ion-title>
  </ion-toolbar>
</ion-footer>
