import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IRefund} from '../models/finance';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefundService {

  refunds = new BehaviorSubject([] as IRefund[]);
  refunds$ = this.refunds.asObservable();

  constructor(private httpClient: HttpClient) { }

  createRefund(refund: IRefund, purchaseIds: number[]): Observable<IRefund> {
    const url = `${environment.baseUrl}/clients/${refund.client_id}/refunds`
    const data = {
      refund,
      refund_purchases_attributes: purchaseIds.map(id => ({purchase_id: id}))
    }
    return this.httpClient.post<IRefund>(url, data).pipe(tap(newRefund => {
      this.setRefunds([...this.refunds.getValue(), newRefund])
    }));
  }

  setRefunds(refunds: IRefund[]) {
    this.refunds.next(refunds)
  }

  deleteRefund(refund: IRefund): Observable<IRefund[]> {
    const url = `${environment.baseUrl}/clients/${refund.client_id}/refunds/${refund.id}`
    return this.httpClient.delete<IRefund[]>(url).pipe(tap(remainingRefunds => {
      this.setRefunds(remainingRefunds)
    }))
  }
}
