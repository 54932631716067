import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DatePickerComponent} from './date-picker.component';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {DatePickerDirective} from './date-picker.directive';



@NgModule({
  declarations: [
    DatePickerComponent,
    DatePickerDirective
  ],
  exports: [
    DatePickerComponent,
    DatePickerDirective
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ]
})
export class DatePickerModule { }
