import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {vars} from '../models/env-variables';
import {ClientService} from '../pages/clients/clients.service';
import {GlobalsService} from '../globals.service';

interface ticket {
  subject?: string;
  comment?: string;
  priority?: string;
  assignee_id?: number;
  group_id?: number;
  requester_id?: number;
  type?: string;
  external_id?: number
  submitter_id?: number;
  email?: string;
}

// my id: 364870035311

@Injectable({
  providedIn: 'root'
})
export class ZendeskProvider {

  baseUrl: string;

  constructor( public http: HttpClient,
               private clientService: ClientService,
               private global: GlobalsService) {

    this.baseUrl = this.global.base_url;
  }

  initTicket(options : ticket = {}, include_client : boolean ) {
    let employee = this.global.getUser().employee

    options.group_id    = 360002589051
    options.external_id = employee.id
    options.subject     = options.subject    || "Missing Subject"
    options.type        = options.type       || 'problem'
    options.priority    = options.priority   || 'normal'

    if ( include_client ) {
      options.comment     = `${options.comment || "Missing Comment"}
      
    ${JSON.stringify(this.clientService.getClient(), null, 2)}
    `
    } else {
      options.comment     = `${options.comment || "Missing Comment"}`
    }

    options.email = employee['email']

    return options
  }

  getUsers() {
    let headers = this.setOptions()
    let org_id =  360092872932;
    let url = `${this.baseUrl}/organizations/${org_id}/users.json`

    return this.http.get(url, {headers: headers})
  }

  createTicket( options : ticket = {} ) {

    let data = {
      "ticket": {
        "subject": options.subject,
        "comment": {
          "body": options.comment
        },
        "priority": options.priority,
        "group_id": options.group_id,
        "type": options.type,
        "tags": ['app'],
        "external_id": options.external_id,
      },
      email: this.global.getUser().email
    }

    return this.http.post(`${ this.baseUrl }/zendesk`, data)
  }

  uploadPhoto(file) {
    let headers = this.setOptions();
    headers.append("Content-Type", "application/binary")
    let url = `${ this.baseUrl }/uploads.json`

    return this.http.post(url, file, { headers: headers })
  }

  search( query : string ) {
    let headers = this.setOptions()
    return this.http.get(`${ this.baseUrl }/search.json?query=${ query }`, { headers: headers })
  }

  setKey() {
    let key = `jlopez@awaken180weightloss.com:Pepper77!`
    let encodedKey = btoa(key)

    return `Basic ${encodedKey}`
  }

  setOptions() {
    return new HttpHeaders({
      'Authorization': `Bearer ${vars.ZENDESK_TOKEN}`,
      "Content-Type": "application/json"
    })
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();

    if ("withCredentials" in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }

    return xhr;
  }
}
