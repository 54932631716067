import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'a180-awaken-popover',
  templateUrl: './awaken-popover.component.html',
  styleUrls: ['./awaken-popover.component.scss'],
})
export class AwakenPopoverComponent implements OnInit {
  @Input() popoverSrc: {
    name: string,
    disabled?: boolean,
    icon?: {name: string, cssClass?: string[]},
    cssClass?: string[]
  }[]
  @Input() data: any;

  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit() {
    console.log(this)
  }

  handePopoverClick(popoverRow: { name: string }) {
    this.popoverCtrl.dismiss(popoverRow, this.data)
  }
}
