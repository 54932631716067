import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Goal, IGoal} from '../../../models/goal';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {flyInOut} from '../../../animations/flyInOut';
import {Subscription} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {AwakenModal} from '../../../shared/awaken-modal/awaken-modal.component';

@Component({
  selector: 'a180-goal-form',
  templateUrl: './goal-form.component.html',
  styleUrls: ['./goal-form.component.scss'],
  animations: [flyInOut]
})
export class GoalFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() goal: Goal;
  @Input() programId: number;
  @Input() duringOnboarding = false
  @Output() goalForSubmission = new EventEmitter<FormGroup>()

  items: FormArray;
  goalForm: FormGroup
  goals: FormArray
  editMode: boolean;

  object = Object;

  goalTypeSubscriptions: Subscription[] = [];
  fieldSubscriptions: Subscription[] = [];
  directionSubscriptions: Subscription[] = [];
  valueSubscriptions: Subscription[] = [];
  recommendedDescriptions = [];

  constructor(private fb: FormBuilder,
              private modalCtrl: ModalController) { }

  ngOnInit(): void {
    if (this.goal) {
      this.editMode = true
    } else {
      this.editMode = false
      this.goal = new Goal({program_id: this.programId})
    }

    if (this.duringOnboarding) this.editMode = false

    this.programId = this.programId || this.goal.program_id
    this.initializeForm()
    console.log(this)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.programId?.currentValue && this.duringOnboarding) {
      console.log(changes)
      console.log(this)
      this.programId = changes.programId.currentValue
      this.goal = new Goal({program_id: this.programId})
      this.clearForm()
    }
  }

  ngOnDestroy() {
    this.goalTypeSubscriptions.forEach(subscription => subscription.unsubscribe())
    this.fieldSubscriptions.forEach(subscription => subscription.unsubscribe())
    this.directionSubscriptions.forEach(subscription => subscription.unsubscribe())
    this.valueSubscriptions.forEach(subscription => subscription.unsubscribe())
  }

  initializeForm(): void {
    this.goalForm = this.fb.group({
      goals: this.fb.array([this.createItem(this.goal)])
    })
  }

  clearForm(): void {
    this.recommendedDescriptions = []
    this.goalForm = this.fb.group({
      programId: [this.goal.program_id],
      goals: this.fb.array([this.createItem({program_id: this.programId})])
    })
  }

  async submitGoal() {
    const shouldSubmit = await this.verifyScaleValueInDescription()
    console.log(this.goalForm)
    if (shouldSubmit) this.goalForSubmission.emit(this.goalForm)
  }


  /**
   * Presents the User with a modal to either allow or reject a goal description that
   * does not contain the scale value they are intended to achieve.
   */
  async verifyScaleValueInDescription(): Promise<boolean> {
    const goal = this.goalForm.get('goals') as FormArray;
    const input = goal.controls as FormGroup[];
    const currentValue = input[0].get('goal_type').value || '';
    console.log(currentValue)
    const descriptionError = this.findValueInDescription()
    console.log(descriptionError)
    let outcome;

    if (currentValue === 'external_scale' && descriptionError) {
      const modal = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          title: `The scale value: ${descriptionError.value} is missing from the description: ${descriptionError.description}`,
          subtitle: 'Are you sure you want to continue?',
          type: 'confirm'
        },
        cssClass: 'small-modal'
      })
      await modal.present()
      const response = await modal.onDidDismiss()
      outcome = response?.data === 'yes';

    } else {
      outcome = true
    }

    return outcome
  }


  /**
   * Ensures the goal description contains the scale value to make it easier to
   * understand when another coach or the client looks at the goal.
   */
  findValueInDescription() {
    const goal = this.goalForm.get('goals') as FormArray;
    const inputs = goal.controls as FormGroup[];
    let error;
    inputs.forEach(goalInput => {
      const currentValue = goalInput.get('value').value || '';
      const currentDescription = goalInput.get('description').value || '';
      const currentDescriptionNumbers = currentDescription.match(/\d+/g) || ''

      if (!currentDescriptionNumbers.includes(currentValue.toString())) {
        error = {value: currentValue, description: currentDescription}
      }
    })
    return error
  }

  createItem(goal: IGoal = new Goal({program_id: this.programId})): FormGroup {
    this.recommendedDescriptions.push([])

    const formGroup = this.fb.group({
      id: [goal.id],
      completed_at: [goal.completed_at],
      program_id: [goal.program_id],
      goal_type: [goal.goal_type, Validators.required],
      field: [goal.field],
      value: [goal.value],
      direction: [goal.direction],
      description: [goal.description, Validators.required],
    })

    const goalTypeControl = formGroup.get('goal_type')
    const fieldControl = formGroup.get('field')
    const directionControl = formGroup.get('direction')
    const valueControl = formGroup.get('value')
    const currentRecommendationLength = this.recommendedDescriptions.length
    let field = null

    const dependentFields = [
      {
        control: formGroup.get('field'),
        validators: [Validators.required]
      }, {
        control: formGroup.get('value'),
        validators: [Validators.required, Validators.pattern('^[0-9]*$')]
      }, {
        control: formGroup.get('direction'),
        validators: [Validators.required]
      }
    ]

    const goalTypeSubscription = goalTypeControl.valueChanges.subscribe(value => {
      if (value === 'external_scale') {
        dependentFields.forEach(item => {
          item.control.setValidators(item.validators)
          item.control.updateValueAndValidity()
        })
      } else {
        dependentFields.forEach(item => {
          item.control.clearValidators()
          item.control.updateValueAndValidity()
        })
      }
    })

    const fieldSubscription = fieldControl.valueChanges.subscribe(value => {
      field = value
      if (value === 'weight') this.recommendedDescriptions[currentRecommendationLength - 1][0] = 'Weigh'
      if (value === 'body_fat') this.recommendedDescriptions[currentRecommendationLength - 1][0] = 'Get body fat'
    })

    const directionSubscription = directionControl.valueChanges.subscribe(value => {
      if (value === 'less_than') this.recommendedDescriptions[currentRecommendationLength - 1][1] = 'less than'
      if (value === 'greater_than') this.recommendedDescriptions[currentRecommendationLength - 1][1] = 'greater than'
    })

    const valueSubscription = valueControl.valueChanges.subscribe(value => {
      this.recommendedDescriptions[currentRecommendationLength - 1][2] = `${value}`
    })

    this.goalTypeSubscriptions.push(goalTypeSubscription)
    this.fieldSubscriptions.push(fieldSubscription)
    this.directionSubscriptions.push(directionSubscription)
    this.valueSubscriptions.push(valueSubscription)

    return formGroup
  }

  addItem(): void {
    this.goals = this.goalForm.get('goals') as FormArray;
    this.goals.push(this.createItem({program_id: this.programId}))
  }

  removeItem(index: number): void {
    this.goals = this.goalForm.get('goals') as FormArray;
    this.goals.removeAt(index)
  }


  setDescription(i: number) {
    const goal = this.goalForm.get('goals') as FormArray;
    const input = goal.controls as FormGroup[];
    const currentValue = input[i].get('description').value || '';
    input[i].get('description').setValue(`${currentValue}  ${this.recommendedDescriptions[i].join(' ')}`)
  }
}
