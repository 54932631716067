import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash'
import {map, tap} from 'rxjs/operators';
import { IReferral } from '../models/referral';
import { ClientService } from '../pages/clients/clients.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ReferralsService implements OnDestroy {

  base_url = environment.baseUrl;

  private referrals = new BehaviorSubject(<IReferral[]>[]);
  referrals$: Observable<IReferral[]> = this.referrals.asObservable();

  private unusedReferrals = new BehaviorSubject(<IReferral[]>[]);
  unusedReferrals$: Observable<IReferral[]> = this.unusedReferrals.asObservable();

  clientSubscription: Subscription;

  constructor(private http: HttpClient,
              private clientService: ClientService) {

    this.unusedReferrals$ = this.referrals$.pipe(
      map( referrals => {
        console.log(referrals)
        if (referrals) {
          return referrals.filter(referral => referral.used == false)
        } else {

        }
      })
    )
    this.subscribeToClient()
  }

  ngOnDestroy(): void {
    if (this.clientSubscription) this.clientSubscription.unsubscribe()
  }

  subscribeToClient() {
    this.clientSubscription = this.clientService.client$.subscribe( client => {
      console.log('in here', client);

      this.setReferrals(client.referrals)
    })
  }


  /**
   * Updates observable value of {@link referrals}
   * @param referrals
   */
  setReferrals(referrals: IReferral[]) {
    this.referrals.next(referrals)
  }


  /**
   * Adds supplied referral to the end of the current referrals array and sets new value as {@link referrals}.
   * @param referral
   */
  addToReferrals(referral: IReferral) {
    let refs = this.referrals.getValue()
    refs.push(referral)
    this.setReferrals(refs)
  }

  /**
   * Returns the name of the client who referred the provided client_id
   * @param client_id: ID of client that may have been referred by another client
   */
  getReferredBy(client_id: number) : Observable<{name: string}> {
    let final_url = `${ this.base_url }/clients/${ client_id }/referrals/referred_by`
    return this.http.get<{name: string}>(final_url)
  }


  /**
   * Returns clone of referrals.  Safe to use without changing source.
   */
  getReferrals() : IReferral[] {
    return this.referrals.getValue()
  }


  /**
   * Pulls in all referrals for the {@link Client} with supplied client_id
   * @param client_id
   */
  index(client_id) {
    let final_url = `${ this.base_url }/clients/${ client_id }/referrals`
    return this.http.get<IReferral[]>(final_url).pipe(
      tap(referrals => {
        this.setReferrals(referrals)
      })
    )
  }


  /**
   * Creates a new referral.
   * @param referral
   */
  create(referral: IReferral) {
    let final_url = `${ this.base_url }/referrals`
    return this.http.post(final_url, referral)
  }


  /**
   * Updates the referral with supplied parameters.
   * @param referral
   */
  update(referral: IReferral) {
    let final_url = `${ this.base_url }/referrals/${ referral.id }`
    return this.http.put<IReferral>(final_url, referral)
  }


  /**
   * Destroys the referral in the database.
   * @param referral
   */
  destroy(referral: IReferral) {
    let final_url = `${ this.base_url }/referrals/${ referral.id }`
    return this.http.delete(final_url).pipe(
      tap(() => {
        let newVal = _.pull(this.getReferrals(), referral)
        this.setReferrals(newVal)
      })
    )
  }
}
