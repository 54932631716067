import {Injectable} from '@angular/core';
import {IAWS_bucket_object} from '../models/s3';
import {BehaviorSubject} from 'rxjs';
import {LoaderService} from './loader.service';
import { AuthService } from '../auth/auth.service';

declare let AWS:any;

@Injectable({
  providedIn: 'root'
})

export class S3_Service {

  config: any;
  progress = new BehaviorSubject<number>(0);
  progress$ = this.progress.asObservable();

  constructor( private loaderService: LoaderService,
               private authService: AuthService,
  ) {
    this.config = {
      // General
      region: 'us-east-2',
      bucket: 'https://awaken180-assets.s3.us-east-2.amazonaws.com/'
    };
    AWS.config.region = this.config.region;
  }


  /**
   * Initializes constants for security.
   */
  init() : void {
    AWS.config.accessKeyId = this.config.accessKeyId;
    AWS.config.secretAccessKey = this.config.secretAccessKey;
    AWS.config.region = this.config.region;
  }

  async requestTokens() {
    const tokens = await this.authService.getAwsCredentials()
    AWS.config.accessKeyId = tokens['AWS_ACCESS_KEY_ID']
    AWS.config.secretAccessKey = tokens['AWS_SECRET_ACCESS_KEY']
  }


  /**
   * Returns contents of S# bucket.
   * @param prefix prefix to search for in the bucket
   * @param bucket name of the S3 bucket
   */
  async getBucketContents(prefix: string, bucket: string = 'awaken180-assets', ) : Promise<IAWS_bucket_object> {
    await this.requestTokens()

    const aws = new AWS.S3();
    const params = {Bucket: 'awaken180-assets', Prefix: prefix};


    return new Promise(resolve => {
      // tslint:disable-next-line:only-arrow-functions
      aws.listObjectsV2(params, function (err, data) {
        resolve(data)
      });
    })
  }


  /**
   * Fetches image using supplied key and returns signed URL
   * @param key
   */
  async getSignedUrlFromAws(key: string) : Promise<string> {
    await this.requestTokens()

    const aws = new AWS.S3();
    const params = {Bucket: 'awaken180-assets', Key: key};
    console.log(key)

    return new Promise((resolve, reject) => {
      aws.getSignedUrl('getObject', params, function (err, url) {
        if (err) { reject(err) }
        resolve(url)
      });
    })
  }


  /**
   * Locate S3 document with supplied key and delete it.
   * @param key S3 file key
   */
  async deleteFromS3( key : string ) : Promise<any> {
    await this.requestTokens()

    const aws = new AWS.S3();
    const params = {Bucket: 'awaken180-assets', Key: key};

    return new Promise((resolve, reject) => {
      aws.deleteObject(params, (err, data) => {
        if (err) {
          console.log('errerer', err)
          reject(err)
        } else {
          console.log('11111', data)
          resolve(data)
        }
      })
    });
  }


  getKeyFromUrl(amazonUrl: string): string {
    if (amazonUrl) return amazonUrl.split('.com/')[1]
  }


  /**
   * Makes the file public on S3.
   * @param full_key
   * @param image_type
   * @param client_id
   */
  async makePublic(full_key, image_type, client_id) {
    if ( full_key ) {
      await this.requestTokens()

      const s3 = new AWS.S3();
      let filename = full_key.split('/');
      filename = filename[filename.length - 1].split('?')[0];
      const key = `clients/${image_type}s/000/000/${client_id}/original/${filename}`;

      const s3_params = {
        Bucket: 'awaken180-assets',
        Key: key,
        ACL: 'public-read'
      };

      return new Promise((fulfilled, rejected) => {
        s3.putObjectAcl(s3_params, (err, data) => {
          if (err) {
            return rejected(err)
          } else {
            return fulfilled(data)
          }
        })
      })
    }
  }


  /**
   * Uploads the document to S3.
   * @param params
   * @returns result from S3 upload
   */
  async upload(params) : Promise<any> {
    await this.requestTokens()

    const aws = new AWS.S3();
    this.loaderService.showLoader()
    const that = this;
    this.progress.next(0)
    return new Promise((fulfilled, rejected) => {

      aws.upload(params, (err, data) => {

        if (err) {
          alert(JSON.stringify(err))
          this.loaderService.hideLoader()
          return rejected(err)

        } else {
          this.loaderService.hideLoader()
          fulfilled(data)
        }

        console.log(err, data);
      }).on('httpUploadProgress', function (progress) {
        console.log(progress.loaded, progress.total)
        const prog = progress.loaded * 100
        const total = progress.total
        const percent = prog / total
        that.progress.next(percent)
      })
    });
  }
}
