import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InviteClientComponent} from './invite-client/invite-client.component';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {TextMaskModule} from 'angular2-text-mask';
import {AddressModule} from '../../../components/address/address.module';
import {LinkHealthProfileModule} from '../../../components/health-profile/link-health-profile.module';
import {ESignatureModule} from '../../../components/e-signature/e-signature.module';
import {SignedDocumentSelectorModule} from '../../../components/signed-document-selector/signed-document-selector.module';
import {SendForSignatureModule} from '../../../components/send-for-signature/send-for-signature.module';
import {ReferralCardModule} from '../../../components/referral-card/referral-card.module';
import {CommonCardModule} from '../../../components/common-card/common-card.module';
import {DatePickerModule} from '../../../components/date-picker/date-picker.module';
import {WeighInSearchComponentModule} from '../../../components/weigh-in-search/weigh-in-search.module';
import {GoalModule} from '../../../components/goal/goal.module';

const routes : Routes = [
  {
    path: '',
    component: InviteClientComponent
  }
]

@NgModule({
  declarations: [
    InviteClientComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    SignedDocumentSelectorModule,
    SendForSignatureModule,
    TextMaskModule,
    DatePickerModule,
    GoalModule,
    ESignatureModule,
    WeighInSearchComponentModule,
    ReferralCardModule,
    ReactiveFormsModule,
    CommonCardModule,
    RouterModule,
    SharedModule,
    LinkHealthProfileModule,
    AddressModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    InviteClientComponent,
    RouterModule
  ]
})
export class ClientInviteModule { }
