import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AddressComponent} from './address.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {AddressService} from '../../providers/address.service';

@NgModule({
  declarations: [
    AddressComponent
  ],
  providers: [
    AddressService
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule
  ],
  exports: [
    AddressComponent
  ]
})
export class AddressModule { }
