<ion-toolbar>
  <ion-searchbar
    [(ngModel)]="search"
    [showCancelButton]="true"
    (ionChange)="searchByEmailOrClient()"
    placeholder="Search by email or client's name that referred:"
  ></ion-searchbar>
</ion-toolbar>

<ion-list lines="true" *ngIf="referralCards.length > 0">
  <ng-container *ngFor="let referralCard of referralCards">
    <ng-container *ngFor="let referral_card_entry of referralCard.referral_card_entries">
      <ion-item *ngIf="referral_card_entry.status == 'scanned'" button (click)="linkToReferral(referralCard, referral_card_entry)">
        <ion-label class="ion-text-wrap">
          <h2>
            <strong>Referred By: {{referralCard.sent_from_name || referralCard.client_id}}</strong>
          </h2>
          <ion-note *ngIf="referral_card_entry.send_to">
            Sent to: {{referral_card_entry.send_to}}
          </ion-note>
          <ion-note *ngIf="!referral_card_entry.send_to">
            It appears they shared it via email. We cannot see the recipient(s).
          </ion-note>
        </ion-label>

        <ion-note slot="end">
          <small>Scanned At: {{referral_card_entry.scanned_at | date:'short'}}</small>
        </ion-note>
      </ion-item>
    </ng-container>
<!--    <ion-item button *ngFor="let referral_card_entry of referralCard.referral_card_entries" (click)="linkToReferral(referralCard, referral_card_entry)">-->
<!--      <ng-container *ngIf="referral_card_entry.status == 'scanned'">-->
<!--        <ion-label class="ion-text-wrap">-->
<!--          <h2>-->
<!--            <strong>Referred By: {{referralCard.sent_from_name || referralCard.client_id}}</strong>-->
<!--          </h2>-->
<!--          <ion-note>Sent to: {{referral_card_entry.send_to || "Unknown <&#45;&#45; this isn't a problem :)"}}</ion-note>-->
<!--        </ion-label>-->

<!--        <ion-note slot="end">-->
<!--          <small>Scanned At: {{referral_card_entry.scanned_at | date:'short'}}</small>-->
<!--        </ion-note>-->
<!--      </ng-container>-->
<!--    </ion-item>-->
  </ng-container>
</ion-list>
