import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ITransaction, Payment} from '../../../../../models/finance';
import {HttpClient} from '@angular/common/http';
import {GlobalsService} from '../../../../../globals.service';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash'
import {TransactionsProvider} from './transactions.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  private total = new BehaviorSubject(<number>0);
  private payments = new BehaviorSubject(<Payment[]>[]);
  total$: Observable<number> = this.total.asObservable()
  payments$: Observable<Payment[]> = this.payments.asObservable();
  base_url: string;

  constructor(
    private global: GlobalsService,
    private http: HttpClient,
    private transactionsService: TransactionsProvider) {

    this.base_url = this.global.base_url

    this.payments$.subscribe( payments => {
      let total = 0
      for (let payment of payments) {
        payment.amount && payment.amount > 0 ? total -= payment.amount : total += payment.amount
      }
      this.total.next(total)
    })
  }

  addPayment(payment: Payment) {
    let payments = this.payments.getValue()
    let transactions = this.transactionsService.getTransactions()
    transactions.push(payment)
    payments.push(payment)
    this.payments.next(payments)
    console.log(transactions)
    this.transactionsService.setTransactions(transactions)
  }

  setPayments(payments: Payment[]) {
    this.payments.next(payments)
  }

  getAndSetPayments(client_id) {
    let final_url = `${ this.base_url }/clients/${ client_id }/payments`
    this.http.get<Payment[]>(final_url).subscribe(
      success => {
        this.payments.next(success)
      },
      err => {
        this.global.handleResponse(err.error)
      }
    )
  }

  create(payment: Payment) {
    console.log("creating payment", payment)
    let url = `${this.base_url}/clients/${ payment.client_id }/payments`
    return this.http.post<Payment>(url, payment).pipe(
      tap(payment => this.addPayment(payment))
    )
  }

  update(payment: Payment) {
    let curr_payments = this.payments.getValue()
    let curr_transactions = this.transactionsService.getTransactions()
    let final_url = `${ this.base_url }/clients/${ payment.client_id }/payments/${ payment.id }`
    return this.http.put<Payment>(final_url, payment).pipe(
      tap(payment => {
        let transactionIndex = _.findIndex(curr_transactions, {id: payment.id, tran_type: "payment"})
        curr_transactions[transactionIndex] = payment
        this.setPayments(curr_payments)
        this.transactionsService.setTransactions(curr_transactions)
      })
    )
  }

  deletePayment(payment: Payment|ITransaction) {
    console.log("Delete Payment: ", payment)
    let curr_transactions = this.transactionsService.getTransactions()
    let final_url = `${ this.base_url }/clients/${ payment.client_id }/payments/${ payment.id }`
    return this.http.delete(final_url).pipe(
      tap(() => {
        let newVal = _.pull(this.payments.getValue(), payment)
        this.setPayments(newVal)
        let updatedTransactions = _.pull(curr_transactions, payment)
        this.transactionsService.setTransactions(updatedTransactions)
      })
    )
  }
}
