import {Injectable, OnDestroy} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {BasicCheckIn, IBasicCheckIn, ICheckIn} from '../models/check_in';
import {ModalController} from '@ionic/angular';
import {ClientService} from '../pages/clients/clients.service';
import {GlobalsService} from '../globals.service';
import {tap} from 'rxjs/operators';
import {IBasicWeighIn, IWeighIn} from '../models/weigh-in';
import {IBasicRepositioning, IRepositioning} from '../models/repositioning';
import {ProgramsService} from '../pages/clients/client-detail/providers/programs/programs.service';
import {IProgram} from '../models/program';
import {AwakenModal} from '../shared/awaken-modal/awaken-modal.component';
import {Router} from '@angular/router';
import {Client} from '../models/client';
import * as Sentry from 'sentry-cordova';
import {IReEvaluation} from '../models/re-evaluation';
import {IReminder} from '../models/reminder';

export interface IMeasurement {
  id: number;
  date?: string;
  body_fat?: number;
  current_weight?: number;
  visceral_fat?: number;
}

@Injectable({
  providedIn: 'root'
})
export class CheckInsProvider implements OnDestroy {

  private check_ins = new BehaviorSubject([] as ICheckIn[]);
  check_ins$ = this.check_ins.asObservable();

  private programOverviews = new BehaviorSubject([] as IProgram[])
  programOverviews$ = this.programOverviews.asObservable();

  pendingCoachcareCheckInInfo: {start_date: string, end_date: string, list_length: number};

  private visitsUpdated = new BehaviorSubject(false as boolean);
  visitsUpdated$ = this.visitsUpdated.asObservable();

  private measurements = new BehaviorSubject([] as IMeasurement[])
  measurements$ = this.measurements.asObservable();
  measurementsLoaded = new BehaviorSubject(false as boolean);
  measurementsLoaded$ = this.measurementsLoaded.asObservable();

  reversed_check_ins = new BehaviorSubject([] as ICheckIn[]);
  reversed_check_ins$ = this.reversed_check_ins.asObservable()
  has_week_numbers = true;
  numberOfProducts = new BehaviorSubject(0 as number);
  numberOfProducts$ = this.numberOfProducts.asObservable();
  program_status: string;
  base_url: string;
  used_weeks = 0
  num_weeks = 0
  programSubscription: Subscription;
  allProgramSubscription: Subscription;
  most_recent_weigh_in: IWeighIn;

  constructor( private global: GlobalsService,
               private http: HttpClient,
               private router: Router,
               private modalCtrl: ModalController,
               private clientService: ClientService,
               private programsService: ProgramsService ) {

    this.base_url = this.global.base_url
    this.program_status = this.clientService.getClient()?.program_status
    this.programSubscription = this.programsService.programs$.subscribe(
      programs => this.setupPrograms(programs)
    )

    this.allProgramSubscription = this.programsService.allPrograms$.subscribe(
      programs => this.setupPrograms(programs)
    )
  }

  ngOnDestroy(): void {
    if (this.programSubscription) this.programSubscription.unsubscribe()
    if (this.allProgramSubscription) this.allProgramSubscription.unsubscribe()
  }

  setupPrograms(programs: IProgram[]) {
    console.log('PROGRAMS', programs)
    const data = this.setupVisits(programs)
    this.setupMeasurements(data.measurements)

    if (!_.isEmpty(programs)) {
      this.pendingCoachcareCheckInInfo = programs[0].pending_coachcare_check_in_info
    }

    this.setCheckIns(data.checkIns, false)
    this.setProgramOverviews(data.overviews)
    this.calculateUsedWeeks()
  }


  setupMeasurements(measurements: IMeasurement[]) {
    if (!_.isEmpty(measurements)) {
      this.setMeasurements(measurements)
      this.measurementsLoaded.next(true)
    }
  }


  setupVisits(programs: IProgram[]) {
    const overviews = []
    const measurements = []
    const checkIns = _.flatten(_.transform(programs, (result, p) => {
      if (p.check_ins && p.check_ins.length > 0) {
        p.check_ins.forEach(ci => {
          if (ci.weigh_in) {
            const weigh_in = ci.weigh_in;
            const measurement = {
              id: weigh_in.id,
              date: weigh_in.date,
              body_fat: weigh_in.body_fat,
              current_weight: weigh_in.current_weight,
              visceral_fat: weigh_in.visceral_fat
            };
            measurements.push(measurement);
          }
          ci.segmentColor = p.color;
        });
        result.push(p.check_ins);
      }
      if (p.program_overview) {
        overviews.push(p);
      }
    }, []));

    return {overviews, measurements, checkIns}
  }


  /**
   * Sets the measurements for the profile charts.
   * @param measurements Array of measurements
   */
  setMeasurements(measurements: IMeasurement[]): void {
    this.measurements.next(measurements)
  }


  /**
   * Sets the value for {@link CheckInsProvider#programOverviews}
   * @param programOverviews The overview of the program when it is not the active program.
   */
  setProgramOverviews(programOverviews : IProgram[]) : void {
    this.programOverviews.next(programOverviews)
  }


  // Check Ins ----------------------------------------------------------------------------


  /**
   * Creates a new check in in the database and calls {@link CheckInsProvider#setCheckIns}
   * @param check_in The check in to be created.
   */
  create(check_in: ICheckIn) {
    const url = `${this.base_url}/check_ins`
    return this.http.post<ICheckIn>(url, check_in).pipe(
      tap( (ci) => {
        // let checkIns = this.getCheckIns()
        // checkIns.unshift(ci)
        // this.setCheckIns(checkIns)
        const programs = this.programsService.getPrograms()
        const program = programs[_.findIndex(programs, {id: ci.program_id})]
        program?.check_ins?.unshift(ci)
        console.log(program, programs)
        this.programsService.setPrograms(programs)
      })
    )
  }


  /**
   * Updates the check_in in the database and calls {@link CheckInsProvider#setCheckIns}
   * @param checkIn The check_in being updated
   * @param sendEmail Whether to send the client the report card email
   * @param setPrograms Whether this update should impact programs
   */
  update(checkIn: ICheckIn, sendEmail = false, setPrograms = true) {
    const currCheckIns = this.check_ins.getValue()
    const url = `${ this.base_url }/check_ins/${ checkIn.id }?sendReportCardEmail=${sendEmail}`

    return this.http.put<ICheckIn>(url, checkIn).pipe(
      tap((newCheckIn) => {
        console.log(newCheckIn)
        const index = _.findIndex(currCheckIns, { id: checkIn.id })
        currCheckIns.splice(index, 1, newCheckIn)
        this.setCheckIns(currCheckIns, setPrograms)
        if (setPrograms) this.updatePrograms(newCheckIn.client_id)
        if (newCheckIn.reminders) this.launchReminderModals(newCheckIn.reminders).then(() => console.log('done'))
      })
    )
  }


  async launchReminderModals(reminders: IReminder[]) {
    for (const reminder of reminders) {
      const modal = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          title: `Week Based Reminder: ${reminder.body}`,
          urgent: true
        },
        cssClass: 'small-modal'
      })

      await modal.present()
      await modal.onDidDismiss()
    }
  }


  updatePrograms(client_id: number) {
    console.log('refreshing programs')
    this.programsService.fetchClientPrograms(client_id).subscribe()
  }


  /**
   * Destroys the provided check_in from the database and updates the value of {@link CheckInsProvider#check_ins}
   * @param check_in
   */
  destroy(check_in: ICheckIn) {
    const url = `${ this.base_url }/check_ins/${ check_in.id }`
    const curr_check_ins = this.getCheckIns()
    const programs = this.programsService.getPrograms()
    const program_index = _.findIndex(programs, {id: check_in.program_id})
    const index = _.findIndex(programs[program_index]?.check_ins, {id: check_in.id})

    return this.http.delete(url).pipe(
      tap(() => {
        if (index) _.pullAt(programs[program_index].check_ins, index)
        this.programsService.setPrograms(programs)
      })
    )
  }

  /**
   * Updates the CheckIn with id: visibleCheckInId to visible and check_ins with IDs in hiddenCheckInIds to hidden.
   * @returns the check_ins.client
   * @param visibleCheckInId The check-in ID being marked visible
   * @param hiddenCheckInIds The check-in IDs for those being marked hidden
   */
  bulkUpdateVisibilityStatus(visibleCheckInId: number, hiddenCheckInIds: number[]) : Observable<Client> {
    const url = `${ this.base_url }/check_ins/bulk_update_visibility_status`
    return this.http.post<Client>(url, {visible_id: visibleCheckInId, hidden_ids: hiddenCheckInIds}).pipe(
      tap(client => {
        // this.setCheckIns(_.reverse(client.check_ins))
      })
    )
  }


  show(checkInId: number) : Observable<{check_in: BasicCheckIn, warning: {title: string, subtitle: string}}> {
    const url = `${this.base_url}/check_ins/${checkInId}`;
    return this.http.get<{check_in: BasicCheckIn, warning: {title: string, subtitle: string}}>(url)
  }


  /**
   * @returns the index of the first official visit (has an associated type of weigh in and data source = 0.
   */
  firstOfficialVisitIndex() : number {
    const checkIns = this.getCheckIns()
    return _.findIndex(checkIns, (ci) => { return ci.type_of_weighin !== null || ci.weigh_in_data_source == 0 })
  }


  /**
   * Informs you whether there are unvalidated (pending) visits already present to prevent you from creating multiple.
   */
  pendingVisitsPresent() {
    const unvalidated = _.filter(this.getCheckIns(), { validated: false });
    if ( unvalidated && unvalidated.length > 0 ) {
      return true
    }
    return false
  }


  /**
   * Fetches a specific check_in from the database.
   * @param check_in_id
   */
  fetchCheckIn(check_in_id: number) : Observable<ICheckIn> {
    const url = `${this.base_url}/check_ins/${check_in_id}`
    return this.http.get<ICheckIn>(url)
  }


  fetchClientCheckIns(client_id) : Observable<IBasicCheckIn[]> {
    const url = `${this.base_url}/clients/${client_id}/check_ins`
    return this.http.get<IBasicCheckIn[]>(url).pipe(
      tap(data => {
        this.setCheckIns(data)
      })
    )
  }


  /**
   * Reorders a client's programs and check ins based on the IDs provided.
   * @returns the client's programs and their check_ins in the newly defined order
   * @example reorderVisits(243, [3,4,1,2])
   * @param client_id
   * @param visitIDs
   */
  reorderVisits( client_id : number, visitIDs : number[] ) : Observable<IProgram[]> {
    const url = `${this.base_url}/clients/${client_id}/apply_week_numbers`
    return this.http.post<IProgram[]>(url, {change_array: visitIDs}).pipe(
      tap(programs => {
        console.log(programs)
        this.programsService.setPrograms(programs)
        this.programsService.setAllPrograms(programs)
      })
    )
  }

  // Re-Evaluations

  addReEvaluationToCheckIn(re_evaluation: IReEvaluation): void {
    console.log(this.getCheckIns())
    const check_in = _.find(this.getCheckIns(), {id: re_evaluation.check_in_id})
    check_in.re_evaluation = re_evaluation
    console.log(this.getCheckIns())
    this.setCheckIns(this.getCheckIns())
  }


  // Helpers -------------------------------------------------------------------------

  /**
   * This check in's prior repositioning to compare to
   * @param check_in
   */
  priorRepositioning( check_in : BasicCheckIn ) : IBasicRepositioning|null {
    const priorCheckIn = this.priorCheckIn(check_in)
    if (priorCheckIn) return priorCheckIn.repositioning
    return null
  }


  /**
   * @returns the previous check in (in order from newest to oldest)
   * @param checkIn The check-in whose prior you are looking for.
   * @param includeCoachcare Whether coachcare check ins should be included or not.
   * @param field Field required to be present in the prior check-in's weigh-in.
   */
  priorCheckIn( checkIn : BasicCheckIn | ICheckIn, includeCoachcare = false, field = null ) : IBasicCheckIn|null {
    let checkIns = this.getCheckIns()
    let priorCheckIn = null

    if ( !includeCoachcare ) {
      checkIns = _.filter(checkIns, (ci) => {
        return ci.weigh_in_data_source === 0 || ci.type_of_weighin !== null
      })
    }

    // If field is included you must filter the checkIns for those with a value for this measurement.
    if (field) {
      checkIns = _.filter(checkIns, (ci) => {
        return ci.weigh_in && ci.weigh_in[field]  !== null
      })
    }

    if ( checkIn ) {
      const index = _.findIndex(checkIns, {id: checkIn.id})
      priorCheckIn = checkIns[index + 1] // checkIns is in order from most recent to oldest
    }
    return priorCheckIn
  }


  /**
   * This check_in's prior weigh in.
   * @param check_in
   */
  priorWeighIn( check_in : BasicCheckIn, field = null ) : IBasicWeighIn|null {
    const priorCheckIn = this.priorCheckIn(check_in, false, field)
    // console.log(check_in, priorCheckIn)
    if (priorCheckIn) return priorCheckIn.weigh_in
    return null
  }


  /**
   * Adds the counter to the visits depending on the type of weigh in and data source.
   * @param check_ins
   */
  addWeekCounter(check_ins: ICheckIn[]) {
    if (check_ins && check_ins.length) {
      let index = 0
      const original_index = index
      const programs = this.programsService.getPrograms().reverse()

      for (const program of programs) {
        if (!program.check_ins.length) {
          // console.log("prog id", program.id, program.program_overview.used_weeks)
          index += program.program_overview.used_weeks
        } else {
          if (programs.length && index !== 0) index++
          // console.log("has check_ins")

          for ( const check_in of check_ins ) {

            // For Coachcare check_ins
            if ( check_in.weigh_in_data_source === 1) {
              check_in.week_counter = check_in.type_of_weighin || 'CC';
              if (check_in.counts_towards_weeks) {
                check_in.week_counter = index.toString()
                index++
              }

              // For Non-Coachcare check_ins
            } else {
              if (check_in.type_of_weighin == 'standard' || check_in.type_of_weighin == 'Standard') {
                check_in.week_counter = index.toString()
                index++
              } else {
                if (check_in.type_of_weighin === 'start') {
                  check_in.week_counter = 'Restart'

                  // if 'restart' is the first weigh in type, count it as standard
                  if (original_index == index) {
                    index++
                  }
                } else {
                  check_in.week_counter = check_in.type_of_weighin
                }
              }
            }
          }
        }
      }
    }
  }

  checkForWeighIn(check_in) {
    if ( check_in.weigh_in != null ) { return true }
  }

  getEndsOfCheckInsWithWeighIns(check_ins) {
    const first_and_last = []

    if (check_ins) {
      let count = 0

      const num_check_ins = check_ins.length

      if (num_check_ins >= 2) {
        const check_ins_with_weigh_ins = []

        for (const ci of check_ins) {
          if (this.checkForWeighIn(ci)) {
            ++count
            check_ins_with_weigh_ins.push(ci)
          }
        }

        first_and_last.push(_.first(check_ins_with_weigh_ins))
        first_and_last.push(_.last(check_ins_with_weigh_ins))
      }
    }

    return first_and_last
  }


  async launchCompletedWeeksModal( purchased_weeks, num_weeks_remaining ) {
    console.log('in checkForTran in check-in.modal')
    const weeks_remaining = purchased_weeks - this.used_weeks;
    const client = this.clientService.getClient();

    console.log(weeks_remaining, num_weeks_remaining, client.program_status);

    if ( weeks_remaining === num_weeks_remaining && client.program_status == 'active' ) {
      const finshedWeeks = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          title: 'They\'ve finished their weeks!',
          subtitle: `It looks like they've finished their weeks.  Please choose whether they are
            moving onto maintenance or if they are purchasing more weeks.`,
          urgent: true,
          image: 'alert',
          type: 'prompt',
          // auth_token: this.global.getUser().auth_token,
          values: [
            {name: 'Full Maintenance', status: 'full maintenance', model: 'client', attribute: 'program_status', model_id: client.id},
            {
              name: 'Limited Maintenance',
              status: 'limited maintenance',
              model: 'client',
              attribute: 'program_status',
              model_id: client.id
            },
            {name: 'Purchase Weeks', status: 'active', model: 'client', attribute: 'program_status', model_id: client.id},
          ]
        },
        cssClass: 'medium-modal'
      });

      await finshedWeeks.present();

      finshedWeeks.onDidDismiss().then(data => {
        console.log(data)

        if ( !data.data ) return

        if ( data.data == 'no' ){

        } else if ( data.data.value == 'active' ) {
          this.router.navigate(['clients', client.id, 'finance'], { queryParams: {allowBack: true}})

        } else {
          client[data.data.attribute] = data.data.value
          this.clientService.update(client, false).subscribe()
        }
      })
    }
  }


  dismiss() {
    this.modalCtrl.dismiss()
  }


  calculateUsedWeeks(provided_check_ins = null) {
    let used_weeks = 0
    const programs = this.programsService.getPrograms()

    programs.forEach((program, index) => {
      if (program.check_ins.length) {

        // If this is the initial program, the first check_in should not count towards weeks
        const deductor = index == programs.length - 1 ? 1 : 0

        this.num_weeks = program.check_ins.length - 1
        const countTowardsWeeks = _.filter(program.check_ins, {type_of_weighin: 'standard', counts_towards_weeks: true})
        // console.log("CTW", Math.max(countTowardsWeeks.length - deductor, 0), used_weeks)
        used_weeks += Math.max(countTowardsWeeks.length - deductor, 0)
      } else {
        used_weeks += program.program_overview.used_weeks
        // console.log("PO", program.program_overview.used_weeks)
        // console.log(used_weeks)
      }
    })

    if (provided_check_ins) {
      const countTowardsWeeks = _.filter(provided_check_ins, {type_of_weighin: 'standard', counts_towards_weeks: true})
      used_weeks = Math.max(countTowardsWeeks.length - 1, 0)
      return used_weeks
    }

    this.used_weeks = used_weeks
  }


  /**
   * Calculates the number of used weeks on or before a given date
   * @param filterDate
   */
  usedWeeksOnDate(filterDate: moment.Moment): number {
    console.log(filterDate)
    const visits = this.getAllCheckIns()
    console.log(visits)

    const countTowardsWeeks = visits.filter(check_in => {
      const isStandard = check_in.type_of_weighin === 'standard'
      const countsTowardsWeeks = check_in.counts_towards_weeks
      const checkInDate = moment((check_in.date || check_in.created_at))

      return isStandard && countsTowardsWeeks && checkInDate <= filterDate
    })

    return Math.max(countTowardsWeeks.length - 1, 0)
  }


  getAllCheckIns(): ICheckIn[] {
    console.log(this.programsService.getPrograms())
    return _.flatMap(this.programsService.getPrograms(), 'check_ins')
  }



  setCheckIns(check_ins: ICheckIn[], setPrograms = true) {
    console.log('called set checkins', check_ins)

    if (setPrograms) {
      const programs = this.programsService.getPrograms()
      console.log('P', programs)
      const program_index = _.findIndex(programs, {id: check_ins[0].program_id})
      console.log('I', program_index)

      if (program_index === -1) {
        programs.unshift()
      }
      programs[program_index].check_ins = check_ins
      console.log('Calling programsService.setPrograms')
      this.programsService.setPrograms(programs)
    } else {
      this.check_ins.next(check_ins)
    }

    console.log('normal', check_ins)
    const reversed = _.reverse(Object.assign([], check_ins))

    const first_check_in_with_weighin = _.find(check_ins, (o) => o.weigh_in != null)
    if (first_check_in_with_weighin) this.most_recent_weigh_in = first_check_in_with_weighin.weigh_in

    // console.log("reversed", reversed)
    this.addWeekCounter(reversed)
    this.reversed_check_ins.next(reversed)
    this.visitsUpdated.next(true)



    // let program_id = check_ins.f
    // let program = _.find(this.programsService.getPrograms(), {id:
    console.log(`Used Weeks: ${this.used_weeks}`)
  }

  getCheckIns() : ICheckIn[] {
    return Object.assign([], this.check_ins.getValue())
  }

  // addCheckIn(check_in: ICheckIn) {
  //   let cis = this.check_ins.getValue()
  //   // cis.push(check_in)
  //   cis.unshift(check_in)
  //   this.setCheckIns(cis)
  // }

  // Weigh Ins -----------------------------------------------------------------------

  /**
   * Adds this weigh in to the proper check in and calls {@link CheckInsProvider#setCheckIns}
   * @param weigh_in
   */
  addWeighIn(weigh_in : IWeighIn) : void {
    console.log(weigh_in)
    const curr_check_ins = this.getCheckIns()
    const index = _.findIndex(curr_check_ins, {id: weigh_in.check_in_id})
    const new_ci = Object.assign({}, curr_check_ins[index])
    const program_id = new_ci.program_id
    new_ci.weigh_in = weigh_in
    curr_check_ins.splice(index, 1, new_ci)
    this.setCheckIns(curr_check_ins)

    const programs = this.programsService.getPrograms()
    const programIndex = _.findIndex(programs, {id: new_ci.program_id})
    const program = programs[programIndex]
    program.check_ins = curr_check_ins

    console.log(programs)

    this.programsService.setPrograms(programs)
    // this.updatePrograms(weigh_in.client_id)

  }


  /**
   * Following deletion of a weigh in, this removes it from it's check in and updates {@link CheckInsProvider#check_ins }
   * @param weigh_in
   */
  removeWeighIn(weigh_in : IBasicWeighIn) : void {
    const curr_check_ins = this.getCheckIns()
    const index = _.findIndex(curr_check_ins, {id: weigh_in.check_in_id})
    curr_check_ins[index].weigh_in = null;
    this.setCheckIns(curr_check_ins)
  }


  // Repositionings ------------------------------------------------------------------

  addRepositioning(repo : IRepositioning) {
    console.log('adding repo', repo)
    const currCheckIns = this.getCheckIns()

    if ( !currCheckIns || currCheckIns.length === 0 ) {
      Sentry.captureException('!currCheckIns || currCheckIns.length == 0')
      // this.global.handleResponse('TELL JEREMY ERROR: addRepo1, SCREENSHOT, DO NOT CLOSE', true)
      this.programsService.fetchClientPrograms(repo.client_id).subscribe()
    }

    const index = _.findIndex(currCheckIns, {id: repo.check_in_id})
    if ( index === -1 ) {
      Sentry.captureException(`Cannot locate check in with ID: ${repo.check_in_id}, Check Ins: ${JSON.stringify(currCheckIns)}`)
      // this.global.handleResponse('TELL JEREMY ERROR: addRepo2, SCREENSHOT, DO NOT CLOSE', true)
      this.programsService.fetchClientPrograms(repo.client_id).subscribe()
    }

    const checkIn = currCheckIns[index]

    if ( !checkIn ) {
      Sentry.captureException(`Located index with Check In ID: ${repo.check_in_id}, but cannot locate check in.  Check In length: ${currCheckIns.length} Index: ${index}`)
      // this.global.handleResponse('TELL JEREMY ERROR: addRepo3, SCREENSHOT, DO NOT CLOSE', true)
      this.programsService.fetchClientPrograms(repo.client_id).subscribe()
    }

    const visit = Object.assign({}, checkIn)
    visit.repositioning = repo

    Sentry.addBreadcrumb({
      message: `Verifying checkIn: ${JSON.stringify(checkIn)}`
    })

    // console.log(visit, repo, index)

    currCheckIns.splice(index, 1, visit)

    // console.log(`New Visit: `, checkIn)

    if ( !currCheckIns || currCheckIns.length === 0 ) {
      Sentry.captureException('TELL JEREMY ERROR: addRepo4, SCREENSHOT, DO NOT CLOSE')
      this.global.handleResponse('TELL JEREMY ERROR: addRepo4, SCREENSHOT, DO NOT CLOSE', true)
    }

    const programs = this.programsService.getPrograms()
    const programIndex = _.findIndex(programs, {id: visit.program_id})
    const program = programs[programIndex]
    program.check_ins = currCheckIns
    programs.splice(programIndex, 1, program)
    this.programsService.setPrograms(programs)

    this.setCheckIns(currCheckIns)
  }

  removeRepositioning(repo : IRepositioning) {
    const curr_check_ins = this.getCheckIns()
    const index = _.findIndex(curr_check_ins, {id: repo.check_in_id})
    curr_check_ins[index].repositioning = null;
    this.setCheckIns(curr_check_ins)
  }
}
