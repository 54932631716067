import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalsService} from '../globals.service';
import {Address, IAddress} from '../models/address_model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  base_url: string;

  constructor( public http: HttpClient,
               private global: GlobalsService ) {
    this.base_url = this.global.base_url
  }

  /**
   * Submits the given address to the server.
   * @param address
   */
  create( address : Address ) : Observable<Address> {
    let url = `${this.global.base_url}/addresses`;
    return this.http.post<Address>(url, address)
  }


  /**
   * Updates an address in the database.
   * @param address
   */
  update(address: Address): Observable<Address> {
    let url = `${this.global.base_url}/addresses/${address.id}`;
    return this.http.put<Address>(url, address)
  }

  createOrUpdate() {

  }
}
