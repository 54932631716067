import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ComponentHostDirective} from '../../component-host.directive';
import {GenericData} from '../../models/generic-data';

@Component({
  selector: 'a180-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss'],
})
export class CommonModalComponent implements OnInit {
  @Input() title: string;
  @Input() data: any;
  @Input() dataInterface: any;
  @Input() childComponent: {component: any, data: any};
  @Input() transparent = true;
  @Input() fullscreen = true;
  @Input() gradientBackground = true;
  @Input() toolbar: any;
  @ViewChild(ComponentHostDirective, {static: true}) componentHost: ComponentHostDirective;

  constructor(private modalCtrl: ModalController,
              private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    console.log(this)
    this.loadComponent()
  }

  dismiss(data = null) {
    this.modalCtrl.dismiss(data)
  }

  loadComponent() {
    // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.childComponent.component)

    const viewContainerRef = this.componentHost.viewContainerRef;
    viewContainerRef.clear()
    const componentRef = viewContainerRef.createComponent(this.childComponent.component);
    (componentRef.instance as GenericData).data = this.childComponent.data;
    (componentRef.instance as GenericData).modalCtrl = this.modalCtrl;
  }
}
