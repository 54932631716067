<ion-label class="py-2 d-flex align-items-center">
<!--  <ng-container>-->
    <ion-text
      [style.color]="(signedUp != undefined && signedUp != null) ? (signedUp == false ? 'red' : 'green') : 'black'"
      [ngClass]="[ signedUp != undefined && signedUp != null ? (signedUp == false ? 'consult-fail' : 'consult-sucess') : 'nada' ]"
    >
      <div class="flex align-items-center">
        <h3 class="pl-2 fw-500" style="line-height: 2.9em;" [ngClass]="{'strikethrough': (signedUp === false)}">
          {{ client.name || "Missing Name" | nameFormat }}
        </h3>
        <ion-badge class="ion-margin-horizontal ml-3 fw-400" *ngIf="location_id != null">
          <i class="fa fa-map-marker pr-2"></i>
          {{ locationName }}
        </ion-badge>
      </div>
    </ion-text>
    <ion-text *ngIf="showDetail">
      <div>
        <small class="fw-300">Client: {{id}}</small>
        <small class="fw-300"> User: {{user_id}}</small>
        <small class="fw-300"> MB: {{mindbody_id}}</small>
      </div>
    </ion-text>
<!--  </ng-container>-->

<!--  <ng-container-->
<!--    *ngIf="type == 'Consultation' ||-->
<!--                     type == 'Post-Consultation' ||-->
<!--                     type == 'World-Wide Consultation'">-->
<!--    <button [id]="client.id" style="background:none;"-->
<!--            [style.color]="(signedUp != undefined && signedUp != null) ? (signedUp == false ? 'red' : 'green') : 'black'"-->
<!--            [ngClass]="[ signedUp != undefined && signedUp != null ? (signedUp == false ? 'consult-fail' : 'consult-sucess') : 'nada' ]">-->
<!--      {{ client.name | nameFormat }}-->
<!--      <span class="chip chip-ios awaken-chip ion-padding-horizontal" *ngIf="client.location_id != null">-->
<!--            <span>-->
<!--              <i class="fa fa-map-marker pr-2"></i>-->
<!--              {{ locationName }}-->
<!--            </span>-->
<!--          </span>-->
<!--    </button>-->
<!--  </ng-container>-->

  <span *ngIf="client.name == null" style="font-size:13px;color:#aaa;">
    {{ mindbody_id }}
  </span>
</ion-label>
