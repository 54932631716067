import { Pipe } from '@angular/core';

@Pipe({
	name: 'capitalize',
})

export class Capitalize {

	transform(value) {
	  if ( !value ) return
		let words = value.split(" ")
		let new_words = []

		for (let word of words) {
			let new_word = word.charAt(0).toUpperCase() + word.slice(1);
			new_words.push(new_word)
		}

		return new_words.join(" ")
	}
}
