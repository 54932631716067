<ion-header  class="pt-2" [hidden]="allowScreenshot">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dismiss()" class="text-black">Close</ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">Create Zendesk Ticket</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" class="text-black" [disabled]="ticket_form.invalid" (click)="submitForm()">Submit</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="blue-red-gradient quicksand" [class.hidden]="allowScreenshot">
  <ion-grid [hidden]="allowScreenshot">
    <ion-row>
      <ion-col size="10" offset="1">
        <ion-card class="card awaken mt-5 p-5 google-pop-up-shadow">
          <div class="card-content">

            <form #ticket_form="ngForm" name="ticket_form">
              <ion-list>
                <ion-item class="border-top-0 better-validation" [class.invalid]="subjectx.invalid && ticket_form.dirty">
                  <ion-label position="floating">Title</ion-label>
                  <ion-input [(ngModel)]="subject" name="subject" required type="text" #subjectx="ngModel"></ion-input>
                </ion-item>
                <p class="text-danger pl-4" *ngIf="subjectx.invalid && ticket_form.dirty">
                  Title is required.
                </p>

                <ion-item class="better-validation" [class.invalid]="commentx.invalid && ticket_form.dirty">
                  <ion-label position="floating">Description</ion-label>
                  <ion-textarea [(ngModel)]="comment" name="comment" required rows="4" type="text" #commentx="ngModel"></ion-textarea>
                </ion-item>
                <p class="text-danger pl-4" *ngIf="commentx.invalid && ticket_form.dirty">
                  Comment is required.
                </p>

                <ion-row class="ion-no-padding">
                  <ion-col class="pl-4">
                    <ion-item class="better-validation" [class.invalid]="priorityx.invalid && ticket_form.dirty">
                      <ion-label position="stacked" class="blue fs-16">Priority</ion-label>
                      <ion-select [(ngModel)]="priority" name="priority" required interface="popover" #priorityx="ngModel">
                        <ion-select-option value="urgent">Urgent</ion-select-option>
                        <ion-select-option value="high">High</ion-select-option>
                        <ion-select-option value="normal">Normal</ion-select-option>
                        <ion-select-option value="low">Low</ion-select-option>
                      </ion-select>
                    </ion-item>
                    <p class="text-danger pl-4" *ngIf="priorityx.invalid && ticket_form.dirty">
                      Priority is required.
                    </p>
                  </ion-col>
                  <ion-col class="pr-4">
                    <ion-item class="better-validation" [class.invalid]="typex.invalid && ticket_form.dirty">
                      <ion-label position="stacked" class="blue fs-16">Type</ion-label>
                      <ion-select [(ngModel)]="type" name="type" required interface="popover" #typex="ngModel">
                        <ion-select-option value="problem">Bug</ion-select-option>
                        <ion-select-option value="question">Question</ion-select-option>
                        <ion-select-option value="task">Feature Request</ion-select-option>
                      </ion-select>
                    </ion-item>
                    <p class="text-danger pl-4" *ngIf="typex.invalid && ticket_form.dirty">
                      Type is required.
                    </p>
                  </ion-col>
                </ion-row>

                <ion-row class="ion-no-padding">
                  <ion-col size="6" class="pl-4">
                    <ion-item>
                      <ion-label class="blue">Include Client?</ion-label>
                      <ion-toggle [(ngModel)]="include_client" name="include_client"></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size="6" class="pr-4 flex align-items-center" *ngIf="include_client">
                    <ng-template [ngIf]="(this.clientService.client$ | async)" [ngIfElse]="noClient">
                      <ion-note class="fs-16 pl-4" *ngIf="(this.clientService.client$ | async); else noClient">
                        {{ (this.clientService.client$ | async)?.name }}
                      </ion-note>
                    </ng-template>

                    <ng-template #noClient class="text-muted pl-4">
                      <ion-note class="fs-16 pl-4">No Client Found</ion-note>
                    </ng-template>
                  </ion-col>
                </ion-row>
              </ion-list>
            </form>

          </div>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center mt-5">
      <ion-button fill="outline" class="white border-white" (click)="submitForm()">Submit</ion-button>
    </ion-row>
  </ion-grid>
</ion-content>
