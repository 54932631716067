import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import * as _ from 'lodash';
import {map, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {Storage} from '@ionic/storage';
import {GlobalsService} from '../globals.service';
import {AuthService} from '../auth/auth.service';
import {ManagerCheckIn} from '../models/check_in';
import {INotification} from '../models/notification';
import {Notification} from '../models/notification';
import {ModalController} from '@ionic/angular';
import {AwakenModal} from '../shared/awaken-modal/awaken-modal.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  base_url: string;
  auth_token: string;
  headers: HttpHeaders;

  private notifications = new BehaviorSubject<Notification[]>([])
  notifications$ = this.notifications.asObservable()

  private unread = new BehaviorSubject<Notification[]>([])
  unread$ = this.unread.asObservable()

  private read = new BehaviorSubject<Notification[]>([])
  read$ = this.read.asObservable()

  constructor(private global: GlobalsService,
              private http: HttpClient,
              private storage: Storage,
              private modalCtrl: ModalController,
              private auth: AuthService ) {

    this.base_url = this.global.base_url;
    this.notifications$.subscribe(notification_array => {
      let arr = _.filter(notification_array, { read_at: null })
      this.unread.next(arr)
    })

    this.read$ = this.notifications$.pipe(
      map((notification_array) => {
        let arr = _.sortBy(_.reject(notification_array, { read_at: null }), [
          function(o) { return moment(o.read_at)}])
          .reverse()
        this.read.next(arr)
        return arr
      })
    )
  }

  setNotifications(notifications : Notification[], setOnSignIn = false) {
    this.notifications.next(notifications)
    this.storage.set('notifications', notifications)
    console.log("setNotifications unread", this.unread.getValue())

    console.log(this)
  }

  create(notification: INotification) : Observable<INotification> {
    let url = `${this.base_url}/notifications`
    return this.http.post<INotification>(url, notification)
  }

  getNotifications() {
    return this.notifications.getValue()
  }

  getReadNotifications() {
    return this.read.getValue()
  }

  getUnreadNotifications() {
    return this.unread.getValue()
  }

  getAll(): Observable<Notification[]> {
    let url = `${this.base_url}/users/${this.auth.getUser().id}/notifications`
    console.log(url)
    return this.http.get<Notification[]>(url)
  }

  getNotificationClient( id: number ) : Observable<ManagerCheckIn> {
    let url = `${this.base_url}/users/${this.auth.getUser().id}/notifications/${id}/client`
    return this.http.get<ManagerCheckIn>(url)
  }

  update(notification: Notification) {
    let url = `${this.base_url}/users/${this.auth.getUser().id}/notifications/${notification.id}`
    return this.http.put<Notification>(url, notification).pipe(
      tap( success => {
        let notifications = this.getNotifications()
        let index = _.findIndex(notifications, { id: success.id })
        notifications.splice(index, 1, success)
        this.setNotifications(notifications)
        this.storage.ready().then(() => {
          this.storage.set('notifications', notifications)
          console.log(this.getNotifications())
          console.log(this.storage.get('notifications').then(val => console.log(val)))
        })
      })
    )
  }


  /**
   * Allows you to update a non-check in notification.  These notifications have no impact on storage and are more likely
   * general notifications for a client's profile (i.e. Do not display current weight).
   * @param notification
   */
  updateNonCheckInNotification(notification: INotification) : Observable<INotification> {
    let url = `${this.base_url}/notifications/${notification.id}`
    return this.http.put<INotification>(url, notification)
  }


  async launchUnreadNotificationModal(numberOfNotifications: number): Promise<boolean> {
    let pronoun = numberOfNotifications > 1 ? 'them' : 'it'
    let modal = await this.modalCtrl.create({
      component: AwakenModal,
      componentProps: {
        title: `You have ${numberOfNotifications} Unread Notification(s)`,
        subtitle: `Please read through ${pronoun} as soon as possible. Would you like to read ${pronoun} now?`,
        urgent: true,
        type: 'confirm',
        confirmationButtonAffirmative: 'Go To Notifications',
        confirmationButtonNegative: 'No',
      },
      cssClass: "small-modal"
    })

    await modal.present()
    return await modal.onDidDismiss().then( data => {
      return data?.data === 'yes';
    })
  }
}
