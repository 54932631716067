import {Injectable, OnDestroy} from '@angular/core';
import * as _ from 'lodash';
import {IMissingFieldError} from '../../models/external_document';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendForSignatureService implements OnDestroy {
  missingFields: {document_id: number, fields: IMissingFieldError[]}[] = [];
  _allFields = new BehaviorSubject([] as IMissingFieldError[]);
  allFields$ = this._allFields.asObservable()
  toggles: any = {};

  constructor() { }

  ngOnDestroy() {
    this.setMissingFields([])
    this.allFields = []
  }

  setMissingFields(missingFields: {document_id: number, fields: IMissingFieldError[]}[]) {
    this.missingFields = missingFields
    this.allFields = _.uniqBy(_.flatten(_.map(this.missingFields, 'fields')), 'message')
  }

  get allFields() {
    return this._allFields.getValue()
  }
  set allFields(val) {
    this._allFields.next(val)
  }


  /**
   * If not already present, adds the missing field to {@link missingFields}
   * @param event: Document
   */
  addToMissingFields(event: {document_id: number, missingFields: IMissingFieldError[]}): void {
    console.log('add to missing fields...', event)
    const document_id = event.document_id
    const missingFields = event.missingFields
    const existingDocumentFields: {
      document_id: number, fields: IMissingFieldError[]} = _.find(this.missingFields, {document_id}) || {document_id: null, fields: []
    }

    if (existingDocumentFields.fields.length) {
      missingFields.forEach(field => {
        const existingField = _.find(existingDocumentFields.fields, {message: field.message})
        if (!existingField) {
          existingDocumentFields.fields = [...existingDocumentFields.fields, field]
          this.setMissingFields([{document_id, fields: existingDocumentFields.fields}])
        }
      })
    } else if (!existingDocumentFields.fields.length) {
      const content = {document_id, fields: missingFields}
      this.setMissingFields([...this.missingFields, content])
    }
  }


  /**
   * Removes all the documents fields from {@link missingFields}
   * @param documentId: The document ID
   */
  removeFromMissingFields(documentId: number): void {
    this.setMissingFields(_.reject(this.missingFields, {document_id: documentId}))
  }
}
