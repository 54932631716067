<ion-list>
  <form #reminderForm="ngForm" name="reminderForm">
    <ion-item>
      <ion-label color="primary" position="stacked">Number of {{friendlyItemName}}:</ion-label>
      <ion-range
        [(ngModel)]="reminder.number_of_items"
        name="number_of_items"
        [min]="1"
        [max]="24"
        [pin]="true"
        [snaps]="true"
        [required]="true"
      ></ion-range>
    </ion-item>

    <ion-item>
      <ion-label
        color="primary"
        position="floating"
      >
        Reminder Content:
      </ion-label>
      <ion-textarea
        class="better-validation"
        [rows]="3"
        [spellcheck]="true"
        autocapitalize="sentences"
        [required]="true"
        [(ngModel)]="reminder.body"
        name="body"
      ></ion-textarea>
    </ion-item>

    <div class="text-center pt-5">
      <ion-button
        (click)="submitReminder()"
        [disabled]="reminderForm.invalid"
      >
        {{action === 'edit' ? 'Update' : 'Add'}} Reminder for {{reminder.number_of_items || 'n/a'}} {{friendlyItemName}} From Now
      </ion-button>
    </div>
  </form>
</ion-list>
