<ion-header>
  <ion-toolbar>
    <ion-buttons slot="secondary">
      <ion-button (click)="dismiss()">
        <ion-text color="primary">Close</ion-text>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="primary" *ngIf="showFullscreen">
      <ion-button (click)="dismiss(true)">
        <ion-text color="primary">Fullscreen</ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">{{ document_name }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="h-100">
    <ion-row class="h-100">
      <ion-col size="12" id="iframeContainer" class="h-100 align-items-center justify-content-center">
        <img *ngIf="fileType === 'image'" [src]="document_url" [alt]="document_name">

        <pdf-viewer
          *ngIf="this.base64file"
          [src]="{data: this.base64file}"
          [render-text]="true"
          [original-size]="false"
          [ngStyle]="{width: '100%', height: '100%'}"
        ></pdf-viewer>

        <iframe
          id="iFrame1"
          *ngIf="fileType === 'pdf'"
          [src]="this.safeUrl"
          [height]="iframeHeight"
          width="100%"
        ></iframe>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
