<ion-item-sliding class="documentRowSlider shaded-slider" #slider>
  <ion-item class="documentRow" style="background:none;">
    <ion-icon *ngIf="startIcon" [name]="startIcon.name" slot="start"></ion-icon>

    <ion-label class="pointer">
      <div class="flex align-items-center" (click)="handleClick($event)">
        <h2 class="pb-1">
          <strong class="pr-3">
            {{ label | titleize | capitalize }}
          </strong>
        </h2>
        <ion-badge
          *ngIf="badgeColor"
          [color]="badgeColor"
        >{{badgeLabel}}</ion-badge>
      </div>
      <p *ngIf="subLabel">
        {{ subLabel }}
      </p>
    </ion-label>

    <ion-icon *ngIf="endIcon.type !== 'button'" [name]="endIcon.name" slot="end"></ion-icon>

    <ion-button *ngIf="endIcon.type === 'button'" slot="end" fill="clear" (click)="handleIconClick($event, endIcon)">
      <ion-icon [name]="endIcon.name" style="font-size: 26px;"></ion-icon>
    </ion-button>
  </ion-item>

  <ion-item-options *ngIf="slidingOptions" side="end" class="sliding-button">
    <ion-item-option
      *ngFor="let option of slidingOptions"
      [color]="option.color"
      (click)="handleSlidingOptionClick($event, option)"
    >
      <ion-icon [name]="option.icon" class="mx-2"></ion-icon>
      {{ option.label }}
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>
