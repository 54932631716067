import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SignedDocument} from '../../../models/signed_document';
import {SignedDocumentsService} from '../signed-documents.service';

@Component({
  selector: 'signed-document-item',
  templateUrl: './signed-document-item.component.html',
  styleUrls: ['./signed-document-item.component.scss'],
})
export class SignedDocumentItemComponent implements OnInit {
  @Input() documents: SignedDocument[];
  @Input() showBackground: boolean = false;
  @Input() iconName: string;
  @Input() iconColor: string;
  @Output() documentSelected = new EventEmitter<SignedDocument>()

  constructor(public signedDocumentService: SignedDocumentsService) {}

  ngOnInit() {}

  verifySelectedStatus(document: SignedDocument): boolean {
    return this.signedDocumentService.selectedDocuments.indexOf(document) > -1
  }

  selectDocument(document: SignedDocument) {
    this.iconName = "checkmark-circle"
    this.iconColor = "success"
    this.documentSelected.emit(document)
  }
}
