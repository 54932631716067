import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from 'sentry-cordova';
import {environment} from '../../environments/environment';
// @ts-ignore
import packageInfo from "../../../package.json"

Sentry.init({
  dsn: 'https://03a7e54614834949a2fd2273c18dc107@o375628.ingest.sentry.io/5261277',
  release: packageInfo.version,
  beforeSend(event) {
    console.log(event)
    event.user = environment.current_user
    return event;
  },
  integrations(integrations) {
    return integrations.filter(integration => {
      if (!environment.production) {
        // Disables breadcrumbs unless in production mode
        return integration.name !== 'Breadcrumbs'
      }
    })
  }
});

@Injectable({
  providedIn: 'root'
})
export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error) {
    // console.log(`Error Status: ${error.status}`)
    super.handleError(error);
    try {
      if (error.status !== 401) {
        Sentry.captureException(error.originalError || error);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
