<ion-content class="width-80" style="left:10%;height:80%;border-radius:1em;">
  <ion-grid>
    <ion-row>
      <ion-col class="text-right">
        <ion-button slot="end" fill="clear" (click)="dismiss()">
          <ion-icon name="close" color="primary" class="fs-36"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="safeUrl">
      <ion-col class="text-center">
        <iframe [src]="safeUrl" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col [innerHtml]="content" size="10" offset="1"></ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ng-content></ng-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
