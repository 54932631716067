import {animate, state, style, transition, trigger} from '@angular/animations';

export const fadeInOutUpDown = trigger('fadeInOutUpDown', [
  transition(':enter', [
    style({transform: 'translateY(-100%)', opacity: 0}),
    animate(150, style({transform: 'translateY(0)', opacity: 1}))
  ]),
  transition(':leave', [
    style({transform: 'translateY(0)', opacity: 1}),
    animate('150ms ease-out', style({transform: 'translateY(-100%)', opacity: 0}))
  ])
])

export const dropdownAnimation = trigger('dropdownAnimation', [
  state('true', style({
    maxHeight: '{{startHeight}}px',
    overflow: 'hidden',
    opacity: 1
  }),  {params: {startHeight: 200}}),
  state('false',   style({
    maxHeight: '0px',
    overflow: 'hidden',
    opacity: 0
  })),
  transition('true => false', animate('500ms ease-out')),
  transition('false => true', animate('500ms ease-in'))
])
