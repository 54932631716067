import {Component} from '@angular/core';
import * as _ from 'lodash';
import {ModalController, NavParams} from '@ionic/angular';
import {CommentsProvider} from '../../../../../providers/comments.service';
import {Payment, Purchase} from '../../../../../models/finance';
import {ClientService} from '../../../clients.service';
import {Observable} from 'rxjs';
import {TransactionsProvider} from '../../providers/finances/transactions.service';
import {PurchasesService} from '../../providers/finances/purchases.service';
import {PaymentsService} from '../../providers/finances/payments.service';
import {GlobalsService} from '../../../../../globals.service';
import {Comment} from '../../../../../models/comment';
import {AuthService} from '../../../../../auth/auth.service';
import {CheckInsProvider} from '../../../../../providers/check-ins.service';
import {ConsultationProvider} from '../../../../../providers/consultation.service';
import {IClient} from '../../../../../models/client';
import { ReferralsService } from 'src/app/providers/referrals.service';

@Component({
  selector: 'new-transaction',
  templateUrl: 'new-transaction.modal.html'
})

export class NewTransaction {
  current_user: any;
  paymentMode: boolean;
  referral_used:boolean;
  purchaseMode: boolean;
  weeks: any;
  client: IClient;
  payment_comment: string;
  purchase_comment: string;
  has_referrals:boolean = false;
  weekly_cost: any;
  amount: any;
  paid_in_full: boolean = false;
  paymentOnly: boolean = false;
  paymentLength: number;
  purchaseLength: number;
  default_payment_amount: boolean;
  form: any;
  referral:any;
  discount: any;
  client_id: any;
  trans_type: any;
  sale_type: string;

  constructor( public navParams: NavParams,
               private clientService: ClientService,
               private commentService: CommentsProvider,
               private consultationService: ConsultationProvider,
               private checkInsProvider: CheckInsProvider,
               private referralService: ReferralsService,
               private purchaseService: PurchasesService,
               private paymentService: PaymentsService,
               private auth: AuthService,
               public transactionService: TransactionsProvider,
               private global: GlobalsService,
               private modalCtrl: ModalController) {

    this.paymentMode = navParams.get('mode') == 'paymentMode';
    this.purchaseMode = false;
    this.client_id = navParams.get('client_id');
    this.has_referrals = this.checkForReferrals()
    this.current_user = this.auth.getUser()
    this.amount = navParams.get("amount")
    this.paymentOnly = navParams.get("paymentOnly")
    this.client = this.clientService.getClient()
  }

  checkForReferrals() {
    let unused_referrals = _.filter(this.referralService.getReferrals(), { 'used': false })
    if (unused_referrals && unused_referrals.length > 0 ) {
      this.referral = _.first(unused_referrals)
      return true
    }
    return false
  }

  selectMode(tran_type) {
    if ( tran_type == 'payment' ) {
      this.paymentMode  = true
      this.trans_type   = 'payment'
      this.purchaseMode = false
    } else {
      this.purchaseMode = true
      this.trans_type   = 'purchase'
      this.paymentMode  = false
    }
  }

  markReferralUsed() {
    this.referral.used = true
    this.referralService.update(this.referral).subscribe( referral => {
      this.referral = referral
    }, err => {
      this.global.handleResponse(err.error)
    })
  }

  checkOutWithReferral() {

    let referral_purchase: Purchase = {
      weeks: 1,
      weekly_cost: 0,
      client_id: this.client_id,
      program_id: this.clientService.getClient().program_id,
      referral_used: true,
      discount: this.discount || 0,
      sale_type: this.sale_type
    }

    let totalData = []

    this.purchaseService.createPurchase(referral_purchase).subscribe( ref_purchase => {
      totalData.push(ref_purchase)
      let comment = `Referral #${this.referral.id} used.  Referred ${this.referral.referred_name}.`
      if ( this.purchase_comment) comment += `  ${this.purchase_comment}`
      this.addComment(ref_purchase, comment, 'Purchase')
      this.weeks--
      this.purchase_comment = null

      this.markReferralUsed()

      if ( this.weeks > 0 ) {

        let std_purchase: Purchase = {
          weeks: this.weeks,
          weekly_cost: this.weekly_cost,
          client_id: this.client_id,
          program_id: this.clientService.getClient().program_id,
          discount: this.discount
        }

        this.purchaseService.createPurchase(std_purchase).subscribe( std_purchase => {
          totalData.push(std_purchase)

          // this.checkForPendingWeeks()

          if ( this.paid_in_full == true ) {
            this.payInFull(totalData);
          } else {
            this.modalCtrl.dismiss(totalData)
          }

        }, err => {
          this.modalCtrl.dismiss(totalData);
          this.global.handleResponse(err.error, true)
        })

      } else {
        this.modalCtrl.dismiss(totalData)
      }
    }, err => {
      this.modalCtrl.dismiss();
      this.global.handleResponse(err.error, true)
    })
  }

  /**
   * Moves the client 'on program'.  Fired after adding a purchase as that only occurs when the client is on program.
   */
  moveOnProgram() {
    let data = {
      id: this.client_id,
      program_status: 'active'
    }

    this.clientService.update(data).subscribe(
      success => {
        // this.client.program_status = 'active'
        let client = this.clientService.getClient()
        client.program_status = 'active'
        this.clientService.setClient(client)
      }, err => {
        this.global.handleResponse(err.error)
      }
    )
  }


  /**
   * Creates a purchase for the client.
   */
  createPurchase() {
    let purchase: Purchase = {
      weeks: this.weeks,
      weekly_cost: this.weekly_cost,
      discount: this.discount || 0,
      program_id: this.clientService.getClient().program_id,
      client_id: this.client_id,
      referral_used: this.referral_used,
      sale_type: this.sale_type
    }

    if (!this.transactionService.has_purchases) {
      this.consultationService.fetchByUniqueMB(this.clientService.getClient().unique_mb).subscribe(consult => {
        if (consult) {
          purchase.purchaseable_id = consult.id
          purchase.purchaseable_type = "Consultation"
        }
      })
    }

    if ( this.current_user && this.current_user.employee ) {
      purchase.employee_id = this.current_user.employee.id
    }

    if ( purchase.referral_used ) {
      console.log("Using Referral")
      this.checkOutWithReferral()

    } else {
      if (purchase.weekly_cost == 0 && this.current_user && this.current_user.user_type > 2) {
        this.global.handleResponse("You must enter a weekly cost > 0.  If this is an exception ask your manager to enter this sale.", true)
      } else {
        this.purchaseService.createPurchase(purchase).subscribe(
          success => {
            // this.checkForPendingWeeks()

            if (this.purchase_comment) this.addComment(success, this.purchase_comment, 'Purchase')
            this.moveOnProgram()

            if (this.paid_in_full == true) {
              this.payInFull(success);
            } else {
              this.modalCtrl.dismiss(success)
            }
          }, err => {
            this.global.handleResponse(err.error)
          }
        )
      }
    }
  }


  /**
   * Adds a payment for the client.
   */
  createPayment() {
    let payment: Payment = {
      amount: this.amount,
      client_id: this.client_id,
      program_id: this.clientService.getClient().program_id,
      employee_id: this.current_user.employee.id
    }

    if (!this.transactionService.has_payments) {
      this.consultationService.fetchByUniqueMB(this.clientService.getClient().unique_mb).subscribe(consult => {
        if (consult) {
          payment.paymentable_id = consult.id
          payment.paymentable_type = "Consultation"
        }
      })
    }

    this.processPayment(payment).subscribe(
      payment => {
        if ( this.payment_comment ) { this.addComment(payment, this.payment_comment, 'Payment') }
        this.modalCtrl.dismiss(payment)
      },
      err => {
        this.global.handleResponse(err.error)
        this.modalCtrl.dismiss()
      }
    )
  }


  /**
   * Creates a payment and interacts with the {@link paymentService}
   * @param payment_params
   */
  processPayment(payment_params): Observable<Payment> {
    let payment: Payment = {
      amount: payment_params.amount,
      client_id: payment_params.client_id,
      program_id: this.clientService.getClient().program_id,
      employee_id: payment_params.employee_id,
      default_payment_amount: this.default_payment_amount
    }

    if ( this.referral_used ) {
      console.log("referral is used")
      this.checkOutWithReferral()
      return
    }

    return this.paymentService.create(payment)
  }


  /**
   * Creates a comment of type: 'type' with body: 'body' for item supplied in 'data'.
   * @param data
   * @param body
   * @param type
   */
  addComment(data, body, type) {
    console.log(data, body, type)

    let comment : Comment = {
      body: body,
      commentable_id: data['id'],
      commentable_type: type
    }

    if ( this.current_user.employee ) { comment.employee_id = this.current_user.employee.id }

    this.commentService.create(comment).subscribe(
      success => {
        console.log(success, success['commentable_id'])
        this.commentService.addFinancialComment(success)
      }, err => {
        this.global.handleResponse(err.error)
      }
    )
  }


  /**
   * Generates a payment for the total purchase value (automatically).
   * @param prevData
   */
  payInFull(prevData) {
    let totalData = []

    Array.isArray(prevData) ? totalData = [...prevData] : totalData.push(prevData)

    console.log(prevData)
    let purch = new Purchase(_.last(totalData))

    console.log(purch)

    let payment : Payment = {
      client_id: this.client_id,
      program_id: this.clientService.getClient().program_id,
      amount: purch.total()
    }

    this.paymentService.create(payment).subscribe(
      payment => {
        totalData.push(payment)
        this.modalCtrl.dismiss(totalData)
      }, err => {
        this.global.handleResponse(err.error)
        this.modalCtrl.dismiss()
      }
    )
  }


  /**
   * Closes the modal.
   */
  dismiss() {
    this.modalCtrl.dismiss()
  }
}
