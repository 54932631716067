<ion-header>
  <ion-toolbar>
    <ion-buttons>
      <ion-button fill="clear" (click)="dismiss()">Close</ion-button>
    </ion-buttons>
    <ion-title>Exclude Client</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid class="h-100 d-flex flex-col ion-justify-content-evenly">
    <ion-row>
      <ion-col>
        <h2 class="ion-text-center">Please select what the client should be excluded from</h2>
        <br>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label>Type of exclusion:</ion-label>
            <ion-select [multiple]="true" [(ngModel)]="excluded">
              <ion-select-option *ngFor="let exclusionType of exclusionTypeOptions"
                [value]="exclusionType"
              >{{exclusionType | titleize | capitalize}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button expand="block" (click)="submit()">Submit</ion-button>
  </ion-toolbar>
</ion-footer>
