import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SignedDocumentsService} from '../signed-documents.service';

@Component({
  selector: 'selected-documents',
  templateUrl: './selected-documents.component.html',
  styleUrls: ['./selected-documents.component.scss'],
})
export class SelectedDocumentsComponent implements OnInit {
  @Output() complete = new EventEmitter<boolean>()

  constructor(public signedDocumentService: SignedDocumentsService) { }

  ngOnInit() {}

  emitCompletion(boolean) {
    this.complete.emit(boolean)
  }
}
