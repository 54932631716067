import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Address, IAddress} from '../../models/address_model';
import {IonInput} from '@ionic/angular';
import {GlobalsService} from '../../globals.service';
import {AddressService} from '../../providers/address.service';
import {states} from '../../shared/states';

declare var google: any;

@Component({
  selector: 'address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() client_id: number;
  @Input() set addressInput(value: IAddress) {
    this.address = value
    this.setAddressInputs()
  };

  @ViewChild('search', {static: true}) addressField: IonInput;
  @Output() addressOutput: EventEmitter<Address | null> = new EventEmitter<Address | null>();
  addressForm: FormGroup;
  address: Address;
  states: string[];

  get street() { return this.addressForm.get('street')}
  get street2() { return this.addressForm.get('street2')}
  get city() { return this.addressForm.get('city')}
  get state() { return this.addressForm.get('state')}
  get zip_code() { return this.addressForm.get('zip_code')}


  constructor( private fb: FormBuilder,
               private addressService: AddressService,
               private globals: GlobalsService ) { }

  ngOnInit() {
    this.states = states
    this.setAddressInputs()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.client_id) this.client_id = changes.client_id.currentValue
  }

  setAddressInputs() {
    if (!this.address) this.address = new Address({});
    this.addressForm = this.fb.group({
      client_id: [this.client_id],
      street: [this.address.street, [Validators.required]],
      street2: [this.address.street2],
      city: [this.address.city, [Validators.required]],
      state: [this.address.state, [Validators.required]],
      zip_code: [this.address.zip_code, [Validators.required]],
    })
  }

  ngAfterViewInit() {
    this.addressField.getInputElement().then((input) => {
      const autocomplete = new google.maps.places.Autocomplete(input, {
        types: ['address']
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        this.street.setValue(`${place.address_components[0].long_name} ${place.address_components[1].short_name}`)
        this.state.setValue(place.address_components[5].short_name)
        this.city.setValue(place.address_components[3].long_name)
        this.zip_code.setValue(place.address_components[7].long_name)
      });

    })
  }

  /**
   * Submits an address to the server for the given client.
   * Moves to following slide.
   */
  submitAddress() : void {
    const address = this.addressForm.value
    address.addressable_id = this.client_id
    address.addressable_type = 'Client'

    if (this.address.id) {
      address.id = this.address.id
      this.addressService.update(address).subscribe(
        val => {
          console.log(val);
          this.emit(val)
        },
        err => {
          this.globals.handleResponse(err.error, true)
        }
      );
    } else {
      this.addressService.create(address).subscribe(
        val => {
          this.emit(val)
        },
        err => {
          this.globals.handleResponse(err.error, true)
        }
      );
    }
  }

  /**
   * Emits a given address to parent component.
   * @param val: The address to emit
   */
  emit( val : Address | null = null ) : void {
    this.addressOutput.emit(val)
  }
}
