<ion-content>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-card-title class="flex justify-content-space-between align-items-center ion-text-center" color="danger">
            I discovered an authentication issue
          </ion-card-title>
          <ion-card-subtitle class="ion-text-center pt-2">
            I need you to re-enter your pin or head to the login page.
          </ion-card-subtitle>
        </ion-col>
      </ion-row>

      <ion-row class="ion-justify-content-center" [ngStyle]="{'margin-top': isSafe ? '0px' : '-16px'}">
        <ion-col class="text-center">

          <form #signInForm="ngForm" name="signInForm" *ngIf="isSafe">
            <ion-item>
              <ion-label position="stacked">Please enter your pin</ion-label>
              <ion-input [(ngModel)]="pin" type="password" inputmode="numeric" name="pin" #pinField="ngModel" autofocus="true" required="true" #pinInput></ion-input>
            </ion-item>
            <p class="ion-padding-start danger" *ngIf="signInForm.dirty && pinField.invalid">Pin is required</p>
            <div class="pt-5">
              <ion-button class="d-inline-block" color="danger" (click)="cancel()">Cancel...and go home.</ion-button>
              <ion-button (click)="submitPin()" class="d-inline-block mx-auto my-4" color="success" [disabled]="signInForm.invalid">Submit</ion-button>
            </div>
          </form>

          <form #signInForm="ngForm" name="signInForm" *ngIf="!isSafe">
            <ion-list>
              <ion-item class="better-validation" [class.invalid]="signInForm.dirty && emailField.invalid">
                <ion-label position="floating">Email</ion-label>
                <ion-input type="email" [(ngModel)]="email" name="email" #emailField="ngModel" autofocus="true" required="true" #pinInput></ion-input>
              </ion-item>
              <p class="ion-padding-start danger" *ngIf="signInForm.dirty && emailField.invalid">Email is required</p>
              <ion-item class="better-validation" [class.invalid]="signInForm.dirty && passField.invalid">
                <ion-label position="floating">Password</ion-label>
                <ion-input type="password" [(ngModel)]="password" name="password" #passField="ngModel" required="true"></ion-input>
              </ion-item>
              <p class="ion-padding-start danger" *ngIf="signInForm.dirty && passField.invalid">Password is required</p>

              <div class="pt-5">
                <ion-button class="d-inline-block" color="danger" (click)="cancel()">Cancel...and go home.</ion-button>
                <ion-button (click)="submitPin()" class="d-inline-block mx-auto my-4" color="success" [disabled]="signInForm.invalid">Submit</ion-button>
              </div>
            </ion-list>
          </form>

        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-content>
