import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IHealthProfile} from '../../models/health_profile';
import {ClientService} from '../../pages/clients/clients.service';
import {GlobalsService} from '../../globals.service';
import {IClient} from '../../models/client';
import {HealthProfilesProvider} from '../../pages/clients/client-detail/providers/health-profiles/health-profile.service';

@Component({
  selector: 'link-health-profile',
  templateUrl: 'link-health-profile.html'
})
export class LinkHealthProfileComponent implements OnInit, OnChanges {

  status: string;
  @Input() client: IClient;
  @Input() exitText: string;
  @Output() clientWasSelected = new EventEmitter<IHealthProfile>();
  // @Output() clientWasSelected = new EventEmitter<boolean>();
  has_health_profile: boolean = false;
  records: IHealthProfile[] = [];
  clientSearch: string;

  constructor( private hpService: HealthProfilesProvider,
               private clientService: ClientService,
               private global: GlobalsService) {
  }

  ngOnInit(): void {
    console.log(this)
    this.setStatus("<strong>Please search for the health profile below.</strong>");
    if ( this.client && this.client.health_profiles ) {
      if ( this.client.health_profiles.length > 0 ) { this.has_health_profile = true }
      if  ( !this.has_health_profile ) {
        if (this.client.id ) {
          this.searchForHealthProfile()
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.client ) this.client = changes.client.currentValue
    if (this.client.id ) this.searchForHealthProfile()
    console.log(this)
  }

  searchForHealthProfile(id = null) {
    let search_id = id || this.client['id']

    if ( search_id ) {
      this.hpService.findByClientID(search_id).subscribe(
        val => {
          let status = `After searching for potential matches I've found <strong><u>${val.length} complete</u></strong> health profiles!`;
          if (val.length > 0) {
            status += "  <br><strong><u>Please choose 1 of the following to link.</u></strong>"
          } else {
            status += "  <br><strong><u>Please search for the health profile below.</u></strong>"
          }
          this.setStatus(status);
          this.records = val;
        },
        err => this.global.handleResponse(err.error, true)
      )
    }
  }

  setStatus( text: string ) {
    this.status = text
  }

  cancel() {
    this.clientWasSelected.emit(null);
    // this.clientWasSelected.emit(false);
  }

  manualSearchForHealthProfile(event: any) {
    console.log(this.clientSearch, event)
    this.setStatus("Searching...")
    this.hpService.search(this.clientSearch).subscribe(
      val => {
        let status = `After searching I've located <strong>${val.length}</strong> health profiles.`
        if (val.length > 0) {
          status += "<br><strong><u>Please choose 1 of the following to link.</u></strong>"
        } else {
          status += "<br><strong><u>Please try altering your search (click 'help' below).</u></strong>"
        }
        this.setStatus(status);
        console.log(this.records)
        this.records = val
      },
      err => { this.global.handleResponse(err.error) }
    )
  }

  clientSelected(hp: IHealthProfile) {
    console.log("Client selected", hp)
    this.clientWasSelected.emit(hp)
  }
}
