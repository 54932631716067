import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {User} from '../../models/user';
import {Storage} from '@ionic/storage';
import {IonInput, ModalController, NavController, Platform} from '@ionic/angular';
import {GlobalsService} from '../../globals.service';
import {AuthService} from '../auth.service';

@Component({
  selector: 'login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})

export class LoginModal implements OnInit {

  pin: string;
  email: string;
  password: string;
  isSafe: boolean;

  @ViewChild('pinInput', {static: false}) myInput: IonInput;
  @Input() prevUser: User;

  constructor( private storage: Storage,
               public plt: Platform,
               private globalService: GlobalsService,
               private auth: AuthService,
               private navCtrl: NavController,
               private modalCtrl: ModalController) {
    this.isSafe = this.plt.is('ipad') || /localhost/.test(document.location.host);
  }

  ngOnInit() {}

  ionViewDidEnter() : void {
    setTimeout(() => {
      this.myInput.setFocus()
    }, 400);
  }


  /**
   * Cancel login and route to the login page.
   */
  cancel() : void {
    this.modalCtrl.dismiss({success: false})
    this.auth.logout().then(() => {
      this.navCtrl.navigateRoot("/login")
    })
  }

  /**
   * Login w/ pin and update the value for current_user.
   */
  submitPin() : void {
    let pin = this.pin ? this.pin.toString() : null
    this.auth.newLogin(pin, this.email, this.password).subscribe(
      successData => {
        this.storage.set('isLoggedIn', true);
        this.storage.set('current_user', successData);
        let notifications = successData['notifications'] || []
        this.storage.set("notifications", notifications)
        this.modalCtrl.dismiss({success: true})
        this.auth.authModalIsOpen = false;
      },
      error => {
        this.globalService.handleResponse(error.error)
      }
    )
  }
}

