<a180-profile-menu
  [client]="this.client"
  (tabSelected)="selectTab($event)"
></a180-profile-menu>

<div class="ion-page" id="main-content">
  <ion-header [class.darkMode]="titleService.darkMode" [translucent]="titleService.isScrolled$ | async" [class.translucent]="titleService.isScrolled$ | async">
    <ion-toolbar [class.transparent]="titleService.transparentHeader" *ngIf="!titleService.hideToolbar">
      <ion-buttons slot="start">
        <ion-back-button
          defaultHref="clients"
          [color]="(titleService.isScrolled$ | async) ? 'dark' : 'light'"
          (click)="goBack()">
        </ion-back-button>
        <ion-menu-button
          class="pl-4"
          [color]="(titleService.isScrolled$ | async) ? 'dark' : 'light'"
          menu="profileMenu"
        ></ion-menu-button>
      </ion-buttons>
      <ion-title *ngIf="!titleService.hideTitle">{{ titleService.title$ | async }} - {{ (clientService.client$ | async)?.name }}</ion-title>
      <ion-buttons slot="end">
        <logout-component [color]="(titleService.isScrolled$ | async) ? 'dark' : 'light'"></logout-component>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-tabs #tabs>
    <ion-tab-bar slot="bottom" color="dark">
      <ion-tab-button tab="profile">
        <ion-icon name="person"></ion-icon>
        <ion-label>Profile</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="visits">
        <ion-icon name="scale"></ion-icon>
        <ion-label class="fs-10">Weigh Ins</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="check-ins">
        <ion-icon name="calendar"></ion-icon>
        <ion-badge color="primary" *ngIf="(dailyCheckInsService.numberOfResponses | async) > 0">
          {{ dailyCheckInsService.numberOfResponses | async }}
        </ion-badge>
        <ion-label class="fs-10">Check Ins</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="finance">
        <ion-icon name="cash"></ion-icon>
        <ion-badge color="danger" *ngIf="(transactionsService.balance$ | async) > 0 ? '!' : ''">
          !
        </ion-badge>
        <ion-label>Finance</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="documents">
        <ion-icon name="document"></ion-icon>
        <ion-label>Documents</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="food-orders" *ngIf="(clientService.client$ | async)?.has_food_orders || (clientService.client$ | async)?.location_name === 'At Home'">
        <i class="fa fa-lunch fs-30"></i>
        <ion-label class="fs-10">Food Orders</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="images">
       <ion-icon name="images"></ion-icon>
       <ion-badge color="danger" *ngIf="(individualClientService.alerts$ | async)?.images > 0">
         {{ (individualClientService.alerts$ | async)?.images }}
       </ion-badge>
       <ion-label>Images</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="meals" *ngIf="(clientService.client$ | async)?.coach_care_account_created_at || (clientService.client$ | async)?.invited_to_awaken_app_at">
        <ion-icon name="book"></ion-icon>
        <ion-badge color="danger" *ngIf="(clientService.client$ | async)?.has_meals">!</ion-badge>
        <ion-label>Meals</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</div>
