import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {GlobalsService} from '../globals.service';
import {ILocation} from '../models/location';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class LocationService {

  locations: any;
  locationSubject = new BehaviorSubject([] as ILocation[])
  locations$ = this.locationSubject.asObservable()

  constructor( private globalVarService: GlobalsService,
               private http: HttpClient,
               private auth: AuthService,
               private storage: Storage) {}


  /**
   * Fetches locations from the server and updates the value for locations.
   */
  getLocations(): Observable<ILocation[]> {
    const url = `${this.globalVarService.base_url}/locations`;
    if (this.auth.isLoggedIn) {
      return this.http.get<ILocation[]>(url).pipe(
        tap(locations => {
          this.globalVarService.setLocations(locations);
          this.storage.set('locations', locations);
          this.setLocations(locations)
        })
      )
    } else {
      return of([])
    }
  }


  /**
   * Sets the local value for locations
   * @param locations: Locations to be set
   */
  setLocations( locations : ILocation[] ) {
    if (locations === null) {
      // this.getLocations().subscribe()
    } else {
      this.locations = locations;
      this.locationSubject.next(locations)
    }
  }


  /**
   * Grabs the name associated with the location that has id: {@param locationId}
   *
   * @example
   * Fetch the Newton location's name
   * getLocationName(1)
   *
   * @param locationId: The location's ID to fetch
   * @returns location name
   */
  getLocationName( locationId : number ): string {
    const loc = _.find(this.locations, {id: locationId})
    if (loc) return loc.name
  }


  /**
   * Finds the location based on a location name.
   * @param locationName: The location name to search for
   */
  getLocationByName(locationName: string): ILocation {
    return _.find(this.locations, {name: locationName})
  }
}
