import {Injectable} from '@angular/core';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {ExtendedCropOptions} from '../models/crop';
import {Crop} from '@ionic-native/crop/ngx';
import {ActionSheetController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {IClient} from '../models/client';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  imageData: string;
  imageSource: number;

  constructor( private camera: Camera,
               private http: HttpClient,
               private crop: Crop,
               private actionSheetController: ActionSheetController) {
  }


  /**
   * Allows user to select or take a photo
   * @param srcType: 0 -> library, 1 -> camera
   * @returns Promise containing image data
   */
  selectImage(srcType : number = 1) : Promise<any> {

    let options: CameraOptions = {
      quality: 100,
      sourceType: srcType,
      targetWidth: 600,
      targetHeight: 1200,
      destinationType: this.camera.DestinationType.FILE_URI,
      correctOrientation: true,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE
    }

    return new Promise(resolve => {
      this.camera.getPicture(options)
        .then((imageData) => {

          // this.imageData = "data:image/jpeg;base64," + imageData;
          this.imageData = imageData;

          console.log("in photo", this.imageData)

          resolve(this.imageData)
        })
        .catch(err => alert(err))
    })
  }


  /**
   * Allows user to crop a photo to the desired size
   * @param fileUrl
   * @param cropOptions
   * @returns Promise containing path to cropped photo.
   */
  cropImage(fileUrl, cropOptions) : Promise<any> {
    return new Promise( (resolve, reject) => {
      this.crop.crop(fileUrl, cropOptions).then(
        newPath => {
          resolve(newPath.split('?')[0])
        },
        error => {
          reject(error)
        }
      );
    })
  }


  /**
   * Begins the process of photo selection and calls {@link cropImage}
   * @param sourceType
   * @returns Promise containing path to cropped photo
   */
  pickImage(sourceType: number) : Promise<any> {
    const cropOptions : ExtendedCropOptions = {
      quality: 50,
      widthRatio: 3,
      heightRatio: 4
    }

    return new Promise((resolve, reject) => {
      this.selectImage(sourceType).then( image => {
        this.cropImage(image, cropOptions)
          .then( path => {
            resolve(path)
          })
          .catch( error => reject(error.err))
      })
    })
  }


  /**
   * Presents {@link actionSheetController} with choices for camera or library to select a photo.
   */
  async selectImageSheet() : Promise<any> {
    const actionSheet = await this.actionSheetController.create({
      header: "Select Image source",
      buttons: [
        {
          text: 'Load from Library',
          handler: () => {
            this.imageSource = this.camera.PictureSourceType.PHOTOLIBRARY
          }
        },
        {
          text: 'Use Camera',
          handler: () => {
            this.imageSource = this.camera.PictureSourceType.CAMERA
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            this.imageSource = null
          }
        }
      ]
    })
    await actionSheet.present();
    await actionSheet.onDidDismiss()
  }

  /**
   * Sends email to team about a good before and after photo.
   * @param clientId The client's ID
   */
  bragAboutPhotos(clientId: number): Observable<IClient> {
    const url = `${environment.baseUrl}/clients/${clientId}/brag_about_photos`;
    return this.http.get<IClient>(url)
  }
}
