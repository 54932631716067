import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ClientDetailPage} from './client-detail.page';
import {SharedModule} from '../../../shared/shared.module';
import {TransactionsProvider} from './providers/finances/transactions.service';
import {PaymentsService} from './providers/finances/payments.service';
import {PurchasesService} from './providers/finances/purchases.service';
import {HealthProfilesProvider} from './providers/health-profiles/health-profile.service';
import {WeighInsProvider} from './providers/weigh-ins/weigh-ins.service';
import {CommentsProvider} from '../../../providers/comments.service';
import {ClientDocService} from './providers/client-doc/client-doc.service';
import {VisitService} from './providers/visits/visits.service';
import {CommonModalModule} from '../../../components/common-modal/common-modal.module';
import {TransparentHeaderScrollModule} from '../../../directives/transparent-header-scroll/transparent-header-scroll.module';
import {ProfileMenuComponentModule} from '../../../components/profile-menu/profile-menu.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    CommonModalModule,
    TransparentHeaderScrollModule,
    ProfileMenuComponentModule
  ],
  declarations: [
    ClientDetailPage,
  ],
  providers: [
    HealthProfilesProvider,
    WeighInsProvider,
    PurchasesService,
    CommentsProvider,
    PaymentsService,
    TransactionsProvider,
    ClientDocService,
    VisitService,
  ],
  exports: [
  ]
})
export class ClientDetailPageModule {}
