export interface IReviewRequest {
  user_id: number;
  employee_id: number;
  force?: boolean;
}

export class ReviewRequest implements IReviewRequest {
  user_id: number;
  employee_id: number;
  force: boolean;

  constructor(user_id, employee_id, force = false) {
    this.user_id = user_id;
    this.employee_id = employee_id;
    this.force = force;
  }
}
