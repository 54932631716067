<ion-grid>
  <ion-row>
    <ion-col>
      <form [formGroup]="addressForm">
        <!--<ion-item>-->
          <!--<ion-label >Address:</ion-label>-->
          <!--<ion-input type="text" [(ngModel)]="addressSearch"></ion-input>-->
        <!--</ion-item>-->

        <ion-item class="better-validation" [class.invalid]="addressForm.dirty && street.invalid">
          <ion-label position="floating">Street:</ion-label>
          <ion-input formControlName="street" type="text" #search name="addressSearch"></ion-input>
        </ion-item>
        <small class="text-danger ion-padding-start" *ngIf="addressForm.dirty && street.invalid">
          Street is required.
        </small>

        <ion-item class="better-validation">
          <ion-label position="floating">Street 2:</ion-label>
          <ion-input formControlName="street2" type="text"></ion-input>
        </ion-item>

        <ion-item class="better-validation" [class.invalid]="addressForm.dirty && city.invalid">
          <ion-label position="floating">City:</ion-label>
          <ion-input formControlName="city" type="text"></ion-input>
        </ion-item>
        <small class="text-danger ion-padding-start" *ngIf="addressForm.dirty && city.invalid">
          City is required.
        </small>

        <ion-item class="better-validation" [class.invalid]="addressForm.dirty && state.invalid">
          <ion-label position="floating">State:</ion-label>
          <ion-select formControlName="state">
            <ion-select-option
              *ngFor="let state of states"
              [value]="state"
            >{{state}}</ion-select-option>
          </ion-select>
        </ion-item>
        <small class="text-danger ion-padding-start" *ngIf="addressForm.dirty && state.invalid">
          State is required.
        </small>

        <ion-item class="better-validation" [class.invalid]="addressForm.dirty && zip_code.invalid">
          <ion-label position="floating">Zip Code:</ion-label>
          <ion-input formControlName="zip_code" type="text"></ion-input>
        </ion-item>
        <small class="text-danger ion-padding-start" *ngIf="addressForm.dirty && zip_code.invalid">
          Zip code is required.
        </small>

        <ion-button class="skipAddress" color="light" (click)="emit()">Skip</ion-button>
        <ion-button class="submitAddress" [disabled]="addressForm.invalid" (click)="submitAddress()">Submit</ion-button>

      </form>
    </ion-col>
  </ion-row>
</ion-grid>
