import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {IClient} from '../../../models/client';
import {SendForSignatureService} from '../send-for-signature.service';

@Component({
  selector: 'a180-send-for-signature-container',
  templateUrl: './send-for-signature-container.component.html',
  styleUrls: ['./send-for-signature-container.component.scss'],
})
export class SendForSignatureContainerComponent implements OnInit {
  client: IClient;

  constructor( private params: NavParams,
               private modalController: ModalController,
               private sendForSignatureService: SendForSignatureService) { }

  ngOnInit() {
    this.client = this.params.get("client")
    console.log(this)
  }

  dismiss() {
    this.sendForSignatureService.setMissingFields([])
    this.sendForSignatureService.allFields = []
    this.modalController.dismiss()
  }
}
