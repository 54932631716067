import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IClient} from '../../models/client';
import {IBasicWeighIn} from '../../models/weigh-in';
import {WeighInsProvider} from '../../pages/clients/client-detail/providers/weigh-ins/weigh-ins.service';
import {ModalController} from '@ionic/angular';
import {AwakenModal} from '../../shared/awaken-modal/awaken-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'a180-weigh-in-search',
  templateUrl: './weigh-in-search.component.html',
  styleUrls: ['./weigh-in-search.component.scss'],
})
export class WeighInSearchComponent implements OnInit, OnChanges {
  @Output() weighInSelected = new EventEmitter<IBasicWeighIn>();
  @Input() client: IClient;
  records: IBasicWeighIn[] = [];
  name: string;

  constructor( private weighInsService: WeighInsProvider,
               private modalCtrl: ModalController ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.client ) this.client = changes.client.currentValue
    if (this.client) this.name = this.client.name
    if (this.name && this.name !== "") {
      this.searchForWeighInByName({detail: {value: this.name}})
    }
  }

  searchForWeighInByName(nameEvent: {detail: {value: string}}) {
    const name = nameEvent.detail.value
    this.weighInsService.search(name).subscribe( weigh_ins => {
      this.records = weigh_ins
    })
  }

  cancel() {
    this.weighInSelected.emit(null)
  }

  async confirmWeighInSelection(weighIn: IBasicWeighIn) {
    const modal = await this.modalCtrl.create({
      component: AwakenModal,
      componentProps: {
        title: `Are you sure you want to link ${weighIn.name}'s weigh in from ${moment(weighIn.date).format('LLL')}?`,
        type: 'confirm'
      },
      cssClass: 'small-modal'
    })

    await modal.present();

    await modal.onDidDismiss().then(data => {
      if (data?.data === 'yes') {
        this.name = null
        this.records = []
        this.weighInSelected.emit(weighIn)
      }
    })
  }
}
