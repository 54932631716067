import { Pipe, PipeTransform } from '@angular/core';
import {IEmployee} from '../models/employee';
import * as moment from 'moment';

@Pipe({
  name: 'employeeTerminationFilter'
})
export class EmployeeTerminationFilterPipe implements PipeTransform {

  transform(collection: IEmployee[], terminationDateString: string): IEmployee[] {
    console.log(terminationDateString)
    if (!Array.isArray(collection)) {
      return;
    }
    const terminationDate = terminationDateString ? moment(terminationDateString) : null
    let response;

    if (terminationDate) {
      response = collection.filter(employee => {
        return employee.terminated_at === null || moment(employee.terminated_at).isAfter(terminationDateString)
      })
    } else {
      response = collection.filter(employee => employee.terminated === false)
    }

    return response
  }

}
