<ion-content class="blue-purple-gradient montserrat" [scrollEvents]="true" (ionScroll)="onScroll($event)">
  <ion-grid class="mt-5">
    <ion-row>
      <ion-col>

        <a180-common-card
          class="animated fadeInDown fast"
          title="Documents"
          helpText="Pull down to refresh"
        >
          <ng-container *ngIf="(clientDocService.mergedDocuments$ | async).length > 0; else noDocuments">
            <ion-list class="mt-4" [@listStagger]='(clientDocService.mergedDocuments$ | async).length'>
              <a180-list-item
                *ngFor="let document of clientDocService.mergedDocuments$ | async | orderBy:'date':true"
                [item]="document"
                [startIcon]="{name: determineIcon(document)}"
                [badgeLabel]="document.badge.name"
                [badgeColor]="document.badge.color || 'warning'"
                [label]="document.name"
                [subLabel]="document.date | date:'M/d/yyyy':'-0500'"
                [slidingOptions]="[{
                  icon: 'trash',
                  color: 'danger',
                  label: determineSlidingOptionLabel(document)
                }]"
                [endIcon]="{type: 'button', name: 'ellipsis-vertical'}"
                (itemClicked)="handleDocumentClick(document)"
                (slidingOptionClicked)="deleteDocument(document)"
                (iconClicked)="showOptions($event.$event, document)"
              ></a180-list-item>
            </ion-list>

            <div *ngIf="isLoading" class="text-center py-5">
              <ion-spinner name="bubbles"></ion-spinner>
            </div>
          </ng-container>

          <ng-template #noDocuments>
            <h1 class="text-center specialHere" *ngIf="(clientDocService.mergedDocuments$ | async).length == 0 && !isLoading">
              No Documents Available
              <br>
              <small class="subtle">
                Please add a document below.
              </small>
            </h1>
          </ng-template>

          <ion-refresher slot="fixed" (ionRefresh)="loadClientDocuments($event, true)">
            <ion-refresher-content></ion-refresher-content>
          </ion-refresher>
        </a180-common-card>

        <div class="text-center">
          <ion-button color="danger" (click)="loadAllDocuments()" fill="clear">Missing Documents? Click here.</ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-fab slot="fixed" vertical="bottom" horizontal="end" class="awaken-fab">
  <ion-fab-button class="addDocument" color="success" (click)="newDocumentModal()">
    <ion-icon name="add"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-fab slot="fixed" vertical="bottom" horizontal="end" #fab class="awaken-fab">
  <ion-fab-button class="addDocument" color="success">
    <ion-icon name="add" style="font-weight:900;font-size:3rem;"></ion-icon>
  </ion-fab-button>
  <ion-fab-list side="top">
    <ion-fab-button class="newTransaction" side-left data-desc="New Document" (click)="newDocumentModal()">
      <ion-icon name="document"></ion-icon>
    </ion-fab-button>

    <ion-fab-button data-desc="In Person E-Signature" side-left (click)="newESigModal('in_person', fab)">
      <ion-icon name="pencil"></ion-icon>
    </ion-fab-button>

    <ion-fab-button data-desc="Search for Signed Document" side-left (click)="newESigModal('search', fab)">
      <ion-icon name="search"></ion-icon>
    </ion-fab-button>

    <ion-fab-button data-desc="Send Document for E-Signature" side-left (click)="newESigModal('send', fab)">
      <ion-icon name="send"></ion-icon>
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>
