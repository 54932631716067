import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {TestComponent} from './components/test/test.component';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'users/:id/edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/users/edit/edit.module').then(m => m.EditPageModule)
  },
  {
    path: 'users/edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/users/edit/edit.module').then(m => m.EditPageModule)
  },
  { path: 'referral-card-scanner',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./pages/referral-card-scanner/referral-card-scanner.module').then(m => m.ReferralCardScannerPageModule)},
  { path: 'edit', canActivate: [AuthGuard], loadChildren: () => import('./pages/users/edit/edit.module').then(m => m.EditPageModule) },
  { path: 'food-order-queue', canActivate: [AuthGuard], loadChildren: () => import('./pages/clients/client-detail/food-orders/food-orders.module')
      .then(m => m.FoodOrdersModule) },
  { path: 'consultations', canActivate: [AuthGuard], loadChildren: () => import('./pages/manager-dashboard/consultations/consultations.module')
      .then(m => m.ConsultationsPageModule) },
  { path: 'manager-dashboard', canActivate: [AuthGuard], loadChildren: () => import('./pages/manager-dashboard/manager-dashboard.module')
      .then(m => m.ManagerDashboardPageModule) },
  { path: 'manager-dashboard/weigh-ins/:id', canActivate: [AuthGuard], loadChildren: () => import('./pages/manager-dashboard/visit/visit.module')
      .then(m => m.VisitModule)},
  { path: 'notifications',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule) },
  {
    path: 'document-signing',
    loadChildren: () => import('./pages/document-signing/document-signing.module').then(m => m.DocumentSigningModule),
  },
  { path: 'test', component: TestComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'daily-check-ins',
    loadChildren: () => import('./pages/daily-check-ins/daily-check-ins.module').then(m => m.DailyCheckInsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // enableTracing: true
    }),
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
