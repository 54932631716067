<ion-card class="bg-white">
  <ion-card-content>
    <ion-card-title class="flex justify-content-space-between">
      <span>Selected Documents:</span>
      <span><small class="subtle fs-16">Click document name to remove</small></span>
    </ion-card-title>
    <div class="py-3">
      <ion-list>
        <signed-document-item
          [documents]="signedDocumentService.selectedDocuments"
          iconColor="success"
          iconName="checkmark-circle"
          (documentSelected)="signedDocumentService.removeFromSelectedDocuments($event)"
        ></signed-document-item>
      </ion-list>
    </div>
    <div class="pt-3 ion-text-center">
      <ion-button (click)="emitCompletion(true)">Continue</ion-button>
    </div>
  </ion-card-content>
</ion-card>
