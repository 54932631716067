import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Reminder} from '../../../models/reminder';

@Component({
  selector: 'a180-reminder-form',
  templateUrl: './reminder-form.component.html',
  styleUrls: ['./reminder-form.component.scss'],
})
export class ReminderFormComponent implements OnInit {
  @Input() reminder: Reminder;
  @Input() action: 'edit'|'create';
  @Input() friendlyItemName: string;
  @Output() submittedReminder = new EventEmitter<{reminder: Reminder, action: 'edit'|'create'}>();

  constructor() { }

  ngOnInit() {}

  submitReminder() {
    this.submittedReminder.emit({reminder: this.reminder, action: this.action})
  }

}
