<form name="consultationForm" #consultationForm="ngForm">
  <ion-grid *ngIf="isLoaded">
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label>Date</ion-label>
            <a180-date-picker
              [buttonStyle]="{float: 'right'}"
              [required]="true"
              [inputDate]="consultation.date"
              [displayDate]="true"
              (dateSelected)="consultation.date = $event.value"
            ></a180-date-picker>
          </ion-item>
          <small class="danger text-left ion-padding-start pb-2 d-block" *ngIf="(consultationForm.dirty && !dateValid)">
            Consultation date is required.
          </small>

          <ion-item *ngIf="(globalVarService.current_user$ | async)?.location_id === 3">
            <ion-label position="floating">Consultation Type</ion-label>
            <ion-select required interface="popover" name="consultation_type" [(ngModel)]="consultation.consultation_type">
              <ion-select-option [value]="0">Standard</ion-select-option>
              <ion-select-option [value]="1">Info Call</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item>
            <ion-label position="floating">Did they show up?</ion-label>
            <ion-select required interface="popover" name="showed_up" [(ngModel)]="consultation.showed_up">
              <ion-select-option [value]="true">Yes</ion-select-option>
              <ion-select-option [value]="false">No</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item
            *ngIf="consultation.showed_up"
            [@flyInOut]="consultation.showed_up"
          >
            <ion-label position="floating">Did they sign up?</ion-label>
            <ion-select
              required
              interface="popover"
              name="signed_up" [(ngModel)]="consultation.signed_up" #signed_up="ngModel">
              <ion-select-option [value]="true">Yes</ion-select-option>
              <ion-select-option [value]="false">No</ion-select-option>
            </ion-select>
          </ion-item>

           <ion-item
            *ngIf="consultation.showed_up === false"
            [@flyInOut]="consultation.showed_up === false"
          >
            <ion-label position="floating">Who would have been the consultant (will not impact stats)?</ion-label>
            <ion-select required [(ngModel)]="consultation.consultant_id" name="employee">
              <ion-select-option *ngFor="let employee of employeeService.allEmployees$ | async | employeeTerminationFilter:consultation.date" [value]="employee.id">{{ employee.name }}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item
            *ngIf="consultation.showed_up"
            [@flyInOut]="consultation.showed_up"
          >
            <ion-label position="floating">Sales Consultant</ion-label>
            <ion-select required [(ngModel)]="consultation.consultant_id" name="employee">
              <ion-select-option *ngFor="let employee of employeeService.allEmployees$ | async | employeeTerminationFilter:consultation.date" [value]="employee.id">{{ employee.name }}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item
            *ngIf="consultation.showed_up"
            [@flyInOut]="consultation.showed_up"
          >
            <ion-label position="floating">Post Consultant</ion-label>
            <ion-select [(ngModel)]="consultation.post_consultant_id" name="post_consultant">
              <ion-select-option *ngFor="let employee of employeeService.employees$ | async" [value]="employee.id">{{ employee.name }}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item
            *ngIf="consultation.signed_up === false || addingLateConsultation"
            [@flyInOut]="consultation.signed_up === false || addingLateConsultation"
          >
            <ion-label position="floating">Did they schedule a post-consultation?</ion-label>
            <ion-select
              required
              interface="popover"
              name="scheduled_post_consultation"
              [(ngModel)]="consultation.scheduled_post_consultation">
                <ion-select-option [value]="true">Yes</ion-select-option>
                <ion-select-option [value]="false">No</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item
            *ngIf="addingLateConsultation && consultation.scheduled_post_consultation"
            [@flyInOut]="addingLateConsultation && consultation.scheduled_post_consultation"
          >
            <ion-grid class="m-0 p-0">
              <ion-row>
                <ion-col class="px-0">
                  <ion-label position="stacked" class="text-muted">
                    Post-Consultation Date
                  </ion-label>
                  <ion-input [readonly]="true" class="text-muted">
                    {{ consultation.post_consultation_date | date:'shortDate' }}
                  </ion-input>
                </ion-col>
                <ion-col class="px-0">
                  <ion-label position="stacked" class="text-muted">
                    Post-Consultation Attended?
                  </ion-label>
                  <ion-input [readonly]="true" class="text-muted">
                    {{ consultation.attended_post_consultation }}
                  </ion-input>
                </ion-col>
              </ion-row>
            </ion-grid>

          </ion-item>

          <ion-item
            *ngIf="consultation.signed_up === false"
            [@flyInOut]="consultation.signed_up === false"
          >
            <ion-label position="floating">Reason they did not sign up</ion-label>
            <ion-select
              (ngModelChange)="checkForOther($event)"
              [(ngModel)]="consultation.excuse"
              required
              name="excuse"
            >
              <ion-select-option value="health concerns">Health Concerns</ion-select-option>
              <ion-select-option value="finances">Finances</ion-select-option>
              <ion-select-option value="food">Does not like the food</ion-select-option>
              <ion-select-option value="check with spouse">Needs to check with spouse</ion-select-option>
              <ion-select-option value="too busy">Too busy</ion-select-option>
              <ion-select-option value="still thinking">Still Thinking</ion-select-option>
              <ion-select-option value="not medically eligible">Not Medically Eligible</ion-select-option>
              <ion-select-option value="shopping around">Shopping Around</ion-select-option>
              <ion-select-option value="no show">No Show</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item>
            <ion-label position="floating">
              Comment:
            </ion-label>
            <ion-textarea [(ngModel)]="consultation.comment_attributes.body" [required]="!isEditing && requireComment" name="comment" #comment="ngModel"></ion-textarea>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row class="ion-text-center ion-align-items-center ion-justify-content-center">
      <ion-button [disabled]="!consultationForm.form.valid || !consultation.date || isSubmitting || ((globalVarService.current_user$ | async)?.location_id === 3 && ![0,1].includes(consultation.consultation_type))" (click)="createConsult()">
        {{ isSubmitting ? 'loading...' : 'Submit'}}
      </ion-button>
    </ion-row>
  </ion-grid>
</form>
