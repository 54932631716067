<ion-list [formGroup]="goalForm">
  <ion-grid
    class="ion-align-items-center goalRow mb-5"
    formArrayName="goals"
    *ngFor="let item of goalForm.get('goals')['controls']; let i = index;"
  >
    <ion-row [formGroupName]="i" class="ion-align-items-center">
      <ion-col size="11" class="p-0">
        <ion-col size="12" class="p-0">
          <ion-item>
            <ion-label position="stacked" color="primary">Goal Type</ion-label>
            <ion-select
              formControlName="goal_type"
              interface="popover"
            >
              <ion-select-option value="external_scale">Scale Goal</ion-select-option>
              <ion-select-option value="external_non_scale">Non-Scale Goal</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>

        <ion-col
          size="12"
          class="p-0"
          *ngIf="item.value.goal_type === 'external_scale'"
        >
          <ion-row
            [@flyInOut]="item.value.goal_type === 'external_scale'"
          >
            <ion-col size="12" class="px-0">
              <ion-item>
                <ion-label position="stacked" color="primary">Scale Field</ion-label>
                <ion-select
                  formControlName="field"
                  interface="popover"
                >
                  <ion-select-option value="weight">Weight (lbs.)</ion-select-option>
                  <ion-select-option value="body_fat">Body Fat (%)</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col size="6" class="pr-0">
              <ion-item>
                <ion-label position="stacked" color="primary">Desired Direction</ion-label>
                <ion-select
                  formControlName="direction"
                  interface="popover"
                >
                  <ion-select-option value="less_than">Lower Than</ion-select-option>
                  <ion-select-option value="greater_than">Greater Than</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col size="6" class="pl-0">
              <ion-item>
                <ion-label position="stacked" color="primary">Scale Value</ion-label>
                <ion-input
                  type="number"
                  nxNoScroll
                  inputmode="decimal"
                  [required]="true"
                  formControlName="value"
                ></ion-input>
              </ion-item>
              <p class="ion-padding-left pl-5 text-danger" *ngIf="item.controls['value'].errors">
                Please enter a number
              </p>
            </ion-col>
          </ion-row>

        </ion-col>

        <ion-col size="12">
          <ion-item>
            <ion-label position="floating" color="primary">Description</ion-label>
            <ion-textarea
              style="--background: transparent;"
              formControlName="description"
              placeholder="Add goal description here..."
              inputmode="text"
              autocapitalize="sentences"
              [rows]="3"
            ></ion-textarea>
          </ion-item>
        </ion-col>
      </ion-col>
      <ion-col size="1" class="ion-align-items-center ion-justify-content-center">
        <ion-button fill="clear" *ngIf="i !== 0" (click)="removeItem(i)">
          <ion-icon name="remove-circle" size="large" color="warning"></ion-icon>
        </ion-button>
        <ion-button fill="clear" (click)="addItem()" *ngIf="!editMode">
          <ion-icon name="add-circle" size="large" color="success"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row
      *ngIf="item.value.goal_type === 'external_scale' && recommendedDescriptions[i].length && !editMode"
      style="margin-top: -16px">
      <ion-col>
        <ion-button
          class="pl-4"
          size="small"
          color="primary"
          (click)="setDescription(i)"
        >Insert: "{{recommendedDescriptions[i].join(" ")}}"</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-row class="mt-5" *ngIf="goal.completed_at">
    <ion-col class="ion-text-center">
      <ion-text color="danger" class="fw-600">
        Any changes made will not be saved as this goal was marked complete on: {{ goal.completed_at | date:'shortDate'}}
      </ion-text>
    </ion-col>
  </ion-row>

  <ion-button *ngIf="!goal.completed_at" (click)="submitGoal()" [disabled]="goalForm.invalid">
    {{duringOnboarding ? 'Next' : 'Submit'}}
  </ion-button>
</ion-list>
