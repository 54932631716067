import { Pipe } from '@angular/core';

@Pipe({
	name: 'titleize',
})

export class Titleize {

	transform(value) {
		if (value) {
			let new_value = value.replace(/_|-/g, ' ')

			return new_value
		}
	}
}
