<ion-menu contentId="main-content" id="profileMenu" menuId="profileMenu" #profileMenu type="reveal">
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>Client Menu</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-list-header>Pages</ion-list-header>
      <ion-item button="true" (click)="selectTab('images')">
        <ion-icon slot="start" name="images"></ion-icon>
        <ion-label>Images</ion-label>
        <ion-badge slot="end" color="danger" *ngIf="(clientService.alerts$ | async)?.images > 0">
          {{ (clientService.alerts$ | async)?.images }}
        </ion-badge>
      </ion-item>

      <ion-item button="true" (click)="selectTab('all-visits')">
        <ion-icon slot="start" name="scale"></ion-icon>
        <ion-label>All Weigh-Ins</ion-label>
      </ion-item>

      <ion-item button="true" (click)="selectTab('food-orders')">
        <i class="fa fa-lunch fs-30"></i>
        <ion-label class="ml-4">Food Orders</ion-label>
      </ion-item>

      <ion-list-header class="pt-3">Actions</ion-list-header>
      <ion-item tappable (click)="addOrEditReminder(null, profileMenu)">
        <ion-icon slot="start" name="bulb"></ion-icon>
        <ion-label>Add Reminder</ion-label>
      </ion-item>

      <ion-item tappable (click)="requestReview(profileMenu)">
        <ion-icon slot="start" name="star"></ion-icon>
        <ion-label>Request Review</ion-label>
      </ion-item>

      <ion-list-header class="pt-3">Danger Zone</ion-list-header>
      <ion-item tappable (click)="addToExclusionList(profileMenu)">
        <ion-icon color="danger" slot="start" name="close-circle"></ion-icon>
        <ion-label color="danger">Add to Exclusion List</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>
