<ion-list lines="none" class="ion-no-margin p-0">
  <ion-item
    *ngFor="let popoverRow of popoverSrc"
    button="true"
    [ngClass]="popoverRow.cssClass"
    [disabled]="popoverRow.disabled"
    (click)="handePopoverClick(popoverRow)"
  >
    <ion-icon
      slot="start"
      class='ion-padding-end'
      *ngIf="popoverRow.icon"
      [name]="popoverRow.icon.name"
      [ngClass]="popoverRow.icon.cssClass"
    ></ion-icon>

    {{ popoverRow.name }}
  </ion-item>
</ion-list>
