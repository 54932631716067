<ion-grid>
  <ion-row>
    <ion-col size="12" [sizeMd]="10" [sizeLg]="8" [offsetMd]="1" [offsetLg]="2">
      <a180-common-card
        title="Add New Client Goal(s)"
      >

        <a180-goal-form
          [programId]="data.programId"
          [duringOnboarding]="duringOnboarding"
          (goalForSubmission)="createGoal($event)"
        ></a180-goal-form>

      </a180-common-card>
    </ion-col>
  </ion-row>
</ion-grid>
