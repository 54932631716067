<ion-header>
  <ion-toolbar class="transparent">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dismiss()" color="light">
        <ion-icon name="arrow-back"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="bg-purple-blue" fullscreen="true">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-card class="bg-white animated fadeInDown fast">
          <ion-card-content>
            <router-outlet></router-outlet>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
