import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RecoverLostRepositioningPageRoutingModule } from './recover-lost-repositioning-routing.module';
import { RecoverLostRepositioningPage } from './recover-lost-repositioning.page';
import {RepositioningComponent} from './repositioning/repositioning.component';
import {RepositioningIndexComponent} from './repositioning-index/repositioning-index.component';
import {RecoverRepositioningModalComponent} from './recover-repositioning-modal/recover-repositioning-modal.component';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    IonicModule,
    RecoverLostRepositioningPageRoutingModule
  ],
  declarations: [
    RecoverLostRepositioningPage,
    RepositioningComponent,
    RepositioningIndexComponent,
    RecoverRepositioningModalComponent
  ]
})
export class RecoverLostRepositioningPageModule {}
