import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GoalComponent} from './goal.component';
import {AddGoalComponent} from './add-goal/add-goal.component';
import {IonicModule} from '@ionic/angular';
import {CommonCardModule} from '../common-card/common-card.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GoalFormComponent} from './goal-form/goal-form.component';
import {EditGoalComponent} from './edit-goal/edit-goal.component';
import {GoalRowComponent} from './goal-row/goal-row.component';
import {PipesModule} from '../../pipes/pipes.module';
import {ReviewGoalComponent} from './review-goal/review-goal.component';
import {ReviewGoalFormComponent} from './review-goal-form/review-goal-form.component';
import {ReEvaluationService} from '../re-evaluation/re-evaluation.service';


@NgModule({
  entryComponents: [
    AddGoalComponent,
    EditGoalComponent,
    ReviewGoalComponent
  ],
  declarations: [
    GoalComponent,
    AddGoalComponent,
    ReviewGoalComponent,
    GoalFormComponent,
    ReviewGoalFormComponent,
    EditGoalComponent,
    GoalRowComponent
  ],
  exports: [
    AddGoalComponent,
    GoalFormComponent,
    ReviewGoalComponent,
    EditGoalComponent,
    GoalRowComponent
  ],
  providers: [
    // GoalService
    // ReEvaluationService
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    CommonCardModule,
    FormsModule,
    PipesModule,
  ]
})
export class GoalModule { }
