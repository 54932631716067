<ion-header class="darkMode">
  <ion-toolbar class="transparent">
    <ion-buttons slot="secondary">
      <ion-button (click)="dismiss()" color="light">Close</ion-button>
    </ion-buttons>
    <ion-title>
      New Weigh In
    </ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="submitWeighIn()" color="light" [disabled]="weighInForm.invalid">Submit</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="blue-purple-gradient" fullscreen="true">
  <ion-grid>
    <ion-row>
      <ion-col size="10" offset="1">
        <ion-card class="bg-white">
          <ion-card-header class="ion-no-padding">
            <ion-toolbar>
              <ion-buttons slot="primary">
                <ion-button
                  fill="clear"
                  size="small"
                  [disabled]="weighInForm.invalid"
                  (click)="submitWeighIn(false)"
                >Submit</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-card-header>

          <ion-card-content>
            <form #weighInForm="ngForm" name="weighInForm">

              <ion-list>
                <ion-item
                  *ngIf="!client?.id"
                  class="better-validation" [class.invalid]="name.invalid && weighInForm.dirty"
                >
                  <ion-label position="floating" style="color:dodgerblue;">Name</ion-label>
                  <ion-input
                    [(ngModel)]="barcodeData.name"
                    name="name"
                    [required]=true
                    type="test"
                    #name="ngModel"
                  ></ion-input>
                  <small *ngIf="!client?.id && name.invalid && weighInForm.dirty" class="text-danger ion-padding-start">
                    Name is required
                  </small>
                </ion-item>


                <ion-item class="better-validation" [class.invalid]="current_weight.invalid && weighInForm.dirty">
                  <ion-label position="floating" style="color:dodgerblue;">Current Weight</ion-label>
                  <ion-input
                    [(ngModel)]="barcodeData.current_weight"
                    name="current_weight"
                    [required]=true
                    type="number"
                    nxNoScroll
                    [min]="1"
                    #current_weight="ngModel"
                  ></ion-input>
                </ion-item>
                <small *ngIf="current_weight.invalid && weighInForm.dirty" class="text-danger ion-padding-start">
                  Current weight is required
                </small>

                <ion-item class="better-validation" [class.invalid]="body_fat.invalid && weighInForm.dirty">
                  <ion-label position="floating" style="color:dodgerblue;">Body Fat (%)</ion-label>
                  <ion-input
                    [(ngModel)]="barcodeData.body_fat"
                    name="body_fat"
                    nxNoScroll
                    [required]='false'
                    type="number"
                    #body_fat="ngModel"
                  ></ion-input>
                </ion-item>
                <small *ngIf="weighInForm.dirty && body_fat.invalid" class="text-danger ion-padding-start">
                  Body Fat is required
                </small>

                <ion-item class="better-validation" [class.invalid]="visceral_fat.invalid && weighInForm.dirty">
                  <ion-label position="floating" style="color:dodgerblue;">Visceral Fat</ion-label>
                  <ion-input
                    [(ngModel)]="barcodeData.visceral_fat"
                    name="visceral_fat"
                    [required]='false'
                    type="number"
                    #visceral_fat="ngModel"
                  ></ion-input>
                </ion-item>
                <small *ngIf="weighInForm.dirty && visceral_fat.invalid" class="text-danger ion-padding-start">
                  Visceral Fat is required
                </small>

                <ion-item class="better-validation">
                  <ion-label position="floating" style="color:dodgerblue;">Fat Mass (lbs)</ion-label>
                  <ion-input
                    [(ngModel)]="barcodeData.fat_mass"
                    name="fat_mass"
                    type="number"
                  ></ion-input>
                </ion-item>

                <ion-item class="better-validation">
                  <ion-label position="floating" style="color:dodgerblue;">Fat Free Mass (lbs)</ion-label>
                  <ion-input
                    [(ngModel)]="barcodeData.fat_free_mass"
                    name="fat_free_mass"
                    type="number"
                  ></ion-input>
                </ion-item>

                <ion-item class="better-validation">
                  <ion-label position="floating" style="color:dodgerblue;">Body Water (%)</ion-label>
                  <ion-input
                    [(ngModel)]="barcodeData.body_water_percent"
                    name="body_water_percent"
                    type="number"
                  ></ion-input>
                </ion-item>

                <ion-item class="better-validation">
                  <ion-label position="floating" style="color:dodgerblue;">Total Body Water (lbs)</ion-label>
                  <ion-input
                    [(ngModel)]="barcodeData.tbw"
                    name="tbw"
                    type="number"
                  ></ion-input>
                </ion-item>

                <ion-item class="better-validation">
                  <ion-label position="floating" style="color:dodgerblue;">Muscle Mass (lbs)</ion-label>
                  <ion-input
                    [(ngModel)]="barcodeData.muscle_mass"
                    name="muscle_mass"
                    type="number"
                  ></ion-input>
                </ion-item>

                <ion-item class="better-validation">
                  <ion-label position="floating" style="color:dodgerblue;">Bone Mass (lbs)</ion-label>
                  <ion-input
                    [(ngModel)]="barcodeData.bone_mass"
                    name="bone_mass"
                    type="number"
                  ></ion-input>
                </ion-item>
              </ion-list>
              <div class="text-center mt-4">
                <ion-button
                  class="submitWeighinWithoutRepositioning"
                  size="large"
                  (click)="submitWeighIn(false)"
                  [disabled]="weighInForm.invalid"
                >Submit</ion-button>
              </div>
            </form>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-fab *ngIf="plt.is('ipad')" slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button color="success" (click)="beginScanningBarcode()" [disabled]="beginScanDisabled">
      <ion-icon name="qr-code-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
