import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {IBasicClient} from '../../../models/client';
import {SignedDocumentsService} from '../signed-documents.service';
import {ClientDocService} from '../../../pages/clients/client-detail/providers/client-doc/client-doc.service';

@Component({
  selector: 'a180-signed-document-selector-container',
  templateUrl: './signed-document-selector-container.component.html',
  styleUrls: ['./signed-document-selector-container.component.scss'],
})
export class SignedDocumentSelectorContainerComponent implements OnInit {
  client: IBasicClient;

  constructor(private modalController: ModalController,
              private params: NavParams,
              private clientDocService: ClientDocService,
              private signedDocumentService: SignedDocumentsService) { }

  ngOnInit() {
    this.client = this.params.get("client")
  }

  dismiss() {
    this.signedDocumentService.setExecutedDocuments([])
    this.signedDocumentService.setSignedDocuments([])
    this.modalController.dismiss()
  }

  dismissWithRefresh() {
    this.signedDocumentService.setExecutedDocuments([])
    this.signedDocumentService.setSignedDocuments([])
    this.clientDocService.fetchClientAndSignedDocuments(this.client.id, true).subscribe(
      ([clientDocs, signedDocs]) => {
        this.clientDocService.setMergedDocuments(signedDocs, clientDocs)
      },
      () => {},
      () => this.modalController.dismiss()
    )
  }
}
