import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferralCardEditorComponent} from './referral-card-editor/referral-card-editor.component';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ReferralCardSearchComponent} from './referral-card-search/referral-card-search.component';
import {ReferralCardEntryService} from './referral-card-entry.service';
import {ReferralCardModalComponent} from './referral-card-modal/referral-card-modal.component';
import {CommonCardModule} from '../common-card/common-card.module';
// import {ReferralCardService} from './referral-card.service';



@NgModule({
  declarations: [
    ReferralCardEditorComponent,
    ReferralCardSearchComponent,
    ReferralCardModalComponent
  ],
  exports: [
    ReferralCardEditorComponent,
    ReferralCardSearchComponent,
    ReferralCardModalComponent
  ],
  providers: [
    ReferralCardEntryService
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CommonCardModule
  ]
})
export class ReferralCardModule { }
