import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: 'orderBy'
})
export class OrderBy implements PipeTransform {

  /**
   * Sorts the provided array by the provided field in ascending order.  Pass true to the reverse field to sort in descending order.
   * @param array
   * @param field
   * @param reverse
   */
  transform(array: any, field: string, reverse: boolean = false): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    let multiplier = reverse ? -1 : 1
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1 * multiplier;
      } else if (a[field] > b[field]) {
        return 1 * multiplier;
      } else {
        return 0;
      }
    });
    return array;
  }
}
