import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {GlobalsService} from '../../../../../globals.service';
import {ClientService} from '../../../clients.service';
import {Observable} from 'rxjs';
import {IHealthProfile} from '../../../../../models/health_profile';
import {IClient} from '../../../../../models/client';
import {tap} from 'rxjs/operators';


@Injectable()
export class HealthProfilesProvider {

  base_url: string;

  constructor( public http: HttpClient,
               private global: GlobalsService,
               private clientService: ClientService ) {
    this.base_url = this.global.base_url
  }

  /**
   * Locate health profile by client ID
   * @param client_id
   */
  findByClientID( client_id: number ) : Observable<IHealthProfile[]> {
    let url = `${this.base_url}/clients/${client_id}/health_profiles/find`;
    return this.http.get<IHealthProfile[]>(url)
  }

  /**
   * Link a health profile and a client using the health profile ID and the client ID
   * @param client_id
   * @param health_profile_id
   */
  linkToClient( client_id: number, health_profile_id: number ) : Observable<IClient> {
    let url = `${this.base_url}/clients/${client_id}/health_profiles/${health_profile_id}/link`;
    return this.http.get<IClient>(url).pipe(
      tap( client => {
        console.log(client);
        this.clientService.setClient(client)
      })
    )
  }

  /**
   * Search for a health profile by client name
   * @param params
   */
  search( params: string ) : Observable<IHealthProfile[]> {
    let url = `${this.base_url}/health_profiles/search?params=${params}`;
    return this.http.get<IHealthProfile[]>(url)
  }
}
