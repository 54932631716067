<ion-card-title class="flex justify-content-space-between align-items-center">
  <span>Repositionings You've Edited</span>
  <span *ngIf="clientName">{{clientName.split("-")[0]}}</span>
  <ion-button *ngIf="!clientName" fill="clear" color="danger" (click)="clearDraftRepositionings()">
    Clear Storage
    <ion-icon name="trash" slot="start"></ion-icon>
  </ion-button>
</ion-card-title>

<ng-container *ngIf="!isLoading && clientNames">
  <ion-list class="ion-padding-top" *ngIf="!clientName">
    <ion-list-header>
      <ion-label class="mt-3">Limited to 200 clients</ion-label>
    </ion-list-header>
    <ion-item *ngFor="let client of draftRepos" button (click)="selectClient(client.name)">
      {{client.name.split("-")[0]}}
    </ion-item>
  </ion-list>

  <ng-container *ngIf="clientsVisits">
    <ion-list class="ion-padding-top">
      <ion-list-header>
        <ion-label class="mt-3">Limited to 5 visits per client.  Please select one.</ion-label>
      </ion-list-header>
      <ng-container *ngIf="clientsVisits.length">
        <ion-item *ngFor="let visit of clientsVisits" button (click)="selectVisit(visit.id)" >
          <ion-label class="ion-text-wrap fs-16" [ngClass]="{'text-success fw-600': this.visit_id === visit.id.toString()}">
            Visit Date: {{moment(visit.date).format("MMMM Do, YYYY")}}
          </ion-label>
        </ion-item>
      </ng-container>
    </ion-list>
  </ng-container>

  <ng-container *ngIf="draftRepo">
    <hr>
    <ion-list class="ion-padding-top">
      <ng-container *ngIf="draftRepo.changeBased.length">
        <ion-list-header color="primary">
          <ion-label class="mt-3">Change Based Saves (limit: 5):</ion-label>
        </ion-list-header>
        <ion-item *ngFor="let changeBasedRepo of draftRepo.changeBased" button (click)="openDraftRepositioning(changeBasedRepo.key, false)" >
          <ion-label class="ion-text-wrap fs-16">
            Changes made on: {{moment(changeBasedRepo.key.split("/")[2], "YYYY-MM-DD:h:mm:ss").format('MM/DD/YYYY, h:mm:ss')}}
            <br>
            <small>Visit Date: {{moment(changeBasedRepo.data.check_in_date).format("MMMM Do, YYYY")}}</small>
          </ion-label>
        </ion-item>
      </ng-container>

      <ng-container *ngIf="draftRepo.auto">
        <ion-list-header color="primary">
          <ion-label class="mt-3">Autosave (limit: 1):</ion-label>
        </ion-list-header>
        <ion-item button (click)="openDraftRepositioning(draftRepo.auto.key, true)" >
          <ion-label class="ion-text-wrap fs-16">
            Changes made on: {{moment(draftRepo.auto.key.split("/")[2], "YYYY-MM-DD:h:mm:ss").format('MM/DD/YYYY, h:mm:ss')}}
            <br>
            <small>Visit Date: {{moment(draftRepo.auto.data.check_in_date).format("MMMM Do, YYYY")}}</small>
          </ion-label>
        </ion-item>
      </ng-container>
    </ion-list>
  </ng-container>

</ng-container>

<h1 class="text-center ion-padding-top"
    *ngIf="(!isLoading && noDraftRepos) ||
     (!isLoading && draftRepos && draftRepo &&
     (!draftRepo.changeBased.length && !draftRepo.auto))">
  No Draft Repositionings Available <br>
  <small class="subtle">Please add or edit a repositioning to a client's profile to see data here.</small>
</h1>

<div *ngIf="isLoading" class="text-center py-5">
  <ion-spinner name="bubbles"></ion-spinner>
</div>
