<ion-header class="darkMode ion-no-border">
  <ion-toolbar class="transparent">
    <ion-buttons slot="end">
      <ion-button fill="clear" color="light" (click)="dismiss()"><ion-icon name="close"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="blue-purple-gradient" [fullscreen]="true">

  <ion-slides pager="true" [options]="slideOpts" *ngIf="didInit">

    <!-- Check for user with email -->
    <ion-slide>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col class="text-center p-3" style="height:200px;">
            <svg style="height: 100%;width:auto;" width="64px" height="65px" viewBox="0 0 64 65" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Sketch.</desc>
              <defs></defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="iPad-Portrait" transform="translate(-747.000000, -777.000000)">
                  <g id="man-add" transform="translate(747.000000, 777.000000)">
                    <rect id="Rectangle-path" fill="#DAC2A7" x="22" y="32" width="17" height="21"></rect>
                    <path d="M46,15 L46,25.9473684 C46,34.2468428 39.0461371,41 30.5,41 C21.9538629,41 15,34.2468428 15,25.9473684 L15,15 L46,15 Z" id="Shape" fill="#EAD8C5"></path>
                    <path d="M61,59.5839882 C61,57.3284651 59.2962542,54.7703256 57.1868072,53.8668925 L38.8181818,46 C36.4780004,47.6964286 33.6137731,48.7142857 30.5,48.7142857 C27.3862269,48.7142857 24.5219996,47.6964286 22.2067722,46 L3.81747982,53.8668925 C1.70914375,54.7688345 0,57.3316955 0,59.5839882 L0,63.6388813 C0,64.3906049 0.623607991,65 1.38868164,65 L59.6113216,65 C60.3782719,65 61,64.4057322 61,63.6388811 L61,59.5839882 Z" id="Shape" fill="#444444"></path>
                    <path d="M15,15.0035619 C15,10.3685967 18.5724538,5.34449635 22.9669722,3.78628545 L33.4022778,0.0861368925 C34.1340597,-0.173332811 35.007794,0.173332952 35.3527107,0.858232685 L37.5454545,5.21223718 C42.2147722,5.21223718 46,8.97973474 46,13.6061186 L46,22 C46,18.1368263 42.8456363,15.0050988 38.9545455,15.0050988 L22.0454545,15.0050988 C18.1543624,15.0050988 15,18.1368263 15,22 L15,15.0035619 Z" id="Shape" fill="#C6A279"></path>
                    <circle id="Oval" fill="#72C472" fill-rule="nonzero" cx="53" cy="50" r="11"></circle>
                    <path d="M56.625,48.125 L53.875,48.125 L53.875,45.375 C53.875,44.6155777 53.2594223,44 52.5,44 C51.7405777,44 51.125,44.6155777 51.125,45.375 L51.125,48.125 L48.375,48.125 C47.6155777,48.125 47,48.7405777 47,49.5 C47,50.2593384 47.6155777,50.875 48.375,50.875 L51.125,50.875 L51.125,53.625 C51.125,54.3843384 51.7405777,55 52.5,55 C53.2594223,55 53.875,54.3843384 53.875,53.625 L53.875,50.875 L56.625,50.875 C57.3844223,50.875 58,50.2593384 58,49.5 C58,48.7405777 57.3844223,48.125 56.625,48.125 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                  </g>
                </g>
              </g>
            </svg>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col>
            <ion-card class="bg-white">
              <ion-card-content>
                <ion-list class="list-no-lines">
                  <form action="">
                    <ion-item>
                      <ion-label position="floating">Email</ion-label>
                      <ion-input type="email" [(ngModel)]="consultClient.email" name="email"></ion-input>
                    </ion-item>
                    <p class="danger text-left pl-3 pt-3 font-medium" *ngIf="emailExists">
                      A client with this email already exists ({{existingUser.name}}).
                      Please use a new email address or proceed without an email (client will not be able to sign into
                      the website.
                    </p>
                    <ion-button class="mt-3" (click)="checkForUser()">
                      Next
                    </ion-button>
                  </form>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card class="bg-white border border-danger">
              <ion-card-content class="text-left">
                <h4 class="text-danger">Note:</h4>
                <p>You can continue without an email but the client will not be notified and they cannot sign into
                  the website.</p>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!-- Optional Mindbody ID Slide if no email -->
    <ion-slide *ngIf="!consultClient.unique_mb">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col class="ion-padding ion-text-center col-12" style="height:200px;">
            <svg style="height: 100%;width:auto;" width="64px" height="65px" viewBox="0 0 64 65" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Sketch.</desc>
              <defs></defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="iPad-Portrait" transform="translate(-747.000000, -777.000000)">
                  <g id="man-add" transform="translate(747.000000, 777.000000)">
                    <rect id="Rectangle-path" fill="#DAC2A7" x="22" y="32" width="17" height="21"></rect>
                    <path d="M46,15 L46,25.9473684 C46,34.2468428 39.0461371,41 30.5,41 C21.9538629,41 15,34.2468428 15,25.9473684 L15,15 L46,15 Z" id="Shape" fill="#EAD8C5"></path>
                    <path d="M61,59.5839882 C61,57.3284651 59.2962542,54.7703256 57.1868072,53.8668925 L38.8181818,46 C36.4780004,47.6964286 33.6137731,48.7142857 30.5,48.7142857 C27.3862269,48.7142857 24.5219996,47.6964286 22.2067722,46 L3.81747982,53.8668925 C1.70914375,54.7688345 0,57.3316955 0,59.5839882 L0,63.6388813 C0,64.3906049 0.623607991,65 1.38868164,65 L59.6113216,65 C60.3782719,65 61,64.4057322 61,63.6388811 L61,59.5839882 Z" id="Shape" fill="#444444"></path>
                    <path d="M15,15.0035619 C15,10.3685967 18.5724538,5.34449635 22.9669722,3.78628545 L33.4022778,0.0861368925 C34.1340597,-0.173332811 35.007794,0.173332952 35.3527107,0.858232685 L37.5454545,5.21223718 C42.2147722,5.21223718 46,8.97973474 46,13.6061186 L46,22 C46,18.1368263 42.8456363,15.0050988 38.9545455,15.0050988 L22.0454545,15.0050988 C18.1543624,15.0050988 15,18.1368263 15,22 L15,15.0035619 Z" id="Shape" fill="#C6A279"></path>
                    <circle id="Oval" fill="#72C472" fill-rule="nonzero" cx="53" cy="50" r="11"></circle>
                    <path d="M56.625,48.125 L53.875,48.125 L53.875,45.375 C53.875,44.6155777 53.2594223,44 52.5,44 C51.7405777,44 51.125,44.6155777 51.125,45.375 L51.125,48.125 L48.375,48.125 C47.6155777,48.125 47,48.7405777 47,49.5 C47,50.2593384 47.6155777,50.875 48.375,50.875 L51.125,50.875 L51.125,53.625 C51.125,54.3843384 51.7405777,55 52.5,55 C53.2594223,55 53.875,54.3843384 53.875,53.625 L53.875,50.875 L56.625,50.875 C57.3844223,50.875 58,50.2593384 58,49.5 C58,48.7405777 57.3844223,48.125 56.625,48.125 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                  </g>
                </g>
              </g>
            </svg>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card class="bg-white">
              <ion-card-content>
                <ion-list class="list-no-lines">
                  <form action="">
                    <ion-item>
                      <ion-label position="floating">Mindbody ID</ion-label>
                      <ion-input type="number" [(ngModel)]="client.mindbody_id" name="mindbody_id"></ion-input>
                    </ion-item>
                    <small class="danger text-left pb-2 d-block"
                       *ngIf="(!consultClient.email && !client.mindbody_id) || (consultClient.email && (!client.mindbody_id && !consultClient.unique_mb))">
                      MindBody ID is required without email or when adding a client not on the schedule.
                    </small>
                    <div class="btn-group">
                      <ion-button (click)="slidePrev(300)">Previous</ion-button>
                      <ion-button (click)="checkForConsultation()" class="checkForConsultation"
                                  [disabled]="(!consultClient.email && !client.mindbody_id) || (consultClient.email && (!client.mindbody_id && !consultClient.unique_mb)) ">
                        Next
                      </ion-button>
                    </div>
                  </form>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!-- Optional potential matches -->
    <ion-slide *ngIf="potentialMatches">
      <ion-grid>
        <ion-row class="ion-padding-bottom">
          <ion-col>
            <ion-text color="danger">
              <h1 class="text-white">Hmmm... I found some potential matches for {{client.name}}!</h1>
            </ion-text>
            <h4 class="text-light">To avoid duplicates, please make sure this list does not include your client.</h4>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card class="bg-white">
              <ion-card-content>
                <ion-grid>
                  <ion-row>
                    <ion-col>
                      <ion-list>
                        <ion-item *ngFor="let potentialMatch of potentialMatches">
                          <client-row-content
                            [showDetail]="true"
                            [locationName]="locationService.getLocationName(potentialMatch.location_id)"
                            [client]="potentialMatch"
                          ></client-row-content>
                        </ion-item>
                      </ion-list>
                    </ion-col>
                  </ion-row>
                  <ion-row class="spacer ion-justify-content-around ion-align-items-center">
                    <ion-button color="danger" (click)="dismiss()"><strong class="pr-2">Abort!</strong>My Client Is Listed</ion-button>
                    <ion-button color="success" fill="outline" (click)="slideNext()"><strong class="pr-2">Proceed!</strong>My Client Is Not Listed</ion-button>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!-- Update consultation / create client -->
    <ion-slide>
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1 class="text-white">Great, now let's add {{consultClient.name || 'the client' }}'s consultation details.</h1>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col class="ion-padding ion-text-center col-12" style="height:200px;">
            <svg style="height: 100%;width:auto;" width="64px" height="65px" viewBox="0 0 64 65" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Sketch.</desc>
              <defs></defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="iPad-Portrait" transform="translate(-747.000000, -777.000000)">
                  <g id="man-add" transform="translate(747.000000, 777.000000)">
                    <rect id="Rectangle-path" fill="#DAC2A7" x="22" y="32" width="17" height="21"></rect>
                    <path d="M46,15 L46,25.9473684 C46,34.2468428 39.0461371,41 30.5,41 C21.9538629,41 15,34.2468428 15,25.9473684 L15,15 L46,15 Z" id="Shape" fill="#EAD8C5"></path>
                    <path d="M61,59.5839882 C61,57.3284651 59.2962542,54.7703256 57.1868072,53.8668925 L38.8181818,46 C36.4780004,47.6964286 33.6137731,48.7142857 30.5,48.7142857 C27.3862269,48.7142857 24.5219996,47.6964286 22.2067722,46 L3.81747982,53.8668925 C1.70914375,54.7688345 0,57.3316955 0,59.5839882 L0,63.6388813 C0,64.3906049 0.623607991,65 1.38868164,65 L59.6113216,65 C60.3782719,65 61,64.4057322 61,63.6388811 L61,59.5839882 Z" id="Shape" fill="#444444"></path>
                    <path d="M15,15.0035619 C15,10.3685967 18.5724538,5.34449635 22.9669722,3.78628545 L33.4022778,0.0861368925 C34.1340597,-0.173332811 35.007794,0.173332952 35.3527107,0.858232685 L37.5454545,5.21223718 C42.2147722,5.21223718 46,8.97973474 46,13.6061186 L46,22 C46,18.1368263 42.8456363,15.0050988 38.9545455,15.0050988 L22.0454545,15.0050988 C18.1543624,15.0050988 15,18.1368263 15,22 L15,15.0035619 Z" id="Shape" fill="#C6A279"></path>
                    <circle id="Oval" fill="#72C472" fill-rule="nonzero" cx="53" cy="50" r="11"></circle>
                    <path d="M56.625,48.125 L53.875,48.125 L53.875,45.375 C53.875,44.6155777 53.2594223,44 52.5,44 C51.7405777,44 51.125,44.6155777 51.125,45.375 L51.125,48.125 L48.375,48.125 C47.6155777,48.125 47,48.7405777 47,49.5 C47,50.2593384 47.6155777,50.875 48.375,50.875 L51.125,50.875 L51.125,53.625 C51.125,54.3843384 51.7405777,55 52.5,55 C53.2594223,55 53.875,54.3843384 53.875,53.625 L53.875,50.875 L56.625,50.875 C57.3844223,50.875 58,50.2593384 58,49.5 C58,48.7405777 57.3844223,48.125 56.625,48.125 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                  </g>
                </g>
              </g>
            </svg>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col>
            <ion-card class="bg-white">
              <ion-card-content>
                <consultation-form
                  [displayAllEmployees]="false"
                  [consultation]="consultation"
                  (submission)="createClient($event)"
                ></consultation-form>
              </ion-card-content>
            </ion-card>

            <p class="text-white">Status: {{status}}</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!-- Health Profile Slide -->
    <ion-slide>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col class="text-center ion-padding">
            <link-health-profile
              [client]="this.client"
              (clientWasSelected)="healthProfileSelected($event)"
              exitText="Skip this step"
            ></link-health-profile>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!-- Update Client Slide -->
    <ion-slide class="p-4">
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1 class="text-white">Now that that's out of the way, let's update {{client.name}}'s info</h1>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card class="bg-white">
              <ion-card-content>
                <form #updateClientForm="ngForm" name="updateClientForm">
                  <ion-list class="list-no-lines text-left">

                    <ion-item class="better-validation" [class.invalid]="name.invalid && updateClientForm.dirty">
                      <ion-label position="floating">Name:</ion-label>
                      <ion-input required="true" [(ngModel)]="client.name"  #name="ngModel" name="name"></ion-input>
                    </ion-item>
                    <small class="text-danger ion-padding-start" *ngIf="name.invalid && updateClientForm.dirty">
                      Name is required.
                    </small>

                    <ion-item class="better-validation" [class.invalid]="client.start_date == null && updateClientForm.dirty">
                      <ion-label position="stacked">Start Date:</ion-label>
                      <ion-input type="date" [(ngModel)]="client.start_date" name="start_date"></ion-input>
                    </ion-item>
                    <small class="text-danger ion-padding-start" *ngIf="client.start_date == null && updateClientForm.dirty">
                      Start date is required.
                    </small>

                    <ion-item class="">
                      <ion-label position="floating">Program Type:</ion-label>
                      <ion-select name="program_type" [(ngModel)]="client.program_type" interface="popover">
                        <ion-select-option value="standard">Standard</ion-select-option>
                        <ion-select-option value="whole_foods">Whole Foods</ion-select-option>
                        <ion-select-option value="hybrid">Hybrid</ion-select-option>
                      </ion-select>
                    </ion-item>
                    <small class="text-danger ion-padding-start" *ngIf="!['standard', 'whole_foods', 'hybrid'].includes(client.program_type) && updateClientForm.dirty">
                      Program type weeks is required.
                    </small>

                    <ion-item class="better-validation" [class.invalid]="rec_weeks.invalid && updateClientForm.dirty">
                      <ion-label position="floating">Recommended Weeks:</ion-label>
                      <ion-input type="number" required="true" #rec_weeks="ngModel" name="recommended_weeks" [(ngModel)]="client.recommended_weeks"></ion-input>
                    </ion-item>
                    <small class="text-danger ion-padding-start" *ngIf="rec_weeks.invalid && updateClientForm.dirty">
                      Recommended weeks is required.
                    </small>

                    <ion-item no-border class="better-validation" [class.invalid]="phone.invalid && updateClientForm.dirty">
                      <ion-label position="floating">Phone:</ion-label>
                      <ion-input
                        [(ngModel)]="client.phone"
                        type="tel"
                        name="phone"
                        required="true"
                        #phone="ngModel"
                      ></ion-input>
                    </ion-item>
                    <small class="text-danger ion-padding-start" *ngIf="phone.invalid && updateClientForm.dirty">
                      Phone is required.
                    </small>

                    <ion-row>
                      <ion-col class="p-0">
                        <ion-item>
                          <ion-label position="floating">Height (ft.)</ion-label>
                          <ion-input [(ngModel)]="heightInputFeet" name="heightInputFeet" type="number"></ion-input>
                        </ion-item>
                        <small class="ion-padding-start">
                          Client entered {{height || 'n/a'}} as their height on the health profile.
                        </small>
                      </ion-col>
                      <ion-col class="p-0">
                        <ion-item>
                          <ion-label position="floating">Height (in.)</ion-label>
                          <ion-input [(ngModel)]="heightInputInches" name="heightInputInches" type="number"></ion-input>
                        </ion-item>
                      </ion-col>
                    </ion-row>

                    <ion-item>
                      <ion-label>Date of Birth:</ion-label>
                      <a180-date-picker
                        name="dob"
                        [buttonStyle]="{float: 'right'}"
                        [preferWheel]="isIpad"
                        [closeModal]="false"
                        [inputDate]="client.dob"
                        [displayDate]="true"
                        (dateSelected)="client.dob = $event.value"
                      ></a180-date-picker>
                    </ion-item>
                    <small class="ion-padding-start">
                      Client entered: {{client.dob}}
                    </small>

                    <ion-item class="">
                      <ion-label position="floating">Gender:</ion-label>
                      <ion-select [(ngModel)]="client.gender" name="gender" required #gender="ngModel" interface="popover">
                        <ion-select-option value="male">Male</ion-select-option>
                        <ion-select-option value="female">Female</ion-select-option>
                      </ion-select>
                    </ion-item>
                    <small class="text-danger ion-padding-start"
                           [hidden]="( updateClientForm.pristine || ( !updateClientForm.pristine && gender.valid))">
                      Gender is required.
                    </small>

                    <ion-item>
                      <ion-label position="stacked" color="primary">Referrer</ion-label>
                      <ion-select [(ngModel)]="client.referred_by" name="referred_by" placeholder="Referred By:" interface="action-sheet">
                        <ion-select-option value=""></ion-select-option>
                        <ion-select-option
                          *ngFor="let referrer of referrersService.referrers$ | async"
                          [value]="referrer.internal_name"
                          >{{referrer.display_name}}
                        </ion-select-option>
                      </ion-select>
                    </ion-item>

                    <br>

                    <div class="text-center">
                      <ion-button
                        shape="round"
                        color="primary"
                        (click)="updateClientInDatabase()"
                        [disabled]="!updateClientForm.form.valid || client.start_date == null || !['standard', 'whole_foods', 'hybrid'].includes(client.program_type)"
                      >Next</ion-button>
                    </div>
                  </ion-list>
                </form>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!-- Add address slide -->
    <ion-slide>
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1 class="text-white">Alright, how about we add an address?</h1>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="text-center p-3" style="height:200px;">
            <svg style="height: 100%;width:auto;" width="64px" height="65px" viewBox="0 0 64 65" xmlns="http://www.w3.org/2000/svg">
              <ellipse style="fill:#23A24D;" cx="29" cy="50" rx="29" ry="8"/>
              <path style="fill:#EBBA16;" d="M41.676,5.324L41.676,5.324c-7.098-7.098-18.607-7.098-25.706,0h0
              C9.574,11.72,8.855,23.763,14.282,31l14.541,21l14.541-21C48.792,23.763,48.072,11.72,41.676,5.324z M29,24c-3.314,0-6-2.686-6-6
              s2.686-6,6-6s6,2.686,6,6S32.314,24,29,24z"/>
            </svg>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-card class="bg-white">
              <ion-card-content>
                <address
                  [client_id]="client.id"
                  [addressInput]="address"
                  (addressOutput)="handleAddressEmission($event)"
                ></address>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!-- Coachcare -->
    <ion-slide *ngIf="!usingAwakenApp">
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1 class="text-center text-white">We're so close... Should we invite them to use our app?</h1>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card class="bg-white">
              <ion-card-content>
                <ion-grid>
                  <ion-row>
                    <ion-col class="p-0">
                      <ion-item>
                        <ion-label position="floating">Height (ft.)</ion-label>
                        <ion-input [(ngModel)]="heightInputFeet" name="heightInputFeet" type="number"></ion-input>
                      </ion-item>
                      <small class="ion-padding-start">
                        Client entered {{height || 'n/a'}} as their height on the health profile.
                      </small>
                    </ion-col>
                    <ion-col class="p-0">
                      <ion-item>
                        <ion-label position="floating">Height (in.)</ion-label>
                        <ion-input [(ngModel)]="heightInputInches" name="heightInputInches" type="number"></ion-input>
                      </ion-item>
                    </ion-col>
                  </ion-row>

                  <ion-item>
                    <ion-label position="stacked">Date of Birth:</ion-label>
                    <ion-input>
                      <a180-date-picker
                        style="margin:0 auto;max-width:100%;"
                        name="dob"
                        [closeModal]="false"
                        [inputDate]="client.dob"
                        [displayDate]="true"
                        (dateSelected)="client.dob = $event.value"
                      ></a180-date-picker>
                    </ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label color="warning">Add Client to Coachcare</ion-label>
                    <ion-toggle
                      [(ngModel)]="shouldInviteToCoachcare"
                      name="shouldInviteToCoachcare"
                      (ionChange)="checkForCoachcareFields($event)"
                    ></ion-toggle>

                    <!-- (ionChange)="inviteToCoachcareModal($event)"-->
                  </ion-item>

                  <ion-item *ngIf="shouldInviteToCoachcare" [@flyInOut]="shouldInviteToCoachcare">
                    <ion-label color="warning">DISABLED: Send Client Coachcare Scale.  Use food order.</ion-label>
                    <ion-toggle
                      [disabled]="true"
                      [(ngModel)]="shouldSendCoachcareScale"
                      name="shouldSendCoachcareScale"
                      (ionChange)="sendCoachcareScaleModal($event)"
                    ></ion-toggle>
                  </ion-item>

                  <div class="flex ion-justify-content-between mt-4">
                    <ion-button (click)="slidePrev()">Go Back</ion-button>
                    <ion-button (click)="continuePastCoachcare()">Continue</ion-button>
                  </div>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!-- Scan in existing weigh-in -->
    <ion-slide>
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1 class="text-center text-white">
              Did you already scan in their weigh-in?
              <br>
              <small>Search for it below</small>
            </h1>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <a180-weigh-in-search
              [client]="client"
              (weighInSelected)="linkWeighInToClient($event)"
            ></a180-weigh-in-search>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col class="ion-text-center">
            <ion-button fill="clear" color='danger' (click)="slideNext()">Cancel / Skip</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!-- Further client updates-->
    <ion-slide>
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1 class="text-white">Now that that's out of the way, let's update {{client.name}}'s info</h1>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card class="bg-white">
              <ion-card-content>
                <form #updateClientForm2="ngForm" name="updateClientForm2" class="text-left">
                  <ion-item>
                    <ion-label position="floating">Target Weight (Low):</ion-label>
                    <ion-input [(ngModel)]="client.target_weight2" type="number" name="target_weight2"></ion-input>
                  </ion-item>

                  <ion-item class="better-validation" [class.invalid]="weight.invalid && updateClientForm2.dirty">
                    <ion-label position="floating">Target Weight (High):</ion-label>
                    <ion-input #weight="ngModel" required="true" [(ngModel)]="client.target_weight" type="number" name="target_weight"></ion-input>
                  </ion-item>
                  <small class="text-danger ion-padding-start" *ngIf="weight.invalid && updateClientForm2.dirty">
                    Target weight is required.
                  </small>

                  <ion-item>
                    <ion-label position="floating">Target Body Fat % (Low):</ion-label>
                    <ion-input [(ngModel)]="client.body_fat_target2" type="number" name="body_fat_target2"></ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Target Body Fat % (High):</ion-label>
                    <ion-input [(ngModel)]="client.body_fat_target" type="number" name="body_fat_target"></ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Target Visceral Fat (Low):</ion-label>
                    <ion-input [(ngModel)]="client.visceral_fat_target2" type="number" name="visceral_fat_target2"></ion-input>
                  </ion-item>

                  <ion-item class="better-validation">
                    <ion-label position="floating">Target Visceral Fat (High):</ion-label>
                    <ion-input [(ngModel)]="client.visceral_fat_target" type="number" name="visceral_fat_target"></ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Medications</ion-label>
                    <ion-input [(ngModel)]="client.medications" name="medications"></ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Health Notes:</ion-label>
                    <ion-input [(ngModel)]="client.health_notes" name="health_notes"></ion-input>
                  </ion-item>

                  <div class="text-center">
                    <ion-button class="updateClient" (click)="updateClient()" [disabled]="(!updateClientForm2.form.valid)">Continue</ion-button>
                  </div>
                </form>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!--  Link Referral Card  -->
    <!--  TODO: Make available to all locations-->
    <ion-slide *ngIf="client && client.location_name == 'Newton'">
      <ion-grid>
        <ion-row>
          <ion-col>
            <a180-common-card title="Search and Select Referral Card">
              <a180-referral-card-search
                [client_id]="this.client.id"
                (linkedReferral)="slideNext()"
              ></a180-referral-card-search>
            </a180-common-card>
          </ion-col>
        </ion-row>
        <ion-row class="pb-5">
          <ion-col>
            <ion-button fill="clear" color="warning" (click)="slideNext()" size="medium">
              Skip This Step
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!--  Add Purchase/Payment Slide  -->
    <ion-slide>
      <ion-grid>
        <ion-row>
          <ion-col>
            <transaction
              [consultation]="this.consultation"
              [partOfConsult]="true"
              [program_id]="this.client.program_id"
              [unusedReferrals]="[]"
              saleTypePreference="new_sale"
              purchaseable_type="Consultation"
              (transactionComplete)="handleFinancialsSubmission($event)"
            ></transaction>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!-- Add client goals -->
    <ion-slide>
      <ion-grid>
        <ion-row>
          <ion-col>
            <a180-add-goal
              style="width: 100%"
              [data]="{programId: this.client.program_id}"
              [duringOnboarding]="true"
              (onGoalCreate)="handleNewClientGoal($event)"
            ></a180-add-goal>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="justify-content-center text-center">
            <ion-button fill="clear" color="danger" (click)="this.slideNext()">Skip this step</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>

    <!-- 6) Sign Documents -->
    <ion-slide>
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1 *ngIf="client && client.location_name == 'At Home'" class="text-center text-white">
              Lastly, let's send them any documents they need to sign!
            </h1>
            <h1 *ngIf="client && client.location_name != 'At Home'" class="text-center text-white">
              Let's search for any signed documents we can link to their profile!
            </h1>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <a180-send-for-signature
              *ngIf="client && client.location_name == 'At Home'"
              [client_id]="client.id"
              (formSentForSignature)="handleSignatureSent($event)"
            ></a180-send-for-signature>

            <a180-signed-document-selector
              *ngIf="client && client.location_name !== 'At Home'"
              [client]="this.client"
              (continueProcessing)="continuePastDocuments($event)"
            ></a180-signed-document-selector>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="text-center">
            <ion-button color="danger" fill="clear" (click)="slideNext()">Skip This Step</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>
  </ion-slides>
</ion-content>
