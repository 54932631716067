import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConsultationProvider} from '../../../../providers/consultation.service';
import {UserService} from '../../../users/user.service';
import {Consultation, IConsultation} from '../../../../models/consultation';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {Client, IClient, IndexClient} from '../../../../models/client';
import {User} from '../../../../models/user';
import {GlobalsService} from '../../../../globals.service';
import {IonSlides, ModalController, Platform} from '@ionic/angular';
import {ClientService} from '../../clients.service';
import {Address} from '../../../../models/address_model';
import {LocationService} from '../../../../providers/locations.service';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {IHealthProfile} from '../../../../models/health_profile';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {AwakenModal} from '../../../../shared/awaken-modal/awaken-modal.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import * as Sentry from 'sentry-cordova';
import {ExternalDocumentsService} from '../../../../components/e-signature/e-signature.service';
import * as moment from 'moment';
import {IBasicWeighIn} from '../../../../models/weigh-in';
import {WeighInsProvider} from '../../client-detail/providers/weigh-ins/weigh-ins.service';
import {CheckInsProvider} from '../../../../providers/check-ins.service';
import {IBasicCheckIn, ICheckIn} from '../../../../models/check_in';
import {Goal, IGoal} from '../../../../models/goal';
import {ReferrersService} from '../../../../providers/referrers.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-invite-client',
  templateUrl: './invite-client.component.html',
  styleUrls: ['./invite-client.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in')
      ]),
      transition('* => void', [
        animate('200ms ease-out', style({transform: 'translateX(100%)'}))
      ])
    ])
  ]
})

export class InviteClientComponent implements OnInit, OnDestroy, AfterViewInit {

  slideOpts = {
    initialSlide: 0,
    speed: 400
  };
  oneYearFromNow = moment().add(1, 'year').format()
  @ViewChild(IonSlides, {static: false}) slides: IonSlides;
  @Input() consultClient: IndexClient;
  @Input() locationId: number;
  emailExists: boolean;
  existingUser: User;
  consultSub: Subscription;
  consultation: IConsultation;
  shouldInviteToCoachcare = false;
  shouldSendPolicyPacket: boolean;
  shouldSendInvestmentStructure: boolean;
  shouldSendCoachcareScale = false;
  documentsToSend: number[]
  status: string;
  isIpad: boolean;
  client: IClient;
  clientId: number;
  address: Address;
  potentialMatches: IndexClient[];
  didInit = false;
  repeats = 3
  height: string;
  heightInputFeet: string;
  heightInputInches: string;

  private destroy$: Subject<void> = new Subject<void>();
  usingAwakenApp = false;

  constructor( private consultService: ConsultationProvider,
               private userService: UserService,
               private weighInsService: WeighInsProvider,
               private checkInsService: CheckInsProvider,
               private cdRef : ChangeDetectorRef,
               private auth: AuthService,
               public externalDocumentService: ExternalDocumentsService,
               private globals: GlobalsService,
               private plt: Platform,
               private clientService: ClientService,
               public referrersService: ReferrersService,
               private router: Router,
               private modalCtrl: ModalController,
               public locationService: LocationService ) { }

  ngOnInit() {
    this.globals.showLoader(true)
    this.referrersService.getReferrers()
    this.isIpad = this.plt.is('ipad')
    this.client = new Client({
      name: this.consultClient.name,
      location_id: this.consultClient.location_id,
      referred_by: this.consultClient.referred_by
    });

    this.consultSub = this.consultService.consultation$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      consult => {
        if (!_.isEmpty(consult)) {
          this.consultation = consult;
        } else {
          this.consultation = {
            mindbody_id: this.consultClient.mindbody_id,
            unique_mb: this.consultClient.unique_mb,
            location_id: (this.client.location_id || this.locationId)
          }
        }
      }
    );
    console.log(this);
  }

  ngAfterViewInit() {
    const didInit = this.didInit
    this.didInit = true
    if (didInit !== this.didInit) {
      this.cdRef.detectChanges()
    }
    this.fetchExternalDocuments()
  }

  ionViewDidEnter() : void {
    this.slides.update().then(() => {
      this.slides.lockSwipeToNext(true)
      this.slides.lockSwipeToPrev(true)
      this.globals.hideLoader()
    })
  }

  ngOnDestroy(): void {
    console.log('destroying invite-client')
    this.consultSub.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }


  fetchExternalDocuments() {
    this.externalDocumentService.fetchDocuments('in_person').subscribe()
  }


  /**
   * Validates that the email is unique and thus there is no existing
   * user in the database with that email. Moves to next slide on success.
   * Displays error if false.
   */
  checkForUser() : void {
    if (!this.consultation.email) this.consultation.email = this.consultClient.email
    this.userService.fetchUserByEmail(this.consultClient.email, this.consultClient.name, this.consultClient.phone).subscribe(
      data => {
        if ( data && Array.isArray(data)) {
          this.potentialMatches = data
          this.slideNext()
        } else if (data && !Array.isArray(data)) {
          this.emailExists = true;
          this.existingUser = data
        } else {
          this.checkForConsultation(this.consultation.unique_mb)
        }
      }, err => this.globals.handleResponse(err, true)
    )
  }


  /**
   * Checks for an existing consultation with the provided unique_mb.  If it exists,
   * the returned consultation becomes the local consultation.  Otherwise, the unique_mb is added
   * as an attribute.
   * @param uniqueMb: The unique version of the mindbody_id
   */
  checkForConsultation(uniqueMb: number = null) : void {
    if (!uniqueMb) {
      if (this.client) {
        uniqueMb = parseInt(`${this.locationId}${this.client.mindbody_id}`, 10);
      } else {
        uniqueMb = parseInt(`${this.locationId}${this.consultClient.mindbody_id}`, 10);
      }
    }

    this.consultService.fetchByUniqueMB(uniqueMb).subscribe(
      data => {
        console.log(data);
        if ( data ) {
          this.consultService.setConsultation(data);
        } else {
          this.consultation.unique_mb = uniqueMb
        }
        this.slideNext()
      }, err => this.globals.handleResponse(err.error, true)
    )
  }


  /**
   * Following consultation creation, uses emitted consultation to invite the
   * client if they signed up.  If no email is provided, it simply creates
   * the client.  Calls {@link updateConsult} on success.
   * @param consult: Consultation used to create the client
   */
  createClient(consult: Consultation = null) : void {
    console.log(consult);
    this.status = 'Consultation created.';

    // Search by name, mindbody id, phone and display

    if ( consult ) {
      this.consultation = consult
      this.consultService.setConsultation(consult);

      if (consult.signed_up) {
        console.log('success', this);

        this.status = 'Inviting client...';

        if (this.consultClient.email) {

          this.clientService.inviteClient(this.consultClient.email, consult.location_id).subscribe(
            data => {
              this.status = 'Client invited.';
              console.log('new user', data);
              this.client = new Client(data);
              this.client.name ||= this.consultClient.name
              this.client.phone ||= this.consultClient.phone
              if (this.client.location_name === 'At Home') {
                this.shouldSendPolicyPacket = true
                this.shouldSendInvestmentStructure = true
              }
              if (this.client.invited_to_awaken_app_at)  this.usingAwakenApp = true
              this.clientId = data.id;
              this.updateConsult();
            },
            err => {
              this.status = 'Error';
              this.globals.handleResponse(`Ut oh.. could not invite the client: ${err.error}`, true)
            }
          )
        } else {
          this.clientService.create(this.client).subscribe(
            data => {
              console.log(data);
              this.client = new Client(data);
              this.clientId = data.id;
              if (this.client.location_name === 'At Home') {
                this.shouldSendPolicyPacket = true
                this.shouldSendInvestmentStructure = true
              }
              this.updateConsult()
            }
          )
        }
      } else {
        this.modalCtrl.dismiss()
      }
    }
  }


  /**
   * Ensures Coachcare fields are accurately filled in and if they are calls {@link inviteToCoachcareModal}
   */
  async checkForCoachcareFields(event) {
    if (event.detail.checked) {

      if (!this.client.dob || !this.client.address || !this.heightInputFeet) {

        const values = [
          {val: 'continue', name: 'Continue'}
        ]
        const missing = []

        if (!this.client.address) {
          values.unshift({val: 'go-back', name: 'Add Address'})
          missing.push('address')
        }
        if (!this.client.dob) missing.push('DOB')
        if (!this.heightInputFeet) missing.push('height')

        const m = await this.modalCtrl.create({
          component: AwakenModal,
          componentProps: {
            title: `Client is missing ${missing.join(', ')} so this cannot proceed.`,
            subtitle: 'Please add these details above',
            type: 'custom-prompt',
            values
          },
          cssClass: 'small-modal'
        })

        await m.present()

        await m.onDidDismiss().then(data => {
          console.log(data)
          this.shouldSendCoachcareScale = false
          this.shouldInviteToCoachcare = false
          console.log(this)
          if (data && data.data) {
            if (data.data.value === 'go-back') this.slidePrev()
          }
        })
      } else {
        await this.inviteToCoachcareModal()
        console.log('complete')
      }
    }

    if (event.detail.checked === false) {
      this.shouldSendCoachcareScale = false
    }
  }

  continuePastCoachcare() {
    if (this.heightInputFeet) {
      const feet = this.heightInputFeet.toString()
      const inches = (this.heightInputInches || '0').toString()
      this.client.height = `${feet}_${inches}`
    }
    this.slideNext()
  }


  /**
   * Functionality for handling in-office signed documents ie through {@link SignedDocumentSelectorModule}.
   */
  continuePastDocuments(shouldContinue: boolean) {
    console.log(shouldContinue)
    if (shouldContinue) {
      this.goToClient()
    }
  }

  /**
   * Launches modal to confirm that you would like to invite the client to use Coachcare.
   */
  async inviteToCoachcareModal() {
    console.log(1)
    const modal = await this.modalCtrl.create({
      component: AwakenModal,
      componentProps: {
        title: 'Are you sure you want to invite them to use Coachcare?',
        type: 'confirm'
      },
      cssClass: 'small-modal'
    })

    console.log(2)

    await modal.present()

    console.log(3)

    await modal.onDidDismiss().then(data => {
      if (!data || !data.data || data.data !== 'yes') {
        this.shouldInviteToCoachcare = false
        this.shouldSendCoachcareScale = false
      }
    })
    console.log(4)
  }


  /**
   * Launches modal to confirm that you would like to send the client a scale.
   */
  async sendCoachcareScaleModal(event: CustomEvent) {
    if (event.detail.checked) {
      const modal = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          title: 'Are you sure you want to send them a scale?',
          subtitle: 'To proceed you must collect a payment for the scale.',
          type: 'confirm'
        },
        cssClass: 'small-modal'
      })

      await modal.present()

      await modal.onDidDismiss().then(data => {
        if (!data || !data.data || data.data !== 'yes') {
          this.shouldSendCoachcareScale = false
        }
      })
    }
  }


  /**
   * Uses the form values for feet and inches to generate a height that can be parsed by our system.
   */
  calculateHeight() {
    if (this.heightInputFeet) {
      const feet = this.heightInputFeet.toString()
      const inches = (this.heightInputInches || '0').toString()
      this.client.height = `${feet}_${inches}`
    }
  }


  updateClientInDatabase() {
    this.clientService.update(this.client).subscribe(
      data => {
        console.log(data);
        this.clientService.setClient(data)
        this.client = data
        if (this.client.invited_to_awaken_app_at) this.usingAwakenApp = true;
        const client = new Client(this.client);

        ['visceral_fat_target', 'visceral_fat_target2', 'body_fat_target', 'body_fat_target2'].forEach(field => {
          this.client[field] = client.fetchTarget(field)
        })

        this.slideNext()
      },
      err => this.globals.handleResponse(`Ut oh.. could not update the client: ${err.error}`, true)
    )
  }


  updateClient() {
    this.calculateHeight();

    this.shouldSendCoachcareScale = false
    console.log(`Scale: ${this.shouldSendCoachcareScale} - Account: ${this.shouldInviteToCoachcare}`)

    if (this.consultation.unique_mb) this.client.unique_mb = this.consultation.unique_mb

    if (this.shouldSendCoachcareScale && this.shouldInviteToCoachcare) {

      const account = this.clientService.initiateClientOnboarding(this.client.id, false)
      const scale = this.clientService.requestCoachcareScale(this.client.id)

      this.clientService.update(this.client).subscribe(() => {
        forkJoin([account, scale]).subscribe(data => {
          console.log(data)

          if (data[0] && data[0].success) {
            this.client.coach_care_account_created_at = data[0].client.coach_care_account_created_at
          }

          if (data[1] && typeof data[1] === 'object' && data[1].coach_care_scale_requested_at) {
            this.client.coach_care_scale_requested_at = data[1].coach_care_scale_requested_at
          }

          if (this.clientId !== this.client.id) {
            Sentry.captureException(`clientId ${this.clientId} != Client.id ${this.client.id}`)
          }

          this.updateClientInDatabase()
        })
      })

    } else if (!this.shouldSendCoachcareScale && this.shouldInviteToCoachcare) {

      this.clientService.update(this.client).subscribe(() => {
        this.clientService.initiateClientOnboarding(this.client.id, false).pipe(
          switchMap(client => {
            if (client && client.success) {
              this.client.coach_care_account_created_at = client.client.coach_care_account_created_at
              this.client.coach_care_id = client.client.coach_care_id
            }
            return this.clientService.update(this.client).pipe(
              tap(newClient => {
                console.log(newClient)
                this.clientService.setClient(newClient)
                this.client = newClient
                this.slideNext()
              })
            )
          })
        ).subscribe(val => {
          console.log(val)

        })
      })

    } else {
      this.clientService.update(this.client).subscribe(
        data => {
          this.clientService.setClient(data)
          this.client = data
          this.slideNext()
        },
        err => this.globals.handleResponse(`Ut oh.. could not update the client: ${err.error}`, true)
      )
    }
    console.log('updating client', this.client)
    this.clientService.update(this.client)
  }


  /**
   * Links consultation to the newly created client.
   * After completion, moves to next slide.
   */
  updateConsult() : void {
    this.status = 'Updating consultation...';
    console.log(this.client.id);

    this.consultation.client_id = this.client.id;

    console.log(this.consultation);

    this.consultService.update(this.consultation).subscribe(
      data => {
        this.consultation = data
        this.slides.lockSwipes(false);
        this.slides.slideNext(300);
        this.slides.lockSwipes(true);
      },
      err => this.globals.handleResponse(err.error, true)
    )
  }


  /**
   * Transitions to the next slide at the provided speed.
   * @param speed: Slide transition speed
   */
  slideNext(speed : number = 300) : void {
    this.slides.update().then(() => {
      this.slides.isEnd().then(isEnd => {
        if (isEnd) {

          // Ensures the client is reloaded when visiting the profile
          this.clientService.setClient(null)

          this.goToClient()
        } else {
          this.slides.lockSwipeToNext(false)
            .then(() => {
              this.slides.slideNext(speed, false)
            })
            .then(() => this.slides.lockSwipeToNext(true))
        }
      })
    })
  }


  /**
   * Handles the health profile linkage.  If linked to a health profile, go to profile, otherwise slide next to add an address.
   */
  healthProfileSelected(hp: IHealthProfile) {
    if ( hp ) {
      this.client.phone = hp.address.phone
      this.client.gender = hp.gender
      this.client.medications = hp.medication_list_as_string
      this.client.dob = hp.dob
      this.height = hp.height
      this.address = hp.client_address
      this.slideNext()
    } else {
      this.slideNext()
    }
  }


  handleAddressEmission(address: Address | null): void {
    if (address) {
      this.client.address = address
      this.address = address
      this.clientService.setClient(this.client)
    }
    this.slideNext()
  }


  /**
   * Navigates to the profile page of the current client.
   */
  goToClient() : void {
    this.modalCtrl.dismiss()
    this.router.navigate(['clients', this.client.id, 'profile'])
  }


  /**
   * Transitions to the previous slide at the provided speed.
   * @param speed: Slide transition speed
   */
  slidePrev( speed : number = 300 ) : void {
    this.slides.lockSwipeToPrev(false)
      .then(() => this.slides.slidePrev(speed, false));
  }


  /**
   * Closes the modal.
   */
  dismiss() : void {
    this.modalCtrl.dismiss();
  }


  /**
   * After allowing the team member to fill in missing fields on signature form, the client is updated with those values.
   * Now we have to apply them to {@link client}
   * @param $event: contains updated client fields
   */
  handleSignatureSent($event: any) {
    if ($event) {
      _.keys($event).forEach(key => {
        if (!this.client[key]) this.client[key] = $event[key]
      })
      this.goToClient()
    } else {
      this.goToClient()
    }
  }


  /**
   * Create an unvalidated check in as a first step in the visit creation process.
   * User will be prompted to add a weigh in immediately after creation.
   * User will validate the visit at the end.
   */
  generatePendingCheckIn() : Promise<ICheckIn> {
    const checkIn: IBasicCheckIn =  {
      client_id: this.client.id,
      program_id: this.client.program_id,
      validated: false,
    }

    return new Promise((resolve, reject) => {
      this.checkInsService.create(checkIn).subscribe(
        newCheckIn => {
          resolve(newCheckIn)
        },
        err => {
          reject(err.error)
        }
      )
    })
  }


  /**
   * Links a previously created, unclaimed weigh-in to the newly added client.
   * @param $event: The unclaimed weigh-in
   */
  linkWeighInToClient($event: IBasicWeighIn) {
    this.generatePendingCheckIn()
      .then(newCheckIn => {
        const weighIn = {...$event, client_id: this.client.id, claim_status: 'claimed', check_in_id: newCheckIn.id}
        this.weighInsService.update(weighIn, true).subscribe(
          () => {
            this.globals.handleResponse('Successfully linked weigh-in to the client', false, 'success')
            this.slideNext()
          },
          err => {
            this.globals.handleResponse(err.error, true)
          }
        )
      })
      .catch(errorMsg => this.globals.handleResponse(errorMsg, true))
  }

  handleNewClientGoal(goals: IGoal[]) {
    let goalArray: Goal[]
    if (goals) goalArray = goals.map(goal => new Goal(goal))
    this.client.goals = [...this.client.goals, ...goalArray]
    this.slideNext()
  }

  handleFinancialsSubmission(event: boolean) {
    this.slideNext()
  }

  updateStartDate($event: CustomEvent) {
    console.log($event)
    this.client.start_date = $event.detail.value
  }
}
