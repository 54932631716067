<form #paymentForm="ngForm" name="paymentForm">
  <ion-list>
    <ion-item class="better-validation" [class.invalid]="paymentForm.dirty && a.invalid">
      <ion-label position="floating">Amount:</ion-label>
      <ion-input name="amount" [(ngModel)]="amount" type="number" required #a="ngModel" (ionChange)="newAmount.emit(amount)"></ion-input>
    </ion-item>
    <small class="text-danger ion-padding-start" [hidden]="( paymentForm.pristine || ( !paymentForm.pristine && a.valid))">
      Amount is required
    </small>

    <ion-item class="better-validation">
      <ion-label position="floating">Comment:</ion-label>
      <ion-input spellcheck="on" name="payment_comment" [(ngModel)]="payment_comment" type="text"></ion-input>
    </ion-item>
    <ion-button class="ion-margin submitPayment" expand="full" shape="round" type="submit" color="success" [disabled]="!paymentForm.form.valid" (click)="submitForm()">Submit</ion-button>
  </ion-list>
</form>
