import {Component} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'video-modal',
  templateUrl: 'video-modal.component.html'
})
export class VideoModalComponent {
  safeUrl: any;
  content: string;
  title: string;

  constructor( private params: NavParams,
               public sanitizer: DomSanitizer,
               public viewCtrl: ModalController) {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.params.get('url'))
    this.content = this.params.get("content")
    this.title = this.params.get("title")
  }

  dismiss() : void {
    this.viewCtrl.dismiss()
  }
}
