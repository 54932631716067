<ion-card-subtitle class="pb-2">
  Change made at: {{moment(key.split("/")[2]).format("M/D/YYYY")}}
</ion-card-subtitle>

<ion-card-title class="flex justify-content-space-between">
  <span *ngIf="draftRepo">{{key.split("/")[0]}}</span>
  <span *ngIf="draftRepo">{{moment(key.split("/")[1]).format("M/D/YYYY")}}</span>
  <span *ngIf="!draftRepo">No Draft Repositioning Found</span>
</ion-card-title>

<ion-card-content>
  <h2 class="text-center" *ngIf="!draftRepo">Please go back and try choosing a different draft repositioning.</h2>
  <ion-grid *ngIf="draftRepo">
    <ion-row>
      <ion-col><strong>Overview:</strong></ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label class="ion-text-wrap">
              Emojis:<br>
              <span *ngFor="let emoji of emojis" class="fs-30">{{emoji}}</span>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              Issues: <br>
              <ion-badge *ngFor="let bool of booleans" color="danger" style="margin: 0.2em">{{bool}}</ion-badge>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              Remedies:<br>
              <ion-badge *ngFor="let bool of remedy_bools" color="success" style="margin: 0.2em">{{bool}}</ion-badge>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              Explanations:<br>
              <ion-badge *ngFor="let explain of explanations" style="margin: 0.2em">{{explain}}</ion-badge>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>

    <ion-row class="ion-padding-top">
      <ion-col><strong>Recommendations</strong></ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            Number of Products: {{draftRepo.num_products}}
          </ion-item>
          <ion-item>
            Number of Bars: {{draftRepo.number_of_bars}}
          </ion-item>
          <ion-item>
            Ounces of Protein: {{draftRepo.oz_protein}}
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>

    <ion-row class="ion-padding-top">
      <ion-col><strong>Description:</strong></ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label class="ion-text-wrap">
              {{draftRepo.description}}
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="text-center">
        <ion-button color="primary" (click)="selectDraftRepositioning()">Recover Repositioning</ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>
</ion-card-content>
