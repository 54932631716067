import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IndexClient} from '../../models/client';
import * as _ from 'lodash';

@Component({
  selector: 'client-row-content',
  templateUrl: 'client-row-content.component.html',
  styleUrls: ["client-row-content.component.scss"]
})

export class ClientRowContentComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() status: string;
  // @Input() consult_status: string;
  @Input() client: IndexClient;
  @Input() showDetail: boolean;
  @Input() locationName: string;
  location_id: number;
  mindbody_id: number;
  user_id: number;
  id: number;
  signedUp: boolean;

  constructor() {
    this.showDetail = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.client) {
      this.client = changes.client.currentValue
      this.location_id = this.client.location_id;
      this.mindbody_id = this.client.mindbody_id;
      this.user_id = this.client.user_id;
      this.id = this.client.id;
      this.signedUp = _.get(this.client,['consultation', 'signed_up'])
    }
  }

  ngOnInit() : void {

  }
}
