import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReferralCardService} from '../referral-card.service';
import {ReferralCard} from '../../../models/referral_card';
import {GlobalsService} from '../../../globals.service';
import {ReferralCardEntry} from '../../../models/referral_card_entry';
import {ReferralCardEntryService} from '../referral-card-entry.service';
import {ModalController} from '@ionic/angular';
import {AwakenModal} from '../../../shared/awaken-modal/awaken-modal.component';

@Component({
  selector: 'a180-referral-card-search',
  templateUrl: './referral-card-search.component.html',
  styleUrls: ['./referral-card-search.component.scss'],
})
export class ReferralCardSearchComponent implements OnInit {
  @Input() search: string;
  @Input() client_id: number;
  @Output() linkedReferral: EventEmitter<ReferralCardEntry | null> = new EventEmitter<ReferralCardEntry | null>();
  referralCards: ReferralCard[] = [];

  constructor( private referralCardService: ReferralCardService,
               private modalCtrl: ModalController,
               private referralCardEntryService: ReferralCardEntryService,
               private globals: GlobalsService ) {
    console.log(this)
  }

  ngOnInit() {
    this.searchByEmailOrClient()
  }

  /**
   * Looks for unused referral cards by send_to field on referral card and referrer's name.
   */
  searchByEmailOrClient() {
    if (this.search) {
      this.referralCardService.searchForReferralCard(this.search).subscribe(referral_cards => {
        console.log(referral_cards)
        this.referralCards = referral_cards
      })
    }
  }

  async verifyReferralCardEntrySelection(name, email) {
    let response;
    let modalParams = {
      title: `Are you sure that ${name} referred this client?`,
      subtitle: "You should be performing this from the referred client's profile!  Not the referrer's profile!  \n\n\n",
      type: 'confirm'
    }

    // if (email) {
    //   modalParams['subtitle'] = `It looks like this referral was sent to: ${email}`
    // }

    let modal = await this.modalCtrl.create({
      component: AwakenModal,
      componentProps: modalParams,
      cssClass: "small-modal"
    })

    await modal.present()
    await modal.onDidDismiss().then(data => response = data)

    return response
  }


  async linkToReferral(referral_card: ReferralCard, referral_card_entry: ReferralCardEntry) {
    let modalResponse = await this.verifyReferralCardEntrySelection(referral_card.sent_from_name, referral_card_entry.send_to)

    if (modalResponse && modalResponse.data === 'yes') {
      this.referralCardEntryService.linkToReferral(referral_card_entry, this.client_id).subscribe(
        data => {
          console.log(data)
          this.globals.handleResponse("Successfully linked referral card with profile.", false, 'success')
          this.linkedReferral.emit(data)
        },
        err => this.globals.handleResponse(err.error, true)
      )
    }
  }
}
