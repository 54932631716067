import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { WeighInSearchComponent } from './weigh-in-search.component';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule,],
  declarations: [WeighInSearchComponent],
  exports: [WeighInSearchComponent]
})
export class WeighInSearchComponentModule {}
