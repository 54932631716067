<ion-grid>
  <ion-row>
    <ion-col>
      <a180-common-card
        title="Select the Documents to Send:"
        helpText="Click toggle to select">
        <a180-document-selector
          [client_id]="client_id"
          [location_name]="location_name"
          (documentSelected)="handleNewDocumentSelection($event)"
          (formFields)="sendForSignatureService.addToMissingFields($event)"
        ></a180-document-selector>
      </a180-common-card>
    </ion-col>
  </ion-row>

  <ion-row
    @fadeInOutUpDown
    *ngIf="sendForSignatureService.missingFields.length">
    <ion-col>
      <a180-common-card
        title="Enter the Following Values:"
        helpText="Enter values to send document">
        <a180-missing-fields-form
          [allowNotes]="allowNotes"
          (form_submission)="handleFormSubmission($event)"
        ></a180-missing-fields-form>
      </a180-common-card>
    </ion-col>
  </ion-row>
</ion-grid>
