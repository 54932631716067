<ng-container *ngIf="inputDate && finishedInitialization">
  <ion-datetime-button [datetime]="'datetime-' + randId" [ngStyle]="buttonStyle">
    <ion-icon *ngIf="!displayDate" slot="date-target" name="calendar"></ion-icon>
  </ion-datetime-button>

  <ion-popover [keepContentsMounted]="true" #displayContainer *ngIf="displayMode === 'popover'" trigger="open-date-selection">
    <ng-template>
      <ion-datetime
        #datePicker="ngModel"
        [id]="'datetime-' + randId"
        presentation="date"
        [preferWheel]="preferWheel"
        [name]="name"
        [(ngModel)]="inputDate"
        [required]="required"
        [max]="max"
        [min]="min"
        (ionChange)="emitNewDate($event, datePicker.valid)"
      ></ion-datetime>
    </ng-template>
  </ion-popover>

  <ion-modal [keepContentsMounted]="true" #displayContainer *ngIf="displayMode === 'modal'" trigger="open-date-selection">
    <ng-template>
      <ion-datetime
        #datePicker="ngModel"
        [id]="'datetime-' + randId"
        presentation="date"
        [preferWheel]="preferWheel"
        [name]="name"
        [(ngModel)]="inputDate"
        [required]="required"
        [max]="max"
        [min]="min"
        (ionChange)="emitNewDate($event, datePicker.valid)"
      ></ion-datetime>
    </ng-template>
  </ion-modal>
</ng-container>

<ng-container *ngIf="!inputDate">
  <ion-input
    type="date"
    [(ngModel)]="inputDate"
    [max]="max"
    [min]="min"
    [name]="name"
    [required]="required"
    #desktopDatepicker="ngModel"
    (ionChange)="emitNewDate($event, desktopDatepicker.valid)"
  ></ion-input>
</ng-container>
