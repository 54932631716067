<ion-header>
  <ion-toolbar [color]="urgent ? 'danger' : 'warning'">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss('no')">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content slot="fixed" style="--background: transparent;">
  <ion-card class="bg-white m-0 border" [ngClass]="urgent ? 'border-danger' : 'border-warning'" style="border-top-left-radius: 0;border-top-right-radius: 0;">
    <ion-card-content class="real-wrapper h-100 flex justify-content-center flex-column">
      <div class="ion-padding">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <h2 class="title no-margin fs-18 text-dark fw-600">{{ title }}</h2>
            </ion-col>
            <ion-col size="12">
              <p class="subtitle no-margin">{{ subtitle }}</p>
              <p *ngIf="formattedBody" [innerHTML]="formattedBody"></p>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="type == 'confirm'" class="mt-5">
            <ion-col>
              <ion-button (click)="dismiss('no')" expand="full" shape="round" color="danger">
                {{ this.confirmationButtonNegative || 'No' }}
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button (click)="dismiss('yes')" expand="full" shape="round" color="primary" class="confirmAlert">
                {{ this.confirmationButtonAffirmative || 'Yes' }}
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="type == 'prompt'" class="mt-5">
            <ion-col class="text-center">
              <ion-button *ngFor="let value of values"
                          shape="round"
                          [fill]="value.fill || 'solid'"
                          [color]="value.color || 'primary'"
                          (click)="dismiss({attribute: value.attribute, value: value.status})"
              >{{ value.name }}</ion-button>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="type == 'question'" class="mt-5">
            <ion-col>
              <ion-item *ngFor="let question of questions">
                <ion-label>{{question.label}}</ion-label>
                <ion-input autocapitalize="sentences" spellcheck="true" *ngIf="question.format != 'select' && question.format != 'textarea'" [(ngModel)]="model[question.attribute]"
                           [type]="question.type">
                </ion-input>
                <ion-select *ngIf="question.format == 'select'" [(ngModel)]="model[question.attribute]" interface="action-sheet">
                  <ion-select-option
                    *ngFor="let option of question.choices.options"
                    [value]="option[question.choices.value]">
                    {{option[question.choices.display]}}
                  </ion-select-option>
                </ion-select>
                <ion-textarea *ngIf="question.format == 'textarea'" [(ngModel)]="model[question.attribute]"></ion-textarea>
              </ion-item>
              <ion-row class="mt-5">
                <ion-col><ion-button (click)="dismiss('no')" expand="full" shape="round" color="danger">Cancel</ion-button></ion-col>
                <ion-col><ion-button (click)="dismiss(model)" expand="full" shape="round" color="primary">Submit</ion-button></ion-col>
              </ion-row>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="type === 'custom-prompt'" class="mt-5">
            <ion-col>
              <ion-button *ngFor="let value of values" shape="round" (click)="dismiss({value: value.val })">{{ value.name }}</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
