import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ExternalDocumentsService} from '../e-signature/e-signature.service';
import {ExternalDocument} from '../../models/external_document';
import {SendForSignatureService} from './send-for-signature.service';
import {fadeInOutUpDown} from '../../animations/fadeInOutUpDown';
import {GlobalsService} from '../../globals.service';
import {IBasicClient} from '../../models/client';
import {SignedDocumentsService} from '../signed-document-selector/signed-documents.service';
import * as _ from 'lodash';

@Component({
  selector: 'a180-send-for-signature',
  templateUrl: './send-for-signature.component.html',
  styleUrls: ['./send-for-signature.component.scss'],
  animations: [fadeInOutUpDown]
})
export class SendForSignatureComponent implements OnInit, OnDestroy, OnChanges {
  @Input() client_id: number;
  @Input() location_name: string;
  @Output() formSentForSignature = new EventEmitter<IBasicClient>()

  allowNotes: boolean = false;
  selectedDocuments: ExternalDocument[] = [];

  constructor(public externalDocumentsService: ExternalDocumentsService,
              public sendForSignatureService: SendForSignatureService,
              private signedDocumentService: SignedDocumentsService,
              private globals: GlobalsService) { }

  ngOnInit() {

    console.log(this)
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes?.client_id) {
      console.log("SendForSignatureComponent new client_id")
      this.signedDocumentService.fetchExecutedSignedDocuments(this.client_id).subscribe( docs => {
        console.log(docs)
      })
    }
  }

  ngOnDestroy() {
    console.log("destroyiung sendforsignature")
    this.sendForSignatureService.setMissingFields([])
    this.selectedDocuments = []
    this.client_id = null;
  }


  handleNewDocumentSelection(value: {document: ExternalDocument, isSelected: boolean}): void {
    let isSelected = value.isSelected
    let selectedDocument = value.document

    if (isSelected) {
      this.selectedDocuments.push(value.document)
      console.log(this.selectedDocuments)
    } else {
      this.selectedDocuments = _.reject(this.selectedDocuments, {id: value.document.id})
      this.sendForSignatureService.removeFromMissingFields(selectedDocument.id)
    }

    this.allowNotes = !!_.map(this.selectedDocuments, 'name').includes("New Investment Structure", "WW Investment Structure", "Investment Structure v2.25.22");
  }


  /**
   * Sends the client the {@link selectedDocuments} to send.  If the team member
   * @param event
   */
  handleFormSubmission(event): void {
    let document_ids = this.selectedDocuments.map(doc => doc.id)
    // console.log(event)
    this.externalDocumentsService.sendForSignature(this.client_id, document_ids, event).subscribe(
      response => {
        this.globals.handleResponse("Successfully sent the document(s) for signing!", false, 'success')
        this.formSentForSignature.emit(response.updatedFields)
      },
      err => {
        if ( err.error && Array.isArray(err.error['errors']) ) {
          this.globals.handleResponse(`Missing the following fields: ${err.error['errors'].join(", ")}`, true)
        } else {
          this.globals.handleResponse(`${err.error}`, true)
        }
      }
    )
  }
}
