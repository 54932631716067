import {IGoal} from './goal';
import {IPagination} from './pagination';
import {ValidatorFn, Validators} from '@angular/forms';

export interface IDailyCheckIn {
  answers: IDailyCheckInAnswer[] | DailyCheckInAnswer[];
  checkin_date: string;
  daily_checkin_template: IDailyCheckInTemplate;
}

export interface IDailyCheckinByQuestionAnswerResponse {
  metadata: {
    pagination: IPagination
  },
  result: {
    answers: IDailyCheckinByQuestionAnswer[]
  }
}

export interface IDailyCheckinByQuestionAnswer {
  id: number,
  checkin_date: string,
  question_id: number,
  value: string|number
}

export interface IDailyCheckinByQuestion extends IDailyCheckInQuestion {
  answers: IDailyCheckinByQuestionAnswer[]
}

export interface IDailyCheckInsResponse {
  result: {
    checkins?: IDailyCheckIn[],
    questions?: IDailyCheckinByQuestion[],
    number_of_responses?: number,
    items?: IDailyCheckIn[] | IDailyCheckInQuestion[],
  },
  metadata: {
    pagination: IPagination
  }
}

export interface IDailyCheckInTemplateResponse {
  result: {
    template: IDailyCheckInTemplate,
  },
  metadata: {
    pagination: IPagination
  }
}

export interface IDailyCheckInQuestionResponse {
  result?: IDailyCheckInQuestion,
  status_content?: {message: string}
}

export interface IDailyCheckInAnswer {
  id: number;
  value: number|string;
  question: IDailyCheckInQuestion;
}

export class DailyCheckInAnswer implements IDailyCheckInAnswer {
  id: number;
  value: number|string;
  question: IDailyCheckInQuestion;

  constructor(answer: IDailyCheckInAnswer) {
    Object.entries(answer).forEach(entry => {
      this[entry[0]] = entry[1]
    })
  }
}

export interface IDailyCheckInQuestionValidation {
  value: any;
  message: string;
}

export interface IDailyCheckInQuestion {
  id?: number;
  name: string;
  template_id?: number;
  seed_type: 'generic'|'custom'|'bank'
  author?: {id: number, name: string}
  created_at?: string;
  employee_id?: number;
  question_data: {
    max?: number;
    min?: number;
    step?: number;
    unit?: string;
    label?: string;
    value?: string|number;
    id?: string;
    total_stars?: number;
    allow_half_star?: boolean;
    multiline?: boolean;
    placeholder?: string;
    options?: IDailyCheckInTemplateQuestionDataOption[]
  };
  question_type: 'range_slider'|'smiley_rating'|'star_rating'|'text_input'|'number'|'single_select'|'multi_select'|'radio'
  validation?: {
    max?: IDailyCheckInQuestionValidation;
    min?: IDailyCheckInQuestionValidation;
    required?: IDailyCheckInQuestionValidation;
  }
}

export class DailyCheckInQuestion implements IDailyCheckInQuestion{
  id?: number;
  name: string; // tslint:disable-line:variable-name
  seed_type: 'generic'|'custom'; // tslint:disable-line:variable-name
  employee_id?: number; // tslint:disable-line:variable-name
  template_id?: number; // tslint:disable-line:variable-name
  author?: {id: number, name: string}
  created_at?: string; // tslint:disable-line:variable-name
  question_data: {  // tslint:disable-line:variable-name
    max?: number;
    min?: number;
    step?: number;
    unit?: string;
    label?: string;
    value?: string|number;
    id?: string;
    total_stars?: number;
    allow_half_star?: boolean;
    multiline?: boolean;
    placeholder?: string;
    options?: IDailyCheckInTemplateQuestionDataOption[]
  };
  question_type: 'range_slider'|'smiley_rating'|'star_rating'|'text_input'|'number'|'single_select' // tslint:disable-line:variable-name
  validation?: {
    max?: IDailyCheckInQuestionValidation;
    min?: IDailyCheckInQuestionValidation;
    required?: IDailyCheckInQuestionValidation;
  }

  constructor(question: IDailyCheckInQuestion) {
    Object.entries(question).forEach(entry => {
      this[entry[0]] = entry[1]
    })
  }
}

export interface IDailyCheckInTemplateQuestionDataOption {
  label?: string;
  value?: string|number;
  id?: string;
  valueValidators?: ValidatorFn[]
  labelValidators?: ValidatorFn[]
}

export interface IDailyCheckInTemplate {
  id?: number;
  client_id?: number;
  created_at?: string;
  template_type?: 'default'|'normal'|'bank'
  questions?: IDailyCheckInQuestion[] | DailyCheckInQuestion[]
}
