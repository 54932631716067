import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IonItemOption, IonItemSliding} from '@ionic/angular';

interface ISlidingOption {
  icon?: string,
  color?: string,
  label: string,
  callback?: any,
}

interface IIcon {
  name: string;
  type: string
}

@Component({
  selector: 'a180-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {
  @Input() item: any;
  @Input() options: any;
  @Input() label: string;
  @Input() subLabel: string;
  @Input() badgeLabel: string;
  @Input() startIcon: {name: string};
  @Input() slidingOptions: ISlidingOption[];
  @Input() endIcon: {name: string, type: string};
  @Input() badgeColor: string | CanvasGradient | CanvasPattern | string[];

  @Output() itemClicked = new EventEmitter<{$event: MouseEvent, item: any }>()
  @Output() iconClicked = new EventEmitter<{$event: MouseEvent, icon: IIcon}>()
  @Output() slidingOptionClicked = new EventEmitter<{$event: MouseEvent, slidingOption: ISlidingOption}>()

  @ViewChild('slider') slider: IonItemSliding;

  constructor() { }

  ngOnInit() {}

  handleClick($event: MouseEvent): void {
    const item = this.item
    this.itemClicked.emit({ $event, item })
  }

  handleSlidingOptionClick($event: MouseEvent, slidingOption: ISlidingOption): void {
    this.slidingOptionClicked.emit({ $event, slidingOption })
    this.slider.close()
  }

  handleIconClick($event: MouseEvent, icon: IIcon) {
    this.iconClicked.emit({ $event, icon })
  }
}
