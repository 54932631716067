import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CommonCardComponent} from './common-card.component';
import {IonicModule} from '@ionic/angular';
import {CardFooterComponent} from '../card-footer/card-footer.component';
import {PaginationComponentModule} from '../pagination/pagination.module';



@NgModule({
  declarations: [
    CommonCardComponent,
    CardFooterComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    PaginationComponentModule
  ],
  exports: [
    CommonCardComponent
  ]
})
export class CommonCardModule { }
