import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Client, IClient } from '../models/client';

@Injectable({
  providedIn: "root",
})
export class IndividualClientService {
  private client = new BehaviorSubject<Client>(new Client({}));
  client$: Observable<Client> = this.client.asObservable();

  private alerts = new BehaviorSubject<{ images: number }>({ images: 0 });
  alerts$: Observable<{ images: number }> = this.alerts.asObservable();

  constructor() {}

  setClient(client: Client): void {
    this.client.next(client);
    this.checkForAlerts()
  }

  getClient(): Client {
    return this.client.getValue();
  }

  checkForAlerts() {
    this.checkForImageAlerts(this.client.getValue())
  }

  /**
   * Determines if images are missing or not.
   * @param client The client whose profile is being viewed
   */
  checkForImageAlerts(client: IClient): number {
    if (!client) return

    let imageAlerts = 2
    const imageNames = [
      'before_front',
      'before_side',
      'after_side',
      'after_front'
    ]

    for (const name of imageNames) {
      console.log(name, client[name])
      if (client[name] !== null) imageAlerts--
    }

    console.log(imageAlerts);
    this.alerts.next({ images: imageAlerts });
  }
}
