<ion-card class="bg-white">
  <ion-card-content>
    <ion-toolbar>
      <ion-searchbar
        [(ngModel)]="name"
        [showCancelButton]="true"
        placeholder="Search by name on weigh-in (i.e. from the scale):"
        (ionChange)="searchForWeighInByName($event)"
      ></ion-searchbar>
    </ion-toolbar>

    <ion-list lines="true" *ngIf="records.length > 0">
      <ion-item *ngFor="let weigh_in of records" button="true" (click)="confirmWeighInSelection(weigh_in)">
        <ion-label class="ion-text-wrap">
          <ion-text class="fs-17 mb-2">
            {{weigh_in.name}}
          </ion-text>
          <p class="mt-2">
            Weight: {{weigh_in.current_weight}} | Body Fat: {{weigh_in.body_fat}} | Visceral Fat: {{weigh_in.visceral_fat}}
          </p>
        </ion-label>
        <ion-note slot="end">
          {{weigh_in.date | date:'short'}}
        </ion-note>
      </ion-item>
    </ion-list>
  </ion-card-content>
</ion-card>
