<ion-card [color]="backgroundColor">
  <ion-toolbar *ngIf="hasToolbar">
    <ng-content select="[toolbar]"></ng-content>
  </ion-toolbar>
  <ion-card-header *ngIf="hasHeader">
    <ion-card-subtitle>
      <ng-content select="[subtitle-top]"></ng-content>
    </ion-card-subtitle>
    <ion-card-title class="flex justify-content-space-between align-items-center">
      <span [innerHTML]="title"></span>
      <span *ngIf="helpText"><small class="subtle fs-16">{{ helpText }}</small></span>
      <ng-content select="[card-title-end]"></ng-content>
    </ion-card-title>
    <ng-content select="[subtitle-bottom]"></ng-content>
    <ng-content select="[slot-end]"></ng-content>
  </ion-card-header>

  <ion-card-content [ngClass]="[cardContentCssClasses.join(', '), 'pt-0']" *ngIf="displayCardContent">
    <div class="pb-3">
      <ng-content></ng-content>
    </div>
  </ion-card-content>

  <a180-pagination
    *ngIf="pagination && !hidePagination"
    [pagination]="pagination"
    (prevPageClicked)="prevPageClicked.emit()"
    (nextPageClicked)="nextPageClicked.emit()"
  ></a180-pagination>

  <a180-card-footer [innerHTML]="footerHtml">

  </a180-card-footer>


</ion-card>
