import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecoverLostRepositioningPage } from './recover-lost-repositioning.page';
import {RepositioningComponent} from './repositioning/repositioning.component';
import {RepositioningIndexComponent} from './repositioning-index/repositioning-index.component';
import {AuthGuard} from '../../auth/auth.guard';

const routes: Routes = [
  {
    path: 'recover-lost-repositioning',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: RecoverLostRepositioningPage,
    children: [
      {
        path: "",
        component: RepositioningIndexComponent
      },
      {
        path: ':clientName',
        component: RepositioningIndexComponent
      },
      {
        path: ':clientName/:visit_id',
        component: RepositioningIndexComponent
      },
      {
        path: ':clientName/:visit_id/:id',
        component: RepositioningComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RecoverLostRepositioningPageRoutingModule {}
