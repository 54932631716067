<ion-grid>
  <ion-row>
    <ion-col size="12" [sizeMd]="10" [sizeLg]="8" [offsetMd]="1" [offsetLg]="2">
      <a180-common-card
        [title]="data?.action == 'edit' ? 'Update the Reminder' : 'Add New Reminder'"
      >
        <a180-reminder-form
          [reminder]="reminder"
          [action]="data?.action"
          [friendlyItemName]="data?.friendlyItemName"
          (submittedReminder)="handleSubmittedReminder($event.reminder, $event.action)"
        ></a180-reminder-form>

      </a180-common-card>
    </ion-col>
  </ion-row>
</ion-grid>
