import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IPayment, Payment} from '../../../models/finance';
import {PaymentsService} from '../../../pages/clients/client-detail/providers/finances/payments.service';
import {GlobalsService} from '../../../globals.service';
import {CommentsProvider} from '../../../providers/comments.service';
import * as _ from 'lodash';

@Component({
  selector: 'payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
})
export class PaymentFormComponent implements OnInit, OnChanges {
  @Input() employee_id: number;
  @Input() client_id: number;
  @Input() purchaseTotal: number;
  @Input() paidInFull: boolean;
  @Input() program_id: number;
  @Input() payment: IPayment;
  @Input() paymentable_id: number;
  @Input() paymentable_type: string;
  @Output() paymentData = new EventEmitter<Payment>();
  @Output() error = new EventEmitter<string>();
  @Output() newAmount = new EventEmitter<number>();

  amount: number;
  payment_comment: string;
  paid_in_full: boolean = false;

  constructor(private paymentsService: PaymentsService,
              private globals: GlobalsService,
              private commentService: CommentsProvider) { }

  ngOnInit(): void {
    if (this.payment) {
      this.amount = this.payment.amount
      // if (this.payment.comments.length) this.payment_comment = this.payment.comments[0].body
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && _.keys(changes).indexOf('paidInFull') !== -1) {
      if (changes.paidInFull.currentValue) {
        this.amount = this.purchaseTotal
        this.paid_in_full = true
      } else {
        this.amount = this.amount || null
        this.paid_in_full = false
      }
    } else if (changes && changes.purchaseTotal) {
      if (this.paidInFull) {
        this.amount = changes.purchaseTotal.currentValue
      } else {
        this.amount = this.amount || null
      }
    }
  }


  /**
   * Submits the form, either updating or creating a new payment based on the input, {@link payment}.
   */
  submitForm(): void {
    if (this.payment) {
      this.updatePayment()
    } else {
      this.createPayment()
    }
  }


  /**
   * Adds a payment for the client.
   */
  createPayment() {
    let payment: Payment = {
      amount: this.amount,
      client_id: this.client_id,
      employee_id: this.employee_id,
      program_id: this.program_id,
      paymentable_id: this.paymentable_id,
      paymentable_type: this.paymentable_type,
      comments_attributes: []
    }

    if (this.payment_comment) payment.comments_attributes.push({
      body: this.payment_comment,
      employee_id: this.employee_id
    })

    this.paymentsService.create(payment).subscribe(
      paymentResponse => {
        this.paymentData.emit(paymentResponse)
      },
      err => this.globals.handleResponse(err.error, true)
    )
  }


  /**
   * Updates a payment and saves a comment if present.
   */
  updatePayment(): void {
    this.payment.amount = this.amount

    if (this.payment_comment) {
      this.payment.comments_attributes = [{
        body: this.payment_comment,
        employee_id: this.employee_id
      }]
    }

    this.paymentsService.update(this.payment).subscribe(
      payment => {
        this.paymentData.emit(payment)
      },
      err => this.globals.handleResponse(err.error, true)
    )
  }
}
