import {NgModule} from '@angular/core';
import {NewDocumentModal} from './new-document.modal';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  declarations: [
    NewDocumentModal
  ],
  providers: []
})

export class ImageUploadModule {}
