import {Employee, IEmployee} from './employee';
import {ILocation} from './location';

export class IUser {
  auth_token?: string;
  email?: string;
  employee?: Employee;
  id?: number;
  location_id?: number;
  name?: string;
  notifications?: any[];
  user_type?: number;
  created_at?: string;
  unlock?: string;
  isManager?: () => boolean;
  password?: string;
}

export class User {
  public auth_token?: string;
  public email?: string;
  public employee?: IEmployee;
  public id?: number;
  public location_id?: number;
  public name?: string;
  public notifications?: any[];
  public created_at?: string;
  public user_type?: number;
  public unlock?: string;
  public password?: string;

  constructor(data: Partial<any>) {
    Object.assign(this, data);
  }

  isManager() {
    if (this.user_type && this.user_type <= 2) return true;
    return this.employee && this.employee.isManager;
  }
}
