<ion-grid>
  <ion-row>
    <ion-col>

      <a180-common-card *ngIf="!selectedDocument"
        title="Select a Form to Sign:"
        helpText="Pull down to refresh">
        <ion-refresher slot="fixed" (ionRefresh)="fetchDocuments($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <ion-list class="mt-4" [@listStagger]='(externalDocumentService.externalDocuments$ | async)?.length'>
          <ion-item-sliding class="documentRowSlider shaded-slider" *ngFor="let item of externalDocumentService.externalDocuments$ | async">

              <ion-item button class="documentRow ion-text-wrap" style="background:none;" [disabled]="item.signed_at">
                <ion-icon
                  *ngIf="client"
                  [name]="item.signed_at ? 'checkmark-circle' : 'close-circle'"
                  [color]="item.signed_at ? 'success' : 'danger'"
                  slot="start"
                ></ion-icon>
                <ion-label (click)="selectDocument(item)" class="pointer">
                  <div class="flex align-items-center">
                    <h2 class="pb-1">
                      <strong class="pr-3">
                        {{ item.name | titleize | capitalize }}
                      </strong>
                    </h2>
                  </div>
                  <p *ngIf="client && item.highestStatus">
                    {{item.highestStatus.field | titleize | capitalize}}: {{item.highestStatus.date | date:'short'}}
                  </p>
                </ion-label>
              </ion-item>

          </ion-item-sliding>
        </ion-list>
        <ng-content></ng-content>
      </a180-common-card>

      <ion-card class="bg-white animated fadeInDown fast" *ngIf="selectedDocument">
        <e-signature-card
          [selectedDocument]="selectedDocument"
          [formattedUrl]="formattedUrl"
          [client]="client"
          (close)="handleDocumentSigning($event)"
        ></e-signature-card>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
