import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {S3_Service} from '../../providers/S3-service.service';
import {ModalController, NavParams, Platform} from '@ionic/angular';
import {File, FileEntry} from '@ionic-native/file/ngx';
import {PhotoService} from '../../providers/photo.service';
import {IClientDoc} from '../../models/client_doc';
import * as _ from 'lodash';
import * as Buffer from 'buffer';
import {WebView} from '@ionic-native/ionic-webview/ngx';
import {DomSanitizer} from '@angular/platform-browser';
import {AwakenModal} from '../../shared/awaken-modal/awaken-modal.component';
import {ToastService} from '../../providers/toast.service';

export function getFileReader(): FileReader {
  const fileReader = new FileReader();
  const zoneOriginalInstance = (fileReader as any).__zone_symbol__originalInstance;
  return zoneOriginalInstance || fileReader;
}

@Component({
  selector: 'new-document-modal',
  templateUrl: 'new-document.modal.html'
})

export class NewDocumentModal {
  document: any;
  newDocName: string;
  @ViewChild('image_for_cropping', {static: false}) image: ElementRef;
  @ViewChild('cropper', {static: false}) ImageCropper: any;
  @Input() clientId: number;
  @Input() editableName = true;
  @Input() key: string;
  @Input() docType: string;
  @Input() documentNames: string[];
  canSave: any;
  visibleToClient = false;
  file: any;
  progress = 0;
  data: any;
  fileType: string;
  filePath: string;
  isWeb = false;
  imageSrc: string | ArrayBuffer;
  documentType: string;
  isLoading = false;
  supportedFileTypes: string[];
  documentNameShortcuts = [
    'Weekly Meal Plan',
    'Limited Maintenance Meal Plan',
    'Full Maintenance Meal Plan'
  ]

  constructor(private modalCtrl: ModalController,
              public s3: S3_Service,
              private webview: WebView,
              private filePlugin: File,
              public sanitization: DomSanitizer,
              private params: NavParams,
              private toastService: ToastService,
              private photo: PhotoService,
              public plt: Platform) {

    if (!this.plt.is('ipad')) this.isWeb = true
    console.log(this)
    this.documentType = params.get('documentType') || 'Document'
    this.supportedFileTypes = ['.pdf', '.jpg', '.png', '.jpeg']
  }

  ionViewDidEnter(): void {
    // if (!this.isWeb) this.selectImage()
    this.s3.progress.next(0)
  }

  ionViewDidLeave(): void {
    this.photo.imageSource = null;
  }


  /**
   * Closes modal
   * @param shouldRefresh - param passed to parent component
   */
  dismiss(shouldRefresh: boolean = false) {
    this.modalCtrl.dismiss({
      shouldRefresh
    })
  }


  /**
   * Handles image uploads from desktop.
   * @param event file to be uploaded
   */
  fileInputSelected(event: { target: { files: any[]; }; }): void {
    const file = event.target.files[0]
    this.file = file
    if (file) {
      this.newDocName = file.name ? file.name.split('.')[0] : null
      this.fileType = `.${_.last(file.name.split('.'))}`
      let reader = new FileReader();

      if (this.fileType && this.supportedFileTypes.indexOf(this.fileType) === -1) {
        this.file = null
        this.newDocName = null
        this.fileType = null
        reader = new FileReader()

        this.modalCtrl.create({
          component: AwakenModal,
          componentProps: {
            title: `Unsupported File Type: ${this.fileType}!`,
            subtitle: `The document page only supports ${this.supportedFileTypes.join(', ')} at the moment`,
            type: 'urgent'
          },
          cssClass: 'small-modal'
        }).then(modal => modal.present())
      } else {

        reader.onload = () => {
          this.imageSrc = reader.result
        }

        reader.readAsDataURL(file)
        this.canSave = true
      }
    }
  }


  /**
   * Selects the source type, fires cropping, displays photo.
   */
  selectImage(): void {
    this.photo.selectImageSheet().then(() => {
      if (typeof (this.photo.imageSource) === 'number') {
        this.photo.pickImage(this.photo.imageSource).then(croppedImagePath => {
          const finalForwardSlashIndex = croppedImagePath.lastIndexOf('/');
          const currentName = croppedImagePath.slice(finalForwardSlashIndex + 1);
          const correctPath = croppedImagePath.slice(0, finalForwardSlashIndex + 1);
          this.copyFileToLocalDir(correctPath, currentName, this.createFileName());
        })
      }
    })
  }


  createFileName() {
    const d = new Date(), n = d.getTime();
    return n + '.jpg';
  }


  copyFileToLocalDir(namePath: string, currentName: string, newFileName: string) {
    this.filePlugin.copyFile(namePath, currentName, this.filePlugin.dataDirectory, newFileName).then(() => {
      this.updateStoredImages(newFileName);
    }, () => {
      this.toastService.handleResponse('Error while storing file.', true);
    });
  }


  updateStoredImages(name: string) {
    this.filePath = this.filePlugin.dataDirectory + name;
    this.imageSrc = this.pathForImage(this.filePath);
    this.canSave = true;
  }


  async handleDuplicateName(): Promise<any> {
    const confirm = await this.modalCtrl.create({
      component: AwakenModal,
      componentProps: {
        title: `It looks like the name "${this.newDocName}" is already taken. Do you wish to continue?
            This will overwrite the original document.`,
        urgent: true,
        type: 'confirm'
      },
      cssClass: 'small-modal'
    })
    await confirm.present()
    return await confirm.onDidDismiss()
  }

  async startUpload() {
    if (this.documentNames && this.documentNames.indexOf(this.newDocName.toLocaleLowerCase()) !== -1) {
      const data = await this.handleDuplicateName()
      if (data && data.data && data.data === 'yes') {
        await this.continueUpload()
      }
    } else {
      await this.continueUpload()
    }
  }


  async continueUpload() {
    await this.uploadFile()
  }


  readFile(file: any) {
    const reader = getFileReader()
    reader.onload = () => {
      this.file = reader.result
      this.uploadFile()
    };
    reader.readAsDataURL(file);
  }


  pathForImage(img: string) {
    if (img === null) {
      return '';
    } else {
      return this.webview.convertFileSrc(img);
    }
  }


  /**
   * Uploads the image to S3 and updates the client_docs.  Then closes modal.
   */
  async uploadFile(): Promise<any> {
    let key = `${this.key}`;

    if (this.editableName) key += `/${this.newDocName}---${Date.now()}`
    if (this.fileType) key += this.fileType

    const params = {
      Key: key,
      Body: this.file,
      Bucket: 'awaken180-assets',
      ContentEncoding: 'base64',
      ContentType: this.fileType
    };

    this.canSave = false;

    try {
      const data = await this.s3.upload(params)
      const doc: IClientDoc = {
        name: this.newDocName,
        url: `${this.s3.config.bucket}${data.key}`,
        client_id: this.clientId,
        key: data.key,
        fileKey: data.key,
        visible_to_client: this.visibleToClient
      }

      await this.modalCtrl.dismiss({
        shouldRefresh: true,
        image: doc
      })

    } catch (e) {
      this.toastService.handleResponse('Error uploading the image!', true)
    }
  }

  insertDocumentName(documentName: string) {
    this.newDocName = this.newDocName ? this.newDocName += documentName : documentName
  }
}
