import { Component, OnInit } from '@angular/core';
import {NavController} from '@ionic/angular';
import {IDraftRepositioning} from '../../models/repositioning';
import {Storage} from '@ionic/storage';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-recover-lost-repositioning',
  templateUrl: './recover-lost-repositioning.page.html',
  styleUrls: ['./recover-lost-repositioning.page.scss'],
})
export class RecoverLostRepositioningPage implements OnInit {
  isLoading: boolean = true;
  noDraftRepos: boolean = false;
  draftRepos: {key: string, data: IDraftRepositioning}[];

  constructor(private navCtrl: NavController,
              private storage: Storage,
              private router: Router,
              private location: Location,
              private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Recover Lost Repositioning")
  }


  /**
   * Close page and go back to clients index
   */
  dismiss(): void {
    this.location.back()
  }

}
