import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[a180ComponentHost]'
})
export class ComponentHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
