import {Injectable} from '@angular/core';
import {Client, IBasicClient, IClient, IIndexClient, IndexClient} from '../../models/client';
import {BehaviorSubject, Observable} from 'rxjs';
import {GlobalsService} from '../../globals.service';
import {HttpClient} from '@angular/common/http';
import {ClientIndex, IClientIndex} from '../../models/client_index';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {IPendingWeighIn} from '../../models/weigh-in';
import { DailyCheckInsService } from 'src/app/providers/daily-check-ins.service';
import {RefundService} from '../../providers/refund.service';
import client from 'firebase-tools';
import { IndividualClientService } from '../../providers/client.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  baseUrl: string;

  private clients = new BehaviorSubject<IClientIndex>({});
  clients$ = this.clients.asObservable();

  private client = new BehaviorSubject<Client>(new Client({}));
  client$: Observable<IClient> = this.client.asObservable();

  isWorldwide: boolean = false;

  private client_id = new BehaviorSubject<number>(null);
  client_id$ = this.client_id.asObservable();

  private searchResult = new BehaviorSubject<IClient[]>([]);
  searchResult$ = this.searchResult.asObservable();

  constructor(private globals: GlobalsService,
              private dailyCheckInsService: DailyCheckInsService,
              private refundService: RefundService,
              private individualClientService: IndividualClientService,
              private http: HttpClient) {
    this.baseUrl = globals.base_url;
    // console.log('localService is constructed');
  }

  /**
   * Returns an observable of clients from the server.
   * @param location_id
   * @param day
   */
  getDailyClients(location_id, date): Observable<ClientIndex> {
    return this.http.get<ClientIndex>(`${this.baseUrl}/locations/${location_id}/load_daily_clients?day=${date}`)
  }

  /**
   * Updates the value of clients.
   * @param clients
   */
  setClients(clients: IClientIndex): void {
    this.clients.next(clients)
  }

  /**
   * Updates the value of client.
   * @param client
   */
  setClient(client: IClient) {
    let real_client = new Client(client)
    console.log(real_client)
    if (client) {
      this.isWorldwide = real_client.isWorldwide()
    }
    this.client.next(real_client)
    this.individualClientService.setClient(real_client);
  }

  setClientID(client_id: number): void {
    this.client_id.next(client_id)
  }

  getClientID(): number {
    return this.client_id.getValue()
  }

  /**
   * Returns a value for the BehaviorSubject client.
   */
  getClient(): Client {
    return this.client.getValue()
  }





  /**
   * Initiates coachcare onboarding for the client.
   * @returns a 200 status regardless of success which is why a 'success' value is returned.  If true, it was successful, if
   * false the 'message' value will tell you why.
   * @param clientId: The client's ID
   * @param setClient: Whether or not to update the client$ observable
   */
  initiateClientOnboarding(clientId: number, setClient: boolean = true): Observable<{success: boolean, message: string, client: IBasicClient}> {
    const url = `${this.baseUrl}/clients/${clientId}/invite_to_mobile_app`;
    return this.http.post<{success: boolean, message: string, client: IBasicClient}>(url, null).pipe(tap(client => {
      // let currentClient = this.getClient();
      // currentClient.coach_care_account_created_at = client.client.coach_care_account_created_at;
      if (setClient) this.setClient(client.client)
    }))
  }

  sendTechSupportEmailForClient(client_id: number, email: string): Observable<boolean> {
    let url = `${this.baseUrl}/clients/${client_id}/send_tech_support_email_for_client`
    return this.http.post<boolean>(url, {subject: "Team Member Reset Coachcare Password", request_text: "A team member has reset a client's coachcare password.", email: email})
  }



  /**
   * Requests scale for the client.  This will send an email to Coachcare and should be used cautiously.
   * @param clientId: The client's ID
   */
  requestCoachcareScale(clientId: number): Observable<IBasicClient> {
    const url = `${this.baseUrl}/clients/${clientId}/request_coach_care_scale`;
    return this.http.post<IBasicClient>(url, null).pipe(tap(client => {
      this.setClient(client)
    }))
  }


  /**
   * Sorts client array by either time or name
   * @param {string} method  Must be either "time" or "name"
   * @example sortClients('time')
   */
  sortClients(method: string): void {
    this.clients$.subscribe(clients => {
      for (let type of Object.keys(clients)) {
        let apps = clients[type];
        let sorted = [];

        if (apps) {
          if (method == 'name') {
            sorted = _.sortBy(apps, (client) => {
              if (client && client.name) {
                return _.last(client.name.split(" "))
              }
            })
          } else {
            sorted = apps.sort((a, b) => {
              return new Date(a['time']).valueOf() - new Date(b['time']).valueOf()
            })
          }
          clients[type] = sorted;
        }
      }
    });
  }

  clientSearch(val: string): Observable<IClient[]> {
    let url = `${this.baseUrl}/clients/search?params=${val}`;
    return this.http.get<IClient[]>(url)
  }


  search(val: string): void {
    if (val && val.trim() != "") {
      this.clientSearch(val).subscribe(
        clients => this.searchResult.next(clients),
        err => this.globals.handleResponse(err.error, true)
      )
    } else {
      this.searchResult.next([])
    }
  }

  /**
   * Returns a client from the server based on client_id.
   * Also updates the value for client$.
   * @param id Client ID
   * @param set Whether or not to set this client as the observable
   */
  fetchClient(id: number | string, set = true): Observable<IClient> {
    const url = `${this.baseUrl}/clients/${id}?version=2`;
    return this.http.get<IClient>(url).pipe(
      tap(client => {
        if (set) {
          this.setClient(client)
          this.refundService.setRefunds(client.refunds)
        }
      })
    )
  }

  fetchClientByUniqueMb(unique_mb: number): Observable<IClient> {
    let url = `${this.baseUrl}/clients/clientsByUnqiueMb/${unique_mb}`
    return this.http.get<IClient>(url).pipe(
      tap(client => {
        // if ( set ) {
        this.setClient(client)
        // }
      })
    )
  }

  /**
   * Sends request that generates prepared meal reminder email to client.
   * @param client_id
   */
  prepared_meal_reminder(client_id: number): Observable<Object> {
    let url = `${this.baseUrl}/clients/${client_id}/prepared_meal_reminder`
    return this.http.get(url)
  }

  // inviteClient( consult_client : IIndexClient ) : Observable<IClient> {
  inviteClient(email: string, location_id: number, client_id: number = null, resendInvitation = false): Observable<IClient> {
    let url = `${this.baseUrl}/users`;
    let options = {
      email: email,
      location_id: location_id,
      client_id: client_id,
      resendInvitation
    };
    return this.http.post<IClient>(url, options)
  }

  create(client: IIndexClient): Observable<IClient> {
    const url = `${this.baseUrl}/clients`;
    return this.http.post<IClient>(url, client)
  }

  fetchPendingCoachcareCheckIns(clientId: number, programId: number): Observable<{ id: number, weigh_in: IPendingWeighIn }[]> {
    const url = `${this.baseUrl}/clients/${clientId}/pendingCoachcareCheckIns?program_id=${programId}`
    return this.http.get<{ id: number, weigh_in: IPendingWeighIn }[]>(url)
  }

  /**
   * Unlinks the client's health profile
   * @param clientId: Client whose health profile should be unlinked
   */
  unlinkHealthProfile(clientId: number): Observable<IClient> {
    const url = `${this.baseUrl}/clients/${clientId}/unlinkHealthProfile`
    return this.http.put<IClient>(url, null)
  }

  /**
   * Updates client with params supplied.  Must include ID in client param.
   * @param client
   */
  update(client: any, updateObservable: boolean = true): Observable<IClient> {
    let url = `${this.baseUrl}/clients/${client.id}`
    return this.http.put<IClient>(url, client).pipe(
      tap(client => {
        if (updateObservable) this.setClient(client)
      })
    )
  }


  /**
   * Deletes the client from the database.
   * @param client
   */
  deleteClient(client: IndexClient): Observable<null> {
    let url = `${this.baseUrl}/clients/${client.id}`
    return this.http.delete<null>(url).pipe(tap(
      () => {
        let clients = this.clients.getValue()
        let searchClients = this.searchResult.getValue()

        _.each(clients, (v) => _.remove(v, {id: client.id}))
        _.remove(searchClients, {id: client.id})

        this.searchResult.next(searchClients)
        this.setClients(clients)
      }
    ))
  }


  /**
   * Checks MindBody for the purchase of a scale
   * @returns true or false depending on the presence of a scale purchase.
   */
  checkForPurchase(clientId: number, itemName: string): Observable<boolean> {
    const url = `${this.baseUrl}/clients/${clientId}/check_for_purchase?item_name=${itemName}`
    return this.http.get<boolean>(url)
  }
}
