import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {ICheckIn} from '../models/check_in';
import {CheckInsProvider} from './check-ins.service';
import {IBasicRepositioning, IRepositioning} from '../models/repositioning';
import {GlobalsService} from '../globals.service';
import {map, tap} from 'rxjs/operators';
import * as _ from 'lodash'
import * as Sentry from 'sentry-cordova';
import {IProgram} from '../models/program';
import {ProgramsService} from '../pages/clients/client-detail/providers/programs/programs.service';

@Injectable({
  providedIn: 'root'
})
export class RepositioningsService {
  private repos = new BehaviorSubject(<IRepositioning[]>[]);
  repos$ = this.repos.asObservable();
  check_ins$: Observable<ICheckIn[]>;
  last_repo: IRepositioning;
  base_url: string;

  constructor( private global: GlobalsService,
               private checkInsProvider: CheckInsProvider,
               private programsService: ProgramsService,
               private http: HttpClient ) {

    this.base_url = this.global.base_url;
    this.check_ins$ = this.checkInsProvider.check_ins$
    this.repos$ = this.check_ins$.pipe(
      map( array_of_cis => {
        let arr = []
        array_of_cis.map( ci => {
          if (ci.repositioning) {
            arr.push(ci.repositioning)
          }
        })
        this.last_repo = _.last(arr)
        return arr
      })
    )
  }


  // CRUD -----------------------------------------------------------------------

  create(repo: IRepositioning) {
    console.log('Creating Repo: ', repo)
    const url = `${ this.base_url }/repositionings`

    // return this.http.post<IProgram[]>(url, repo).pipe(
    //   tap(program => {
    //     console.log(program)
    //     // Sentry.addBreadcrumb({
    //     //   message: `Loaded program from repo: ${program.id} - ${JSON.stringify(repo)}`,
    //     // })
    //     // this.checkInsProvider.addRepositioning(repositioning)
    //   })
    // )

    return this.http.post<IRepositioning>(url, repo).pipe(
      tap(repositioning => {
        Sentry.addBreadcrumb({
          message: `CheckInID: ${repositioning.check_in_id} - ${JSON.stringify(repositioning)}`,
        })
        this.checkInsProvider.addRepositioning(repositioning)
        // console.log(repositioning)
        // this.programsService.fetchClientPrograms(repositioning.client_id).subscribe()
      })
    )
  }

  createOrUpdate(repo: IRepositioning): Observable<IRepositioning> {
    if (repo.id) {
      return this.update(repo)
    } else {
      return this.create(repo)
    }
  }

  update(repo: IRepositioning) {
    console.log("Updating Repo: ", repo)
    let url = `${ this.base_url }/repositionings/${ repo.id }`
    return this.http.put<IRepositioning>(url, repo).pipe(
      tap(repositioning => {
        console.log("Updated! ", repositioning)
        this.checkInsProvider.addRepositioning(repositioning)
      })
    )
  }

  destroy(repo: IRepositioning): Observable<any> {
    let url = `${ this.base_url }/repositionings/${ repo.id }`
    return this.http.delete(url).pipe(
      tap(() => {
        if (repo) this.checkInsProvider.removeRepositioning(repo)
      })
    )
  }

  sendEmail(repositioning: IBasicRepositioning): Observable<null> {
    let url = `${this.base_url}/repositionings/${repositioning.id}/send_weekly_report_card`
    return this.http.post<null>(url, {})
  }
}
