import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {IonModal, IonPopover, ModalController, Platform} from '@ionic/angular';

@Component({
  selector: 'a180-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnChanges {
  @ViewChild('displayContainer') displayContainer: IonModal|IonPopover;
  @Input() inputDate: string;
  @Input() displayDate: boolean;
  @Input() buttonStyle: any;
  @Input() name: string;
  @Input() max: string;
  @Input() min: string;
  @Input() displayMode: 'modal'|'popover' = 'popover'
  @Input() required: boolean;
  @Input() closeModal: boolean;
  @Input() preferWheel: boolean;
  @Output() dateSelected: EventEmitter<{value: string, valid: boolean}> = new EventEmitter<{value: string, valid: boolean}>();
  startingDate: string;
  desktop: boolean;
  randId: number;
  finishedInitialization = false;


  constructor(private modalCtrl: ModalController,
              private plt: Platform,
              private cdr: ChangeDetectorRef) {}

  ionViewWillEnter() {
    setTimeout(() => this.finishedInitialization = true, 500)
  }

  ngOnInit() {
    this.randId = Math.round(Math.random() * 10000)
    this.startingDate = this.inputDate
    this.desktop = this.plt.is('desktop')
    setTimeout(() => {
      this.finishedInitialization = true
    }, 500)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cdr.reattach()
    if (changes.inputDate) {
      const date = changes.inputDate.currentValue;
      this.inputDate = date
      this.startingDate = date
    }
  }

  async emitNewDate($event: any, fieldValid: boolean) {
    const isModalOpen = await this.modalCtrl.getTop();
    if (![''].includes($event.detail.value) && this.finishedInitialization) {
      if (this.displayContainer) await this.displayContainer.dismiss()
      if (isModalOpen && this.closeModal) {
        await this.modalCtrl.dismiss({
          value: $event.detail.value,
          valid: fieldValid
        })
      } else {
        this.dateSelected.emit({
          value: $event.detail.value,
          valid: fieldValid
        })
      }
    }
  }
}
