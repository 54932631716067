import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientsRoutingModule} from './clients-routing.module';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '../../pipes/pipes.module';
import {ClientService} from './clients.service';
import {MomentModule} from 'ngx-moment';
import {ClientDetailPageModule} from './client-detail/client-detail.module';
import {ClientIndexPageModule} from './client-index/client-index.module';
import {ClientInviteModule} from './client-invite/client-invite.module';
import {DocumentsPageModule} from './client-detail/documents/documents.module';
import {ESignatureModule} from '../../components/e-signature/e-signature.module';
// import {ProfilePageModule} from './client-detail/profile/profile.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    // ProfilePageModule,
    DocumentsPageModule,
    ClientDetailPageModule,
    PipesModule,
    ESignatureModule,
    ClientIndexPageModule,
    ClientInviteModule,
    ClientsRoutingModule,
    MomentModule.forRoot()
  ],
  declarations: [
  ],
  providers: [
    ClientService,
  ]
})
export class ClientsModule { }
