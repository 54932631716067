import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertController, IonMenu, IonRouterOutlet, ModalController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Router, RouterEvent} from '@angular/router';
import {Storage} from '@ionic/storage';
import {AuthService} from './auth/auth.service';
import {LocationService} from './providers/locations.service';
import {EmployeeService} from './providers/employee.service';
import {slideInAnimation} from './animations/slideInAnimation';
import {GlobalsService} from './globals.service';
import {Deploy} from 'cordova-plugin-ionic';
import {Subscription} from 'rxjs';
import {NgxZendeskWebwidgetService} from 'ngx-zendesk-webwidget';
import {NewWeighInComponent} from './components/new-weigh-in/new-weigh-in.component';
// @ts-ignore
import * as packageInfo from '../../package.json';
import { environment } from 'src/environments/environment';

window['zESettings'] = {

  // authenticate: {jwt: `${0}`},
  webWidget: {
    chat: {
      suppress: true
    },
    position: {horizontal: 'right', vertical: 'top'},
    launcher: {
      label: {
        '*': 'Helpxxxx',
      }
    },
    helpCenter: {
      messageButton: {
        '*': 'Poop Poop!'
      },

    },
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  animations: [slideInAnimation]
})
export class AppComponent implements OnDestroy {
  rootPage: any = 'LoginPage';
  selectedPath = '';
  @ViewChild('menu', {static: false}) menu: IonMenu;
  pages: { title: string, requireManager?: boolean, url: string, routerDirection?: string, icon?: string }[];
  managerPages: { title: string, requireManager?: boolean, url: string, routerDirection?: string }[];
  browserTitle: string;
  showAdvanced: boolean = false;
  availableChannels: any[];
  deployChannel: string;
  currentUserSubscription: Subscription;
  packageInfo = packageInfo;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private alertCtrl: AlertController,
    public authService: AuthService,
    private modalCtrl: ModalController,
    private locationService: LocationService,
    private storage: Storage,
    private globals: GlobalsService,
    private employeeService: EmployeeService,
    private zendesk: NgxZendeskWebwidgetService,
  ) {
    this.initializeApp();

    this.router.events.subscribe((event: RouterEvent) => {
      if (event && event.url) {
        this.selectedPath = event.url;
      }
    });

    this.currentUserSubscription = this.authService.current_user$.subscribe(() => {
      this.setupPages();
    })

    this.availableChannels = ['Production', 'Beta', 'Testing'];
    this.setupPages();
    this.initializeLocations();
    this.initializeUser();
    this.initializeEmployees();
    this.checkChannel()
    this.performManualUpdate()
    // zendesk.zE('webWidget', 'hide')
  }

  ngOnDestroy() {
    if (this.currentUserSubscription) this.currentUserSubscription.unsubscribe()
  }

  async performManualUpdate() {
    try {
      const currentVersion = await Deploy.getCurrentVersion();
      console.log(currentVersion)
      const resp = await Deploy.sync({updateMethod: 'auto'}, percentDone => {
        console.log(`Update is ${percentDone}% done!`);
      });
      if (!currentVersion || currentVersion.versionId !== resp.versionId){
        // We found an update, and are in process of redirecting you since you put auto!
      }else{
        // No update available
      }
    } catch (err) {
      // We encountered an error.
    }
  }


  /**
   * Asks the user if they want to proceed and then continues to update the app depending on the user's channel selection.
   * @param event
   */
  async confirmFetch(event) {
    const confirm = await this.alertCtrl.create({
      header: 'Are you sure you want the beta features?',
      message: 'This could have a drastic negative impact on performance.  Do not proceed without instruction.',
      buttons: [
        {
          text: 'No',
          handler: () => {}
        },
        {
          text: 'Yes',
          handler: () => {
            this.fetchNew()
          }
        }
      ]
    });

    await confirm.present();
  }


  /**
   * Grabs the configuration of the provided channel and updates the user's app.
   */
  async fetchNew() {
    try {
      // await Pro.deploy.configure(config);
      // await this.checkChannel();
      // await Pro.deploy.sync({updateMethod: 'auto'}); // Alternatively, to customize how this works, use performManualUpdate()
      // console.log(this.deployChannel)
      await Deploy.configure({channel: this.deployChannel});
      await this.performManualUpdate()
    } catch (err) {
      // Pro.monitoring.exception(err);
    }
  }


  /**
   * Grabs Ionic Pro configuration and sets {@link this.deployChannel} to the config channel.
   */
  async checkChannel() {
    try {
      const res = await Deploy.getConfiguration()
      if (res) this.deployChannel = res.channel;
    } catch (err) {
      // Pro.monitoring.exception(err);
    }
  }


  /**
   * Fetch locations from storage and set them in the {@link LocationService}.
   */
  initializeLocations() : void {
    this.storage.get("locations").then((val) => {
      this.locationService.setLocations(val);
    })
  }


  /**
   * Fetch user from storage and set them in the {@link GlobalsService}.
   */
  initializeUser() : void {
    this.storage.get('current_user').then((val) => {
      this.globals.setUser(val)
    })
  }


  /**
   * Fetch employees from storage and if value exists, set them in the {@link EmployeeService}
   * otherwise fetch the employees from the server.
   */
  initializeEmployees() : void {
    this.storage.get("employees").then((val) => {
      if (val) this.employeeService.setEmployees(val)
    })
  }

  /**
   * Setup the pages for use in the side menu.
   */
  setupPages() : void {
    this.pages = [
      {
        title: 'Update Profile',
        requireManager: false,
        url: '/users/edit',
        icon: 'person'
      },
      {
        title: 'Client Index',
        url: '/clients',
        requireManager: false,
        icon: 'people'
      },
      {
        title: 'Notifications',
        url: '/notifications',
        requireManager: false,
        icon: 'notifications'
      },
      {
        title: 'Food Order Queue',
        url: '/food-order-queue',
        routerDirection: 'forward',
        requireManager: false,
        icon: 'fast-food'
      },
      {
        title: 'Recover Lost Repositioning',
        url: '/recover-lost-repositioning',
        routerDirection: 'forward',
        requireManager: false,
        icon: 'arrow-undo'
      },
      {
        title: 'Scan Referral Card',
        url: 'referral-card-scanner',
        routerDirection: 'forward',
        requireManager: false,
        icon: 'card'
      }
    ];

    let managerPages = [
      {
        title: "Manager Tools",
        url: '/manager-dashboard',
        requireManager: true,
        icon: 'settings'
      },
      {
        title: 'Dashboard',
        url: '/manager-dashboard/dashboard',
        requireManager: true,
        icon: 'home'
      }
    ]

    if (this.authService.getCurrentUser().user_type <= 2) {
      this.pages = [...managerPages, ...this.pages]
    }
  }


  /**
   * Opens modal to scan a weigh-in without a client.
   */
  scanWeighIn() {
    this.modalCtrl.create({
      component: NewWeighInComponent,
      componentProps: {
        claim_status: "unclaimed"
      },
      cssClass: 'modal-fullscreen'
    }).then(modal => modal.present())
  }


  prepareRoute(outlet: IonRouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  displayAdvancedOptions(event) {
    console.log('displaying advanced options')
    event.preventDefault()
    event.stopPropagation()
    this.showAdvanced = !this.showAdvanced
    this.menu.setOpen(true)
  }


  /**
   * Logout the current user.
   */
  logout() : void {
    this.authService.logout()
    this.menu.close()
  }

  handleWillClose(event) {
    console.log('closing', event)
  }


  /**
   * Setup initial app values.
   */
  initializeApp() : void {
    console.log(`Environment Is Production: ${environment.production} running version ${packageInfo?.version}`)

    if (packageInfo?.version !== '8.0.0') {
    //   location.reload(true)
      // window.location.href = window.location.href.replace(/#.*$/, '');
    }

    this.platform.ready().then(() => {
      console.log(`Environment Is Production: ${environment.production} running version ${packageInfo?.version}`)
      if ( !this.platform.is('desktop') ) {
        this.statusBar.overlaysWebView(false);
        this.statusBar.hide()
        this.statusBar.backgroundColorByHexString("0072F0")
        this.splashScreen.hide();
      }
    });
  }

  // Refreshes the app by reloading the page
  refresh() {
    location.reload()
  }
}
