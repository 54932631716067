export interface IComment {
  body: string;
  commentable_type?: string;
  commentable?: any;
  id?: number;
  commentable_id?: number;
  employee_id?: number;
  created_at?: string;
  employee?: string;
}

export class Comment {
  public body: string;
  public commentable_type?: string;
  public commentable?: any;
  public id?: number;
  public commentable_id?: number;
  public employee_id?: number;
  public created_at?: string;
  public employee?: string;

  constructor( data: Partial<IComment> ) {
    Object.assign(this, data);
  }
}