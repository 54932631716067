<ion-header [ngClass]="{'pt-2': fullscreen}">
  <ion-toolbar [ngClass]="{'transparent': transparent}">
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title class="ion-text-center">{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" [color]="{'light': transparent}" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'blue-purple-gradient': gradientBackground}" [fullscreen]="fullscreen">
  <ng-template a180ComponentHost></ng-template>
</ion-content>
