import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {BasicCheckIn, ICheckIn} from '../../../../../models/check_in';
import {CheckInsProvider} from '../../../../../providers/check-ins.service';
import {IWeighIn} from '../../../../../models/weigh-in';
import {IRepositioning} from '../../../../../models/repositioning';
import * as _ from 'lodash'

@Injectable()
export class VisitService implements OnDestroy {

  private check_in = new BehaviorSubject(<BasicCheckIn|null>{});
  check_in$: Observable<BasicCheckIn> = this.check_in.asObservable();

  private repo = new BehaviorSubject(<IRepositioning|null>{});
  repo$: Observable<IRepositioning> = this.repo.asObservable();

  private prev_ci = new BehaviorSubject(<BasicCheckIn|null>{});
  prev_ci$: Observable<BasicCheckIn> = this.prev_ci.asObservable();

  usedWeeks: number;
  usedWeekChange: Subject<number> = new Subject<number>();

  check_ins$: Observable<ICheckIn[]>;
  checkInSub: Subscription;
  check_ins: ICheckIn[];
  isReEvalAppointment: boolean;
  outOfWeeks: boolean;
  partOfVisit: boolean = false;

  constructor( private checkInsProvider: CheckInsProvider ) {
    this.check_ins$ = this.checkInsProvider.check_ins$
    this.checkInSub = this.check_ins$.subscribe( check_ins => {
      this.check_ins = check_ins
      this.usedWeeks = this.checkInsProvider.used_weeks
    })
    this.usedWeekChange.subscribe(() => {})
  }

  ngOnDestroy(): void {
    if (this.checkInSub) this.checkInSub.unsubscribe()
  }

  getCheckIn() {
    return Object.assign({}, this.check_in.getValue())
  }

  determineReEvalStatus(purchasedWeeks: number, isWorldwide: boolean = false, checkInId = null): boolean {
    let deductor = isWorldwide ? 1 : 1
    let check_ins = null
    let reEvalStatus = null
    let used_weeks = null

    if (checkInId) {
      check_ins = this.checkInsProvider.getCheckIns()
      let i = _.findIndex(check_ins, {id: checkInId})
      check_ins = _.slice(check_ins, i+1, check_ins.length)
      used_weeks = this.checkInsProvider.calculateUsedWeeks(check_ins)
    }

    if (used_weeks != null) {
      reEvalStatus = used_weeks == (purchasedWeeks - deductor)
    } else {
      reEvalStatus = this.checkInsProvider.used_weeks == (purchasedWeeks - deductor)
    }

    this.isReEvalAppointment = !!reEvalStatus;

    return this.isReEvalAppointment
  }

  initiateVisit(purchasedWeeks: number, programStatus: string) {
    let usedWeeks = this.checkInsProvider.used_weeks

    if (usedWeeks >= purchasedWeeks && (['limited maintenance', 'full maintenance', 'limited maintenence', 'full maintenence']).indexOf(programStatus) == -1) {
      this.outOfWeeks = true
    } else {
      this.outOfWeeks = false
    }
  }

  getPrevCheckIn() {
    return Object.assign({}, this.prev_ci.getValue())
  }

  getRepo() {
    return Object.assign({}, this.repo.getValue())
  }


  changeIn( field : string ) {
    let final = null
    let ci = this.getCheckIn() || this.check_in.getValue()
    let prev_ci = this.getPrevCheckIn() || this.prev_ci.getValue()

    if ( ci && ci.weigh_in && prev_ci && prev_ci.weigh_in ) {
      let curr_val = (Math.round(parseFloat(ci.weigh_in[field]) * 10)) / 10
      let prev_val = (Math.round(parseFloat(prev_ci.weigh_in[field]) * 10)) / 10

      final = (curr_val - prev_val)
    }

    return final
  }

  setCheckIn( check_in : BasicCheckIn ) : void {
    console.log("setting check in: ", check_in)
    console.log(`beginning visit, used weeks: ${this.usedWeeks}`)
    this.check_in.next(check_in)
    this.findPriorCheckIn(check_in)
  }


  /**
   * Sets the prior check in based on the current check in.
   * @param check_in: The current check in used to find the prior check in.
   */
  findPriorCheckIn( check_in : ICheckIn) : void {
    let prev_ci = this.checkInsProvider.priorCheckIn(check_in)
    console.log(prev_ci)
    this.setPrevCheckIn(prev_ci)
  }

  setWeighIn( weigh_in: IWeighIn ) {
    let ci = this.check_in.getValue()

    if ( ci != null ) {
      ci.weigh_in = weigh_in
      this.setCheckIn(ci)
    }
  }

  setRepo( repo : IRepositioning ) {
    this.repo.next(repo)
  }

  setPrevCheckIn( check_in : ICheckIn ) {
    console.log("Setting Prev CheckIn: ", check_in)
    let ci = new BasicCheckIn(check_in)
    this.prev_ci.next(ci)
  }

  resetVisit() {
    console.log("resetting visit!")
    this.setCheckIn(null)
    this.setPrevCheckIn(null)
    this.setRepo(null)
  }

  visitFinished() {
    this.checkInSub.unsubscribe();
  }
}
