import {Injectable, OnDestroy} from '@angular/core';
import {GlobalsService} from '../../../../../globals.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {IProgram} from '../../../../../models/program';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {IComment} from '../../../../../models/comment';
import { GoalService } from 'src/app/components/goal/goal.service';
import { Goal } from 'src/app/models/goal';

@Injectable({
  providedIn: 'root'
})
export class ProgramsService implements OnDestroy {
  baseUrl: string;

  private currentProgramId = new BehaviorSubject<number>(null);
  currentProgramId$ = this.currentProgramId.asObservable();

  private currentProgram = new BehaviorSubject<IProgram>({} as IProgram);
  currentProgram$ = this.currentProgram.asObservable();

  private programs = new BehaviorSubject([] as IProgram[]);
  programs$ = this.programs.asObservable();

  private allPrograms = new BehaviorSubject([] as IProgram[]);
  allPrograms$ = this.allPrograms.asObservable();

  private repoComments = new BehaviorSubject([] as IComment[]);
  repoComments$ = this.repoComments.asObservable()

  showCoachcareWeighins = false;

  constructor( private globals: GlobalsService,
               private goalService: GoalService,
               private http: HttpClient ) {
    this.baseUrl = globals.base_url
    console.log(this)
  }

  ngOnDestroy() : void {}


  /**
   * Creates an empty, new program for the client in the database.
   * @param clientId The client's ID
   */
  create(clientId : number) : Observable<IProgram> {
    const url = `${this.baseUrl}/clients/${clientId}/programs`
    return this.http.post<IProgram>(url, null).pipe(
      tap(program => this.setCurrentProgramId(program.id))
    )
  }

  getCurrentProgram() : IProgram {
    return this.currentProgram.getValue()
  }

  getCurrentProgramById(id: number) : IProgram {
    return _.find(this.programs.getValue(), {id})
  }

  setCurrentProgram(program: IProgram) : void {
    this.currentProgram.next(program)
    const goals = program?.goals?.map(goal => new Goal(goal))
    this.goalService.setGoals(goals)
  }

  setCurrentProgramById(id: number) : void {
    this.setCurrentProgram(this.getCurrentProgramById(id))
  }

  getCurrentProgramId() : number {
    return this.currentProgramId.getValue()
  }

  setCurrentProgramId(id: number) : void {
    this.currentProgramId.next(id)
    console.log(`Setting current program id to ${id}`)

    this.setCurrentProgramById(id)
  }

  refreshCurrentProgram() : void {
    this.setCurrentProgramById(this.getCurrentProgramId())
  }



  /**
   * Loads all programs (and it's check_ins) for the client from the database.
   * @param clientId The client's ID
   * @param allWeighIns Whether to include hidden weigh ins
   * @param hideLoader Whether to display a loader or not
   * @param set Whether to update {@link programs}
   * @param allPrograms Whether to load all programs or just the current program.
   * @param programId The program ID to load
   */
  fetchClientPrograms(
    clientId: number,
    allWeighIns = false,
    hideLoader=false,
    set=true,
    allPrograms = false,
    programId = null) : Observable<IProgram[]> {
    let url = `
      ${this.baseUrl}/clients/${clientId}/programs?allWeighIns=${allWeighIns}&allPrograms=${allPrograms}&program_id=${programId}
    `
    if (hideLoader) url += '&loader=false'
    return this.http.get<IProgram[]>(url).pipe(
      tap(programs => {
        console.log(programs)
        if (programId) this.setCurrentProgramId(programId)
        if (set) {
          if (allWeighIns) {
            this.setAllPrograms(programs)
          } else {
            this.setPrograms(programs)
          }
        }
      })
    )
  }


  getCurrentProgramIndex(): number {
    const currentProgramId = this.getCurrentProgramId()
    return _.findIndex(this.programs.getValue(), {id: currentProgramId})
  }


  /**
   * Updates the value of {@link ProgramsService#programs}.
   * @param programs The programs to set {@link programs}
   * @param setProgramId Whether or not to update {@link currentProgramId}
   */
  setPrograms(programs : IProgram[], setProgramId = true) : void {
    if (this.showCoachcareWeighins) return

    console.log('setting programs', programs, 'setting program id', setProgramId)
    const colors = ['#0072f0', '#CB0108']
    const repoComments = []
    programs.forEach((program, i) => {
      program.color = colors[i % 2]
      program.check_ins.forEach(checkIn => {
        if (checkIn?.repositioning?.description) {
          repoComments.push({
            body: checkIn?.repositioning?.description,
            employee: checkIn.employee_name,
            created_at: checkIn?.repositioning?.created_at
          })
        }
      })
    })
    this.setRepoComments(repoComments)
    this.programs.next(programs)
    this.refreshCurrentProgram()
    console.log(this)
  }


  /**
   * Updates the value of {@link ProgramsService#allPrograms}.
   * @param programs The programs to set {@link programs}
   */
  setAllPrograms(programs : IProgram[]) : void {
    if (!this.showCoachcareWeighins) return

    console.log('setting all programs', programs)
    const colors = ['#0072f0', '#CB0108']
    programs.forEach((program, i) => {program.color = colors[i % 2]})
    this.allPrograms.next(programs)
    this.setPrograms(programs)
  }


  setRepoComments(comments: IComment[]): void {
    console.log('setting repo comments', comments)
    this.repoComments.next(comments)
  }


  /**
   * Returns a clone of the current value for {@link ProgramsService.programs}.
   */
  getPrograms() : IProgram[] {
    // console.trace()
    // console.log('programs', this.programs.getValue())
    return Object.assign([], this.programs.getValue())
  }


  /**
   * Returns a clone of the current value for {@link ProgramsService.allPrograms}.
   */
  getAllPrograms() : IProgram[] {
    console.log('all programs', this.allPrograms.getValue())
    return Object.assign([], this.allPrograms.getValue())
  }


  /**
   * Deletes a program from the database.
   * @param program The program to be destroyed
   */
  destroy(program: IProgram) : Observable<IProgram> {
    const url = `${this.baseUrl}/clients/${program.client_id}/programs/${program.id}`
    return this.http.delete<IProgram>(url).pipe(
      tap(destroyedProgram => {
        let programs = this.programs.getValue()
        programs = programs.filter(existingProgram => existingProgram.id !== destroyedProgram.id)
        this.setPrograms(programs)
        const newestProgramId = programs[0]?.id
        if (newestProgramId) {
          this.fetchClientPrograms(program.client_id, false, false, true, false, newestProgramId)
            .subscribe()
        }
      })
    )
  }
}
