import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class RouteInterceptorService {
  private _previousUrl: string;
  private _previousManagerUrl: string;
  private _currentUrl: string;
  private readonly _routeHistory: string[];
  private _shouldNavigateBackToManagerDashboard: boolean;

  constructor(router: Router) {
    this._routeHistory = [];
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this._setURLs(event);
      });
  }

  private _setURLs(event: NavigationEnd): void {
    this._previousUrl = this._currentUrl;
    this._currentUrl = event.urlAfterRedirects;
    this.handleManagerRouting();
    this._routeHistory.push(this._currentUrl);
  }


  /**
   * Controls navigation to allow managers to go back to the manager dashboard after visiting a client's page.
   */
  handleManagerRouting(): void {
    let wasAtManagerDashboard = false
    let isAtManagerDashboard = false
    let isAtClientPage = false
    let isNotIndexPage = true

    if (this._currentUrl) {
      const urls = ['/manager-dashboard/weigh-ins', '/manager-dashboard/finance']
      for (const url of urls) {
        if (this._currentUrl.indexOf(url) !== -1) {
          isAtManagerDashboard = true
          break;
        }
        isAtManagerDashboard = false
      }
      isAtClientPage = this._currentUrl.indexOf('clients') !== -1
      isNotIndexPage = this._currentUrl !== '/clients'
    }

    if (this._previousUrl) {
      const urls = ['/manager-dashboard/weigh-ins', '/manager-dashboard/finance']
      for (const url of urls) {
        if (this._previousUrl.indexOf(url) !== -1) {
          wasAtManagerDashboard = true
          break;
        }
        wasAtManagerDashboard = false
      }
    }

    if (isAtManagerDashboard) {
      this._previousManagerUrl = this._currentUrl
    }

    if (wasAtManagerDashboard && isAtClientPage && isNotIndexPage) {
      this._shouldNavigateBackToManagerDashboard = true
    }

    if (!isNotIndexPage) {
      this._shouldNavigateBackToManagerDashboard = false
    }
  }

  get previousUrl(): string {
    return this._previousUrl;
  }

  get currentUrl(): string {
    return this._currentUrl;
  }

  get previousManagerUrl(): string {
    return this._previousManagerUrl
  }

  get routeHistory(): string[] {
    return this._routeHistory;
  }

  get shouldNavigateBackToManagerDashboard(): boolean {
    return this._shouldNavigateBackToManagerDashboard
  }
}
