<ion-app>
  <ion-menu contentId="content" id="myMenu" menuId="myMenu" #menu (ionWillClose)="handleWillClose($event)">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list>
        <ion-menu-toggle auto-hide="false">
          <ion-item  *ngFor="let p of pages" [routerLink]="p.url" [routerDirection]="p.routerDirection || 'root'" [class.active-item]="selectedPath.startsWith(p.url)">
            <ion-icon *ngIf="p.icon" [name]="p.icon" slot="start"></ion-icon>
            <ion-label>
              {{ p.title }}
            </ion-label>
          </ion-item>

          <ion-item button="true" (click)="scanWeighIn()">
            <ion-icon name="scale" slot="start"></ion-icon>
            <ion-label>
              Scan Weigh-In
            </ion-label>
          </ion-item>

          <ion-item tappable routerLink="/login" routerDirection="root" (click)="logout()">
            <ion-icon name="log-out" slot="start"></ion-icon>
            <ion-label>
              Logout
            </ion-label>
          </ion-item>

          <ion-item tappable (click)="refresh()">
            <ion-icon name="refresh-circle" slot="start"></ion-icon>
            <ion-label>
              Refresh App
            </ion-label>
          </ion-item>

          <ion-item tappable (click)="displayAdvancedOptions($event)">
            <ion-icon name="skull" slot="start" color="medium"></ion-icon>
            <ion-label color="medium">
              {{ showAdvanced ? 'Hide Advanced' : 'Show Advanced' }}
            </ion-label>
          </ion-item>

          <ion-item *ngIf="showAdvanced">
            <ion-icon name="nuclear" slot="start" color="warning"></ion-icon>
            <ion-label color="warning">Opt in to Beta</ion-label>
            <ion-select [(ngModel)]="deployChannel" (ionChange)="confirmFetch($event)" inferface="popover">
              <ion-select-option *ngFor="let channel of availableChannels" [value]="channel">{{ channel }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <div [@routeAnimations]="prepareRoute(outlet)">
    <ion-router-outlet id="content" #outlet="outlet"></ion-router-outlet>
  </div>
</ion-app>
