import {NgModule} from '@angular/core';
import {LinkHealthProfileModalComponent} from './link-health-profile-modal.component';
import {LinkHealthProfileComponent} from './link-health-profile.component';
import {HealthProfileRow} from './hp-row';
import {GlobalsService} from '../../globals.service';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule
  ],
  exports: [
    LinkHealthProfileModalComponent,
    LinkHealthProfileComponent,
    HealthProfileRow,
  ],
  declarations: [
    LinkHealthProfileModalComponent,
    LinkHealthProfileComponent,
    HealthProfileRow,
  ],
  providers: [
    GlobalsService
  ],
})
export class LinkHealthProfileModule {}
