import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {IHealthProfile} from '../../models/health_profile';
import {GlobalsService} from '../../globals.service';
import {AwakenModal} from '../../shared/awaken-modal/awaken-modal.component';
import {HealthProfilesProvider} from '../../pages/clients/client-detail/providers/health-profiles/health-profile.service';

@Component({
  selector: 'hp-row',
  template: `
    <ion-item button (click)="linkToClient()" [disabled]="record.linked_to">
      <strong class="ion-padding-end">{{record.name}}</strong> <span *ngIf="record.linked_to">(already linked to: {{record.linked_to}})</span>
      <ion-text><small>Office: {{record.location?.name || 'n/a'}} | Phone: {{record.phone || 'n/a'}} | Address: {{record.address_as_string || 'n/a'}}</small></ion-text>
      <ion-note slot="end"><small>{{record.created_at | date:'shortDate'}}, ID: {{record.id}}</small></ion-note>
    </ion-item>
  `
})
export class HealthProfileRow {
  @Input() record: IHealthProfile;
  @Input() name: string;
  @Input() client_id: number;
  @Output() clientSelected = new EventEmitter<IHealthProfile>();
  // @Output() clientSelected = new EventEmitter<boolean>();

  constructor( public modalCtrl: ModalController,
               private hpService: HealthProfilesProvider,
               private global: GlobalsService ) {
  }

  /**
   * Links health profile to the client.
   */
  async linkToClient() {
    let alert = await this.modalCtrl.create({
      component: AwakenModal,
      componentProps: {
        title: "Are you sure?",
        subtitle: `This will link this client to ${this.record.name}'s health profile`,
        type: 'confirm',
      },
      cssClass: "small-modal"
    });

    alert.present();

    alert.onDidDismiss().then( data => {
      console.log(data)
      if ( data && data.data == 'yes' ) {
        console.log('yes')
        this.hpService.linkToClient(this.client_id, this.record.id).subscribe(
          data => {
            console.log(data)
            this.clientSelected.emit(data);
            // this.clientSelected.emit(true);
          },
          err => this.global.handleResponse(err.error)
        )
      }
    })
  }
}
