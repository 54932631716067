import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LocationService} from '../../../../providers/locations.service';
import {ClientService} from '../../clients.service';
import {ModalController} from '@ionic/angular';
import {VideoModalComponent} from '../../../../shared/video-modal/video-modal.component';
import {EmployeeService} from '../../../../providers/employee.service';
import {AuthService} from '../../../../auth/auth.service';
import {GlobalsService} from '../../../../globals.service';
import {IEmployee} from '../../../../models/employee';
import * as moment from 'moment';

@Component({
  selector: 'client-index-search',
  templateUrl: './client-index-search.component.html',
  styleUrls: ['./client-index-search.component.scss'],
})
export class ClientIndexSearchComponent {
  @Input() date: string;
  @Input() location: number;
  @Output() sortClients = new EventEmitter<string>();
  @Output() cancelSearch = new EventEmitter<null>();
  @Output() searchMade = new EventEmitter<null>();
  @Output() isSearching = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter<{location: number, date: string, force: boolean}>();

  search: string;
  moment = moment;

  constructor( public locationService: LocationService,
               private clientService: ClientService,
               public authService: AuthService,
               private globals: GlobalsService,
               private employeeService: EmployeeService,
               private modalCtrl: ModalController ) {
    if (!this.date) this.date = moment().format('YYYY-MM-DD')
  }

  /**
   * On user interaction emits sort method to be consumed in {@link ClientIndexPage}
   * @param event: Schedule sort event
   */
  sortClientList( event : CustomEvent ) : void {
    const value = event.detail.value;
    const employeeId = this.authService.getCurrentUser()?.employee?.id
    this.employeeService.update({id: employeeId, client_sort_method: value}).subscribe(
      employee => {
        this.updateCurrentUser(employee)
      },
      err => this.globals.handleResponse(`Error: ${err.error}`)
    )
    this.sortClients.emit(value)
  }


  /**
   * Updates the {@link AuthService.current_user$} value with new employee value.
   * @param employee: New current User based on employee
   */
  updateCurrentUser(employee: IEmployee) {
    const user = this.authService.getCurrentUser()
    user.employee = employee
    this.authService.setCurrentUser(user)
  }


  cancelClientSearch() : void {
    this.cancelSearch.emit()
  }


  /**
   * Refreshes client list with new values for location and day.
   */
  refreshEvent( force : boolean, locationId: number = null ) : void {
    if ( this.location && this.date ) {
      this.refresh.emit({location: (locationId || this.location), date: this.date, force})
    }
  }


  /**
   * Searches for clients that match the search value.  Cancels search if query is empty.
   * @param search: Search string
   */
  clientSearch(search: string) : void {
    const val = this.search
    this.clientService.search(val)
    if (val === '') {
      this.cancelSearch.emit()
    } else {
      this.searchMade.emit()
    }
  }


  /**
   * Displays help modal for how to search.
   */
  async showSearchHelp() {
    const modal = await this.modalCtrl.create({
      component: VideoModalComponent,
      componentProps: {
        url: 'https://player.vimeo.com/video/378860135',
        title: 'New Search Feature',
        content: `
        <p>This search feature allows you to search by:</p>
        <ul>
          <li>Name</li>
          <li>Email</li>
          <li>Phone</li>
          <li>Street</li>
          <li>Minbdbody ID</li>
          <li>Office Name</li>
        </ul>
        <p>The search is case-insensitive so searching for "John" and "john" will yield the same result</p>
        <p>You can also combine search parameters and search for things such as "John 617" to pull in a health profile with name containing 'john' and phone number containing '617'.</p>
        `
      }
    })

    await modal.present()
  }


  /**
   * Updates the employees preference on which location's schedule to use.  Different than the location_id on Employee becasue that
   * field is used for many other functions.
   * @param event: Location change event
   */
  updateLocationSort(event: any) {
    const value = event.detail.value;
    const employeeId = this.authService.getCurrentUser()?.employee?.id

    this.employeeService.update({id: employeeId, location_sort: value}).subscribe(
      employee => {
        this.updateCurrentUser(employee)
        this.refreshEvent(false, value)
      },
      err => this.globals.handleResponse(`Error: ${err.error}`)
    )
    this.refreshEvent(false)
  }

  /**
   * Fired when the user chooses a new date to search for.
   * @param event: Date change event
   */
  handleNewDate(event: { value: string; valid: boolean }) {
    if (event.valid) this.date = event.value
    this.refreshEvent(false)
  }
}
