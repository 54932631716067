import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginModal} from '../auth/login-modal/login-modal.component';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '../pipes/pipes.module';
import {FormsModule} from '@angular/forms';
import {ConsultationDetailComponent} from './consultation-detail/consultation-detail.component';
import {ConsultationFormComponent} from './consultation-detail/consultation-form';
import {CommentComponent} from './comment/comment';
import {AwakenModal} from './awaken-modal/awaken-modal.component';
import {LogoutComponent} from './logout/logout.component';
import {AwakenCardComponent} from './awaken-card/awaken-card';
import {VideoModalComponent} from './video-modal/video-modal.component';
import {NewCommentModal} from './comment/new-comment/new-comment.modal';
import {ScrollCardComponent} from './scroll-card/scroll-card.component';
import {ClientRowContentComponent} from './client-row-content/client-row-content.component';
import {ChangeArrowComponent} from './change-arrow/change-arrow.component';
import {BooleanButtonComponent} from './boolean-button/boolean-button.component';
import {RangeChartComponent} from './range-chart/range-chart.component';
import {NewTransaction} from '../pages/clients/client-detail/finance/new-transaction/new-transaction.modal';
import {ProgressModalComponent} from './progress-modal/progress-modal.component';
import {TransactionComponent} from './transaction/transaction.component';
import {PurchaseFormComponent} from './transaction/purchase-form/purchase-form.component';
import {PaymentFormComponent} from './transaction/payment-form/payment-form.component';
import {GeneralModalComponent} from './general-modal/general-modal.component';
import {CommonCardModule} from '../components/common-card/common-card.module';
import {DatePickerModule} from '../components/date-picker/date-picker.module';
import {CommonModalModule} from '../components/common-modal/common-modal.module';
import { PreventNumberInputScrollDirective } from '../directives/preventNumberInputScroll.directive';

@NgModule({
  declarations: [
    LoginModal,
    ConsultationDetailComponent,
    ConsultationFormComponent,
    PurchaseFormComponent,
    CommentComponent,
    TransactionComponent,
    PreventNumberInputScrollDirective,
    AwakenModal,
    LogoutComponent,
    GeneralModalComponent,
    AwakenCardComponent,
    RangeChartComponent,
    PaymentFormComponent,
    NewTransaction,
    ProgressModalComponent,
    VideoModalComponent,
    NewCommentModal,
    ScrollCardComponent,
    ClientRowContentComponent,
    ChangeArrowComponent,
    BooleanButtonComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    DatePickerModule,
    PipesModule,
    CommonCardModule,
    FormsModule,
    CommonModalModule,
  ],
  exports: [
    LoginModal,
    ConsultationDetailComponent,
    ConsultationFormComponent,
    CommentComponent,
    VideoModalComponent,
    NewTransaction,
    AwakenModal,
    PaymentFormComponent,
    PreventNumberInputScrollDirective,
    PurchaseFormComponent,
    LogoutComponent,
    AwakenCardComponent,
    TransactionComponent,
    GeneralModalComponent,
    ProgressModalComponent,
    NewCommentModal,
    RangeChartComponent,
    ScrollCardComponent,
    ClientRowContentComponent,
    ChangeArrowComponent,
    BooleanButtonComponent,
  ]
})
export class SharedModule { }
