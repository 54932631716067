<ion-list>
  <ion-item *ngFor="let document of (externalDocumentsService.externalDocuments$ | async)" class="ion-text-wrap">
    <ion-icon
      [name]="document.signed_at ? 'checkmark-circle' : 'close-circle'"
      [color]="document.signed_at ? 'success' : 'danger'"
      slot="start"
    ></ion-icon>
    <ion-label>
      <div class="flex align-items-center">
        <h2 class="pb-1">
          <strong class="pr-3">
            {{ document.name | titleize | capitalize }}
          </strong>
        </h2>
      </div>
      <p *ngIf="document.highestStatus">
        {{document.highestStatus.field | titleize | capitalize}}: {{document.highestStatus.date | date:'short' }}
      </p>
    </ion-label>
    <ion-toggle
      [disabled]="document.alreadySent"
      [(ngModel)]="toggles[document.id]"
      (click)="toggleDocumentStatus($event, document)"
    ></ion-toggle>
  </ion-item>
</ion-list>
