<ion-header>
  <ion-toolbar>
    <ion-buttons slot="secondary">
      <ion-button (click)="dismiss()">
        <ion-text color="primary">Close</ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title class="text-center">New Transaction</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="container">
    <ion-grid>
      <ion-row>

        <!-- Payments -->
        <ion-col class="text-center" style="height:100vh;">
          <ion-button  fill="outline" class="flex-col w-100 addPurchase" color="success" style="font-size:16px;height:90%;border-width:1px;" (click)="selectMode('purchase')" *ngIf="!paymentMode">
            <i class="fa fa-purchase text-shadow ion-padding"  style="font-size: 100px;"></i>
            <span>New Purchase</span>
          </ion-button>
          <ion-grid style="height:90%;" *ngIf="paymentMode" class="justify-content-center">
            <div>
              <ion-row>
                <ion-col>
                  <div class="bd-callout bd-callout-success">
                    <table style="margin:0 auto;">
                      <tr>
                        <td class="ion-padding-end ion-text-right">Current Balance:</td>
                        <td class="text-left">${{ transactionService.balance$ | async }}</td>
                      </tr>
                      <tr>
                        <td class="ion-padding-end text-right">New Payment:</td>
                        <td class="text-left">${{ amount || "0" }}</td>
                      </tr>
                      <tr class="ion-padding-top" style="border-top:1px solid;">
                        <td class="end text-right ion-padding-end">Remaining Balance:</td>
                        <td class="text-left" *ngIf="amount != (transactionService.balance$ | async); else fully_paid">
                          <ng-container *ngIf="(transactionService.balance$ | async) as balance;">
                            ${{ ( amount && amount > 0 ? balance - amount : balance + amount) || balance }}
                          </ng-container>
                        </td>
                        <ng-template #fully_paid>$0</ng-template>
                      </tr>
                    </table>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row style="flex:3;">
                <ion-col class="text-left">
                  <form #paymentForm="ngForm" name="paymentForm">
                    <ion-list>
                      <ion-item class="better-validation" [class.invalid]="paymentForm.dirty && a.invalid">
                        <ion-label>Amount:</ion-label>
                        <ion-input name="amount" [(ngModel)]="amount" type="number" required #a="ngModel"></ion-input>
                        <ion-button
                          (click)="amount = client?.default_payment_amount"
                          *ngIf="client?.default_payment_amount"
                          slot="end"
                        >
                          Default: ${{client.default_payment_amount}}
                        </ion-button>
                      </ion-item>
                      <small class="text-danger ion-padding-start" [hidden]="( paymentForm.pristine || ( !paymentForm.pristine && a.valid))">
                        Amount is required
                      </small>

                      <ion-item>
                        <ion-label position="floating">Comment:</ion-label>
                        <ion-input spellcheck="on" name="payment_comment" [(ngModel)]="payment_comment" type="text"></ion-input>
                      </ion-item>

                      <ion-item class="better-validation">
                        <ion-label>Set as Default:</ion-label>
                        <ion-checkbox name="default_payment_amount" [(ngModel)]="default_payment_amount"></ion-checkbox>
                      </ion-item>

                      <ion-button class="ion-margin submitPayment" expand="full" shape="round" type="submit" color="success" [disabled]="!paymentForm.form.valid" (click)="createPayment()">Submit</ion-button>
                    </ion-list>
                  </form>
                </ion-col>
              </ion-row>
              <ion-row class="ion-align-items-end ion-justify-content-end" style="flex:1;">
                <ion-button *ngIf="paymentMode" fill="clear" color="danger" (click)="paymentMode = false">Cancel ></ion-button>
              </ion-row>
            </div>
          </ion-grid>
        </ion-col>

        <!-- Purchases -->
        <ion-col style="height:100vh;" *ngIf="!paymentOnly">
          <ion-button fill="outline" class="flex-col w-100 addPayment" color="success" style="font-size:16px;height:90%;border-width:1px;" (click)="selectMode('payment')"  *ngIf="!purchaseMode">
            <i class="fa fa-payment text-shadow ion-padding" style="font-size: 100px;"></i>
            <span>New Payment</span>
          </ion-button>
          <ion-grid style="height:90%;" *ngIf="purchaseMode" class="justify-content-center">
            <div>
              <ion-row class="ion-align-items-center">
                <ion-col size="10" offset="1">
                  <div class="bd-callout bd-callout-success">
                    <table style="margin:0 auto;">
                      <tr>
                        <td class="ion-padding-end text-right">Current Balance:</td>
                        <td class="text-left">${{ transactionService.balance | async }}</td>
                      </tr>
                      <tr>
                        <td class="ion-padding-end text-right">+ new purchase:</td>
                        <td class="text-left">${{ ( weekly_cost * weeks ) || "0" }}</td>
                      </tr>
                      <tr *ngIf="discount != undefined">
                        <td class="ion-padding-end text-right">- discount</td>
                        <td class="text-left">${{ ( weekly_cost * weeks ) * ( discount / 100 ) || 0 }}</td>
                      </tr>
                      <tr class="ion-padding-top" style="border-top:1px solid;">
                        <td class="ion-padding-end text-right">Remaining Balance:</td>
                        <td class="text-left">
                          <ng-container>
                            ${{ discount == undefined ?
                              ( (transactionService.balance$ | async) + ( weekly_cost * weeks ) || (transactionService.balance$ | async) ) :
                              ( (transactionService.balance$ | async) + ( weekly_cost * weeks ) - ( ( weekly_cost * weeks ) * ( discount / 100 )))
                            }}
                          </ng-container>
                        </td>
                      </tr>
                    </table>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center ion-align-items-start" style="flex:3;">
                <ion-col class="ion-padding" size="10">
                  <form #purchaseForm="ngForm" name="purchaseForm">
                    <ion-list>

                      <ion-item class="better-validation"
                                [class.invalid]="( purchaseForm.dirty && saleType.invalid)">
                        <ion-label color="primary">Sale Type:</ion-label>
                        <ion-select [(ngModel)]="sale_type" name="sale_type" required #saleType=ngModel interface="action-sheet">
                          <ion-select-option value="new_sale">Initial Purchase</ion-select-option>
                          <ion-select-option value="add_weeks">Additional Weeks</ion-select-option>
                        </ion-select>

                      </ion-item>
                      <small class="text-danger text-left ion-padding-start" [hidden]="( purchaseForm.pristine || ( !purchaseForm.pristine && saleType.valid))">
                        Sale type is required.
                      </small>

                      <ion-item class="better-validation" [class.invalid]="( purchaseForm.dirty && x.invalid)">
                        <ion-label position="floating">Weeks:</ion-label>
                        <ion-input required type="number" min=1  [(ngModel)]="weeks" name="weeks" #x="ngModel"></ion-input>
                      </ion-item>
                      <small class="text-danger text-left ion-padding-start" [hidden]="( purchaseForm.pristine || ( !purchaseForm.pristine && x.valid))">
                        Number of weeks is required.
                      </small>

                      <ion-item class="better-validation" [class.invalid]="( purchaseForm.dirty && y.invalid)">
                        <ion-label position="floating">Weekly Cost:</ion-label>
                        <ion-input name="weekly_cost" type="number" min="1" required [(ngModel)]="weekly_cost" #y="ngModel"></ion-input>
                      </ion-item>
                      <small class="text-danger text-left ion-padding-start" [hidden]="( purchaseForm.pristine || ( !purchaseForm.pristine && y.valid))">
                        Weekly cost is required.
                      </small>

                      <ion-item>
                        <ion-label position="floating">Discount (%):</ion-label>
                        <ion-input name="discount" min="0" type="number" [(ngModel)]="discount"></ion-input>
                      </ion-item>
                      <ion-item>
                        <ion-label position="floating">Comment:</ion-label>
                        <ion-input spellcheck="on" name="comment" [(ngModel)]="purchase_comment" type="text"></ion-input>
                      </ion-item>
                      <ion-item>
                        <ion-label color="primary">Paid in Full?</ion-label>
                        <ion-toggle name="paid_in_full" [(ngModel)]="paid_in_full"></ion-toggle>
                      </ion-item>
                      <ion-item>
                        <ion-label color="primary">Use Referral?</ion-label>
                        <ion-toggle [disabled]="!has_referrals" name="referral_used" [(ngModel)]="referral_used"></ion-toggle>
                      </ion-item>
                      <ion-button class="ion-margin submitPurchase" expand="full" shape="round" type="submit" color="success" [disabled]="!purchaseForm.form.valid" (click)="createPurchase()">Submit</ion-button>
                    </ion-list>
                  </form>
                </ion-col>
              </ion-row>
              <ion-row class="ion-align-items-end ion-justify-content-start" style="flex:1;">
                <ion-button *ngIf="purchaseMode" fill="clear" color="danger" (click)="purchaseMode = false">< Cancel</ion-button>
              </ion-row>
            </div>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
