import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
    name: 'nameFormat'
})
export class NameFormat implements PipeTransform {

  transform(value: any): any {
    if (!value) return
    let split = value.split(" ")
    let last_name = split[split.length - 1]
    split = split.splice(0, split.length - 1)
    let first_name = split.join(" ")
    let final = last_name + ", " + first_name
    return final
  }

}
