import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {GlobalsService} from '../globals.service';
import {IUser, User} from '../models/user';
import {EncryptionService} from '../providers/encryption/encryption.service';
// import {NotificationService} from '../providers/notification.service';
// import * as Sentry from "@sentry/browser";
import * as _ from 'lodash';
import * as Sentry from 'sentry-cordova';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  current_user = new BehaviorSubject<User>(new User({}));
  current_user$: Observable<User> = this.current_user.asObservable();

  isLoggedIn: boolean;
  user_obs: Subscription;

  // store the URL so we can redirect after logging in
  redirectUrl: string;
  loginUrl: string;

  authModalIsOpen = false;

  constructor( private http: HttpClient,
               private storage: Storage,
               private globals: GlobalsService,
               private encryptionService: EncryptionService ) {
    this.loginUrl = `${this.globals.base_url}/users/sign_in`;
    this.setupUser()
  }

  setCurrentUser(user: User) {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'Authenticated user ' + user.email,
    });
    Sentry.configureScope(scope => {
      scope.setUser({email: user.email, id: user.id.toString()})
    })
    this.current_user.next(user)
  }

  getCurrentUser() : User {
    return Object.assign({}, this.current_user.getValue())
  }

  newLogin(pin, email, password) {
    const user: IUser = {unlock: null}

    if ( pin ) {
      user.unlock = this.encryptionService.encrypt(pin)
    } else {
      user.email = email
      user.password = this.encryptionService.encrypt(password)
    }

    console.log("USER", user)

    return this.http.post<IUser>(this.loginUrl, user).pipe(
      tap( updatedUser => {
        environment.current_user = {email: updatedUser.email}
        const userInstance = new User(updatedUser)
        this.setCurrentUser(userInstance)
        this.globals.setUser(userInstance);
        this.isLoggedIn = true
      })
    )
  }

  /**
   * Removes values for 'isLoggedIn' and 'current_user' from storage
   */
  logout(): Promise<any> {
    return this.storage.keys().then(keys => {
      _.reject(keys, (o) => o === 'draftRepositionings').forEach(key => this.storage.remove(key))})
      .then(() => { this.storage.set('isLoggedIn', false);}
    );
  }

  getAwsCredentials(): Promise<unknown> {
    const url = `${this.globals.base_url}/tokens`
    return this.http.get<unknown>(url).toPromise()
  }

  setupUser() {
    this.user_obs = this.current_user$.subscribe(user => {
      if ( user && Object.keys(user).length ) {
        const userInstance = new User(user)
        this.storage.set('current_user', userInstance)
        this.isLoggedIn = true
      } else {
        this.storage.get('current_user').then( val => {
          if ( val) {
            const userInstance = new User(val)
            this.current_user.next(userInstance);
            this.isLoggedIn = true
          }
        })
      }
    })
  }

  setUser(user: any) : void {
    user = new User(user)
    this.current_user.next(user)
  }

  currentUserIsManager() : boolean {
    return this.getCurrentUser()?.user_type <= 2
  }

  getUser() {
    const currentUser = this.current_user.getValue()
    if (!currentUser) {
      this.storage.get('current_user').then(user => {
        this.setUser(user)
      })
    }
    return Object.assign({}, this.current_user.getValue())
  }
}
