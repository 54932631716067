import { Comment } from "./comment"

export interface IPurchase {
  weeks: number;
  weekly_cost: number;
  client_id: number;
  id?: number;
  date?: string;
  discount?: number;
  employee_id?: number;
  referral_used?: boolean;
  comment?: string;
  sale_type?: string;
  comments?: Comment[];
  created_at?: string;
  program_id?: number;
  purchaseable_id?: number;
  purchaseable_type?: string;
  comments_attributes?: {id?: number, employee_id?: number, body: string}[]
  referral_attributes?: {id?: number, used_at?: string, used?: boolean, purchase_id?: number}
}

export class Purchase implements Purchase {
  public weeks: number;
  public weekly_cost: number;
  public client_id: number;
  public id?: number;
  public discount?: number;
  public date?: string;
  public employee_id?: number;
  public sale_type?: string;
  public referral_used?: boolean;
  public comment?: string;
  public comments?: Comment[];
  public created_at?: string;
  public program_id?: number;
  public purchaseable_id?: number;
  public paymenteable_id?: number;
  public purchaseable_type?: string;
  public paymentable_type?: string;
  public comments_attributes?: {id?: number, employee_id?: number, body: string}[]
  public referral_attributes?: {id?: number, used?: boolean, used_at?: string, purchase_id?: number}

  constructor(data: Partial<IPurchase>) {
    Object.assign(this, data);
  }

  total?() : number {
    let total = this.weeks * this.weekly_cost

    if ( this.discount > 0 ) {
      total = (total * (100-this.discount))/100
    }

    return total
  }
}

export interface IPayment {
  client_id: number;
  amount: number;
  id?: number;
  date?: string;
  comment?: string;
  comments?: Comment[];
  created_at?: string;
  employee_id?: number;
  program_id?: number;
  paymentable_id?: number;
  paymentable_type?: string;
  default_payment_amount?: boolean;
  comments_attributes?: {id?: number, employee_id?: number, body: string}[]
}

export class Payment {
  public client_id: number;
  public amount: number;
  public id?: number;
  public date?: string;
  public comment?: string;
  public comments?: Comment[];
  public created_at?: string;
  public employee_id?: number;
  public program_id?: number;
  public paymentable_id?: number;
  public default_payment_amount?: boolean;
  public paymentable_type?: string;
  public comments_attributes?: {id?: number, employee_id?: number, body: string}[]

  constructor(data: Partial<IPayment>) {
    Object.assign(this, data);
  }
}

export interface IRefund {
  client_id: number;
  employee_id: number;
  excuse_id: number;
  id?: number;
  employee_name?: string;
  weeks?: number
  amount?: number;
  created_at?: string;
  purchase_ids?: number[];
  status?: string;
  comment?: string;
}

export interface ITransaction {
  client_id: number;
  id?: number;
  tran_type?: string;
  amount?: number;
  comment?: string;
  comments?: Comment[];
  date?: string;
  sale_type?: string;
  created_at?: string;
  employee_id?: number;
  weeks?: number;
  program_id?: number;
  weekly_cost?: number;
  discount?: number;
  referral_used?: boolean;
  purchaseable_id?: number;
  paymentable_id?: number;
  purchaseable_type?: string;
  paymentable_type?: string;
  comments_attributes?: {id?: number, employee_id?: number, body: string}[]
  referral_attributes?: {id?: number, used_at?: string, used?: boolean, purchase_id?: number}
}
