import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IndexClient} from '../../../../models/client';
import {LocationService} from '../../../../providers/locations.service';
import {ModalController} from '@ionic/angular';
import {AwakenModal} from '../../../../shared/awaken-modal/awaken-modal.component';
import * as _ from 'lodash'
import {TransactionComponent} from '../../../../shared/transaction/transaction.component';

@Component({
  selector: 'client-index-row',
  templateUrl: './client-index-row.component.html',
  styleUrls: ['./client-index-row.component.scss'],
})
export class ClientIndexRowComponent implements OnInit, OnChanges {

  @Input() client: IndexClient;
  @Input() type: string;
  @Input() isSearching: boolean;
  @Output() selectClient = new EventEmitter<number>();
  @Output() inviteClient = new EventEmitter<IndexClient>();
  @Output() loadClientByUniqueMb = new EventEmitter<number>();
  @Output() deleteClient = new EventEmitter<IndexClient>();
  // consult_status: boolean;
  status: string;
  found: boolean;
  id: number;
  isConsultation: boolean = false;
  location_id: number;
  mindbody_id: number;
  user_id: number;
  requires_invite: boolean;

  constructor( public locationService: LocationService,
               private modalCtrl: ModalController, ) { }

  ngOnInit() {
    if (['Consultation', 'Post-Consultation', 'World-Wide Consultation', 'World-Wide Info Call'].includes(this.type)) {
      this.isConsultation = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.client) {
      let client = changes.client.currentValue
      this.client = new IndexClient(client, this.locationService);
      this.status = client.status;
      this.id = client.id;
      this.found = client.found;
      this.location_id = client.location_id;
      this.mindbody_id = client.mindbody_id;
      this.user_id = client.user_id;
      this.id ? this.requires_invite = false : this.requires_invite = true;
    }
  }


  /**
   * Handles user click of the row.  If the client has signed up, load the client.  If the client
   * has not signed up, load the client invitation process.  If the client is a normal client (non-consultation)
   * simply navigate to their profile.
   */
  handleClientClick() {
    console.log(this)

    if ( this.requires_invite ) {
      if (this.client.id) {
        this.selectClient.emit(this.client.id)
      } else {
        this.inviteUser()
      }
    } else {
      this.loadClientData()
    }
  }


  /**
   * Returns the name of the client's location from {@link IndexClient}
   */
  locationName() : string {
    return this.client.getLocationName()
  }


  /**
   * Emits a value for client_id to use in {@link ClientIndexPage}
   */
  loadClientData() : void {
    this.selectClient.emit(this.id)
  }


  /**
   * Emits that the selected client requires and invitation in the {@link ClientIndexPage}
   */
  inviteUser() : void {
    this.inviteClient.emit(this.client)
  }


  /**
   * Verifies you want to delete the client and then emits the client to the {@link ClientIndexPage} for deletion.
   * @param client
   */
  async selectClientForDeletion(client : IndexClient) {
    let modal = await this.modalCtrl.create({
      component: AwakenModal,
      componentProps: {
        title: `Are you sure you want to delete ${client.name || 'Missing Name'}`,
        type: "confirm",
        urgent: true
      },
      cssClass: 'small-modal'
    })

    await modal.present()

    await modal.onDidDismiss().then(result => {
      console.log(result)
      if ( result && result.data === 'yes' ) {
        console.log("delete")
        this.deleteClient.emit(client)
      }
    })

  }
}
