import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {IPayment, IPurchase, ITransaction, Purchase} from '../../../../../models/finance';
import {GlobalsService} from '../../../../../globals.service';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {CommentsProvider} from '../../../../../providers/comments.service';
import {ClientService} from '../../../clients.service';

@Injectable()
export class TransactionsProvider implements OnDestroy {

  base_url: string;
  auth_token: string;
  // balance: number = 0

  purchased_weeks = new BehaviorSubject(<number>0);
  purchased_weeks$ = this.purchased_weeks.asObservable()

  purchases$: Observable<ITransaction[]>;
  payments$: Observable<ITransaction[]>;

  balance = new BehaviorSubject(<number>0);
  balance$ = this.balance.asObservable();

  totalPaid = new BehaviorSubject(<number>0);
  totalPaid$ = this.totalPaid.asObservable();

  private transactions = new BehaviorSubject([] as ITransaction[]);
  transactions$ = this.transactions.asObservable();
  transactionsSubscription: Subscription;
  clientSubscription: Subscription;
  has_purchases: boolean = false;
  has_payments: boolean = false;

  constructor(private globalVarService: GlobalsService,
              private commentsService: CommentsProvider,
              private clientService: ClientService,
              private http: HttpClient ) {
    this.base_url = this.globalVarService.base_url;
    this.calculateTotalCost()
    this.subscribeToClient()
  }

  subscribeToClient() {
    this.clientSubscription = this.clientService.client$.subscribe( client => {
      console.log("New Client: TransactionsProvider")
      this.setBalance(client.balance)
      this.setPurchasedWeeks(client.purchased_weeks)
    })
  }

  ngOnDestroy(): void {
    console.log("destroy txn service")
    if (this.transactionsSubscription) this.transactionsSubscription.unsubscribe()
    if (this.clientSubscription) this.clientSubscription.unsubscribe()
  }


  /**
   * Subscribe to transactions and calculate the balance based on the transactions.
   */
  calculateTotalCost() {
    this.transactionsSubscription = this.transactions.subscribe( transactions => {
      let total = 0
      let purchased_weeks = 0
      let totalPaid = 0
      for (let transaction of transactions) {
        if (transaction.weekly_cost || transaction.weeks) {
          total += new Purchase(transaction).total()
          purchased_weeks += transaction.weeks
          transaction.tran_type = 'purchase'
          this.has_purchases = true
        } else {
          total -= transaction.amount
          transaction.tran_type = 'payment'
          this.has_payments = true
          totalPaid += transaction.amount
        }
      }
      this.setBalance(total)
      this.setPurchasedWeeks(purchased_weeks)
      this.setTotalPaid(totalPaid)
    })
  }


  /**
   * Fetches an array of payments and purchases as transactions from the server and sets the value to transactions.
   * @param clientId: The client's ID
   */
  fetchTransactions( clientId : number ) : Observable<ITransaction[]> {
    return this.http.get<ITransaction[]>(`${this.base_url}/clients/${clientId}/transactions`).pipe(
      tap( transactions => {
        this.setTransactions(transactions)
      })
    )
  }


  /**
   * Sets the BehaviorSubject transactions to the value in params.
   * @param transactions: Combination of purchases and payments
   */
  setTransactions( transactions : ITransaction[] ) : void {
    this.transactions.next(transactions)
    const comments = _.flatMap( transactions, (transaction) => transaction.comments )
    this.commentsService.setFinanceComments( comments )
  }


  addTransaction( transaction : IPayment|IPurchase ) {
    const transactions = this.getTransactions()
    transactions.push(transaction)
    this.setTransactions(transactions)
  }


  // getPurchases() {
  //   return Object.assign([], this.transactions.getValue())
  // }


  /**
   * Returns the current value for transactions.
   */
  getTransactions() : ITransaction[] {
    return Object.assign([], this.transactions.getValue())
  }


  /**
   * Sets the BehaviorSubject balance to the value in params.
   * @param value
   */
  setBalance(value: number) : void {
    this.balance.next(value)
  }


  /**
   * Returns the client's balance as a number.
   */
  getBalance(): number {
    return this.balance.getValue()
  }


  /**
   * Returns the client's total amount paid as a number.
   */
  getTotalPaid(): number {
    return this.totalPaid.getValue()
  }


  /**
   * Sets the BehaviorSubject totalPaid to the total.
   * @param total
   */
  setTotalPaid(total: number): void {
    this.totalPaid.next(total)
  }


  /**
   * Sets the BehaviorSubject purchased_weeks to the value in params.
   * @param value
   */
  setPurchasedWeeks( value : number ) : void {
    this.purchased_weeks.next(value)
  }


  /**
   * Returns the number of purchased weeks.
   */
  getPurchasedWeeks() : number {
    return this.purchased_weeks.getValue()
  }
}
