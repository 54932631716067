import { Injectable } from '@angular/core';
import * as jsencrypt from "jsencrypt";

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  private publicKey = "-----BEGIN PUBLIC KEY-----\n" +
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCo31lWmO/VfhJcTxUgJaAQ1Kcv\n" +
    "av/EICMVEw2j8oeS+/XSYu0pNrh29bjLT+yAX9f/cr4UPY6qjL0awL8Ld0AZDG3l\n" +
    "po8KhSu6LHdSI5D30SEr5H1eGzZ7pb0xSj0avE7NhfMnP0V1Deuiza1cbGxbKL1A\n" +
    "h/yRLHn58zVvaMDqeQIDAQAB\n" +
    "-----END PUBLIC KEY-----"

  constructor() { }

  encrypt( unencryptedValue ) : string {
    var encryptor = new jsencrypt.JSEncrypt();
    encryptor.setKey(this.publicKey)
    let encrypted = encryptor.encrypt(unencryptedValue)
    return encrypted
  }
}
