import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SignedDocumentSelectorComponent} from './signed-document-selector.component';
import {IonicModule} from '@ionic/angular';
import {SignedDocumentsService} from './signed-documents.service';
import {SelectedDocumentsComponent} from './selected-documents/selected-documents.component';
import {PipesModule} from '../../pipes/pipes.module';
import {SignedDocumentItemComponent} from './signed-document-item/signed-document-item.component';
import {SignedDocumentSelectorContainerComponent} from './signed-document-selector-container/signed-document-selector-container.component';


@NgModule({
  declarations: [
    SignedDocumentSelectorComponent,
    SelectedDocumentsComponent,
    SignedDocumentSelectorContainerComponent,
    SignedDocumentItemComponent
  ],
  providers: [
    SignedDocumentsService
  ],
  imports: [
    CommonModule,
    PipesModule,
    IonicModule,
  ],
  exports: [
    SignedDocumentSelectorComponent
  ]
})
export class SignedDocumentSelectorModule { }
