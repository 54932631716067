import {RoundPipe} from "./round-pipe";
import {AbsoluteValue} from "./absolute-value";
import {NgModule} from "@angular/core";
import {InitialFormat} from "./initial-format";
import {Capitalize} from "./capitalize-pipe";
import {Titleize} from "./titleize-pipe";
import {NameFormat} from './name-format';
import {EscapeHtmlPipe} from './keep-html.pipe';
import {PhoneFormat} from './phone-format';
import {WeightFormat} from './weight-format';
import {GetLocation} from './get-location';
import {MoneyFormat} from './money-format';
import {OrderDate} from './order-date';
import {RemoveDash} from './remove-dash';
import {ReversePipe} from './reverse-pipe';
import {WeekFormat} from './week-format';
import {FoodOrderItemsFilter} from './food-order-items-filter';
import {RemoveCoachcarePipe} from './remove-coachcare';
import {FilterByLocationPipe} from './filterByLocationPipe';
import {OrderBy} from './orderBy.pipe';
import {CompletedPipe} from './completed-pipe';
import {OverviewTagsPipe} from './overview-tags.pipe';
import {FilterByPipe} from './filterBy.pipe';
import {ExcludeByPipe} from './excludeBy.pipe';
import {FirstInitialLastNamePipe} from './first-initial-last-name.pipe';
import {OrderFlexible} from './order-flexible.pipe';
import { EmployeeTerminationFilterPipe } from './employee-termination-filter.pipe';

@NgModule({
  declarations: [
    RoundPipe,
    AbsoluteValue,
    InitialFormat,
    Capitalize,
    CompletedPipe,
    Titleize,
    OrderFlexible,
    RoundPipe,
    MoneyFormat,
    NameFormat,
    OrderDate,
    ReversePipe,
    RemoveDash,
    OrderBy,
    WeekFormat,
    FirstInitialLastNamePipe,
    EscapeHtmlPipe,
    PhoneFormat,
    GetLocation,
    WeightFormat,
    FilterByLocationPipe,
    RemoveCoachcarePipe,
    OverviewTagsPipe,
    FilterByPipe,
    ExcludeByPipe,
    FoodOrderItemsFilter,
    EmployeeTerminationFilterPipe,
  ],
  imports: [],
  exports: [
    RoundPipe,
    ExcludeByPipe,
    AbsoluteValue,
    OrderDate,
    WeekFormat,
    ReversePipe,
    OrderFlexible,
    InitialFormat,
    RemoveDash,
    Capitalize,
    Titleize,
    FirstInitialLastNamePipe,
    MoneyFormat,
    OrderBy,
    CompletedPipe,
    RemoveCoachcarePipe,
    RoundPipe,
    GetLocation,
    NameFormat,
    EscapeHtmlPipe,
    FilterByPipe,
    PhoneFormat,
    FilterByLocationPipe,
    WeightFormat,
    FoodOrderItemsFilter,
    OverviewTagsPipe,
    EmployeeTerminationFilterPipe
  ]
})

export class PipesModule {}
