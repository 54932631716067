import { Injectable } from '@angular/core';
import {GlobalsService} from '../../globals.service';
import {HttpClient} from '@angular/common/http';
import {ReferralCard} from '../../models/referral_card';
import {Observable} from 'rxjs';
import {ReferralCardEntry} from '../../models/referral_card_entry';

@Injectable()
export class ReferralCardEntryService {

  constructor(private globals: GlobalsService,
              private http: HttpClient) { }

  /**
   * Creates a {@link Referral} from the referral_card_entry
   * @param referral_card_entry
   * @param referred_id: The ID of the client being referred
   */
  linkToReferral(referral_card_entry: ReferralCardEntry, referred_id: number): Observable<ReferralCard> {
    let url = `${this.globals.base_url}/referral_card_entries/${referral_card_entry.id}/link_to_referral`
    return this.http.put<ReferralCard>(url, {referred_id: referred_id})
  }
}
