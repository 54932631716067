import {Injectable, OnDestroy} from '@angular/core';
import {GlobalsService} from '../globals.service';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Employee, IEmployee, IManager} from '../models/employee';
import {Storage} from '@ionic/storage';
import * as _ from 'lodash'
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class EmployeeService implements OnDestroy {
  // Only contains active employees
  employees = new BehaviorSubject([] as IEmployee[]);
  employees$ = this.employees.asObservable()

  // Contains terminated employees as well
  allEmployees = new BehaviorSubject([] as IEmployee[]);
  allEmployees$ = this.employees.asObservable()

  private managers = new BehaviorSubject([] as IManager[]);
  managers$ = this.managers.asObservable()

  directReports: IEmployee[]

  employee: Employee;
  employeeSubscription: Subscription;
  base_url: string;

  constructor(private globalVarService: GlobalsService,
              private auth: AuthService,
              private http: HttpClient,
              private storage: Storage) {

    this.base_url = this.globalVarService.base_url;
    this.employeeSubscription = this.employees$.subscribe(employees => {
      if (employees.length === 0 && this.auth.isLoggedIn) {
        setTimeout(() => {
          this.getEmployees().subscribe()
        }, 1000)
      }
    })
  }

  ngOnDestroy(): void {
    if (this.employeeSubscription) this.employeeSubscription.unsubscribe()
  }

  /**
   * Fetches employees from the server and sets the value in storage.
   */
  getEmployees() {
    const url = `${this.base_url}/employees`;
    return this.http.get<Employee[]>(url).pipe(
      tap(employees => {
        this.setEmployees(employees.filter(employee => !employee.terminated))
        this.setAllEmployees(employees)
        this.storage.set('employees', employees)
      })
    )
  }


  /**
   * Fetches employees that are managers and their direct reports.
   */
  fetchManagers() {
    const url = `${this.base_url}/employees/managers`;
    return this.http.get<Employee[]>(url).pipe(
      tap(managers => this.setManagers(managers))
    )
  }

  /**
   * Fetches employees from storage.  If empty, it grabs them from the server and updates storage.
   */
  returnEmployees() : IEmployee[] {
    return Object.assign([], this.employees.getValue())
  }

  /**
   * Updates the local and storage value for employees
   * @param employees: Actively employed employees
   */
  setEmployees( employees : Employee[] ) {
    this.employees.next(employees)
    this.storage.set('employees', employees);
  }


  /**
   * Updates the local and storage value for employees
   * @param employees: All employees (including terminated)
   */
  setAllEmployees( employees : Employee[] ) {
    this.allEmployees.next(employees)
    this.storage.set('allEmployees', employees);
  }

  /**
   * Updates the local and storage value for managers
   * @param managers: Array of managers
   * @param setStorage: Whether or not to update the storage value
   */
  async setManagers(managers: IManager[], setStorage: boolean = true) {
    console.log(1)
    if (setStorage) await this.storage.set('managers', managers)
    console.log(2)
    this.managers.next(managers)
    // this.directReports = _.flatMap(managers, 'direct_reports')
    // console.log(this)
  }

  getManagers(): IManager[] {
    return this.managers.getValue()
  }


  /**
   * Updates the employee in the DB based on provided data.
   * @param data
   */
  update(data: Employee) : Observable<Employee> {
    const final_url = `${this.base_url}/employees/${data.id}`;

    return this.http.put(final_url, data).pipe(
      tap( employee => {
        this.employee = new Employee(employee);
      })
    )
  }
}
