import {IClientDoc} from './client_doc';
import {ISignedDocument} from './signed_document';

export interface IMergedDocument {
  id?: number;
  name?: string;
  client_id?: number;
  url?: string;
  date?: string;
  key?: string;
  visible_to_client?: boolean;
  fileKey?: string;
  Key?: string;
  badge?: {name: string, color?: string};
  className?: string;
  canBeViewed?: boolean;
  canBeDeleted?: boolean;
  signed_url?: string;
  external_document_id?: number;
  fileType?: 'image'|'pdf'
}

export class MergedDocument implements IMergedDocument {
  public id?: number;
  public name?: string;
  public client_id?: number;
  public url?: string;
  public date?: string;
  public key?: string;
  public Key?: string;
  public signed_url?: string;
  public visible_to_client?: boolean;
  public fileKey?: string;
  public badge?: {name: string, color?: string};
  public className?: string;
  public canBeViewed?: boolean;
  public canBeDeleted?: boolean;
  public fileType?: 'image'|'pdf'

  constructor(mergedDocument: IMergedDocument|IClientDoc|ISignedDocument) {
    Object.entries(mergedDocument).forEach(entry => {
      this[entry[0]] = entry[1]
    })
  }
}
