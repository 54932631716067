import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalsService} from '../globals.service';
import {IUser} from '../models/user';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  base_url: string;

  constructor( private http: HttpClient,
               private global: GlobalsService ) {

    console.log('Hello UserProvider Provider');
    this.base_url = this.global.base_url

  }

  create(email: string, location_id: number) {
    // let url =
  }

  /**
   * Resets the client's password to that of the user's choosing.
   * @param user_id
   * @param password
   */
  resetPassword(user_id: number, password: string) {
    console.log(user_id, password)
    let url = `${this.base_url}/users/${user_id}/reset_password`
    return this.http.post(url, {password: password})
  }

  fetchUserByEmail(email: string) {
    let url = `${this.base_url}/users/getClientFromUserEmail?email=${email}`
    return this.http.get(url)
  }



  resendInvitation(userId: number) {
  //   let url = `${this.base_url}/users/resend_invite`
  //
  //   return this.http.post(url, { email: email})
    const url = `${this.base_url}/users/${userId}/resendInvitation`
    return this.http.post(url, null)
  }

  update( user : IUser ): Observable<IUser> {
    const url = `${this.base_url}/users/${user.id}`
    return this.http.put(url, user)
  }
}
