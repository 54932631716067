import { Client } from "./client";

export interface IPendingWeighIn {
  current_weight?: number;
  body_fat?: number;
  visceral_fat?: number;
  muscle_mass?: number;
  date?: string;
}

export class WeighIn {
  public body_fat?: number;
  public muscle_mass?: number;
  public tbw?: number;
  public metabolic_age?: number;
  public visceral_fat?: number;
  public bmi?: number;
  public current_weight?: number;
  public smt?: string;
  public user_id?: number;
  public created_at?: Date;
  public updated_at?: Date;
  public check_in_id?: number;
  public scale_id?: number;
  public name?: string;
  public date?: string;
  public fat_mass?: number;
  public basal_metabolic_rate?: number;
  public target_body_fat?: number;
  public right_leg_fat_percent?: number;
  public right_leg_fat_mass?: number;
  public right_leg_fat_free_mass?: number;
  public right_leg_muscle_mass?: number;
  public left_leg_fat_percent?: number;
  public left_leg_fat_mass?: number;
  public left_leg_fat_free_mass?: number;
  public left_leg_muscle_mass?: number;
  public right_arm_fat_percent?: number;
  public right_arm_fat_mass?: number;
  public right_arm_fat_free_mass?: number;
  public right_arm_muscle_mass?: number;
  public left_arm_fat_percent?: number;
  public left_arm_fat_mass?: number;
  public left_arm_fat_free_mass?: number;
  public left_arm_muscle_mass?: number;
  public trunk_fat_percent?: number;
  public trunk_fat_mass?: number;
  public trunk_fat_free_mass?: number;
  public trunk_muscle_mass?: number;
  public client_id?: number;
  public body_water_percent?: number;
  public bone_mass?: number;
  public phase_angle?: number;
  public fat_free_mass?: number;
  public weightloss?: number;
  public claim_status?: string;

  constructor(data: Partial<IWeighIn>) {
    Object.assign(this, data);
  }

  isAtHealthyBodyComposition(client: Client) {
    const visceralFatGoal = client.fetchTarget('visceral_fat_target')
    const bodyFatGoal = client.fetchTarget('body_fat_target')
    const passedVisceralFatGoal = visceralFatGoal && this.visceral_fat <= visceralFatGoal
    const passedBodyFatGoal = bodyFatGoal && this.body_fat <= bodyFatGoal

    if (passedBodyFatGoal && passedVisceralFatGoal) {
      return true
    }
  }
}

export interface IBasicWeighIn {
  id?: number
  body_fat?: number
  muscle_mass?: number
  tbw?: number;
  current_weight?: number
  created_at?: Date
  date?: string;
  fat_mass?: number
  visceral_fat?: number;
  body_water_percent?: number;
  check_in_id?: number;
  name?: string;
}

export interface IWeighIn extends IBasicWeighIn {
  metabolic_age?: number
  smt?: string
  updated_at?: Date
  user_id?: number
  fat_free_mass?: number
  scale_id?: number
  bone_mass?: number
  basal_metabolic_rate?: number
  target_body_fat?: number
  right_leg_fat_percent?: number
  right_leg_fat_mass?: number
  right_leg_fat_free_mass?: number
  right_leg_muscle_mass?: number
  left_leg_fat_percent?: number
  left_leg_fat_mass?: number
  left_leg_fat_free_mass?: number
  left_leg_muscle_mass?: number
  right_arm_fat_percent?: number
  right_arm_fat_mass?: number
  right_arm_fat_free_mass?: number
  right_arm_muscle_mass?: number
  left_arm_fat_percent?: number
  left_arm_fat_mass?: number
  left_arm_fat_free_mass?: number
  left_arm_muscle_mass?: number
  phase_angle?: number;
  trunk_fat_percent?: number
  trunk_fat_mass?: number
  trunk_fat_free_mass?: number
  trunk_muscle_mass?: number
  client_id?: number
  weightloss?: number
  claim_status?: string;
}

export class BasicWeighIn {
  public id?: number
  public body_fat?: number
  public muscle_mass?: number
  public tbw?: number;
  public current_weight?: number
  public created_at?: Date
  public date?: string;
  public phase_angle?: number;
  public fat_mass?: number
  public visceral_fat?: number;
  public body_water_percent?: number;
  public check_in_id?: number;
  public name?: string;

  constructor( data: Partial<IBasicWeighIn> ) {
    Object.assign(this, data);
  }
}

export var weigh_in_fields = [
  { name: "current_weight", desired: -1, reversed: false },
  { name: "body_fat", desired: -1, reversed: false },
  { name: "fat_mass", desired: -1, reversed: false },
  { name: "body_water_percent", desired: 1, reversed: true },
  { name: "muscle_mass", desired: 1, reversed: true },
  { name: "visceral_fat", desired: -1, reversed: false },
]
