import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import {AuthService} from './auth.service';
import { Storage } from '@ionic/storage';
import {NavController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor( private authService: AuthService,
               private router: Router,
               private navCtrl: NavController,
               private storage: Storage) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let url: string = state.url;
    this.authService.redirectUrl = url;

    return new Promise((resolve, reject) => {
      if ( url != '/login' ) {

        this.storage.ready().then(() => {
          this.storage.get('isLoggedIn')
            .then((val) => {
              if (val) {
                resolve(val)
              } else {
                // this.navCtrl.navigateRoot('/login')
                this.router.navigate([]);
                resolve(false)
              }
            })
            .catch(() => {
              // this.navCtrl.navigateRoot('/login')
              this.router.navigate(['/login']);
              reject(false)
            })
        })

      } else {

        this.storage.ready().then(() => {
          this.storage.get('isLoggedIn')
            .then((val) => {
              if (val) {
                this.router.navigate(['/clients']);
                resolve(false)

              } else {
                resolve(true)
              }
            })
            .catch(() => {
              console.log('reject');
              reject(true)
            })
        })
      }
    })
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  checkLogin(url: string) {
    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;

    return this.storage.get('isLoggedIn')
  }
}
