<ion-header class="pt-2">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dismiss()">Close</ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">Send For Signature</ion-title>
    <ion-buttons slot="end">

    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="blue-purple-gradient montserrat">
  <a180-signed-document-selector
    [client]="client"
    (continueProcessing)="dismissWithRefresh()"
  ></a180-signed-document-selector>
</ion-content>
