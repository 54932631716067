<ion-grid style="height:90%;" class="justify-content-center">
  <ion-row class="ion-justify-content-center ion-align-items-start" style="flex:3;">
    <ion-col class="ion-padding" size="10">
      <ion-card [ngClass]="{
         'activeSection':step == 'purchase',
         'disabledSection':step !== 'purchase'}">
        <ion-card-header (click)="step = 'purchase'" class="pb-0">
          <ion-card-title class="d-flex ion-justify-content-between cursor">
            <span>
              Add Purchase Details
              <ion-icon *ngIf="purchaseSuccess" name="checkmark-circle" color="success" class="pl-3"></ion-icon>
            </span>
            <span>${{purchaseTotal || 0 | number:'1.0':'en-US' }}</span>
          </ion-card-title>
        </ion-card-header>
        <ion-card-content [ngClass]="{'hidden':step == 'payment'}" class="pt-0">
          <purchase-form
            [employee_id]="employee_id"
            [client_id]="client_id"
            [purchase]="purchase"
            [payment]="payment"
            [purchaseable_id]="purchaseable_id"
            [purchaseable_type]="purchaseable_type"
            [partOfConsult]="partOfConsult"
            [program_id]="program_id"
            [unusedReferrals]="unusedReferrals"
            [saleTypePreference]="saleTypePreference"
            submitButtonText="Continue to Payment"
            (newTotal)="handleNewTotal($event)"
            (purchaseData)="handlePurchaseData()"
            (paidInFullEmitter)="this.paidInFull = $event"
          ></purchase-form>
        </ion-card-content>
      </ion-card>
      <ion-card [ngClass]="{
         'activeSection':step == 'payment',
         'disabledSection':step !== 'payment'}">
        <ion-card-header (click)="step = 'payment'">
          <ion-card-title class="d-flex ion-justify-content-between cursor">
            <span>Add Payment Details</span>
            <span>${{paymentTotal || 0 | number:'1.0':'en-US' }}</span>
          </ion-card-title>
          <ion-card-subtitle class="d-flex ion-justify-content-between">
            <span>Balance</span>
            <span>${{(purchaseTotal || 0) - (paymentTotal || 0) | number:'1.0':'en-US' }}</span>
          </ion-card-subtitle>
        </ion-card-header>
        <ion-card-content [ngClass]="{'hidden':step == 'purchase'}">
          <payment-form
            [employee_id]="employee_id"
            [client_id]="client_id"
            [payment]="payment"
            [paymentable_id]="purchaseable_id"
            [paymentable_type]="purchaseable_type"
            [program_id]="program_id"
            [paidInFull]="paidInFull"
            [purchaseTotal]="purchaseTotal"
            (newAmount)="paymentTotal = $event"
            (paymentData)="handlePaymentData()"
          ></payment-form>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="displaySkipThisStep">
    <ion-col class="justify-content-center text-center">
      <ion-button fill="clear" color="danger" (click)="this.transactionComplete.emit()">Skip this step</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
