import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Consultation} from '../../models/consultation';
import {ReEvaluation} from '../../models/re-evaluation';
import {IPayment, IPurchase, Purchase} from '../../models/finance';
import {IReferral} from '../../models/referral';

@Component({
  selector: 'transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
})
export class TransactionComponent implements OnInit, OnChanges {
  @Input() consultation: Consultation;
  @Input() program_id: number;
  @Input() purchase: IPurchase;
  @Input() payment: IPayment;
  @Input() saleTypePreference: string;
  @Input() purchaseable_type: string;
  @Input() partOfConsult: boolean = false;
  @Output() transactionComplete = new EventEmitter<boolean>();
  @Input() unusedReferrals: IReferral[];
  @Input() displaySkipThisStep = true;

  employee_id: number;
  client_id: number;
  consultation_id: number;
  purchaseable_id: number;
  step: string = "purchase";
  purchaseTotal: number;
  paymentTotal: number;
  paidInFull: boolean = false;
  purchaseSuccess: boolean;


  constructor(private modalCtrl: ModalController) {
    console.log(this)
  }

  ngOnInit() {
    if (this.consultation) {
      this.setupTransactionFields()
    }
    console.log(this)
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    if (changes && changes.consultation) {
      this.consultation = changes.consultation.currentValue
      this.setupTransactionFields()
    }
  }

  dismiss() : void {
    this.modalCtrl.dismiss()
  }

  setupTransactionFields() {
    this.employee_id = this.consultation.consultant_id
    this.client_id = this.consultation.client_id
    this.purchaseable_id = this.consultation.id
  }

  handleNewTotal(amount: number) {
    this.purchaseTotal = amount
  }

  handlePurchaseData() {
    this.purchaseSuccess = true;
    this.step = 'payment'
  }

  handlePaymentData() {
    this.transactionComplete.emit(true)
  }
}
