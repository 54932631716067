<form #purchaseForm="ngForm" name="purchaseForm">
  <ion-list>

    <ion-item class="better-validation pt-4"
              [class.invalid]="( purchaseForm.dirty && saleType.invalid)">
      <ion-label color="primary">Sale Type:</ion-label>
      <ion-select [(ngModel)]="sale_type" name="sale_type" required #saleType=ngModel interface="action-sheet">
        <ion-select-option value="new_sale">Initial Purchase</ion-select-option>
        <ion-select-option value="add_weeks">Additional Weeks</ion-select-option>
      </ion-select>

    </ion-item>
    <small class="text-danger text-left ion-padding-start" [hidden]="( purchaseForm.pristine || ( !purchaseForm.pristine && saleType.valid))">
      Sale type is required.
    </small>

    <ion-item class="pt-4 better-validation">
      <ion-label color="primary">Sales Consultant</ion-label>
      <ion-select required [(ngModel)]="employee_id" name="employee" #saleEmployee=ngModel>
        <ion-select-option *ngFor="let employee of employeeService.employees$ | async" [value]="employee.id">{{ employee.name }}</ion-select-option>
      </ion-select>
    </ion-item>
    <small class="text-danger text-left ion-padding-start" [hidden]="( purchaseForm.pristine || ( !purchaseForm.pristine && saleEmployee.valid))">
      Sales consultant is required.
    </small>

    <ion-item class="better-validation" [class.invalid]="( purchaseForm.dirty && x.invalid)">
      <ion-label position="floating" color="primary">Weeks:</ion-label>
      <ion-input required type="number" min=1  [(ngModel)]="weeks" name="weeks" #x="ngModel" (ionChange)="newTotal.emit(calcTotal())"></ion-input>
    </ion-item>
    <small class="text-danger text-left ion-padding-start" [hidden]="( purchaseForm.pristine || ( !purchaseForm.pristine && x.valid))">
      Number of weeks is required.
    </small>

    <ion-item class="better-validation" [class.invalid]="( purchaseForm.dirty && y.invalid)">
      <ion-label position="floating" color="primary">Weekly Cost:</ion-label>
      <ion-input name="weekly_cost" type="number" min="1" required [(ngModel)]="weekly_cost" #y="ngModel" (ionChange)="newTotal.emit(calcTotal())"></ion-input>
    </ion-item>
    <small class="text-danger text-left ion-padding-start" [hidden]="( purchaseForm.pristine || ( !purchaseForm.pristine && y.valid))">
      Weekly cost is required.
    </small>

    <ion-item>
      <ion-label position="floating" color="primary">Discount (%):</ion-label>
      <ion-input name="discount" min="0" type="number" [(ngModel)]="discount" (ionChange)="newTotal.emit(calcTotal())"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="floating" color="primary">Comment:</ion-label>
      <ion-input spellcheck="on" name="comment" [(ngModel)]="purchase_comment" type="text"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label color="primary">Use Referral?</ion-label>
      <ion-toggle
        [disabled]="!unusedReferrals.length"
        name="referral_used"
        [(ngModel)]="referral_used"
        (ionChange)="newTotal.emit(calcTotal())"
      ></ion-toggle>
    </ion-item>
    <ion-item>
      <ion-label color="primary">Paid in Full?</ion-label>
      <ion-toggle name="paid_in_full" [(ngModel)]="paid_in_full"></ion-toggle>
    </ion-item>
<!--    <ion-item *ngIf="!partOfConsult">-->
<!--      <ion-label color="primary">Use Referral?</ion-label>-->
<!--      <ion-toggle [disabled]="!has_referrals" name="referral_used" [(ngModel)]="referral_used"></ion-toggle>-->
<!--    </ion-item>-->
    <ion-button class="ion-margin submitPurchase" expand="full" shape="round" type="submit" color="success" [disabled]="!purchaseForm.form.valid" (click)="createOrUpdatePurchase()">
      {{submitButtonText}}
    </ion-button>
  </ion-list>
</form>
