<ion-card-content class="p-0">
  <ion-card-title class="flex justify-content-space-between align-items-center" style="padding:20px 20px 10px 20px;">
    <span>{{selectedDocument.name}}</span>
    <ion-button *ngIf="client" fill="clear" color="success" (click)="dismiss(true)">Mark Document Signed</ion-button>
    <ion-button *ngIf="!client" fill="clear" color="danger" (click)="dismiss(false)">Close</ion-button>
  </ion-card-title>
  <div style="display:block;margin:auto;padding:0;border:0;outline:0;font-size:12px!important;color:#AAA!important;vertical-align:baseline;background:transparent;width:100%;">
    <iframe frameborder="0" allowfullscreen="true" [height]="iframeHeight" scrolling="yes" [src]="formattedUrl" width="100%"></iframe>
    <div style="font-family: Helvetica, Arial, Verdana, sans-serif;line-height:13px !important;text-align:center;margin-top: 6px !important;">
      <a href="https://www.esigngenie.com/" target="_blank" style="color:#AAA;text-decoration:none;">Online Signature</a> by eSign Genie ©  • 
      <a href="https://www.esigngenie.com/terms-of-service/" target="_blank" style="color:#888;">Terms of Service</a>  • 
      <a href="https://www.esigngenie.com/privacy-policy/" target="_blank" style="color:#888;">Privacy Policy</a>
    </div>
  </div>
</ion-card-content>
