import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalsService} from '../globals.service';
import {IExclusion} from '../models/exclusion';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExclusionsService {
  baseUrl: string
  exclusionTypes = new BehaviorSubject<{all: {[exclusion_type: string]: number}, individual: IExclusion[]}>({all: {"name": null}, individual: []});
  exclusionTypes$ = this.exclusionTypes.asObservable()

  constructor( private http: HttpClient,
               private globals: GlobalsService ) {
    this.baseUrl = this.globals.base_url
  }

  setExclusionTypes(exclusionTypes: {all: {[exclusion_type: string]: number}, individual: IExclusion[]}) {
    this.exclusionTypes.next(exclusionTypes)
  }

  getExclusionTypes() {
    Object.assign([], this.exclusionTypes.getValue())
  }


  /**
   * Creates new exclusions based on what's selected by the User.  Removes any that are deselected.
   * @param user_id
   * @param exclusion_types
   */
  create(user_id: number, exclusion_types: string[]): Observable<IExclusion> {
    let url = `${this.baseUrl}/users/${user_id}/exclusions`
    return this.http.post<IExclusion>(url, {exclusion_types: exclusion_types})
  }


  /**
   * Fetches the enum values for exclusion types from the database.
   */
  fetchExclusionTypes(user_id: number = null): Observable<{all: {[exclusion_type: string]: number}, individual: IExclusion[]}> {
    let url = `${this.baseUrl}/users/${user_id}/exclusions/exclusion_types`
    return this.http.get<{all: {[exclusion_type: string]: number}, individual: IExclusion[]}>(url).pipe(
      tap(exclusionTypes => {
        this.setExclusionTypes(exclusionTypes)
      })
    )
  }
}
