import {Approval, IApproval} from './approval';
import {Comment} from './comment';
import {Payment, Purchase} from './finance';
import {IBasicWeighIn} from './weigh-in';
import {IBasicRepositioning, IRepositioning} from './repositioning';
import * as moment from 'moment';
import {IEmployee} from './employee';
import {IReEvaluation} from './re-evaluation';
import {IReminder} from './reminder';

export class CheckIn {
  public id: number;
  public program_id: number;
  public repositioning: IRepositioning;
  public weigh_in: any;
  public week: number;
  public client_id       : number;
  public employee        : IEmployee;
  public type_of_weighin : string;
  public employee_id?: number;
  public approved: boolean;
  public approved_by: string;
  public validated?: boolean;
  public approvals?: Approval[];
  public comments?: Comment[];
  public created_at?: string;
  public re_evaluation?: IReEvaluation;
  public re_evaluation_id?: number;

  constructor( data: Partial<ICheckIn> ) {
    Object.assign(this, data);
  }
}

export interface IBasicCheckIn {
  id?: number;
  program_id?: number;
  date?: string;
  week?: number;
  client_id?: number;
  employee_id?: number;
  created_at?: string;
  employee_name?: string;
  type_of_weighin?: string;
  week_counter?: string;
  segmentColor?: string;
  validated?: boolean;
  comments?: Comment[];
  reminders?: IReminder[];
  weigh_in?: IBasicWeighIn;
  repositioning?: IBasicRepositioning;
  weigh_in_data_source?: number;
  counts_towards_weeks?: boolean;
  isReEval?: boolean;
  visibility_status?: string;
  re_evaluation?: IReEvaluation;
  re_evaluation_id?: number;
}

export class BasicCheckIn {
  public id?: number;
  public program_id?: number;
  public week?: number;
  public date?: string;
  public client_id?: number;
  public employee_id?: number;
  public employee_name?: string;
  public type_of_weighin?: string;
  public created_at?: string;
  public week_counter?: string;
  public segmentColor?: string;
  public validated?: boolean;
  public comments?: Comment[];
  public reminders?: IReminder[];
  public weigh_in?: IBasicWeighIn;
  public repositioning?: IBasicRepositioning;
  public visibility_status?: string;
  public weigh_in_data_source: number;
  public counts_towards_weeks: boolean;
  public re_evaluation?: IReEvaluation;
  public re_evaluation_id?: number;

  constructor( data: Partial<IBasicCheckIn> ) {
    Object.assign(this, data);
  }


  /**
   * Date of the check in.
   *
   * @returns either the weigh_in.date or the check_in.created_at depending on what's available.
   */
  getDate() : string {
    if ( this.weigh_in?.date ) return moment.utc(this.weigh_in.date).format("M/D/YY")
    if ( this.date ) return moment.utc(this.date).format("M/D/YY")
    return moment.utc(this.created_at).format("M/D/YY")
  }


  /**
   * Does the check in have an associated weigh in?
   */
  hasWeighIn() : boolean {
    return this.weigh_in !== null && this.weigh_in !== undefined
  }


  /**
   * Does the check in have an associated repositioning?
   */
  hasRepositioning() : boolean {
    return this.repositioning !== null && this.repositioning !== undefined
  }

  /**
   * @returns the color for the badge depending on the type of weigh in
   */
  typeOfWeighInStatus() : string {
    return {
      "standard": 'primary',
      "LM": 'danger',
      "FM": 'warning'
    }[this.type_of_weighin]
  }
}

export interface ICheckIn extends IBasicCheckIn {
  approved?: boolean;
  approved_by?: string;
  approvals?: Approval[];
}

export interface IManagerCheckInIndex {
  id?: number;
  created_at?: string;
  type_of_weighin?: string;
  approved?: boolean;
  validated?: boolean;
  name?: string;
  approvals?: IApproval[];
  // pending_employee: string;
  // prev_ci: CheckIn;
  // program_status?: string;
  // purchased_weeks?: number;
  // used_weeks?: number;
  // weightloss?: number;
  // check_in?: CheckIn;
  // check_in_id?: number;
}

export interface IManagerCheckIn {
  check_in?: CheckIn;
  check_in_id?: number;
  id?: number;
  name?: string;
  pending_employee: string;
  pending_employee_id: number;
  prev_ci: CheckIn;
  program_status?: string;
  purchased_weeks?: number;
  used_weeks?: number;
  weightloss?: number;
}

export class ManagerCheckIn implements IManagerCheckIn {
  public check_in?: CheckIn;
  public check_in_id?: number;
  public id?: number;
  public name?: string;
  public pending_employee: string;
  public pending_employee_id: number;
  public prev_ci: CheckIn;
  public program_status?: string;
  public purchased_weeks?: number;
  public used_weeks?: number;
  public weightloss?: number;

  constructor( data: Partial<IManagerCheckIn> ) {
    Object.assign(this, data);
  }
}

export interface IManagerFinancial {
  balance?: number;
  check_ins?: CheckIn[];
  id?: number;
  matched_check_ins: [CheckIn, Payment[]];
  name?: string;
  payments?: Payment[];
  purchases?: Purchase[]
  weeks_remaining?: number;
}

export class ManagerFinancial {
  public balance?: number;
  public check_ins?: CheckIn[];
  public id?: number;
  public matched_check_ins: [CheckIn, Payment[], Purchase[]][];
  public name?: string;
  public payments?: Payment[];
  public purchases?: Purchase[]
  public weeks_remaining?: number;

  constructor( data: Partial<IManagerFinancial> ) {
    Object.assign(this, data);
  }
}
