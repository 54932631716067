import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {ClientIndexPage} from './client-index.page';
import {PipesModule} from '../../../pipes/pipes.module';
import {MomentModule} from 'ngx-moment';
import {ClientIndexRowComponent} from './client-index-row/client-index-row.component';
import {ClientIndexSearchComponent} from './client-index-search/client-index-search.component';
import {SharedModule} from '../../../shared/shared.module';
import {DatePickerModule} from '../../../components/date-picker/date-picker.module';

@NgModule({
  imports: [
    CommonModule,
    MomentModule.forRoot(),
    PipesModule,
    FormsModule,
    IonicModule,
    RouterModule,
    SharedModule,
    DatePickerModule
  ],
  declarations: [
    ClientIndexPage,
    ClientIndexRowComponent,
    ClientIndexSearchComponent,
  ]
})
export class ClientIndexPageModule {}
