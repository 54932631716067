export interface IAddress {
  id?: number;
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip_code?: number;
  latitude?: number;
  phone?: string;
  longitude?: number;
  client_id?: number;
  addressable_id?: number;
  addressable_type?: string;
}

export class Address {
  public id?: number;
  public street?: string;
  public street2?: string;
  public city?: string;
  public state?: string;
  public zip_code?: number;
  public phone?: string;
  public latitude?: number;
  public longitude?: number;
  public client_id?: number;
  public addressable_id?: number;
  public addressable_type?: string;

  constructor(data: Partial<IAddress>) {
    Object.assign(this, data);
  }

  as_string?() {
    let a = ""
    a = "#{self.street}"
    if (this.street2) { a += ", #{self.street2}" }
    a += ", #{self.city} #{self.state}"
    a += ", #{self.zip_code}";
    return a
  }
}
