import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }

  /**
   * Displays the universal loader.  Auto-timeout after 3s unless stay = true.
   * @param stay Whether or not the loader should automatically close
   */
  showLoader(stay=false) : void {
    const container = document.querySelector('.loader') as HTMLElement
    container.style.display = 'block';

    if ( !stay ) {
      setTimeout(() => {
        this.hideLoader()
      }, 3000)
    }
  }


  /**
   * Hides the universal loader
   */
  hideLoader() : void {
    const container = document.querySelector('.loader') as HTMLElement

    if ( container ) {
      container.style.display = 'none';
    }
  }
}
