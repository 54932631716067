<ion-toolbar class="py-3">
  <ion-buttons slot="start">
    <ion-button
      (click)=prevPageClicked.emit()
      [disabled]="pagination.prev === null"
    >
      <ion-icon name="chevron-back-outline"></ion-icon>
      Prev
    </ion-button>
  </ion-buttons>

  <ion-title *ngIf="pagination.page" class="ion-text-center fw-300 fs-14">
    Displaying page {{pagination.page}} of {{pagination.last}}
  </ion-title>

  <ion-buttons slot="end">
    <ion-button
      (click)="nextPageClicked.emit()"
      [disabled]="pagination.next === null"
    >
      Next
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
