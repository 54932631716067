import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPagination} from '../../models/pagination';

@Component({
  selector: 'a180-common-card',
  templateUrl: './common-card.component.html',
  styleUrls: ['./common-card.component.scss'],
})
export class CommonCardComponent implements OnInit {
  @Input() title: string;
  @Input() helpText: string;
  @Input() displayCardContent = true;
  @Input() footerHtml: HTMLElement;
  @Input() cardContentCssClasses: string[] = [];
  @Input() hasToolbar: boolean;
  @Input() hasHeader = true;
  @Input() pagination: IPagination
  @Input() hidePagination: boolean;
  @Input() backgroundColor: string;

  @Output() prevPageClicked = new EventEmitter()
  @Output() nextPageClicked = new EventEmitter()

  constructor() { }

  ngOnInit() {}

}
