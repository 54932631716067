import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPagination} from '../../models/pagination';

@Component({
  selector: 'a180-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() pagination: IPagination;
  @Output() prevPageClicked = new EventEmitter()
  @Output() nextPageClicked = new EventEmitter()

  constructor() { }

  ngOnInit() {}

}
