import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {IReminder} from '../../models/reminder';
import {CommonModalComponent} from '../common-modal/common-modal.component';
import {ReminderComponent} from '../reminder/reminder/reminder.component';
import {IonMenu, IonTabs, ModalController} from '@ionic/angular';
import {AwakenModal} from '../../shared/awaken-modal/awaken-modal.component';
import {IReviewRequest, ReviewRequest} from '../../models/review_request';
import {ExclusionModalComponent} from '../../pages/clients/client-detail/profile/exclusion-modal/exclusion-modal.component';
import {ReviewRequestsService} from '../../providers/review-requests.service';
import {GlobalsService} from '../../globals.service';
import {Client} from '../../models/client';
import {AuthService} from '../../auth/auth.service';
import { IndividualClientService } from 'src/app/providers/client.service';

@Component({
  selector: 'a180-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent {
  @Input() client: Client;
  @Output() tabSelected = new EventEmitter<string>()
  @ViewChild('profileMenu') profileMenu: IonMenu;

  constructor( private modalCtrl: ModalController,
               private reviewRequestsService: ReviewRequestsService,
               public clientService: IndividualClientService,
               private globals: GlobalsService,
               private auth: AuthService) { }

  async addOrEditReminder(reminder: IReminder = null, menu = null) {
    if (reminder) {
      reminder.number_of_items = reminder.number_of_items - reminder.total_items
    }

    const modal = await this.modalCtrl.create({
      component: CommonModalComponent,
      componentProps: {
        childComponent: {
          component: ReminderComponent,
          data: {
            reminder,
            remindableId: this.client.id,
            remindableType: 'Client',
            itemClass: 'CheckIn',
            action: reminder ? 'edit' : 'create',
            friendlyItemName: 'Visits',
            itemSize: this.client.total_visits
          }
        },
      },
      cssClass: 'modal-fullscreen'
    })

    await modal.present()
    menu.close()
  }



  /**
   * Creates modal for requesting a review and sends API request to generate review request on confirmation.
   */
  async requestReview(menu: IonMenu = null) : Promise<any> {
    const modal = await this.modalCtrl.create({
      component: AwakenModal,
      componentProps: {
        type: 'confirm',
        title: `Would you like to request a review from ${this.client.name}?`
      },
      cssClass: 'small-modal'
    })

    modal.onDidDismiss().then(d => {
      if (d?.data  ) {
        const data = d.data
        const client = this.client
        if (data === 'yes' && client?.user) {
          const userId = client.user.id
          const request = new ReviewRequest(userId, this.auth.getCurrentUser().employee.id)
          this.reviewRequestsService.create(request).subscribe(
            () => this.globals.handleResponse('Successfully sent review request!', false, 'primary'),
            err => {

              if (err.error && err.error[0].indexOf('request was already sent') !== -1) {
                this.handleReviewRequestError(request, err)

              } else {
                this.globals.handleResponse(`Error: ${err.error}`, true)
              }
            }
          )
        }
      }
    })

    if ( menu ) await menu.close();
    return await modal.present();
  }


  /**
   * Handles if a review request has already been sent.  Allows you to force another request.
   * @param request Review request
   * @param err Error being received
   */
  async handleReviewRequestError(request: IReviewRequest, err : {error:string}) : Promise<any> {
    const reminderModal = await this.modalCtrl.create({
      component: AwakenModal,
      componentProps: {
        type: 'confirm',
        title: 'Would you like to send another request anyway?',
        subtitle: `${err.error}`
      },
      cssClass: 'small-modal'
    })

    reminderModal.onDidDismiss().then( d => {
      const data = d.data
      if ( data === 'yes' ) {
        request.force = true
        this.reviewRequestsService.create(request).subscribe(
          () => this.globals.handleResponse('Successfully sent review request!', false, 'primary'),
          error => `Error: ${error.error}`
        )
      }
    })

    return await reminderModal.present();
  }


  async addToExclusionList(menu: IonMenu = null) {
    const modal = await this.modalCtrl.create({
      component: ExclusionModalComponent,
      componentProps: {
        client: this.client
      }
    })

    if (menu) await menu.close()
    await modal.present()
    await modal.onDidDismiss()
  }


  async selectTab(tabName: string) {
    await this.profileMenu.close()
    this.tabSelected.emit(tabName)
  }
}
