import {Location} from "./location";

export class Employee implements IEmployee {
  public id?: number;
  public email?: string;
  public isManager?: boolean;
  public name?: string;
  public phone?: string;
  public user_id?: number;
  public start_date?: string;
  public client_sort_method?: string;
  public location?: Location;
  public location_sort?: number;
  public terminated?: boolean;
  public terminated_at?: string;

  constructor(data: Partial<IEmployee>) {
    Object.assign(this, data);
  }
}

interface IBasicEmployee {
  id?: number;
  name?: string;
  email?: string;
}

export interface IManager extends IBasicEmployee {
  user_id?: number;
  location_id?: number;
  direct_reports?: IEmployee[];
}


export interface IEmployee extends IBasicEmployee{
  terminated_at?: string;
  isManager?: boolean;
  phone?: string;
  start_date?: string;
  client_sort_method?: string;
  location?: Location;
  location_sort?: number;
  terminated?: boolean;
}
