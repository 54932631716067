import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {ClientService} from '../../clients/clients.service';
import {IDraftRepositioning, IRepositioning} from '../../../models/repositioning';
import {IClient} from '../../../models/client';
import {ProgramsService} from '../../clients/client-detail/providers/programs/programs.service';
import {ICheckIn} from '../../../models/check_in';
import * as _ from 'lodash';
import {RepositioningsService} from '../../../providers/repositionings.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../providers/local-storage.service';
import {LocationService} from '../../../providers/locations.service';

@Component({
  selector: 'app-recover-repositioning-modal',
  templateUrl: './recover-repositioning-modal.component.html',
  styleUrls: ['./recover-repositioning-modal.component.scss'],
})
export class RecoverRepositioningModalComponent implements OnInit {
  isLoading: boolean = true;
  draftRepo: IDraftRepositioning;
  client: IClient;
  clients: IClient[];
  clientConfirmed: boolean = false;
  checkIns: ICheckIn[];
  checkIn: ICheckIn;
  recommendedCheckIn: ICheckIn;
  search: string;
  statusText: string = '';
  lodash = _;

  constructor(private modalCtrl: ModalController,
              private alertCtrl: AlertController,
              private clientService: ClientService,
              private router: Router,
              private localStorage: LocalStorageService,
              private repoService: RepositioningsService,
              private programsService: ProgramsService,
              public locationsService: LocationService,
              private params: NavParams) {
    this.draftRepo = params.get("draftRepo")
  }

  ngOnInit() {
    this.fetchClient()
  }


  /**
   * Fetches the clients visits from the database.
   */
  fetchVisits() {
    this.isLoading = true
    this.statusText = "Looking for visits..."
    this.programsService.fetchClientPrograms(this.client.id, false, false).subscribe(programs => {
      console.log(programs)
      this.statusText = ""
      this.isLoading = false
      this.checkIns = programs.flatMap(program => program.check_ins).filter(check_in => check_in)
      if (this.draftRepo.check_in_id) {
        this.recommendedCheckIn = _.find(this.checkIns, {id: this.draftRepo.check_in_id})
      }
    })
  }


  clientSearch(ev: any) {
    let val = ev.target.value

    this.clientService.clientSearch(val).subscribe(
      successData => {
        console.log(successData)
        this.clients = successData
      }
    )
  }

  convertDraftRepoToRealRepo(checkIn: ICheckIn) {
    this.checkIn = checkIn
    this.createOrUpdateRepo()
  }

  createOrUpdateRepo() {
    this.repoService.createOrUpdate(this.draftRepo).subscribe(data => {
      this.programsService.fetchClientPrograms(this.client.id, false, false).subscribe(() => {
        this.dismiss()
        this.router.navigate(["clients", this.client.id, "visits"]).then(() => {
          let storageName = `${this.client.name}-${this.client.id}`
          this.localStorage.removeDraftRepositioning(storageName, this.checkIn.created_at)
        })
      })
    })
  }

  destroyExistingRepo(repositioning: IRepositioning): Observable<any> {
    return this.repoService.destroy(repositioning)
  }


  /**
   * Confirms the client selection and calls {@link fetchVisits}
   */
  confirmClient(client: IClient = null) {
    if (client) this.client = client
    this.clientConfirmed = true;
    this.draftRepo.client_id = this.client.id
    this.fetchVisits()
  }


  /**
   * Loads the client from the server.
   */
  fetchClient() {
    this.statusText = "Looking for client..."
    if (this.draftRepo.client_id) {
      this.clientService.fetchClient(this.draftRepo.client_id, false).subscribe(client => {
        this.statusText = ""
        this.client = client
        this.isLoading = false
      })
    } else {
      this.statusText = ""
      this.isLoading = false
    }
  }


  /**
   * Closes the modal.
   */
  dismiss(): void {
    this.modalCtrl.dismiss()
  }
}
