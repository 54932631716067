import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IonTabs, ModalController, NavController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientService} from '../clients.service';
import {TitleService} from '../../../providers/title.service';
import {Client, IClient} from '../../../models/client';
import {TransactionsProvider} from './providers/finances/transactions.service';
import {CommentsProvider} from '../../../providers/comments.service';
import {Location} from '@angular/common';
import {ProgramsService} from './providers/programs/programs.service';
import {RouteInterceptorService} from '../../../providers/route-interceptor.service';
import {AwakenModal} from '../../../shared/awaken-modal/awaken-modal.component';
import {NewTransaction} from './finance/new-transaction/new-transaction.modal';
import {GlobalsService} from '../../../globals.service';
import {ReminderService} from '../../../components/reminder/reminder.service';
import {FoodOrderProvider} from './providers/food-orders/food-orders.service';
import {DailyCheckInsService} from '../../../providers/daily-check-ins.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { IndividualClientService } from 'src/app/providers/client.service';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.page.html',
  styleUrls: ['./client-detail.page.scss'],
})

export class ClientDetailPage implements OnInit, OnDestroy {
  @ViewChild('tabs', {static: true}) tabs: IonTabs;
  clientId: number;
  hasCoachCareVisits = false;
  browsingHistoryBackEnabled = false;
  alerts: { images: number } = { images: 0 };
  client: Client;

  constructor( private route : ActivatedRoute,
               private router: Router,
               public clientService: ClientService,
               public individualClientService: IndividualClientService,
               private navCtrl: NavController,
               private routeTracingService: RouteInterceptorService,
               public transactionsService: TransactionsProvider,
               private modalCtrl: ModalController,
               public dailyCheckInsService: DailyCheckInsService,
               private programsService: ProgramsService,
               private globals: GlobalsService,
               private remindersService: ReminderService,
               private location: Location,
               private foodOrdersService: FoodOrderProvider,
               private commentsService: CommentsProvider,
               public titleService: TitleService ) { }

  ngOnInit() {
    this.route.queryParams.subscribe( params => {
      this.browsingHistoryBackEnabled = !!params.allowBack;
    })
    this.route.paramMap.subscribe(params => {
      this.clientId = parseInt(params.get('id'), 10)
      this.clientService.setClientID(this.clientId)
      // this.getImages(this.client)

      if (!environment.production) return

      this.loadDailyCheckins();
    })
  }

  ngOnDestroy() : void {
    this.clientService.setClient({})
    this.transactionsService.setTransactions([])
    this.commentsService.setClientComments([])
    this.commentsService.setRepoComments([])
    this.commentsService.setFinanceComments([])
    this.programsService.setPrograms([])
    this.remindersService.setReminders([])
    this.foodOrdersService.setFoodOrders([])
  }

  ionViewDidEnter() {
    this.validatePresenceOfClient();
  }


  /**
   * Intelligent back function that uses various techniques to determine what 'back' should mean.
   */
  goBack() {
    if (this.routeTracingService.shouldNavigateBackToManagerDashboard) {
      this.navCtrl.navigateBack(this.routeTracingService.previousManagerUrl)
    } else {
      if (this.browsingHistoryBackEnabled) {
        const url = this.routeTracingService.previousUrl
        if (url) {
          this.navCtrl.navigateBack(url)
        } else {
          this.location.back()
        }
      } else {
        this.router.navigate(['/clients'])
      }
    }
  }



  loadDailyCheckins() {
    const endDate = moment().format('YYYY-MM-DD')
    const startDate = moment().subtract(3, 'weeks').format('YYYY-MM-DD')

    this.dailyCheckInsService.fetchDailyCheckIns(this.clientId, startDate, endDate, 'question', 1).then(
      data => console.log(data)
    )
  }


  /**
   * If the client has a balance and the user chooses to accept a payment at this time, open a payment modal
   * with an optional amount.
   * @param amount Optional starting amount for the payment
   */
  async handlePayment(amount: number = null) {
    this.transactionsService.fetchTransactions( this.clientId ).subscribe(
      async() => {
        const transaction = await this.modalCtrl.create({
          component: NewTransaction,
          componentProps: {
            client_id: this.client.id,
            mode: 'paymentMode',
            amount,
            paymentOnly: true
          }
        })

        await transaction.present()
      })
  }


  /**
   * Checks if a client has a balance and opens an alert if there is a balance providing the user options
   * to accept payment.
   */
  async checkForBalance() {
    const client = this.clientService.getClient()
    const finalCost = this.transactionsService.getBalance()
    const amount = client.default_payment_amount || 315

    if (finalCost > 0) {
      const values = [{name: `Pay in Full: $${finalCost}`, val: 'pay in full'}]

      if (finalCost >= 315) {
        values.push(
          {name: `Weekly Payment: $${amount}`, val: 'weekly'},
          {name: 'Other', val: 'other'}
        )
      } else {
        values.push({name: 'Other', val: 'other'})
      }

      const balanceModal = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          urgent: true,
          type: 'custom-prompt',
          title: client.name + ' owes $' + finalCost,
          subtitle: 'Please collect payment before their appointment',
          values
        },
        cssClass: 'small-modal'
      })

      await balanceModal.present();

      await balanceModal.onDidDismiss().then(val => {
        if (val?.data) {
          if (val.data.value === 'pay in full') {
            this.handlePayment(finalCost)

          } else if (val.data.value === 'weekly') {
            this.handlePayment(amount)

          } else if (val.data.value === 'other') {
            this.handlePayment()
          }
        }
      })
    }
  }


  /**
   * Returns a value for the BehaviorSubject client.
   */
  getClient() : Client {
    return this.client
  }


  /**
   * Check for presence of client.  If present, do nothing.
   * If not present, fetch from server
   */
  validatePresenceOfClient() {
    if ( Object.keys(this.clientService.getClient()).length === 0 ) {
      this.clientService.fetchClient(this.clientId).subscribe(
        data => {
          this.client = new Client(data)
          this.hasCoachCareVisits = data.has_coach_care_visits
          // this.calcClientAlerts(data)
          this.remindersService.setReminders(data.reminders)
          this.checkForBalance()
        },
        error => {
          this.globals.handleResponse(`Error locating client: ${error.error}`)
          this.navCtrl.navigateRoot('/clients')
        }
      )
    } else {
      const client = this.clientService.getClient()
      this.client = client
      this.hasCoachCareVisits = client.has_coach_care_visits
      this.remindersService.setReminders(client.reminders)
      this.checkForBalance()
    }
  }


  /**
   * Select the tab
   * @param tabName The tab to be selected
   */
  selectTab(tabName: string) {
    this.tabs.select(tabName)
  }
}
