import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IReferrer } from '../models/referrer';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from '../globals.service';
import { log } from 'console';

@Injectable({
  providedIn: 'root'
})
export class ReferrersService {
  referrers = new BehaviorSubject<IReferrer[]>([]);
  referrers$ = this.referrers.asObservable();

  constructor(private http: HttpClient,
              private globals: GlobalsService) {}

  setReferrers(referrers: IReferrer[]) {
    this.referrers.next(referrers);
  }

  getReferrers() {
    let referrers = this.referrers.getValue();
    if (referrers.length === 0) {
      this.fetchReferrers().subscribe(referrers => {
        console.log('referrers', referrers)
        this.setReferrers(referrers);
        return this.getReferrers()
      })
    } else {
      return referrers;
    }
  }

  fetchReferrers(): Observable<IReferrer[]> {
    return this.http.get<IReferrer[]>(`${this.globals.base_url}/referrers`);
  }
}
