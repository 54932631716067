import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '../../../../pipes/pipes.module';
import {MomentModule} from 'ngx-moment';
import {SharedModule} from '../../../../shared/shared.module';
import {DocumentsPage} from './documents';
import {RouterModule, Routes} from '@angular/router';
import {ShowDocumentModal} from './show-document.modal';
import {ESignatureModule} from '../../../../components/e-signature/e-signature.module';
import {SendForSignatureModule} from '../../../../components/send-for-signature/send-for-signature.module';
import {SignedDocumentSelectorModule} from '../../../../components/signed-document-selector/signed-document-selector.module';
import {CommonCardModule} from '../../../../components/common-card/common-card.module';
import {ListItemComponentModule} from '../../../../components/list-item/list-item.module';
import {AwakenPopoverComponentModule} from '../../../../components/awaken-popover/awaken-popover.module';
import {PdfViewerModule} from 'ng2-pdf-viewer';

const routes: Routes = [
  {
    path: '',
    component: DocumentsPage
  }
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    ESignatureModule,
    SendForSignatureModule,
    SignedDocumentSelectorModule,
    MomentModule,
    SharedModule,
    RouterModule.forChild(routes),
    AwakenPopoverComponentModule,
    CommonCardModule,
    ListItemComponentModule,
    PdfViewerModule
  ],
  entryComponents: [
    ShowDocumentModal,
  ],
  declarations: [
    DocumentsPage,
    ShowDocumentModal
  ],
  providers: [
  ],
  exports: [
    RouterModule
  ]
})
export class DocumentsPageModule {}
