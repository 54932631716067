import {Injectable, OnDestroy} from '@angular/core';
import * as _ from 'lodash';
import {Storage} from '@ionic/storage';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {combineLatest} from 'rxjs/index';
import {ClientService} from '../pages/clients/clients.service';
import {GlobalsService} from '../globals.service';
import {Comment, IComment} from '../models/comment';
import {tap} from 'rxjs/operators';
import {CheckInsProvider} from './check-ins.service';
import {TransactionsProvider} from '../pages/clients/client-detail/providers/finances/transactions.service';

@Injectable({
  providedIn: 'root'
})
export class CommentsProvider implements OnDestroy {
  employees: any = [];
  auth_token: string;
  base_url: string;

  private client$: Observable<any>;

  private comments = new BehaviorSubject([]);
  comments$: Observable<any[]> = this.comments.asObservable()

  private client_comments  = new BehaviorSubject(<IComment[]>[]);
  client_comments$ = this.client_comments.asObservable()

  private repo_comments = new BehaviorSubject(<IComment[]>[]);
  repo_comments$ = this.repo_comments.asObservable()

  private finance_comments = new BehaviorSubject(<IComment[]>[]);
  finance_comments$ = this.finance_comments.asObservable()

  private financeAndClientComments = new BehaviorSubject(<IComment[]>[]);
  financeAndClientComments$ = this.financeAndClientComments.asObservable()
  financeAndClientCommentsSubscription: Subscription;
  clientSubscription: Subscription;

  constructor( private clientService: ClientService,
               private storage: Storage,
               private http: HttpClient,
               private global: GlobalsService,
               private checkInsProvider: CheckInsProvider) {

    this.base_url = this.global.base_url

    this.storage.ready().then( () => {
      this.storage.get('employees').then( val => {
        this.employees = val
      })
      this.storage.get("auth_token").then(val => {
        this.auth_token = val
      })
    })
    this.subscribeToClient()
    this.initializeFinanceAndClientComments()
  }

  ngOnDestroy(): void {
    console.log("destroy comments service")
    if ( this.financeAndClientCommentsSubscription ) this.financeAndClientCommentsSubscription.unsubscribe()
    if (this.clientSubscription) this.clientSubscription.unsubscribe()
  }

  subscribeToClient() {
    this.clientSubscription = this.clientService.client$.subscribe( client => {
      console.log("New Client: CommentsProvider")
      this.setClientComments(client.comments)
      this.setRepoComments(client.repo_comments || [])
      this.setFinanceComments(client.finance_comments)
    })
  }

  initializeFinanceAndClientComments() {
    this.financeAndClientCommentsSubscription = combineLatest(this.client_comments$, this.finance_comments$).subscribe( comments => {
      // console.log("new finance and client comments", comments)
      this.setFinanceAndClientComments(_.orderBy(_.flatten(comments), 'created_at', 'desc'))
    })
  }

  setFinanceAndClientComments( comments : IComment[] ) {
    this.financeAndClientComments.next(comments)
  }

  /**
   * Returns array of financeAndClientComments
   */
  getFinanceAndClientComments() {
    return Object.assign([], this.financeAndClientComments.getValue())
  }

  getType(tran) {
    let type = 'Purchase'
    if (tran.amount != null) {
      type = 'Payment'
    }
    if ( tran['commentable_type'] == 'Client') {
      type = 'Client'
    }
    return type
  }

  // Repositioning Comments ------------------------------------------------------------

  /**
   * Takes array of 'comments' and sets repo_comments.
   * @param comments
   */
  setRepoComments( comments : { description: string, employee: string, created_at: string }[] ) : void {
    let formattedComments = comments.map( ci => {
      let c = new Comment({
        body: ci.description,
        created_at: ci.created_at,
        commentable_type: 'Repositioning',
        employee: ci.employee
      })
      return c
    })
    this.repo_comments.next(formattedComments)
  }


  // Client Comments ----------------------------------------------------------------------

  setClientComments(comments: Comment[]) {
    if (comments) {
      this.client_comments.next(comments.reverse())
    }
  }

  getClientComments() : IComment[] {
    return Object.assign([], this.client_comments.getValue())
  }

  // Finance Comments ----------------------------------------------------------------------

  // getComments() : Observable<any[]> {
  //   if (_.isEmpty(Object.assign([], this.comments.getValue()))) {this.initializeComments()}
  //   return this.comments$
  // }

  getFinanceComments() : IComment[] {
    return Object.assign([], this.finance_comments.getValue())
  }

  setFinanceComments( comments : IComment[] ) : void {
    this.finance_comments.next( comments )
  }

  /**
   * Remove the supplied comment from financeAndClientComments
   * @param comment
   */
  removeComment(comment: Comment) {
//     From goals-programs.  Keeping in case needed!
//     if (comment.commentable_type === "Client") {
//       let comments = this.getClientComments()
//       _.remove(comments, {id: comment.id})
//       this.setClientComments(comments)

//     } else {
//       let comments = this.getFinanceAndClientComments()
//       _.remove(comments, { id: comment.id })
//       this.setFinanceAndClientComments(comments)
//     }
    let comments = this.getFinanceAndClientComments()
    _.remove(comments, { id: comment.id })
    this.setFinanceAndClientComments(comments)
  }


  addComment(comment) {
    let commentArray = _.cloneDeep(this.comments.getValue());
    commentArray.unshift(comment)
    this.comments.next(commentArray)
  }


  addFinancialComment(comment: IComment) {
    let comments = this.getFinanceComments()
    comments.push(comment)
    this.setFinanceComments(comments)
  }

  deleteComment(comment: Comment, perform_remove: boolean = true) {
    console.log(comment)
    let final_url = `${ this.base_url }/comments/${ comment.id }`
    return this.http.delete(final_url).pipe(
      tap(() => {
        if ( perform_remove ) {
          this.removeComment(comment)
        }
      })
    )
  }


  // Other ------------------------------------------------------------------------------------

  addClientComment(comment) {
    let commentArray = _.cloneDeep(this.client_comments.getValue());
    commentArray.unshift(comment)
    this.client_comments.next(commentArray)
  }

  /**
   * Submits a {@link Comment} to the server.
   * @param comment
   */
  create(comment: Comment) : Observable<Comment> {
    let final_url = `${ this.base_url }/comments`
    return this.http.post<Comment>(final_url, comment)
  }

  /**
   * Updates the value for a given {@link Comment}
   * @param comment
   */
  update(comment: Comment) : Observable<Comment> {
    let final_url = `${ this.base_url }/comments/${comment.id}`
    return this.http.put<Comment>(final_url, comment)
  }
}
