import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ClientsModule} from './pages/clients/clients.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {IonicStorageModule} from '@ionic/storage';
import {AuthInterceptorService} from './auth/auth-interceptor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoaderInterceptorService} from './loader-interceptor.service';
import {PipesModule} from './pipes/pipes.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from './shared/shared.module';
import {ReviewRequestsService} from './providers/review-requests.service';
import {UsersService} from './providers/users.service';
import {Camera} from '@ionic-native/camera/ngx';
import {File} from '@ionic-native/file/ngx';
import {Crop} from '@ionic-native/crop/ngx';
import {NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetModule} from 'ngx-zendesk-webwidget';
import {ImageUploadModule} from './components/image-upload/image-upload.module';
// import {ZendeskConfig} from './providers/zendesk.config';
import {BarcodeScanner} from '@awesome-cordova-plugins/barcode-scanner/ngx';
// import * as Sentry from '@sentry/angular';
import {WebView} from '@ionic-native/ionic-webview/ngx';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {SentryIonicErrorHandler} from './providers/sentry.service';
import {TicketModalComponent} from './components/ticket-modal/ticket-modal.component';
import {RecoverLostRepositioningPageModule} from './pages/recover-lost-repositioning/recover-lost-repositioning.module';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {TestModule} from './components/test/test.module';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import { AlertDirective } from './directives/alert.directive';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = 'awaken180weightloss.zendesk.com';
  callback(zE) {
    zE.setLocale('en');
    zE('webWidget', 'hide')
  }
}

// import {Injector} from "@angular/core";
//
// export class ServiceLocator {
//   static injector: Injector;
// }

@NgModule({
  declarations: [
    AppComponent,
    TicketModalComponent,
    AlertDirective,
  ],
  imports: [
    PipesModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,

    IonicModule.forRoot({
      mode: 'ios',
      // navAnimation: simpleTransitionAnimation,
    }),

    IonicStorageModule.forRoot(),
    AppRoutingModule,
    ClientsModule,
    RecoverLostRepositioningPageModule,
    HttpClientModule,
    TestModule,
    SharedModule,
    ImageUploadModule,
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    InAppBrowser,
    File,
    BarcodeScanner,
    ScreenOrientation,
    Title,
    WebView,
    Crop,
    ReviewRequestsService,
    UsersService,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: SentryIonicErrorHandler
    }
  ],
  schemas: [
    // CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    AlertDirective,
    // ComponentHostDirective
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
