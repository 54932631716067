import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GlobalsService} from '../globals.service';
import {Consultation, ConsultationAppointment} from '../models/consultation';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Storage} from '@ionic/storage';
import {AuthService} from '../auth/auth.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import {ClientService} from '../pages/clients/clients.service';

@Injectable(
  {
  providedIn: 'root'
})

export class ConsultationProvider {

  base_url: string;

  private consultation = new BehaviorSubject(<Consultation>{});
  consultation$ = this.consultation.asObservable();

  private consultations = new BehaviorSubject(<ConsultationAppointment[]>[]);
  consultations$ = this.consultations.asObservable();

  constructor( private globalVarService: GlobalsService,
               private http: HttpClient,
               private authService: AuthService,
               private clientService: ClientService,
               private storage: Storage ) {

    this.base_url = this.globalVarService.base_url;
  }

  /**
   * Sets the oberservable value for consultation.
   * @param consultation
   */
  setConsultation(consultation: Consultation) {
    if (!consultation.comment_attributes || consultation.comment_attributes?.employee_id == null) {
      consultation.comment_attributes = {
        body: '',
        employee_id: this.authService.getCurrentUser().employee?.id,
      }
    }

    this.consultation.next(consultation)
  }


  /**
   * Returns the value of the observable {@link consultation}
   */
  getConsultation(): Consultation {
    return Object.assign({}, this.consultation.getValue())
  }

  /**
   * Returns an array of consultations based on query parameters.
   * Sets observable value of consultations to the returned data.
   * @param query_params
   * @example index(start_date=2019-01-01&end_date=2019-01-31&location=1)
   */
  index(query_params : string = null) : Observable<Consultation[]> {
    let final_url = `${this.base_url}/consultations`;
    if ( query_params ) final_url += `?${ query_params }`;

    return this.http.get<Consultation[]>(final_url).pipe(
      map ( consults => {
        let new_data = [];

        for (let c of consults) {
          let con = new ConsultationAppointment({
            consultation: c,
            name: c.name
          });
          if ( !con.name || con.consultation.name ) {
            con.consultation.mindbody_id = parseInt(con.consultation.unique_mb.toString().substring(1))
          }
          new_data.push(con)
        }

        return new_data
      }),
      tap( consults => {
        console.log(consults.map(c => c.consultation.consultant_id))
        this.consultations.next( consults )
      })
    )
  }

  /**
   * Fetches a consultation from the server with the provided ID.
   * @param id Consultation ID
   */
  get(id: number) : Observable<Consultation> {
    const url =  `${ this.base_url }/consultations/${ id }`;

    return this.http.get<Consultation>(url).pipe(
      tap( consult => this.setConsultation(consult) )
    )
  }

  /**
   * Creates a consultation in the server and sets the observable value to that consultation.
   * @param consultation
   */
  create(consultation: Consultation) {
    let final_url = `${this.base_url}/consultations`;

    return this.http.post<Consultation>(final_url, consultation).pipe(
      tap( consult => this.setConsultation(consult) )
    )
  }

  /**
   * Updates the consultation in the server and sets the observable value to that consultation.
   * @param consultation
   */
  update(consultation: Consultation) {
    console.log("Updating Consultation: ", consultation);
    let final_url = `${ this.base_url }/consultations/${ consultation.id }`;

    return this.http.put<Consultation>(final_url, consultation).pipe(
      tap( consult => {
        this.setConsultation(consult)
        this.updateScheduleConsult(consult)
      })
    )
  }

  /**
   * Checks storage for a matching consultation and then updates it with the provided consultation.
   * Updates the {@link clientService.clients$} value as well.
   * @param consultation
   */
  updateScheduleConsult(consultation: Consultation) : void {
    // What if storage doesn't have the consult but clientService.clients$ does?

    let location_id = this.authService.getCurrentUser().location_id
    let date = moment().format("YYYY-MM-DD")
    let query = `${location_id}_${date}`
    console.log(query)
    this.storage.get(query).then( data => {
      let matchedConsultation = null
      console.log("data", data)

      if (data) {
        console.log(data, consultation.id)
        if (location_id != 3) {
          matchedConsultation = _.find(data['Consultation'], function (o) {
            return o.consultation_id === consultation.id
          })
          if (!matchedConsultation) {
            matchedConsultation = _.find(data['Post Consultation'], function (o) {
              return o.consultation_id === consultation.id
            })
          }
        } else {
          matchedConsultation = _.find(data['World-Wide Consultation'], function (o) {
            return o.consultation_id === consultation.id
          })
          if (!matchedConsultation) {
            matchedConsultation = _.find(data['World-Wide Post Consultation'], function (o) {
              return o.consultation_id === consultation.id
            })
          }
          if (!matchedConsultation) {
            matchedConsultation = _.find(data['World-Wide Info Call'], function (o) {
              return o.consultation_id === consultation.id
            })
          }
        }
      }

      if ( matchedConsultation ) {
        console.log("matched consultation", matchedConsultation)
        matchedConsultation.consultation = consultation
        matchedConsultation.location_id = consultation.location_id
        matchedConsultation.status = 'Completed'
        matchedConsultation.id = consultation.client_id
        this.storage.set(query, data)
        this.clientService.setClients(data)
      }
    })
  }

  /**
   * Fetches a consultation by the unique mb.  Does NOT set the observable value.
   * @param unique_mb
   */
  fetchByUniqueMB( unique_mb : number ) : Observable <Consultation | null> {
    let url = `${ this.base_url }/consultations/find_by_unique_mb?unique_mb=${ unique_mb }`;
    return this.http.get(url)
  }
}
