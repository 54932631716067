import {Injectable, Input} from '@angular/core';
import {Platform} from '@ionic/angular';
import {BarcodeScanner} from '@awesome-cordova-plugins/barcode-scanner/ngx';

@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerService {
  @Input() startingBarcodeData: any;
  @Input() keyMapping: any;

  barcodeData: any;
  unmappedData: any;

  constructor(public plt: Platform,
              private scanner: BarcodeScanner) {

    // this.determinePlatform().then(
    //   () => this.scanBarcode(),
    //   error => console.warn(error)
    // )
  }


  /**
   * Determines whether the device is an ipad (w/ scanning capabilities) or
   * another device (w/o scanning capabilities).
   */
  async determinePlatform(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.plt.ready().then(() => {
        if ( !this.plt.is('ipad') ) {
          reject("Barcode scanner is not available on your device.")
        }
        resolve(null)
      })
    })
  }


  /**
   * Opens the barcode scanner and assigns the result to {@link BarcodeScannerComponent#barcodeData}
   */
  async scanBarcode(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.plt.ready().then(() => {
        if ( !this.plt.is('ipad') ) {
          this.scanner.scan().then(barcodeData => {
            this.barcodeData = barcodeData.text

            if (barcodeData['cancelled'] == true) {
              reject("Scan cancelled by User.")
            }

            resolve(this.barcodeData)
          },
          error => {
            reject("We experienced in error when attempting to scan the barcode.")
            console.log(error)
          });
        }
      })
    })

  }
}
