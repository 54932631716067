import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private title = new BehaviorSubject<string>('')
  title$ = this.title.asObservable()
  hideTitle = false;
  transparentHeader = false;
  hideToolbar = false;
  isScrolled = new BehaviorSubject<boolean>(false)
  isScrolled$ = this.isScrolled.asObservable()
  darkMode = true;

  constructor(private browserTitleService: Title) { }


  /**
   * Sets the title for the page.
   * @param title: The new title to be set
   */
  setTitle( title : string ) : void {
    this.title.next(title)
    this.browserTitleService.setTitle(title)
    this.hideTitle = false;
  }
}
