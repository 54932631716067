import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PaginationComponent } from './pagination.component';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule,],
  declarations: [PaginationComponent],
  exports: [PaginationComponent]
})
export class PaginationComponentModule {}
