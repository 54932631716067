import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalsService} from './globals.service';
import {catchError, tap} from 'rxjs/operators';
import {ModalController} from '@ionic/angular';
import {LoginModal} from './auth/login-modal/login-modal.component';
import {AuthService} from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
  ignoreLoader: any[] = [
    "http://api.lvh.me:3000/api/daily_visits",
    "https://api.awaken180weightloss.com/api/daily_visits",

    "http://api.lvh.me:3000/api/locations",
    "https://api.awaken180weightloss.com/api/locations",

    "http://api.lvh.me:3000/api/employees",
    "https://api.awaken180weightloss.com/api/employees",

    "http://api.lvh.me:3000/api/referral_cards/search",
    "https://api.awaken180weightloss.com/api/referral_cards/search",
  ];

  doNotIntercept: string[] = [
    "/locations",
    "/document-signing",
    "/document-signing/documents",
    "/test",
    "/login",
  ];

  constructor( private auth: AuthService,
               private global: GlobalsService,
               private modalCtrl: ModalController) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if ( this.ignoreLoader.indexOf(request.url) == -1 && request.url.indexOf("loader=false") == -1) {
      this.global.showLoader(true)
    }

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (this.ignoreLoader.indexOf(event.url) == -1) {
            this.global.hideLoader();
          }
        }
        return event
      }),
      catchError((err: any) => {
        console.log(err, request)
        this.global.hideLoader();
        if (err && err.error && typeof err.error == 'string') {
          err.error = err.error.replace(/"|'|\[|\]/g, '');
        }
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            let modals = document.getElementsByClassName("login-modal")
            if ( modals.length == 0 && this.doNotIntercept.indexOf(window.location.pathname) === -1 ) {
              if (!this.auth.authModalIsOpen) {
                console.log("About to present auth modal.  Is it already open?", this.auth.authModalIsOpen)
                this.presentModal()
              }
            }
            return next.handle(request)

          } else {
            return next.handle(request)
          }
        }
      })
    )
  }

  presentModal() {
    this.modalCtrl.create({
      component: LoginModal,
      backdropDismiss: false,
      componentProps: {
        user: this.auth.getUser()
      },
      cssClass: "small-modal"
    }).then(modal => {
      modal.present()
      this.auth.authModalIsOpen = true
    })
  }
}
