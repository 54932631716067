import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IReminder, Reminder} from '../../models/reminder';
import {GlobalsService} from '../../globals.service';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReminderService {
  private reminders = new BehaviorSubject<Reminder[]>([])
  reminders$ = this.reminders.asObservable()

  constructor(private http: HttpClient,
              private globals: GlobalsService) {
    console.log(this)
  }

  setReminders(reminders: IReminder[] | Reminder[]) {
    this.reminders.next(reminders.map(reminder => new Reminder(reminder)))
  }


  getReminders() {
    return Object.assign([], this.reminders.getValue())
  }


  /**
   * Creates a reminder in the database
   * @param reminder
   */
  createReminder(reminder: IReminder): Observable<IReminder> {
    let url = `${this.globals.base_url}/${reminder.remindable_type.toLowerCase()}s/${reminder.remindable_id}/reminders`

    return this.http.post<IReminder>(url, reminder).pipe(
      tap(reminder => {
        this.setReminders([...this.getReminders(), reminder])
      })
    )
  }


  updateReminder(reminder: IReminder): Observable<IReminder> {
    let url = `${this.globals.base_url}/${reminder.remindable_type.toLowerCase()}s/${reminder.remindable_id}/reminders/${reminder.id}`

    return this.http.put<IReminder>(url, reminder).pipe(
      tap(reminder => {
        let existingReminders = this.getReminders()
        let reminderIndex = existingReminders.findIndex(existingReminder => existingReminder.id === reminder.id)
        existingReminders.splice(reminderIndex, 1, reminder)
        this.setReminders(existingReminders)
      })
    )
  }


  destroyReminder(reminder: IReminder): Observable<IReminder[]> {
    let url = `${this.globals.base_url}/${reminder.remindable_type.toLowerCase()}s/${reminder.remindable_id}/reminders/${reminder.id}`

    return this.http.delete<IReminder[]>(url).pipe(
      tap(reminders => {
        this.setReminders(reminders)
      })
    )
  }
}
