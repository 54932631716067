<h3 class="text-white text-center" style="line-height: 1.5em;" [innerHTML]="status"></h3>

<ion-card class="bg-white">
  <ion-card-content>
    <ion-toolbar>
      <ion-searchbar
        [(ngModel)]="clientSearch"
        [showCancelButton]="true"
        (ionChange)="manualSearchForHealthProfile($event)"
      ></ion-searchbar>
    </ion-toolbar>

    <ion-list lines *ngIf="records.length > 0">
      <hp-row *ngFor="let record of records"
              [record]="record"
              [client_id]="client.id"
              (clientSelected)="clientSelected($event)"
      ></hp-row>
    </ion-list>
  </ion-card-content>
</ion-card>

<ion-row class="ion-justify-content-center">
  <ion-col class="ion-text-center">
    <ion-button fill="clear" color='danger' (click)="cancel()">{{exitText || 'Cancel / Skip'}}</ion-button>
  </ion-col>
</ion-row>
