import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModalComponent } from './common-modal.component';
import {ComponentHostDirective} from '../../component-host.directive';

@NgModule({
  declarations: [
    ComponentHostDirective,
    CommonModalComponent
  ],
  entryComponents: [
    CommonModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports: [
    CommonModalComponent
  ]
})
export class CommonModalModule { }
