<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button menu="myMenu"></ion-menu-button>
    </ion-buttons>
    <ion-title>Awaken180° Clients</ion-title>
    <ion-buttons slot="end">
      <logout-component [color]="'light'"></logout-component>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content id="xyxy">

  <ion-grid style="position:fixed;top: 44px;width:100%;left:0px;z-index:10;background:white;border-bottom:1px solid #ddd;border-top:1px solid #ddd;height:75px;">
    <client-index-search
      *ngIf="!loadingLocations"
      [date]="date"
      [location]="location"
      (refresh)="refresh($event)"
      (isSearching)="isSearching = true"
      (cancelSearch)="lookForClients()"
      (searchMade)="isSearching = true"
      (sortClients)="clientService.sortClients($event)"
    ></client-index-search>
  </ion-grid>

  <div *ngIf="!isLoading && !loadingLocations" style="margin-top: 75px;">

    <ng-container *ngIf="isSearching; else schedule">
      <div *ngIf="( clientService.searchResult$ | async ).length > 0">
        <ion-list class="awaken-striped">
          <ion-list-header class="bg-white awaken-header">
            <ion-label class="mt-2">Search Results</ion-label>
          </ion-list-header>

          <client-index-row
            *ngFor="let client of clientService.searchResult$ | async"
            [client]="client"
            [isSearching]="true"
            (selectClient)="loadClient($event)"
            (loadClientByUniqueMb)="loadClientByUniqueMb($event)"
            (deleteClient)="deleteClient($event)"
          ></client-index-row>

        </ion-list>
      </div>
    </ng-container>

    <ng-template #schedule>
      <div [@listAnimation2]="(clientService.clients$ | async | keyvalue).length">
        <ion-list *ngFor="let clientGroup of clientService.clients$ | async | keyvalue; let i = index;" class="awaken-striped" >
          <ion-list-header class="animateMe bg-white awaken-header" [ngStyle]="{'z-index': (i+1)}">
            <ion-label class="mt-3">{{ clientGroup.key }}</ion-label>
          </ion-list-header>

          <client-index-row
            *ngFor="let client of clientGroup.value"
            [client]="client"
            [type]="clientGroup"
            (selectClient)="loadClient($event)"
            (inviteClient)="inviteClient($event)"
            (loadClientByUniqueMb)="loadClientByUniqueMb($event)"
            (deleteClient)="deleteClient($event)"
          ></client-index-row>

        </ion-list>
      </div>
    </ng-template>
  </div>

  <div *ngIf="isLoading || loadingLocations" style="margin-top: 75px;">
    <ion-list class="awaken-striped">
      <ng-container *ngFor="let x of Array(3).fill(0)">
        <ion-list-header class="bg-white awaken-header">
          <ion-label color="danger" class="mx-auto mt-3">
            <h3>
              <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </h3>
          </ion-label>
        </ion-list-header>

        <ion-item *ngFor="let x of Array(10).fill(0)">
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-label>
        </ion-item>
      </ng-container>
    </ion-list>
  </div>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed" (click)="inviteNewClient()" class="addClient">
    <ion-fab-button color="success" style="margin:0 15px 15px 0;" >
      <ion-icon style="font-weight:900;font-size:3rem;" name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

</ion-content>
